import React, { Fragment, useState } from 'react';
import compose from 'recompose/compose';
import FileSaver from 'file-saver';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import moment from 'moment';
// import PrintIcon from '@mui/icons-material/Print';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Route } from 'react-router';

import { 
    List, 
    TextField, 
    TimeField,
    SelectField,
    Drawer
} from '../_Components';

import { authHeader, notify } from '../_utils';

import Filter from './components/ReportsFilter';
// import SettingsButton from './components/SettingsButton';
// import GeneratorButton from './components/GeneratorButton';

import Utils from './components/utils';
import GeneratorModal from './components/GeneratorModal2';
import PaySheet from './PaySheet';
import PaySheetEdit from './PaySheetEdit';

const { operatorsTypes, places, departments, reportsStatus } = Utils.options();

const Buttons = ({openDialog, openExport, isLoading}) => {
    return (
        <Fragment>
            <Button    
                color="primary" 
                size="small"
                variant="contained"
                endIcon={<GetAppIcon />}
                disabled={isLoading}
                onClick={openExport} 
            >
                Экспорт в Excel
            </Button>
            <Button    
                color="primary" 
                size="small"
                variant="contained"
                endIcon={<LocalAtmIcon />}
                disabled={isLoading}
                onClick={openDialog} 
            >
                Рассчетники
            </Button>
        </Fragment>
    );

}

const  OperatorsReportsList = ({ formValues, perPage, page, ...props }) => {
    const [open, setOpen] = useState(false);
    // const [openShow, setOpenShow] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isGenerated, setIsGenerated] = useState(false);  
    const openDialog = () => {
        setOpen(true);
    }

    const handleCloseClick = () => {
        setOpen(false);
        setIsGenerated(false);
        setIsSaving(false);
    }


    const  save = async () => {
        setIsSaving(true)
        const response = await fetch('/api/operators/reports/generator', {
            credentials: 'same-origin',
            method: 'post',
            headers: {...authHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify({ ...formValues })
        })
        if(response.ok){
            setIsGenerated(true);
            setIsSaving(false);
            setOpen(false)
        }else{
            setIsGenerated(false);
            setIsSaving(false);
            setOpen(false)
            notify({message: "Ошибка при экспорте отчета", type: 'error'});
        }
    }

    const exportReport = async () => {
        setIsSaving(true);
        const response = await fetch('/api/operators/reportsXLSX', {
            credentials: 'same-origin',
            method: 'post',
            responseType: 'arraybuffer',
            headers: {...authHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify({...formValues })
        })
        setIsSaving(false);
        if(response.ok){
            response.arrayBuffer().then(function(buffer) {
                var blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                FileSaver.saveAs(blob, `Отчет по операторам ${moment(formValues.period).format("MMMM YYYY")}.xlsx`);
            });
        }else{
            notify({message: "Ошибка при экспорте отчета", type: 'error'});
        }
    }

    // const handleCloseShow = () => {
    //     // setOpenShow(false);
    // }

    return (
        <Fragment>
            <Route path="/operators/reports/:id/:action">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params
                    );
                    return (
                        <Fragment>
                            <List
                                refresh={true}
                                clear={true}
                                show={true}
                                basePath='operators/reports'
                                actions={<Buttons openDialog={openDialog} openExport={exportReport}/>}
                                filters={<Filter form="operatorsReportsForm" />}
                                filterDefaultValues={formValues}
                                pathToShow={`/show`}
                                perPage={perPage}
                                page={page}
                                deletedField='deleted'
                                sort={{ field: 'time', order: 'ASC' }} 
                            >   
                                <TextField
                                    notSort={true}
                                    source="fullname"
                                    label="Имя"
                                />
                                <TextField
                                    notSort={true}
                                    source="login"
                                    label="Логин"
                                />
                                <TextField
                                    notSort={true}
                                    source="period"
                                    label="Период"
                                />
                                <SelectField
                                    notSort={true}
                                    options={operatorsTypes}
                                    source="type"
                                    label="Тип"
                                />
                                <SelectField
                                    notSort={true}
                                    source="place"
                                    options={places}
                                    label="Площадка"
                                />
                                <SelectField
                                    notSort={true}
                                    options={departments}
                                    source="department"
                                    label="Отдел"
                                />
                                <TimeField
                                    notSort={true}
                                    source="totalTime"
                                    label="Общее время"
                                />
                                <TimeField
                                    notSort={true}
                                    source="paidTime"
                                    label="Время опл."
                                />
                                    <TimeField
                                    notSort={true}
                                    source="talkTime"
                                    label="В разговре"
                                />
                                <TextField
                                    notSort={true}
                                    source="occPercent"
                                    label="OCC"
                                />
                                <TextField
                                    notSort={true}
                                    source="premia"
                                    label="Премия"
                                />
                                <TextField
                                    notSort={true}
                                    source="summa"
                                    label="Сумма"
                                />
                                <SelectField
                                    notSort={true}
                                    options={reportsStatus}
                                    source="status"
                                    label="Статус"
                                />
                                {/* <PrintIcon 
                                    label=""
                                    style={{ color: '#0b2b60', cursor: 'pointer' }} 
                                    source="id" 
                                    onClick={(el, a, b) => console.log(el, a, b)}
                                />
                                <DeleteForeverIcon 
                                    label=""
                                    style={{ color: 'red', cursor: 'pointer' }} 
                                    source="id" 
                                    onClick={(el, a, b) => console.log(el, a, b)}
                                /> */}
                            </List>
                            {(isMatch&&match.params.action === 'show') ? (
                                <Drawer
                                    isMatch={isMatch}
                                    // handleClose={handleCloseShow}
                                    content={isMatch ? <PaySheet id={match.params.id} /> : null}
                                />
                            ) : null}
                            {(isMatch&&match.params.action === 'edit') ? (
                                <Drawer
                                    isMatch={isMatch}
                                    // handleClose={handleCloseShow}
                                    content={isMatch ? <PaySheetEdit id={match.params.id} /> : null}
                                />
                            ) : null}
                            
                        </Fragment>
                    );
                }}
            </Route>
            <GeneratorModal handleSaveClick={save} open={open} handleCloseClick={handleCloseClick} isSaving={isSaving} isGenerated={isGenerated}/>
        </Fragment>
    );
}

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('operatorsReportsForm')(state);
    let pairs = window.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    const period = moment().subtract(1, 'month').startOf("month");
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { period },
    };
};

export default compose(
    connect(mapStateToProps),
)(OperatorsReportsList);
