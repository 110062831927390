import React, { Fragment, useEffect, useState } from 'react';

import { 
    TextField,
    DateField,
    SelectField,
    Labeled,
    CardElement,
    Row,
    CloseButton,
    EditButton
} from '../_Components';

import {GET_ONE, provider, history } from '../_utils';
import Utils from './components/utils';

const { places, statuses, operatorsTypes, contractTypes, departments } = Utils.options();

const OperatorData = ({ data }) => (
    <Fragment>
        <p><b>{data && data.login} {data && data.fullname}</b></p>
        <p>Персональные даннные</p>
        {data&&
            <Fragment>
                <Row>
                    <Labeled label="Тел. моб" style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.phone ? data.contact.phone.mobile : null}/>
                    </Labeled>
                    <Labeled label="Тел. др." style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.mobile2 ? data.contact.phone.mobile2 : null}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="Тел. дом" style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.home ? data.contact.phone.home : null}/>
                    </Labeled>
                    <Labeled label="E-mail" style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.email ? data.contact.email : null}/>
                    </Labeled>
                </Row>
            </Fragment>
        }
        <p>Операторские данные</p>
        {data&&
            <Fragment>
                <Row>
                    <Labeled label="Статус" style={{width: 250}}>
                        <SelectField options={statuses} value={data.status}/>
                    </Labeled>
                    <Labeled label="Тип" style={{width: 250}}>
                        <SelectField options={operatorsTypes} value={data.type}/>
                    </Labeled>
                    <Labeled label="Отдел" style={{width: 250}}>
                        <SelectField options={departments} value={data.department}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="Площадка" style={{width: 250}}>
                        <SelectField options={places} value={data.place}/>
                    </Labeled>
                    <Labeled label="Мотивация" style={{width: 250}}>
                        <TextField value={data.salary ? data.salary.name : "-"}/>
                    </Labeled>
                </Row>
            </Fragment>
        }
        <p>Контракт</p>
        {data&&
            <Fragment>
                <Row>
                    <Labeled label="Компания" style={{width: 250}}>
                        <TextField value={data.company ? data.company.name : null}/>
                    </Labeled>
                    <Labeled label="Тип" style={{width: 250}}>
                        <SelectField options={contractTypes} value={data.contract ? data.contract.type : null}/>
                    </Labeled>
                    <Labeled label="Номер" style={{width: 250}}>
                        <TextField value={data.contract ? data.contract.no : null}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="От" style={{width: 250}}>
                        <DateField value={data.contract ? data.contract.entered: null}/>
                    </Labeled>
                    <Labeled label="До" style={{width: 250}}>
                        <DateField value={data.contract ? data.contract.termitated : null}/>
                    </Labeled>
                    <Labeled label="Продлен" style={{width: 250}}>
                        <DateField value={data.contract ? data.contract.extended : null}/>
                    </Labeled>
                </Row>
            </Fragment>
        }
        <p>Паспортные данные</p>
        {data&&
            <Fragment>
                <Row>
                    <Labeled label="Область" style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.address ? data.contact.address.state : null}/>
                    </Labeled>
                    <Labeled label="Район" style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.address ? data.contact.address.district : null}/>
                    </Labeled>
                    <Labeled label="Нас. пункт" style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.address ? data.contact.address.city : null}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="Улица" style={{width: 250}}>
                        <TextField value={data.contact&&data.contact.address ?  data.contact.address.street : null}/>
                    </Labeled>
                    <Labeled label="Дом" style={{width: 100}}>
                        <TextField value={data.contact&&data.contact.address ?  data.contact.address.house : null}/>
                    </Labeled>
                    <Labeled label="Корп." style={{width: 100}}>
                        <TextField value={data.contact&&data.contact.address ?  data.contact.address.block : null}/>
                    </Labeled>
                    <Labeled label="Кв." style={{width: 100}}>
                        <TextField value={data.contact&&data.contact.address ?  data.contact.address.flat : null}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="Номер" style={{width: 250}}>
                        <TextField value={data.passport ? data.passport.number : null}/>
                    </Labeled>
                    <Labeled label="Личный номер" style={{width: 250}}>
                        <TextField value={data.passport ?data.passport.personal_number: null}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="Выдан" style={{width: 250}}>
                        <TextField value={data.passport ?data.passport.issue: null}/>
                    </Labeled>
                    <Labeled label="Дата выдачи" style={{width: 250}}>
                        <DateField value={data.passport ?data.passport.date_issue : null}/>
                    </Labeled>
                </Row>
            </Fragment>
        }
    </Fragment>
);

const OperatorShow = ({ id, ...params }) => {
    const [data, setData] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        const get = async () => {
            const { data, status } = await provider( 
                GET_ONE,
                '/operators', 
                id
            );
            if(status === 200 ){
                setData(data);
                setIsMounted(true);
            }
        }
    
        if(!isMounted){
            get();
        }
    }, [id, isMounted]);

    const close = () =>{
        return history.goBack();
    }

    const edit = () =>{
        return history.push(`${history.location.pathname.replace('show', 'edit')}`);
    }

    return(
        <CardElement
            // title={}
            height={'calc(100vh - 130px)'}
            action={
                <Fragment>
                    <EditButton handleEditClick={edit} />
                    <CloseButton handleCloseClick={close} />
                </Fragment>
            }
            content={
                <Fragment>
                    <CardElement
                        width={70}
                        style={{ paddingLeft: 16}}
                        content={
                            <OperatorData data={data}/>
                        }
                    />
                    <CardElement
                        width={30}
                        style={{ paddingLeft: 16}}
                    />
                </Fragment>
            }
        />
    )
}
export default OperatorShow;
