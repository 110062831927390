import React, { Fragment, useState } from 'react';

// import ImportDialog from './ImportDialog';
import CreatDialog from './CreatDialog';

import { AddButton } from '../../_Components';

const ListActions = () => {
    // const [showDialog, setShowDialog] = useState(false);
    const [openCreat, setOpenCreate] = useState(false);

    const handleClickOpen = () => {
        setOpenCreate(true);
    }

    const handleCloseCreat = () => {
        setOpenCreate(false);
    }

    // openImport () {
    //     this.setState({showDialog: true})
    // }

    // handleCloseImport () {
    //     this.setState({ showDialog: false })
    // }

    return (
        <Fragment>
            <AddButton  
                label="Создать"
                handleAddClick={handleClickOpen}
            />
            {openCreat&&
                <CreatDialog handleCloseCreat={handleCloseCreat} openCreat={openCreat} />
            }
            {/* <Button    
                className={classes.button}  
                color="primary" 
                size="small"
                variant="contained"
                startIcon={<Icon icon={uploadIcon} />}
                onClick={this.openImport} 
            >
                Импорт
            </Button>
            <ImportDialog handleCloseClick={this.handleCloseImport} showDialog={showDialog}/> */}
        </Fragment>
    );
}

export default ListActions;
