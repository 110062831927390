import React from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import {  TextInput, SelectInput, SwitchInput, RadioInput, MultiSelectInput, CardElement } from '../../_Components';
import PhoneBook from './PhoneBook';

const styles = {
    row: {
        display: 'block',
        paddingBottom: '1em'
    },
    settings: {
        flex: '30em',
        display: 'inline-flex',
        marginRight: '2em'
    },
    result: {
        flex: '30em',
        display: 'inline-flex',
        marginRight: '2em'
    },
    radio: {
        paddingRight: 30
    }
};

const EssenceOptions = [{
    id: 'lead',
    name: 'Лид',
},
{
    id: 'contact',
    name: 'Контакт',
},
{
    id: 'company',
    name: 'Компанию',
},
{
    id: 'company_contact',
    name: 'Контакт > Компанию'
},
{
    id: 'contact_lead',
    name: 'Контакт > Лид'
}];

const Entity_type = [
    {
        id: 'LEAD',
        name: 'Лид'
    },
    {
        id: 'CONTACT',
        name: 'Контакт'
    },
    {
        id: 'COMPANY',
        name: 'Компания'
    }
];

const Settings = ({classes, comboboxes, scripts, formValues , phoneFields, integration, height}) => {
    return(
        <div style={{ height: height }}>
            <div className={classes.row}>
                <Field
                    name="name"
                    required={true}
                    component={TextInput}
                    label={`Название`}
                    className={classes.settings}
                    style={{width: 200}}
                />
                <Field
                    name="task"
                    required={true}
                    component={SelectInput}
                    options={comboboxes}
                    label={`Тема`}
                    className={classes.result}
                    style={{width: 200}}
                />
                <Field
                    name="result"
                    required={true}
                    component={SelectInput}
                    options={comboboxes}
                    label={`Реультат`}
                    className={classes.result}
                    style={{width: 200}}
                />
                 <Field
                    name="oldScript"
                    required={true}
                    component={TextInput}
                    label={`Скрипт (старый)`}
                    className={classes.settings}
                    style={{width: 150}}
                />
                <Field
                    name="script.default"
                    required={true}
                    component={SelectInput}
                    options={scripts}
                    label={`Скрипт`}
                    className={classes.settings}
                    style={{width: 200}}
                />
                <Field
                    name="rating"
                    required={true}
                    component={TextInput}
                    label={`Сценарий оценки`}
                    className={classes.settings}
                    style={{width: 150}}
                />
                
            </div>
            <div className={classes.row}>
                {(integration&&integration.type!=='None')&&
                    <Field
                        name="create.essence"
                        required={true}
                        component={RadioInput}
                        options={EssenceOptions}
                        label={`Создавать сущность`}
                        className={classes.name}
                        style={{width: 300}}
                    />
                }
            </div>
            {(integration&&integration.type!=='None')&&
                <div className={classes.row} style={{display: 'inline-flex'}}>
                        <Field
                            name="create.create"
                            required={true}
                            component={SwitchInput}
                            label={`Авто. создавать сущность (если отсутствует)`}
                            className={classes.name}
                            style={{width: 300}}
                        />
                    <Field
                        name="search.value"
                        required={true}
                        component={SwitchInput}
                        label={`Поиск`}
                        className={classes.radio}
                        style={{paddingRight: 300}}
                    />
                    {(formValues&&formValues.search&&formValues.search.value)&&
                        <Field
                            name="search.crm"
                            required={true}
                            component={SwitchInput}
                            label={`CRM`}
                            className={classes.radio}
                            style={{width: 300}}
                        />
                    }
                    {(formValues&&formValues.search&&formValues.search.value)&&
                        <Field
                            name="search.integration"
                            required={true}
                            component={SwitchInput}
                            label={`Интеграция`}
                            className={classes.radio}
                            style={{width: 300}}
                        />
                    }
                    {(formValues&&formValues.search&&formValues.search.value)&&
                        <Field
                            name="search.contact_phone"
                            required={true}
                            component={SelectInput}
                            options={phoneFields.map( p => ({id: p.value, name: p.label}))}
                            label={`Контактный телефон`}
                            className={classes.settings}
                            style={{width: 200}}
                        />
                    }
                    {(formValues&&formValues.search&&formValues.search.value)&&
                        <Field
                            name="search.entity_type"
                            required={true}
                            component={MultiSelectInput}
                            options={Entity_type}
                            label={`Модули`}
                            className={classes.settings}
                            style={{width: 200}}
                        />
                    }
                </div>
            }
            {formValues&&
                <CardElement 
                    width={80}
                    title={'Телефонная книга'}
                    height={'calc(100vh - 400px)'}
                    content={ <PhoneBook />}
                />
            }
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('card')(state);
    return { 
        formValues
    }
};


const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'card',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
    withStyles(styles)
);

export default enhance(Settings);