import React, { Fragment, useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import { UPDATE, GET_ONE, GET_LIST, provider, history } from '../_utils';

import { TextInput, PhoneInput, SelectInput, DateInput, Row, CardElement, BlockButton, CloseButton, SaveButton } from '../_Components'


import Utils from './components/utils';

const { places, operatorsTypes, departments, contractTypes } = Utils.options();

const OperatorEdit = ({id, formValues, initialize, initialized}) => {
    const [companies, setCompanies] = useState([]);
    const [salaries, setSalaries] = useState([]);
    const [freeLogins, setFreeLogins] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const { data, status } = await provider( GET_ONE, '/operators', id);
            if( status === 200 ){
                data.salary = data.salary ? data.salary._id : null;
                data.company = data.company ? data.company._id : null;
                getFreeLogins(data);
                getSalaries();
                getCompanies();
                initialize(data);
            }
        }

        const getFreeLogins = async (operator) => {
            const { data, status } = await provider( 
                GET_LIST,
                '/infra/agentsFree', 
                {
                    filter: { },
                    sort: { field: 'name', order: "ASC" },
                    pagination: { page:1, perPage: 999 },
                }
            );
            if(status === 200){
                if(operator.login){
                    data.push({ id:  operator.login, name: operator.login})
                }
                setFreeLogins(data);
            }
        }
    
        const getSalaries = async () => {
            const { data, status } = await provider( 
                GET_LIST,
                '/operators/salary', 
                {
                    filter: { },
                    sort: { field: 'name', order: "ASC" },
                    pagination: { page:1, perPage: 999 },
                }
            );
            if(status === 200){
                setSalaries(data);
            }
        }
    
        const getCompanies = async () => {
            const { data, status } = await provider( 
                GET_LIST,
                '/companies', 
                {
                    filter: { },
                    sort: { field: 'name', order: "ASC" },
                    pagination: { page:1, perPage: 999 },
                }
            );
            if(status === 200){
                setCompanies(data);
            }
        }

        if(!initialized){
            fetchData();
        }

    }, [id, initialize, initialized]);


    const block = async () => {
        formValues.status = formValues.status === "Terminated" ? "Active" : "Terminated";
        const { status } = await provider(
            UPDATE,
            '/operators',
            {  id: id,
                data: formValues
            }
        );
        if(status===200){
            history.goBack()
        }
    }

    const save = async () => {
        const { status } = await provider(
            UPDATE,
            '/operators',
            {  id: formValues._id,
                data: formValues
            }
        );
        if(status===200){
            history.goBack()
        }
    }
    const close = () => {
        history.goBack();
    }

    return(
        <CardElement
            // style={{ paddingLeft: 16 }}
            height={'calc(100vh - 130px)'}
            action={
                <Fragment>
                    <SaveButton handleSaveClick={save} />
                    <BlockButton handleBlockClick={block} status={formValues&&formValues.status} />
                    <CloseButton handleCloseClick={close} />
                </Fragment>
            }
            content={
                <Fragment>
                    <CardElement
                        // width={60}
                        content={
                            <Fragment>
                                <p>Персональные даннные</p>
                                <Row>
                                    <Field
                                        name="name.last"
                                        label={`Фамилия`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="name.first"
                                        label={`Имя`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="name.second"
                                        label={`Отчетсво`}
                                        component={TextInput}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        name="contact.phone.mobile"
                                        label={`Мобильный телефон`}
                                        component={PhoneInput}
                                    />
                                    <Field
                                        name="contact.phone.mobile2"
                                        label={`Мобильный телефон (др.)`}
                                        component={PhoneInput}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        name="contact.phone.home"
                                        label={`Домашний телефон`}
                                        component={PhoneInput}
                                    />
                                    <Field
                                        name="contact.email"
                                        label={`E-mail`}
                                        component={TextInput}
                                    />
                                </Row>
                                <p>Операторские данные</p>
                                <Row>
                                    <Field
                                        name="login"
                                        label={`Операторский номер`}
                                        options={freeLogins}
                                        component={SelectInput}
                                    />
                                    <Field
                                        name="type"
                                        label={`Тип`}
                                        options={operatorsTypes}
                                        component={SelectInput}
                                    />
                                    <Field
                                        name="department"
                                        label={`Отдел`}
                                        options={departments}
                                        component={SelectInput}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        name="place"
                                        label={`Площадка`}
                                        options={places}
                                        component={SelectInput}
                                    />
                                    <Field
                                        name="salary"
                                        label={`Мотивация`}
                                        options={salaries}
                                        component={SelectInput}
                                    />
                                </Row>
                                <p>Договор/Контракт</p>
                                <Row>
                                    <Field
                                        name="company"
                                        label={`Компания`}
                                        options={companies}
                                        component={SelectInput}
                                    />
                                    <Field
                                        name="contract.type"
                                        label={`Тип`}
                                        options={contractTypes}
                                        component={SelectInput}
                                    />
                                    <Field
                                        name="contract.no"
                                        label={`№`}
                                        component={TextInput}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        views={["year", "month", "date"]}
                                        name="contract.entered"
                                        label={`От`}
                                        component={DateInput}
                                    />
                                        <Field
                                        views={["year", "month", "date"]}
                                        name="contract.termitated"
                                        label={`До`}
                                        component={DateInput}
                                    />
                                    <Field
                                        name="contract.extended"
                                        views={["year", "month", "date"]}
                                        label={`Продлен до`}
                                        clearable={true}
                                        component={DateInput}
                                    />
                                </Row>
                                <p><b>Паспортные данные</b></p>
                                <Row>
                                    <Field
                                        name="contact.address.state"
                                        label={`Область`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="contact.address.district"
                                        label={`Район`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="contact.address.city"
                                        label={`Населенный пункт`}
                                        component={TextInput}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        name="contact.address.street"
                                        label={`Улица`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="contact.address.house"
                                        label={`Дом`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="contact.address.block"
                                        label={`Корп.`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="contact.address.flat"
                                        label={`Кв.`}
                                        component={TextInput}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        name="passport.number"
                                        label={`Номер`}
                                        component={TextInput}
                                    />
                                    <Field
                                        name="passport.personal_number"
                                        label={`Личный номер`}
                                        component={TextInput}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        name="data.passport.issue"
                                        label={`Выдан`}
                                        component={TextInput}
                                    />
                                    <Field
                                        views={["year", "month", "date"]}
                                        name="passport.date_issue"
                                        label={`Дата выдачи`}
                                        clearable={true}
                                        component={DateInput}
                                    />
                                </Row>
                            </Fragment>
                        }
                    />
                    <CardElement />
                </Fragment>
            }
        />
    );

}

const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('operatorForm')(state);
    return { 
        initialValues: {
            department: "Inbound",
            place: "minsk",
            type: "operator",
        },
        formValues: recordLiveValues,
        isSaving
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'operatorForm',
        // enableReinitialize: true,
        destroyOnUnmount: true,
        validate: (values) => {
            const errors = {
                name: {}
            };
            if (!values.name) {
                errors.name = {
                    first: 'Обазятельное поле',
                    last: 'Обазятельное поле'
                }
            }
            if(values.name){
                if (!values.name.first) {
                    errors.name.first = 'Обазятельное поле'
                } 
                if (!values.name.last) {
                    errors.name.last = 'Обазятельное поле'
                } 
            }
            if (!values.place) {
                errors.place = 'Обазятельное поле'
            } 
            if (!values.type) {
                errors.type = 'Обазятельное поле'
            } 
            if (!values.department) {
                errors.department = 'Обазятельное поле'
            } 
            return errors;
        },
    })
)(OperatorEdit);