import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import LinearProgress from '@mui/material/LinearProgress';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import HelpIcon from '@mui/icons-material/Help';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Utils from '../utils';

const styles = theme => ({
    root: {
        borderBottom: '1px rgba(0, 0, 0, 0.12) solid',
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 0,
        paddingRight: 0,
        height: 35
    },
    card: {
        overflow: 'inherit',
        // textAlign: 'right',
    },
    cardTitle: {
        color: '#ffff;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardTitleSecond: {
        paddingRight: 0,
        color: 'white'
    },
    cardHeader: {
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: '#009688'
    },
    cardContent: {
        // flex: 0,
        // paddingLeft: theme.spacing(2)
    },
    cardAction: {
        // flex: '1 1 auto',
        marginTop: 0
    },
    cardIcon: {
        color: '#ffff',
        width: 34, 
        height: 34,
        padding: 0,
    },
    listItemText: {
        paddingRight: 0,
        // marginRight: 8,
    },
    firstLine: {
        margin: 0,
        display: 'block',
        height: 17,
        fontSize: 11,
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1.5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    secondLine: {
        fontSize: '0.6rem',
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.46429em',
        margin: 0,
        display: 'block',
        height: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttonIcon: {
        marginRight: 4, 
        width: 34, 
        height: 34,
        padding: 0,
        '& span:first-child': {
            display: 'block'
        }
    },
    menuIcon: {
        marginRight: 4, 
        padding: 0,
        width: 30, 
        height: 30
    }
    
});

let anchorEl = null;
let open = false;
let currentAgent = {
    backgroundColor: null,
    login: null
};

const handleClick = (event, login, backgroundColor) => {
    anchorEl = event.currentTarget;
    currentAgent = { login, backgroundColor};
    open = true;
};

const handleClose = () => {
    anchorEl = null;
    currentAgent = {
        backgroundColor: null,
        login: null
    };
    open = false;
};

const StyledMenu = withStyles({
  paper: {
    height: 34,
    marginLeft: 40,
    boxShadow: 'none',
    '& ul': {
        paddingTop: 2,
        paddingBottom: 0
    },
  }
})(props => (
  <Menu
    {...props}
  />
));

const Agents = ({ items = {}, statistics = [], changeState, showAgent, classes, detail }) => (
        <Card className={classes.card}>
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                    root: classes.cardHeader,
                    content: classes.cardContent,
                    action: classes.cardAction
                }}
                title="Операторы"
            />
            {items&&items.agents ? 
                <List style={{paddingTop: 0, paddingBottom: 0}}>
                    {/* {statistics.map(statistic =>{ */}
                    {items.agents.map(record =>{
                        const statistic = statistics.find( s => s.name === record.login);
                        // const record = items.agents.find( r => statistic.id === r.login);
                        // const state = Utils.getAgentState(record.stateCode);
                        const state = record.sessions.length > 0 ? Utils.getSessionState(record.sessions[0].state) : (record.stateCode ?  Utils.getAgentState(record.stateCode) : {} );
                        let backgroundColor = '#ffffff';
                        let color = state.color;
                        if(record.talking > 0){
                            backgroundColor = '#dff0d8';
                            color = "#3c763d"
                        }else if(record.stateDuration>120 && state.value==='Ready'){
                            backgroundColor = '#fff2f1';
                            color = "#ec6859"
                        }else if(record.stateDuration>60 && state.value==='Ready'){
                            backgroundColor = '#fffae1';
                            color = "#8a6d3b"
                        }else{
                            backgroundColor="#ffffff";
                        }
                        let occColor = 'unset';
                        if(statistic){
                            if(+statistic.occPercent>=65){
                                occColor = "#3c763d";
                            }else if(+statistic.occPercent>=55){
                                occColor = "#8a6d3b";
                            }else{
                                occColor = "#ec6859";
                            }
                        }
                        return (
                            <ListItem
                                button
                                className={classes.root}
                                key={record.login}
                                style={{ backgroundColor: backgroundColor}}
                            >   
                                
                                <div className={classes.listItemText} style={{width: '5%', textAlign: 'center'}}>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls={record.id}
                                            aria-haspopup="true"
                                            className={classes.buttonIcon}
                                            disabled={!state ? true : state.value==='Logoff'}
                                            onClick={(e) => handleClick(e, record.id, backgroundColor)}
                                        >
                                            <span className={classes.firstLine} style={{height: 22, paddingTop: 2}}>
                                                {state.icon}
                                            </span>
                                            <p className={classes.secondLine} style={{fontSize: '0.5rem', height: 10}}>
                                                {record.station}
                                            </p>
                                        </IconButton>
                                        
                                    
                                </div>
                                <div onClick={ () => showAgent(record)} className={classes.listItemText} style={{width: '40%', paddingLeft: 13}}>
                                    <span className={classes.firstLine}>
                                        {`${record.login} ${record.name}`}
                                    </span>
                                    <p className={classes.secondLine}>
                                        {state.label}
                                    </p>
                                </div>
                                <div onClick={ () => showAgent(record)} className={classes.listItemText}  style={{ width: '40%', color: color}}>
                                    <span className={classes.firstLine}>{record.sessions.length > 0 ? `${record.sessions[0].queue.name}` : ``}</span>
                                    <p className={classes.secondLine}>
                                        {record.sessions.length > 0 ? `${record.sessions[0].address}` : ``}
                                    </p>
                                </div>
                                <div onClick={ () => showAgent(record)} className={classes.listItemText} style={{ color: color}}>
                                    <span className={classes.firstLine}>{record.sessions.length > 0 ? Utils.secondsToTime(record.sessions[0].lastStateDuration) : record.stateDuration} </span>
                                    <p className={classes.secondLine} style={{color: occColor}}>
                                        {statistic ? `${statistic.occPercent.toFixed(2)}%` : ``}
                                    </p>
                                </div>
                            </ListItem>
                        )
                    })}
                </List>
                :
                <LinearProgress />
            }
            <StyledMenu
                id="state-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    // maxHeight: ITEM_HEIGHT * 4.5,
                    // width: 200,
                    backgroundColor: currentAgent.backgroundColor,
                    paddingTop: 0,
                    paddingBottom: 0,

                  },
                }}
            >
                <ListItemIcon>
                        <IconButton className={classes.menuIcon} onClick={ () => {changeState(currentAgent.login, 536870914); handleClose();}}>
                            <CheckIcon style={{color: '#4CAF50'}}/>
                        </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                        <IconButton className={classes.menuIcon}  onClick={ () => {changeState(currentAgent.login, 536870917); handleClose();}}>
                            <HighlightOffIcon style={{color: '#F44336'}}/>
                        </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                        <IconButton className={classes.menuIcon}  onClick={ () => {changeState(currentAgent.login, 536870918); handleClose();}}>
                            <HelpIcon style={{color: '#3f51b5'}}/>
                        </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                        <IconButton className={classes.menuIcon}  onClick={ () => {changeState(currentAgent.login, 536870919); handleClose();}}>
                            <FreeBreakfastIcon style={{color: '#FF9800'}}/>
                        </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                        <IconButton className={classes.menuIcon}  onClick={ () => {changeState(currentAgent.login, 536870912); handleClose();}}>
                            <ExitToAppIcon />
                        </IconButton>
                </ListItemIcon>
            </StyledMenu> 
        </Card>
);

const enhance = compose(
    withStyles(styles)
);

export default enhance(Agents);
