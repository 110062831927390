import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@material-ui/core';

import { history, permissions } from '../../_utils';

const styles = theme =>({
    button: {
        marginLeft: theme.spacing(1),
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
})


class Actions extends Component {
    constructor(props){
        super(props);
        this.state = {
            permission: null,
            permissionsComponent: ['admin', 'supervisor']
        }
    }

    componentDidMount(){
        const permission = permissions();
        this.setState({permission});
    }

    close = () =>{
        return history.goBack();
    }

    edit = () =>{
        return history.push(`${history.location.pathname.replace('show', 'edit')}`);
    }

    render(){
        const { 
            classes
        } = this.props;
        const { permissionsComponent = [], permission } =this.state;
        return(
            <Fragment>
                {(permission&&permissionsComponent.indexOf(permission)>=0)&&
                    <Button                                                         
                        className={classes.button}   
                        size="small"
                        color="primary"
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={this.edit} 
                    >
                        Редактировать
                    </Button>
                }
                <Button                                                         
                    className={classes.button}   
                    classes={{
                        root: classes.close
                    }}                                                   
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={this.close} 
                >
                    Закрыть
                </Button>
            </Fragment>
        );
    }
}
    

export default  compose(
    withStyles(styles),
)(Actions);
