import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { withStyles } from '@mui/styles';

import { Button } from "@mui/material";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
// import CircularProgress from '@material-ui/core/CircularProgress';

import {  TextInput, SelectInput } from '../../_Components';
import { CREATE, provider, history } from '../../_utils';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        display: 'flex',
        width: '40%'
    },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    upload: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    fileLabel: {
        color: '#0a529f'
    },
    name: {
        marginRight: 20
    },
    type: {
        marginRight: 20
    },
    deleteLabel: {
        color: 'red'
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
const types =  [
    {
        id: 'Support',
        name: 'Поддержка',
    },
    {
        id: 'Sales',
        name: 'Продажи',
    },
    {
        id: 'Survey',
        name: 'Опрос',
    },
];

class CreatScript extends Component {
    state = { };

    save = async () => {
        const data = this.props.formValues;
        const result = await provider(CREATE, '/projects', { data });
        if(result.status === 200 ){
            this.setState({ loading: false });
            let path = `${history.location.pathname}/${result.data._id}`;
            this.props.history.push(path)
        }else{
            this.setState({ loading: false });
        }
    };


    render() {
        const { openCreat, handleCloseCreat, classes } = this.props;
        
        return (
                    <Dialog
                        open={openCreat}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">Новый проект</DialogTitle>
                        <DialogContent>
                                <Field
                                    name="name"
                                    required={true}
                                    component={TextInput}
                                    label={`Название`}
                                    className={classes.name}
                                    style={{width: 300}}
                                />
                                <Field
                                    name="type"
                                    options={types}
                                    required={true}
                                    component={SelectInput}
                                    label={`Тип`}
                                    className={classes.type}
                                    style={{width: 180}}
                                />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                    color="primary"
                                    variant="contained"
                                    // disabled={loading}
                                    onClick={this.save}
                                    startIcon={<SaveIcon />}
                                >
                                    Сохранить
                            </Button>
                            <Button
                                className={classes.close}
                                // disabled={loading}
                                onClick={handleCloseCreat}
                                startIcon={<CloseIcon />}
                            >
                                Отменить
                            </Button>
                        </DialogActions>
                    </Dialog>
        );
    }
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('creatScript')(state);
    return { 
        history,
        formValues,
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'creatScript',
        validate: (values) => {
            const errors = {};
            if (!values.name) {
                errors.operator = "Обязательное поле";
            }
            if (!values.type) {
                errors.place = "Обязательное поле";
            }
            return errors;
        },
    }),
    withStyles(styles)
);

export default enhance(CreatScript);