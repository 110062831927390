import { fetchConstants } from '../_constants';

let requests = 0;
let creat = 0;
// let resources = [];

export function fetch(state = { loading: requests}, action) {
    switch (action.type) {
        case fetchConstants.START:
            // console.log(action);
            requests++
            // resources.push(action.payload)
            return {
                loading : requests,
                creat:  creat,
                // resources: resources
            };
        case fetchConstants.CREATE:
            creat++
            return {
                loading : requests,
                creat:  creat
            };
        case fetchConstants.CREATE_END:
            creat--;
            return {
                loading : requests,
                creat:  creat
            };
        case fetchConstants.ERROR:
            requests--;
            return {
                loading : requests,
                creat:  creat
            };
        case fetchConstants.SUCCESS:
            requests--;
            // console.log(resources.indexOf(action.payload));
            // const element = resources.find(action.payload);
            return {
                loading : requests,
                creat:  creat
            };
        default:
            return state
    }
}