import React, { Fragment, useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
// import { createStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { 
    List, 
    TextField, 
    DateField, 
    PhoneField, 
    TimeField, 
    SuccessField, 
    AddressField,
    CloseButton,
    Drawer
} from '../_Components';

import {GET_ONE, provider, history } from '../_utils';

import Filter from './components/Filter';
import ExportButton from './components/ExportButton';
import SettingsButton from './components/SettingsButton';
import ShowCall from './components/ShowCall';

const ReportList = ({ match, history, formValues, isLoading, search, ...props} ) => {
    const [headers, setHeaders] = useState([]);
    const [settings, setSettings] = useState(null);
    const [filter, setFilter] = useState(formValues || {});
    const [isMounted, setIsMounted] = useState(false);

    const refresh = (val) => {
        if(val){
            setIsMounted(false);
        }
    }

    useEffect(() => {
        const getReport = async () => {
            const { data, status } = await provider( 
                GET_ONE,
                '/reports', 
                match.params.id
            );
            if(status === 200){
                setHeaders(data.headers);
                setSettings(data);
                setFilter({ 
                    report: match.params.id,
                    type: 'call',
                    interval: {
                        startDate: filter.startDate || moment().startOf('day'),
                        endDate: filter.endDate || moment().endOf('day')
                    }
                });
                setIsMounted(true);
            }
        }
        if(!isMounted){
            getReport();
        }
    },[isMounted, match, filter]);

    const close = () => {
        history.push('/reports');
    }

    const handleClose = () => {

    }
    return (
        <Route path="/reports/:id/:call">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params&&
                        match.params.call
                    );
                    if(!isMounted){
                        return <Fragment />;   
                    }
                    return (
                        <Fragment>
                            <List
                                refresh={true}
                                creat={false}
                                show={true}
                                basePath='client/callsList'
                                title={(settings&&settings.project) && `${settings.project.name} || ${settings.name}`}
                                actions={
                                    <Fragment>
                                        <ExportButton report={settings} isLoading={isLoading}/>
                                        <SettingsButton report={settings} isLoading={isLoading} refresh={refresh}/>
                                        <CloseButton handleCloseClick={close}/>
                                    </Fragment>
                                }
                                filters={<Filter />}
                                match={match}
                                search={['callId']}
                                filterDefaultValues={filter}
                                perPage={25}
                                deletedField='deleted'
                                sort={{ field: 'time', order: 'ASC' }} 
                            >   
                                {headers.length>0&&
                                    headers.map( (el, i) => {
                                        switch (el.type) {
                                            case "datetime":
                                                return <DateField
                                                            key={el.value}
                                                            type={el.type}
                                                            notSort={true}
                                                            source={el.value}
                                                            row={true}
                                                            label={el.label}
                                                            format="DD.MM.YYYY HH:mm:ss"
                                                        />
                                            case "address":
                                                return  <AddressField
                                                            key={el.value}
                                                            type={el.type}
                                                            notSort={true}
                                                            row={true}
                                                            source={el.value}
                                                            label={el.label}
                                                        />
                                            case "phone":
                                                return <PhoneField
                                                            key={el.value}
                                                            type={el.type}
                                                            notSort={true}
                                                            row={true}
                                                            source={el.value}
                                                            label={el.label}
                                                        />
                                            case "time":
                                                return <TimeField
                                                            key={el.value}
                                                            type={el.type}
                                                            notSort={true}
                                                            row={true}
                                                            source={el.value}
                                                            label={el.label}
                                                        />
                                            case "success":
                                                return <SuccessField
                                                            key={el.value}
                                                            type={el.type}
                                                            notSort={true}
                                                            row={true}
                                                            source={el.value}
                                                            label={el.label}
                                                        />
                                            default:
                                                return <TextField
                                                            key={el.value}
                                                            type={el.type}
                                                            notSort={true}
                                                            row={true}
                                                            source={el.value}
                                                            label={el.label}
                                                        />
                                        }
                                    })
                                }
                            </List>
                            <Drawer
                                isMatch={isMatch}
                                handleClose={handleClose}
                                content={isMatch ? <ShowCall id={match.params.call} callId={search ? search.callId : null} /> : null}
                            />
                        </Fragment>
                    );
                }}
        </Route>
        
    );
}

const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('filterForm')(state);
    const { fetch } = state;
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    return {
        search,
        formValues: recordLiveValues,
        isLoading: !!fetch.loading
    };
};

export default compose(
    connect(mapStateToProps),
    // withStyles(styles),
)(ReportList);
