import React, {useState, useEffect, Fragment} from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, isInvalid, Field } from 'redux-form';

import {GET_ONE, GET_LIST, UPDATE, provider, history} from '../_utils';
import { Actions, SaveButton, CloseButton, CardElement, MultiSelectInput, SelectInput, TextInput, Row } from '../_Components';
import UsersInput from './components/UsersField';

const Cabinet = ({users, projects, reports}) => {
    return( 
        <Fragment>
            <Row>
                <Field 
                    name={`name`}
                    component={TextInput}
                    label={`Название`}
                    style={{width: 400}}
                />
            </Row>
            <Row>
                <Field
                    name="project"
                    required={true}
                    component={SelectInput}
                    options={projects}
                    disabled={true}
                    label={`Проект`}
                    style={{width: 200}}
                />
            </Row>
            <Row>
                <Field
                    name="reports"
                    required={true}
                    component={MultiSelectInput}
                    options={reports}
                    label={`Отчеты`}
                    style={{width: 400}}
                />
            </Row>
            <Row>
                <Field 
                    label={`Пользователи`}
                    name={`users`}
                    component={UsersInput}
                    usersList={users}
                    style={{width: 400}}
                />
            </Row>
        </Fragment>
    )
}

const CabinetEdit = ({id, initialize, initialized, formValues, invalid, isSaving}) => {
    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        const  fetchData = async () => {
            const { data } = await provider( 
                    GET_ONE,
                    '/cabinets', 
                    id
            );
            getReports(data);
            initialize(data);
        }
    
        const  getProjects = async (data) => {
            const project = await provider( 
                GET_LIST,
                '/projects',
                {
                    filter: { },
                    sort: { field: 'date', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                }
            );
            setProjects(project.data);
        }

        const  getReports = async (data) => {
            const reports = await provider( 
                GET_LIST,
                '/reports',
                {
                    filter: { project:  data.project},
                    sort: { field: 'date', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                }
            );
            setReports(reports.data);
        }

        const  getUsers = async (data) => {
            const users = await provider( 
                GET_LIST,
                '/users',
                {
                    filter: { },
                    sort: { field: 'date', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                }
            );
            setUsers(users.data);
        }

        if(!initialized){
            fetchData();
            getProjects();
            getUsers();
        }

    }, [id,initialize, initialized]);

    const save = async () => {
        provider( UPDATE, '/cabinets/', { id: formValues._id, data: formValues })
    }

    const close = () =>{
        return history.goBack();
    }

    const actions =[
        <SaveButton isSaving={isSaving || invalid} handleSaveClick={save} />,
        // <BlockButton isSaving={isSaving || invalid} handleBlockClick={disableProject} status={formValues ? formValues.status : 'Disabled'}/>,
        <CloseButton isSaving={isSaving || invalid} handleCloseClick={close} />
    ]
    return (
        <CardElement
            title={formValues&&formValues.name}
            action={<Actions actions={actions}/>}
            content={<Cabinet users={users} projects={projects} reports={reports}/>}
        />
    )
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('cabinet')(state);
    const { fetch } = state;
    return { 
        formValues,
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false,
        invalid: isInvalid('cabinet')(state),
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'cabinet',
    })
);

export default enhance(CabinetEdit);


