import React from "react";
import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

const EditButton = ({handleEditClick, isSaving, label, ...props}) => (
    <Button
        color="primary" 
        size="small"
        variant="contained"
        endIcon={<EditIcon />}
        onClick={() => handleEditClick()}
        {...props}
    >
        {label || "Редактировать"}
    </Button>
)

export default EditButton;