import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import LinearProgress from '@mui/material/LinearProgress';

import Utils from '../utils';

const styles = theme => ({
    root: {
        borderBottom: '1px rgba(0, 0, 0, 0.12) solid',
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 5,
        paddingRight: 5,
        height: 35
    },
    main: {
        verticalAlign: 'top',
        boxShadow: 'unset',
        overflow: 'auto',
        flexGrow: 1,
        flexBasis: '25%',
    },
    card: {
        overflow: 'inherit',
        // textAlign: 'right',
    },
    cardTitle: {
        color: '#ffff;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: '#59af50'
    },
    cardContent: {
        // flex: 0,
        // paddingLeft: theme.spacing(2)
    },
    cardAction: {
        // flex: '1 1 auto',
        marginTop: 0
    },
    cardIcon: {
        color: '#ffff',
        width: 34, 
        height: 34,
        padding: 0,
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        paddingRight: 0,
    },
    headerItemText: {
        paddingRight: 0,
        color: '#ffff'
    },
    actionSyle: {
        zIndex: 2,
        float: 'right',
        padding: 0
    },
    firstLine: {
        margin: 0,
        height: 17,
        fontSize: 11,
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1.5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    secondLine: {
        fontSize: '0.6rem',
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.46429em',
        margin: 0,
        display: 'block',
        height: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
    
});

const Inbound = ({ items = {}, statistics = [], marginLeft = 0, showInbound, paddingTop = 0, classes  }) => (
    <div className={classes.main} style={{ paddingTop}}>
        <Card className={classes.card}>
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                    root: classes.cardHeader,
                    content: classes.cardContent,
                    action: classes.cardAction
                }}
                title="Входящие"
            />
           
            {items&&items.inbounds ? 
                <List style={{paddingTop: 0, paddingBottom: 0}}>
                    {items.inbounds.map(record =>{
                        let backgroundColor = 'none';
                        let color = '';
                        if(record.sessions.connected > 0 && record.sessions.wating<=0){
                            backgroundColor = '#e8f5e9';
                            color = "#3c763d"
                        }else if(record.sessions.connected >= 0 && record.sessions.wating===1){
                            backgroundColor = '#fff8e1';
                            color = "#8a6d3b"
                        }else if(record.sessions.connected >= 0 && record.sessions.wating>1){
                            backgroundColor = '#ffebee';
                            color = "#ec6859"
                        }
                        const statistic = statistics.find( s => s.id === record.id);
                        return (
                            <ListItem
                                button
                                className={classes.root}
                                key={record.id}
                                onClick={ () => showInbound(record)}
                                style={{ backgroundColor: backgroundColor}}
                            >   
                                <div className={classes.listItemText} style={{width: '100%'}}>
                                    <span className={classes.firstLine}>
                                        <span className={classes.listItemText} style={{width: '80%', display: 'inline-flex'}}>
                                            {record.name}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                            {record.online}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                            {record.available}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                            {record.sessions.connected}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                            {record.sessions.wating}
                                        </span>
                                    </span>
                                    <p className={classes.secondLine}>
                                        { statistic ? `SL: ${statistic.sl.toFixed(2)}% AR: ${statistic.abandonedPercent.toFixed(2)}% Принято: ${statistic.answered} Время: ${Utils.secondsToTime(statistic.talkTime)}` :  ``}
                                    </p>
                                </div>
                            </ListItem>
                        )
                    } )}
                </List>
                :
                <LinearProgress />
            }
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles)
);

export default enhance(Inbound);
