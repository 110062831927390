import React, { Fragment } from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';

const RadioInput = ({input, options, ...props}) => {
    const handleChange = (event) => {
        const value = event.target.value;
        input.onChange(value ? value : null)
    };

    return(
        <FormControlLabel
            value="top"
            control={
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    {(options&&options.length>0)&&
                        options.map( (opt, index) =>
                            <Fragment key={index}>
                                <Radio
                                    checked={input.value === opt.id}
                                    onChange={handleChange}
                                    value={opt.id}
                                    color="primary"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'A' }}
                                />
                                <label style={{paddingTop: '0.5rem',letterSpacing: '0.00938em'}}>
                                    {opt.name}
                                </label>
                            </Fragment>
                        )
                    }
                </RadioGroup>
            }
            label={props.label}
            labelPlacement="top"
        />
        // <div  style={{display: 'inline-flex', marginRight: 16}}>
        //     <label style={{
        //             color: 'rgba(0, 0, 0, 0.54)',
        //             padding: 0,
        //             fontSize: '0.75rem',
        //             fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        //             fontWeight: 400,
        //             lineHeight: 1,
        //             letterSpacing: '0.00938em'
        //         }}
        //     >
        //         {props.label}
        //     </label>
        //     <RadioGroup row aria-label="position" name="position" defaultValue="top">
        //         {(options&&options.length>0)&&
        //             options.map( (opt, index) =>
        //                 <Fragment key={index}>
        //                     <Radio
        //                         checked={input.value === opt.id}
        //                         onChange={handleChange}
        //                         value={opt.id}
        //                         color="primary"
        //                         name="radio-button-demo"
        //                         inputProps={{ 'aria-label': 'A' }}
        //                     />
        //                     <label style={{paddingTop: '0.5rem',letterSpacing: '0.00938em'}}>
        //                         {opt.name}
        //                     </label>
        //                 </Fragment>
        //             )
        //         }
        //     </RadioGroup>
        // </div>
    )
}

export default RadioInput;
