import React, { Fragment, useState } from 'react';
import { compose } from 'recompose';
import { reduxForm, getFormValues, change, Field } from 'redux-form';
import { connect } from 'react-redux';

import Link from '@mui/material/Link';
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { 
    TextInput, 
    SelectInput,
    AddButton,
    Row,
    Modal
} from '../../_Components';
import { UPDATE, CREATE, provider, history } from '../../_utils';

const NewCardForm = ({isSaving, ...props}) => {
    const comboboxesId = [];
    const options = [];
    props.fields.map( f=> f.type==="select" && comboboxesId.push(f.combobox));
    props.comboboxes.map( c => options.push({id: c._id, name: c.name}));
    return(
        <Fragment>
            <Row>
                <Field
                    name="name"
                    label={`Название`}
                    disabled={isSaving}
                    component={TextInput}
                />
            </Row>
            <Row>
                <Field
                    name="task"
                    label={`Тема`}
                    options={options}
                    disabled={isSaving}
                    component={SelectInput}
                />
                <Field
                    name="result"
                    label={`Результат`}
                    options={options}
                    disabled={isSaving}
                    component={SelectInput}
                />
                <Field
                    name="oldScript"
                    label={`Скрипт (старый)`}
                    disabled={false}
                    component={TextInput}
                />
            </Row>
        </Fragment>
    )
}

const List = ({cards,classes, copyCard, goToCard, combobox}) => (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell><b>Название</b></TableCell>
                    <TableCell><b>Тема</b></TableCell>
                    <TableCell><b>Результат</b></TableCell>
                    <TableCell><b>Скрипт</b></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {cards&&cards.map((row, i) => (
                    <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        // style={{
                        //     cursor: 'pointer', 
                        //     backgroundColor : i===index ? 'antiquewhite' : ''
                        // }}
                    >
                        <TableCell style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">
                            <Link component="button" onClick={() => goToCard(row._id)}>{row.name}</Link>
                        </TableCell>
                        <TableCell style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">{row.result&&combobox.find(o => o._id === row.result).name}</TableCell>
                        <TableCell style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">{row.task&&combobox.find(o => o._id === row.task).name}</TableCell>
                        <TableCell style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">{row.oldScript}</TableCell>
                        <TableCell style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">
                            <IconButton style={{color: '#0a2b61'}} onClick={() => copyCard(row._id)} size='small'>
                                <ContentCopyIcon size="small"/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

const Cards = ({ classes, formValues, ...props }) => {
    const { cards, combobox, fields } = formValues;
    const [newCard, setNewCard] = useState(false);
    const [copyCardId, setCopyCardId] = useState(null);
    
    const goToCard = (id) => {
        history.push('/cards/'+id)
    };

    const copyCard = (id) => {
        setNewCard(!newCard);
        setCopyCardId(id)
    }

    const open = () => {
        setNewCard(!newCard);
    }

    const save = async (card) => {
        if(card){
            let newCard = {...card, project: formValues._id};
            if(copyCardId){
                const oldCard = formValues.cards.find( c => c._id === copyCardId);
                newCard = { ...oldCard, ...card };
                delete newCard._id;
            }
            const { status, data } = await provider(
                CREATE,
                '/cards',
                { data: newCard }
            );
            cards.push(data);
            change('cards', cards);
            if(status===200){
                await provider(
                    UPDATE,
                    '/projects',
                    {  id: formValues._id,
                        data: formValues
                    }
                );
                setNewCard(!newCard);
                setCopyCardId(null);
            }
        }
    }

    return (
        <Fragment>
            <AddButton 
                label={'Новая карточка'}
                handleAddClick={open}
            />
            <List cards={cards} goToCard={goToCard} copyCard={copyCard} combobox={combobox} />
            {newCard&&
                <Modal 
                    width='md'
                    title="Добавить карточку"
                    open={newCard} 
                    handleSaveClick={save} 
                    handleCloseClick={open} 
                    fields={<NewCardForm classes={classes} comboboxes={combobox} fields={fields} {...props}/>} 
                />
            }
        </Fragment>
    );
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('project')(state);
    return { 
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'project',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
);

export default enhance(Cards);;