import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';
import moemnt from 'moment';

import { withStyles } from '@material-ui/core/styles';

import { DateInput, Filter } from '../../_Components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
    code: {
        display: 'flex',
        width: theme.spacing(10),
        marginRight: 20
    },
    search: {
        display: 'flex',
        width: theme.spacing(20),
        marginRight: 20
    },
    reports: {
        display: 'flex',
        width: theme.spacing(40),
        marginRight: 20
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    button: {
        margin: 12,
    },
    
});

class ReportsFilter extends Component {
    state = {
        maxDate: moemnt().endOf('month'),
    };

    render(){
        const { classes, filterDefaultValues = {} , ...props } = this.props;
        const { maxDate } = this.state;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <DateInput
                    disableToolbar
                    variant="inline"
                    source="period"
                    label={`Период`}
                    views={["year", "month"]}
                    formatDate={null}
                    alwaysOn
                    maxDate={new Date(maxDate)}
                    className={classes.date}
                />
            </Filter>
        );
    }
}

export default  compose(
    withStyles(styles),
)(ReportsFilter);