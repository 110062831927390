import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, Field } from 'redux-form';
import compose from 'recompose/compose';

import { Button, Card, CardActions, CircularProgress, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import logo from '../_img/logo.png';
// import Background from '../img/bg_synevo.jpg';

import { userActions } from '../_utils';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: `url(${Background})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#0a529f'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    // actions: {
    //     padding: '0 1em 1em 1em',
    //     flexDirection: 'column'
    // },
    // reset: {
    //     paddingTop: '1em'
    // },
    // button: {
    //     height: 32,
    //     marginRight: 0
    // }

});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) =>{
    return(
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
            focused
            variant="standard"
        />
    )
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: true,
            reset: false
        };
        this.submit = this.submit.bind(this);
        this.setResetForm = this.setResetForm.bind(this);
    }
    
    submit(values){
        this.props.login(values);
    }

    setResetForm(){
        const { history } = this.props;
        history.push("/reset")
    }

    render(){
        const { classes, handleSubmit, isLoading } = this.props;
        return (
            <div className={classes.main}>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                            <img src={logo} alt="Логотип"/>
                    </div>
                    <form onSubmit={handleSubmit(this.submit)}>
                        <div className={classes.hint}>Авторизация</div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="username"
                                    component={renderInput}
                                    label={`E-mail`}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label={`Пароль`}
                                    type="password"
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                type="submit"
                                color="primary" 
                                // size="small"
                                variant="contained"
                                disabled={isLoading}
                                fullWidth
                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                Войти
                            </Button>
                        </CardActions>
                    </form>
                </Card>
            </div>
        );
    }
}

const actionCreators = {
    login: userActions.login,
    reset: userActions.reset,
};

const mapState = (state) => {
    const { fetch} = state;
    const isLoading = fetch.loading>0 || false;
    var recordLiveValues = getFormValues('auth')(state);
    return { formValues: recordLiveValues, isLoading }
}

const enhance = compose(
    connect( mapState, actionCreators),
    reduxForm({
        form: 'auth',
        validate: (values) => {
            const errors = {};
            if (!values.username) {
                errors.username = "Не верный e-mail";
            }
            if (!values.password) {
                errors.password = "Поле не может быть пустым";
            }
            return errors;
        },
    }),
    withStyles(styles)
);

const EnhancedLogin = enhance(Login);


export default EnhancedLogin;
