// import '../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../node_modules/react-resizable/css/styles.css';
import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Field } from 'redux-form';

import { TextInput, SelectInput, SwitchInput, Row } from '../../_Components';

import { Options } from '../../_utils/_constants';

const TypeOptions = Options.FieldsTypesOptions;

const typesArray = ["input", "number", "select", "text", "phone", "email", "name", "boolean", "radio", "integration"]

const ColSettings =({ selectedIndex, fields, path, item, items, combobox }) => (
    <Fragment>
        <Row>
            <Field
                name={`${path}[${selectedIndex}].field`}
                required={true}
                component={SelectInput}
                options={fields.filter(e => items.map(i => i.field).indexOf(e.id)<0 || item.field=== e.id).filter( f => typesArray.indexOf(f.type)>=0)}
                label={`Поле`}
                style={{width: 300}}
            />
            <Field
                name={`${path}[${selectedIndex}].field`}
                component={TextInput}
                label={`Значение`}
                disabled={true}
                style={{width: 300}}

            />
        </Row>
        <Row>
            <SelectInput 
                label={`Тип`}
                value={fields.find( el => el.id===item.field).type}
                disabled={true}
                options={TypeOptions}
                style={{width: 300}}
            />
            <Fragment>
                {fields.find( el => el.id===item.field).type==='select'&&
                    <Field
                        name={`${path}[${selectedIndex}].default`}
                        required={true}
                        component={SelectInput}
                        options={combobox}
                        label={`Значение по умолчанию`}
                        style={{width: 300}}
                    />
                }
            </Fragment>
        </Row>
        <Row>
            <Field
                name={`${path}[${selectedIndex}].required`}
                required={true}
                component={SwitchInput}
                label={`Обязательное`}
            />
            <Field
                name={`${path}[${selectedIndex}].readonly`}
                required={true}
                component={SwitchInput}
                label={`Только чтение`}
            />
        </Row>
        <Row>
            <Field
                name={`${path}[${selectedIndex}].crm`}
                required={true}
                component={SwitchInput}
                label={`Поле CRM`}
            />
            <Fragment>
                {fields.find( el => el.id===item.field).type==='phone'&&
                    <Field
                        name={`${path}[${selectedIndex}].callPhone`}
                        required={true}
                        component={SwitchInput}
                        label={`Тел. звонящего`}
                    />
                }
            </Fragment>
            <Fragment>
                {fields.find( el => el.id===item.field).type==='name'&&
                    <Field
                        name={`${path}[${selectedIndex}].full_name`}
                        required={true}
                        component={SwitchInput}
                        label={`Полное имя`}
                    />
                }
            </Fragment>
        </Row>
    </Fragment>
);

const mapStateToProps = (state, props) => {
    const { fetch } = state;
    return { 
        isLoading: !!fetch.loading
    }
};

export default  compose(
    connect(mapStateToProps),
)(ColSettings);