import React, { Fragment, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';

import { AddButton, CardElement, SortableList, SwitchInput, TextInput, Row, Modal, TableElement } from '../../_Components';

const header = [
    {
        label: 'Справочник',
        width: 70,
        value: 'name'
    },
    {
        label: 'Тема разговора',
        width: 30,
        value: 'isTask',
        options: [{
            id: false,
            name: ''
        },
        {
            id: true,
            name: 'Да'
        }]
    }
];

const EditFormElement = (props) => {
    return(
        <Row>
            <Field
                name='label'
                required={true}
                component={TextInput}
                label={`Надпись`}
                style={{width: 450}}
            />
            <Field
                name='value'
                required={true}
                component={TextInput}
                label={`Значение`}
                style={{width: 100}}
            />
            <Field
                name="deleted"
                component={SwitchInput}
                label={`Блок.`}
            />
        </Row>
    );
}

const ComboboxFormAdd = (props) => {
    return(
        <Row>
            <Field
                name='name'
                required={true}
                component={TextInput}
                label={`Надпись`}
                style={{width: 250}}
            />
        </Row>
    )
}

const Comboboxes = ({formValues, change, ...props}) => {
    const [activeNodeKey, setActiveNodeKey] = useState(0);
    const [activeOptionsKey, setActiveOptionsKey] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [subIndex, setSubIndex] = useState(null);
    const [openAddCombobox, setOpenAddCombobox] = useState(false);
    const [editOption, setEditOption] = useState(false);
    const [editSub, setEditSub] = useState(false);
    const [editForm, setEditForm] = useState(null);
    
    
    const addCombobox =() => {
        setOpenAddCombobox(true);
    }

    const closeAddCombobox = () => {
        setOpenAddCombobox(false);
    }

    const saveCombobox = (form) => {
        const newCombobox = formValues.combobox;
        newCombobox.push({deleted: false, options: [], ...form});
        setActiveNodeKey(newCombobox.length-1);
        setOpenAddCombobox(false);
    }

    const setActiveNode = (index) => {
        setActiveNodeKey(index);
        setActiveOptionsKey(0);
    }

    const setActiveOptions = (index) => {
        setActiveOptionsKey(index);
    }

    const editOptionClick = (form, index) => {
        setEditForm(form);
        setEditIndex(index);
        setEditOption(true);
    }

    const editSubClick = (form, index) => {
        setEditForm(form);
        setSubIndex(index);
        setEditSub(true);
    }

    const closeEdit = () => {
        setEditOption(false);
        setEditSub(false);
        setEditIndex(null);
        setEditForm(null);
    }


    const onSortabeOptions = (newData, newInndex) => {
        const newCombobox = formValues.combobox;
        newCombobox[activeNodeKey].options = newData;
        change('combobox', newCombobox);
    }

    const onSortabeSubs = (newData, newInndex) => {
        const newCombobox = formValues.combobox;
        newCombobox[activeNodeKey].options[activeOptionsKey].sub = newData;
        change('combobox', newCombobox);
    }

    const saveOption = (form) => {
        const newCombobox = formValues.combobox;
        if(editIndex>=0){
            newCombobox[activeNodeKey].options[editIndex] = form;
        }else{
            newCombobox[activeNodeKey].options.push({sub:[], ...form});
        }
        change('combobox', newCombobox);
        setEditOption(false);
        setEditIndex(null);
        setEditForm(null);
    }

    const saveSub = (form) => {
        const newCombobox = formValues.combobox;
        if(subIndex>=0){
            newCombobox[activeNodeKey].options[activeOptionsKey].sub[subIndex]= form;
        }else{
            newCombobox[activeNodeKey].options[activeOptionsKey].sub.push(form);
        }
        change('combobox', newCombobox);
        setEditSub(false);
        setEditForm(null);
        setSubIndex(null);
    }
 

    return(
        <div style={{display: 'flex'}}>
            <div style={{display: 'inline-flex', width: '30%'}}>
                <CardElement
                    height={`calc(100vh - 240px)`}
                    action={<AddButton label={'Добавить'} handleAddClick={addCombobox}/>}
                    content={
                        <TableElement 
                            header={header} 
                            rows={formValues.combobox}
                            onSelect={setActiveNode}
                            selected={activeNodeKey}
                        />
                    }
                />
            </div>
            <div style={{display: 'inline-flex',  width: '70%'}}>
                {formValues.combobox.map((combobox, index) => {
                    return (
                        <Fragment key={index}>
                            {index===activeNodeKey&&
                                <div key={index} style={{width: '100%', display: `${index!==activeNodeKey}&&'none`}}>
                                    <div style={{display: 'inline-flex', width: `50%`}}>
                                        <CardElement 
                                            height={`calc(100vh - 240px)`}
                                            title={
                                                <Fragment>
                                                    <Field 
                                                        name={`combobox[${activeNodeKey}].name`}
                                                        label={`Надпись`}
                                                        component={TextInput}
                                                        style={{width: 200}}
                                                    />
                                                    <Field 
                                                        name={`combobox[${activeNodeKey}].isTask`}
                                                        label={`Тема разговора`}
                                                        component={SwitchInput}
                                                    />
                                                </Fragment>
                                            }
                                            action={<AddButton label={'Добавить опцию'} handleAddClick={editOptionClick}/>}
                                            content={
                                                <SortableList 
                                                    items={combobox.options}
                                                    useDragHandle={true} 
                                                    itemsFields={[{value: 'label'}]}
                                                    setActiveNode={setActiveOptions} 
                                                    activeNodekey={activeOptionsKey}
                                                    sortable={true}
                                                    edit={true}
                                                    onSortabe={onSortabeOptions}
                                                    handleEditClick={editOptionClick}
                                                />
                                            }
                                        />
                                    </div>
                                    <div style={{display: 'inline-flex', width: '50%'}}>
                                        {combobox.options.map((option, key) => (
                                            <Fragment key={key}>
                                                {(combobox.isTask&&key===activeOptionsKey)&&
                                                    <CardElement 
                                                        height={`calc(100vh - 240px)`}
                                                        style={{dispay: 'inline-flex'}}
                                                        title={'Подтема'}
                                                        action={<AddButton label={'Добавить'} handleAddClick={editSubClick}/>}
                                                        content={
                                                            <SortableList 
                                                                items={option.sub}
                                                                useDragHandle={true} 
                                                                itemsFields={[{value: 'label'}]}
                                                                onSortabe={onSortabeSubs}
                                                                sortable={true}
                                                                edit={true}
                                                                handleEditClick={editSubClick}
                                                            />
                                                        }
                                                    />
                                                }
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                            }
                        </Fragment>
                    )
                })}
            </div>
            {editOption&&
                <Modal 
                    width='md'
                    title={editForm ? "Редактировать" : "Добавить"}
                    open={editOption} 
                    handleSaveClick={saveOption} 
                    handleCloseClick={closeEdit} 
                    fields={<EditFormElement />}
                    initial={editForm}
                />
            }
            {editSub&&
                <Modal 
                    width='md'
                    title={editForm ? "Редактировать" : "Добавить"}
                    open={editSub} 
                    handleSaveClick={saveSub} 
                    handleCloseClick={closeEdit} 
                    fields={<EditFormElement />}
                    initial={editForm}
                />
            }
            {openAddCombobox&&
                <Modal 
                    title={"Добавить"}
                    open={openAddCombobox} 
                    handleSaveClick={saveCombobox} 
                    handleCloseClick={closeAddCombobox} 
                    fields={<ComboboxFormAdd />}
                />
            }
        </div>
    )
}


const mapStateToProps = (state, props) => {
    const formValues = getFormValues('project')(state);
    return { 
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'project',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
);

export default enhance(Comboboxes);