import React from 'react';

import UtilsDashboard from '../../utils';

export default({ source, record = {}, ...props }) =>{
    if(!record){
        return <span></span>
    }
    let el = record;
    const elems = source.split(".");
    for (var i = 0; i < elems.length; i++) {
        el = el[elems[i]];
    }
    return <span style={{marginLeft: 10, color: `${props.color}` }}>{UtilsDashboard.secondsToTime(el)}</span>
}