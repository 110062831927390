import React, {Fragment, useEffect, useState, createRef} from 'react';
import { Table, TableBody, TableCell, TableRow, ListItemIcon, Skeleton } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as FileSaver from 'file-saver';
import AudioPlayer from 'react-h5-audio-player';

import { authHeader, GET, provider } from '../../_utils';

import { 
  PhoneField, 
  TimeField,
  DateField
} from '../../_Components';

const Track = ({track, play, currentTrack, download}) => (
  <TableRow style={{ cursor: 'pointer', backgroundColor: currentTrack===track.id ? 'aliceblue' : ''}}>
    <TableCell  onClick={ ()=> play(track)}>
      <DateField value={track.start} format="DD.MM.YYYY HH:mm:ss"/>
    </TableCell>
    <TableCell  onClick={ ()=> play(track)}>
      <PhoneField value={track.originator} />
    </TableCell>
    <TableCell  onClick={ ()=> play(track)}>
      <TimeField
          value={track.duration}
      />
    </TableCell>
    <TableCell>
        <ListItemIcon onClick={() => download(track)} style={{color: '#0b2b60'}}>
          <GetAppIcon/>
        </ListItemIcon>
    </TableCell>
  </TableRow>
)

const Playlist = ({tracks}) => { 
  const player = createRef();
  const [currentTrack, setCurrentTrack] = useState(null);
  const [getLink, setGetLink] = useState(true);
  const [uri, setUri] = useState(null);

  const  _add = (elem) => {
    setUri(getUri(elem));
    setCurrentTrack(elem.id);
  }

  const download = async (elem) => {
    const { data } = await provider( 
        GET,
        '/visor/media', 
        {
            id: elem.id,
            uid: elem.uid
        }
    );
    if( data ){
      const response = await fetch(`/api/visor/play/${data.fileName}`, {
          method: 'get',
          responseType: 'arraybuffer',
          headers: {...authHeader(), 'Content-Type': 'application/json'},
      })
      response.arrayBuffer().then(function(buffer) {
          var blob = new Blob([buffer], {type: "audio/mpeg"});
          FileSaver.saveAs(blob, `${data.fileName}`);
      });
    }
  }


  const onPause = () => {};
  const onPlay = () => {};
  const getUri = async (elem) => {
    setCurrentTrack(elem.id);
    setGetLink(true);
    const response = await fetch(`/api/visor/media/?id=${elem.id}&uid=${elem.uid}`, {
      method: 'get',
      headers: {...authHeader(), 'Content-Type': 'application/json'},
    })
    if( response.status === 200 ){
      response.text().then( text => {
        const data = JSON.parse(text);
          setUri('/api/visor/play/'+data.fileName);
          setGetLink(false);
      });
    }
  }

  useEffect(() => {
    if( tracks.length>0 ){
      getUri(tracks[0]);
    }
  }, [tracks]);

  return  (
        <Fragment>
          {!getLink ?
            <AudioPlayer
                  ref = { player }
                  src={uri}
                  showFilledVolume={true}
                  preload='auto'
                  autoPlay={false}
                  showLoopControl={false}
                  autoPlayAfterSrcChange = {false}
                  onPlay={onPlay}
                  onPause={onPause}
                  showDownloadProgress={true}
                  showJumpControls={false}
                  layout="horizontal"
                  customAdditionalControls={[]}
              />
            :
            <div>
              <Skeleton style={{minHeight: 45}}/>
              <Skeleton style={{minHeight: 45}}/>
            </div>
          }
          <p>Записи</p>
          {tracks.length>0 ?
          <Table style={{marginTop: 8}}>
              <TableBody>
                {tracks.map((track, i) => 
                  <Track
                      track={track}
                      key={'track_' + track.id}
                      index={i}
                      currentTrack={currentTrack}
                      play={_add}
                      download={download}
                    />
                )}
              </TableBody>
          </Table>
          :
          <Skeleton style={{minHeight: 45}}/>
          }
        </Fragment>
  )
}

export default Playlist;
