import React, { useState, Fragment } from 'react';
import CreatDialog from './CreatDialog';
import { AddButton } from '../../_Components';

const ListActions = () => {
    const [openCreat, setOpenCreat] = useState(false);

    const handleClickOpen = () => {
        setOpenCreat(true);
    }

    const handleCloseCreat = () => {
        setOpenCreat(false);
    }

    return(
        <Fragment>
            <AddButton 
                label={'Создать'}
                handleAddClick={handleClickOpen}
            />
            <CreatDialog handleCloseCreat={handleCloseCreat} openCreat={openCreat} />
        </Fragment>
    )
};

export default ListActions;
