import React, { Fragment, useEffect, useState } from 'react';
import { Field } from 'redux-form';

import { 
    TextInput, 
    SelectInput, 
    SwitchInput,
    TrasferSortableInput,
    Modal,
    TabPanel,
    Row,
    QueuesInput
} from '../../_Components';

import {
    UPDATE,
    GET_ONE,
    GET_LIST,
    provider
} from '../../_utils';


const Settings = ({campaigns, queuesList, report}) => (                
    <Fragment>
        <Row>
            <Field
                name="status"
                label={`Статус`}
                options={[{id: 'Active', name: 'Активен'},{id: 'Diasbled', name: 'Заблокирован'}]}
                component={SelectInput}
            />
            <Field
                name="name"
                label={`Название`}
                style={{width: 240}}
                component={TextInput}
            />
            <Field
                name="project.name"
                label={`Проект`}
                style={{width: 240}}
                disabled={true}
                component={TextInput}
            />
            {/* <Field
                name="settings.campaign"
                label={`Компания`}
                options={campaigns}
                component={SelectInput}
            /> */}
        </Row>
        <Row>
            <Field
                name="settings.tarif"
                label={`Тарификация`}
                options={[{id: 'seconds',name:'Посекундная'},{id: 'minutes',name:'Поминутная'}]}
                component={SelectInput}
            />
            <Field
                name="settings.outbound"
                label={`Исходящий`}
                style={{width: 240}}
                component={SwitchInput}
            />
            <Fragment>
                {report.settings.outbound&&
                    <Field
                        name={`settings.prefix`}
                        label={`Префикс`}
                        component={TextInput}
                        style={{width: 50}}
                    />
                }
            </Fragment>
            <Field
                name="settings.visor"
                label={`Прослушка`}
                style={{width: 240}}
                component={SwitchInput}
            />
            <Field
                name="settings.export"
                label={`Экспорт`}
                style={{width: 240}}
                component={SwitchInput}
            />
            <Field
                name="settings.download"
                label={`Скачивание`}
                style={{width: 240}}
                component={SwitchInput}
            />
        </Row>
        <Row> 
            <div style={{width: '60%', display: 'inline-grid'}}>
                <p>Параметры</p>
                <div>
                    <Field
                        name="settings.dashboard.sl"
                        label={`Service Lavel`}
                        component={SwitchInput}
                    />
                    <Field
                        name="settings.dashboard.abandoned"
                        label={`Потеряные`}
                        component={SwitchInput}
                    />
                    <Field
                        name="settings.dashboard.connected"
                        label={`Отвеченые`}
                        component={SwitchInput}
                    />
                    <Field
                        name="settings.dashboard.talktime"
                        label={`Время разговора`}
                        component={SwitchInput}
                    />
                    <Field
                        name="settings.dashboard.held"
                        label={`Время удержания`}
                        component={SwitchInput}
                    />
                </div>
                <p>Статистика</p>
                <div>
                    <Field
                        name="settings.dashboard.statistics.result"
                        label={`Результат`}
                        component={SwitchInput}
                    />
                    <Field
                        name="settings.dashboard.statistics.task"
                        label={`Тема`}
                        component={SwitchInput}
                    />
                    <Field
                        name="settings.dashboard.statistics.trasfer"
                        label={`Перевод`}
                        component={SwitchInput}
                    />
                </div>
            </div>
            <Field 
                name={`settings.queues`} 
                component={QueuesInput}
                queuesList={queuesList}
                style={{
                    width: '40%',
                }}

            />
        </Row>
    </Fragment>
)

const Report = ({ fields }) => (
    <Field
        name="report.report"
        avalible={fields}
        label={`Отчет`}
        component={TrasferSortableInput}
    />
)


const ReportXLSX = ({ fields }) => (
    <Field
        name="report.xls"
        avalible={fields}
        label={`Excel`}
        component={TrasferSortableInput}
    />
)

const SettingsForm = props => {
    const { inbounds, outbounds, campaigns, fields, settings } = props;
    const queuesList = [];
    inbounds.map( q => queuesList.push(q));
    outbounds.map( q => queuesList.push(q));
    const panels = [
        {
            label: 'Общие настройки',
            element: <Settings report={settings} queuesList={queuesList} inbounds={inbounds} outbounds={outbounds} campaigns={campaigns}/>
        },
        {
            label: 'Отчет на экране',
            element: <Report fields={fields}/>
        },
        {
            label: 'Отчет в XLSX',
            element: <ReportXLSX fields={fields}/>
        }
    ];

    return(
        <TabPanel panels={panels} />
    )
}

const SettingsModal = ({ handleCloseClick, showDialog, id, ...props } ) =>{
    const [settings, setSettings] = useState(null);
    const [inbounds, setInbounds] = useState([]);
    const [outbounds, setOutbounds] = useState([]);
    // const [campaigns, setCampaigns] = useState([]);
    const [fields, setFields] = useState([]);
   
    const save = async (formValues) => {
        const { name, report, settings, status } = formValues;
        const response = await provider( UPDATE, '/reports', { id: formValues._id, data: {name, report, settings, status } });
        if(response.status === 200){
            handleCloseClick(true);
        }
    }

    const close = () => {
        handleCloseClick(false);
    }

    useEffect(() => {
        const getReport = async () => {
            const { data, status } = await provider( 
                GET_ONE,
                '/reports', 
                id
            );
            if(status === 200){
                setSettings(data);
                setFields(data.project.fields)
                // getCampaigns(data.project._id);
            }
        };
        const getQueues = async () => {
            const { status, data } = await provider( 
                GET_LIST,
                '/infra/queues', 
                {
                    filter: { },
                    sort: { field: 'name', order: "ASC" },
                    pagination: { page:1, perPage: 999 },
                }
            );
            if(data&&status===200){
                setInbounds(data.filter( q => q.type==="Inbound"));
                setOutbounds(data.filter( q => q.type==="Outbound"));
            }
        }
    
        // const getCampaigns = async (project) => {
        //     const { status, data } = await provider( 
        //         GET_LIST,
        //         '/cards', 
        //         {
        //             filter: { project },
        //             sort: { field: 'name', order: "ASC" },
        //             pagination: { page:1, perPage: 999 },
        //         }
        //     );
        //     if(data&&status===200){
        //         setCampaigns(data);
        //     }
        // }
        getReport();
        getQueues();
    }, [showDialog, id]);
    if(!settings){
        return <div></div>
    }else{
        return (
           
            <Modal
                title={`Настройки отчета`}
                width='lg'
                open={showDialog} 
                handleSaveClick={save} 
                handleCloseClick={close}
                initial={settings}
                style={{height: '100%'}}
                fields={<SettingsForm settings={settings} inbounds={inbounds} outbounds={outbounds} fields={fields} />} 
            />
        )
    }
}

export default SettingsModal;