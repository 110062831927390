import React, {Component, Fragment} from 'react';

import moment from 'moment';
import _ from 'lodash';

import {customItemRenderer, customGroupRenderer} from './components/customRenderers';
import Timeline from './components/timeline';

import './style.css';

import Utils from './utils';

import 'moment/locale/ru';
moment.locale('ru');


class ListTimeline extends Component {
    constructor(props) {
        super(props);
        const startDate = moment().startOf('day');
        const endDate = moment().startOf('day').add(1,'week');
        this.state = {
            loading: false,
            selectedItems: [],
            snap: 60,
            showDialog: false,
            startDate,
            endDate,
            groups: [],
            items: []
        }
    }

    handleInteraction = (type, changes, items) => {
        function absorbChange(itemList, selectedItems) {
          itemList.forEach(item => {
            let i = selectedItems.find(i => {
              return i.key === item.key;
            });
            if (i) {
              item = i;
              item.title = moment.duration(item.end.diff(item.start)).humanize();
            }
          });
        }

        switch (type) {
          case Timeline.changeTypes.dragStart: {
            return this.state.selectedItems;
          }
          case Timeline.changeTypes.dragEnd: {
            const newItems = _.clone(this.state.items);

            absorbChange(newItems, items);
            this.setState({items: newItems});
            break;
          }
          case Timeline.changeTypes.resizeStart: {
            return this.state.selectedItems;
          }
          case Timeline.changeTypes.resizeEnd: {
            const newItems = _.clone(this.state.items);

            // Fold the changes into the item list
            absorbChange(newItems, items);

            this.setState({items: newItems});
            break;
          }
          case Timeline.changeTypes.itemsSelected: {
            this.setState({selectedItems: _.map(changes, 'key')});
            break;
          }
          default:
            return changes;
        }
    }

    handleItemClick = (e, key) => {
        const {selectedItems} = this.state;
        let newSelection = selectedItems.slice();
        const idx = selectedItems.indexOf(key);
        if (idx > -1) {
          newSelection.splice(idx, 1);
        } else {
          newSelection = [Number(key)];
        }
        this.setState({selectedItems: newSelection});
    }

    prepairData = () =>{
        const { data = {}, operators = [], filterValues = {} } = this.props;
        const startDate = moment(this.props.startDate);
        const endDate = moment(this.props.endDate).add(1, 'day');
        const items = [];
        const groups = [];
        this.row=0;
        for (let i = 0; i < operators.length; i++) {
            if(filterValues.operator&&operators[i]._id!==filterValues.operator){
                continue;
            }
            if(!filterValues.empty&&Object.values(data).filter( s=> s.operator === operators[i].id).length<=0){
                continue;
            }
            if(!filterValues.terminated&&operators[i].status==="Terminated"){
                continue;
            }
            groups.push({
                id:  this.row, 
                operator: `${operators[i].id}`, 
                title: `${i} ${operators[i].name}`, 
                data: operators[i]
            });
            this.row++;
        }
        this.key= 0;
        const currentMins = endDate.diff(startDate, 'minutes');
        const days = (currentMins/60)/24;
        for (let j in  data) {
            if(!data[j])
                continue;
            const {color, content, operator, date, start, text, end} = Utils.itemContent(data[j]);
            let row = groups.find( g => g.operator === operator);
            if(row){
                this.key += 1;
                items.push({
                    key: this.key,
                    title: content,
                    id: data[j]._id,
                    color,
                    text,
                    row: row.id,
                    start: days > 4 ? date : start,
                    end: days > 4 ? date.clone().endOf('day') : end,
                    data: data[j]
                });
            }else{
                continue;
            }
        }
        // function groupBy(list, keyGetter) {
        //     const map = new Map();
        //     list.forEach((item) => {
        //          const key = keyGetter(item);
        //          const collection = map.get(key);
        //          if (!collection) {
        //              map.set(key, [item]);
        //          } else {
        //              collection.push(item);
        //          }
        //     });
        //     return map;
        // }
          
        // console.log(groupBy(items, item => item.row));
        return {items, groups};
    }

    render() {
        const {
            selectedItems,
            snap,
        } = this.state;
        const startDate = moment(this.props.startDate);
        const endDate = moment(this.props.endDate).endOf('day');
        const {items, groups} = this.prepairData();
        const rowLayers = Utils.rowLayers(groups, startDate, endDate);

        const handleItemDoubleClick = (e, key) => {
            const item = items.find( i => i.key === key);
            const operator = this.props.operators.find( o => o.id === item.data.operator);
            this.props.handleItemDoubleClick(item, operator);
        }

        const handleRowDoubleClick = (e, rowNumber, clickedTime, snappedClickedTime) => {
            const day = snappedClickedTime.clone().startOf('day');
            const row = groups[rowNumber];
            const operator = this.props.operators.find( o => o.id === row.operator);
            this.props.handleRowDoubleClick(operator.id, day);
        }

        // console.log(items);
        // console.log(groups);


        return (
            <Fragment>
                {groups.length>0&&
                  <Timeline
                    items={items}
                    groups={groups}
                    startDate={startDate}
                    endDate={endDate}
                    rowLayers={rowLayers}
                    selectedItems={selectedItems}
                    timelineMode={0}
                    snapMinutes={snap}
                    onItemDoubleClick={handleItemDoubleClick}
                    onRowDoubleClick={handleRowDoubleClick}
                    // onRowClick={this.handleRowClick}
                    onInteraction={this.handleInteraction}
                    itemRenderer={customItemRenderer}
                    groupRenderer={customGroupRenderer}
                    groupTitleRenderer={undefined}
                  />
                }
            </Fragment>
        );
    }
}

export default ListTimeline;

