import React, { Fragment, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues, Field } from 'redux-form';
import { Route } from 'react-router';

import { Button } from '@mui/material';

import LocalAtmIcon from '@mui/icons-material/LocalAtm';


import { 
    List, 
    TextField, 
    SelectField,
    DateField,
    AddButton,
    Row,
    Modal,
    SelectInput,
    TextInput,
    PhoneInput,
    Drawer,
    AutocompleteInput
} from '../_Components';

import { GET_LIST, CREATE, provider, history } from '../_utils';

import Filter from './Filter';

import Utils from './components/utils';
import Show from './Show';
import Edit from './Edit';

const { operatorsTypes, places, departments, statuses } = Utils.options();


const Options = {
    Place : [
        {
            id: 'minsk',
            name: 'Минск'
        }
    ],
    Department : [
        {
            id: 'Inbound',
            name: 'Входящие',
        },
        {
            id: 'Finance',
            name: 'Финансы',
        },
        {
            id: 'Outbound',
            name: 'Телемаркетинг',
        },{
            id: 'Administaration',
            name: 'Администрация',
        }
    ],
    Status: [
        {
            id: 'Active',
            name: 'Активен'
        },
        {
            id: 'Absence',
            name: 'Отпуск'
        },
        {
            id: 'Terminated',
            name: 'Уволен'
        }
    ],
    Types: [
        {
            id: 'operator',
            name: 'Оператор'
        },
        {
            id: 'leader',
            name: 'Супервизор'
        },
        {
            id: 'staf',
            name: 'Администрация'
        },
        {
            id: 'senior',
            name: 'Старший'
        },
        {
            id: 'test',
            name: 'Обучение'
        }
    ],
    Contract: [
        {
            id:'Agreement',
            name: 'Подряд'
        },
        {
            id:'Contract',
            name: 'Контракт'
        }
    ],
    ScheduleType: [
        {
            id:'day1',
            name: 'Дневная 1',
        },
        {
            id:'day2',
            name: 'Дневная 2',
        },
        {
            id:'shift',
            name: '2/2',
        },
        {
            id:'evening',
            name: 'Вечерняя',
        },
        {
            id:'nigth',
            name: 'Ночная',
        }
    ],
}

const OperatorForm = ({freeLogins}) => (
    <Fragment>  
        <p>Операторские даннные</p>
        <Row>
            <Field
                name="login"
                label={`Операторский номер`}
                options={freeLogins}
                component={AutocompleteInput}
            />
            <Field
                name="type"
                label={`Тип`}
                options={Options.Types}
                component={SelectInput}
            />
            <Field
                name="department"
                label={`Отдел`}
                options={Options.Department}
                component={SelectInput}
            />
            <Field
                name="place"
                label={`Площадка`}
                options={Options.Place}
                component={SelectInput}
            />
        </Row>
        <p>Персональные даннные</p>
        <Row>
            <Field
                name="name.last"
                label={`Фамилия`}
                component={TextInput}
            />
            <Field
                name="name.first"
                label={`Имя`}
                component={TextInput}
            />
            <Field
                name="name.second"
                label={`Отчетсво`}
                component={TextInput}
            />
        </Row>
        <Row>
            <Field
                name="contact.phone.mobile"
                label={`Мобильный телефон`}
                component={PhoneInput}
            />
            <Field
                name="contact.phone.mobile2"
                label={`Мобильный телефон (др.)`}
                component={PhoneInput}
            />
            <Field
                name="contact.phone.home"
                label={`Домашний телефон`}
                component={PhoneInput}
            />
            <Field
                name="contact.email"
                label={`E-mail`}
                component={TextInput}
            />
        </Row>
        <p>Адрес</p>
        <Row>
            <Field
                name="contact.address.state"
                label={`Область`}
                component={TextInput}
            />
            <Field
                name="contact.address.district"
                label={`Район`}
                component={TextInput}
            />
            <Field
                name="contact.address.city"
                label={`Населенный пункт`}
                component={TextInput}
            />
        </Row>
        <Row>
            <Field
                name="contact.address.street"
                label={`Улица`}
                component={TextInput}
            />
            <Field
                name="contact.address.house"
                label={`Дом`}
                component={TextInput}
                style={{width: 50}}
            />
            <Field
                name="contact.address.block"
                label={`Корп.`}
                component={TextInput}
                style={{width: 50}}
            />
            <Field
                name="contact.address.flat"
                label={`Кв.`}
                component={TextInput}
                style={{width: 50}}
            />
        </Row>
    </Fragment>
);

const ReportsButton = () => {
    const openReports = () => {
       history.push('/operators/reports')
    }
    return (
        <Fragment>
            <Button    
                color="primary" 
                size="small"
                variant="contained"
                endIcon={<LocalAtmIcon />}
                onClick={openReports} 
            >
                Отчеты
            </Button>
        </Fragment>
    );
};

const OperatorsList = ({ formValues, perPage, page }) => {
    const [openCreate, setOpenCreate] = useState(false);  
    const [freeLogins, setFreeLogins] = useState([]);
    const onCreate = async () => {
        setOpenCreate(true);
        setFreeLogins([]);
        const { data } = await provider( 
            GET_LIST,
            '/infra/agentsFree', 
            {
                filter: { },
                sort: { field: 'name', order: "ASC" },
                pagination: { page:1, perPage: 999 },
            }
        );
        if(data){
            setFreeLogins(data);
        }
    }

    const handleClose = () => {
        setOpenCreate(false);
    }

    const handleSave = (formValues) => {
        provider( CREATE, '/operators', { data: {status: "Active", ...formValues} })
            .then(({ data }) => {
                setOpenCreate(false);
            });
    }
    return (
        <Fragment>
            <Route path="/operators/:id/:action">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params
                    );
                    return (
                        <Fragment>
                            <List
                                refresh={true}
                                clear={true}
                                show={true}
                                actions={
                                    <Fragment> 
                                        <AddButton handleAddClick={onCreate}/>  
                                        <ReportsButton /> 
                                    </Fragment>
                                }
                                filters={<Filter form="operatorsForm" />}
                                filterDefaultValues={formValues}
                                pathToShow={`/show`}
                                perPage={perPage}
                                page={page}
                                deletedField='deleted'
                                sort={{ field: 'time', order: 'ASC' }} 
                            >   
                                <TextField
                                    notSort={true}
                                    source="fullname"
                                    label="Имя"
                                />
                                <TextField
                                    notSort={true}
                                    source="login"
                                    label="Номер"
                                />
                                <DateField
                                    notSort={true}
                                    source="termitated"
                                    label="Окончание контракта"
                                />
                                <SelectField
                                    notSort={true}
                                    options={places}
                                    source="place"
                                    label="Площадка"
                                />
                                <SelectField
                                    notSort={true}
                                    options={statuses}
                                    source="status"
                                    label="Статус"
                                />
                                <SelectField
                                    notSort={true}
                                    options={departments}
                                    source="department"
                                    label="Отдел"
                                />
                                <SelectField
                                    notSort={true}
                                    options={operatorsTypes}
                                    source="type"
                                    label="Тип"
                                />
                            </List>
                            {(isMatch&&match.params.action === 'show') ? (
                                <Drawer
                                    isMatch={isMatch}
                                    handleClose={handleClose}
                                    content={isMatch ? <Show id={match.params.id} /> : null}
                                />
                            ) : null}
                            {(isMatch&&match.params.action === 'edit') ? (
                                <Drawer
                                    isMatch={isMatch}
                                    handleClose={handleClose}
                                    content={isMatch ? <Edit id={match.params.id} /> : null}
                                />
                            ) : null}
                            
                        </Fragment>
                    );
                }}
            </Route>
            {openCreate && (
                <Modal 
                    width="lg"
                    open={openCreate}
                    title="Создание оператора"
                    handleSaveClick={handleSave} 
                    handleCloseClick={handleClose} 
                    fields={<OperatorForm freeLogins={freeLogins} />}
                />
            )}
        </Fragment>
    );
}

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('operatorsForm')(state);
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { status: 'Active' },
    };
};


export default compose(
    connect(mapStateToProps),
)(OperatorsList);
