import React, { useState, Fragment } from 'react';
import { compose } from 'recompose';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import {  TextInput, SelectInput, CardElement } from '../../_Components';

import OperatorsInput from '../components/OperatorsField';
import { UPDATE, CREATE, provider } from '../../_utils';

import { AddButton, TabPanel, Modal, BlockButton } from '../../_Components';

const rows = ['Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.', 'Вс.'];

const time = Array.apply(null, {length: 17}).map(Number.call,Number).map((el) =>(
    { 'id': (el+7)*60, 'name': `${el+7}:00` }
));

const NewCampaignForm = (props) => {
    const Types = [
        {
            id: 'Inbound',
            name: 'Входящие'
        },
        {
            id: 'Outbound',
            name: 'Исходящие'
        },
    ]
    return(
        <Fragment>
                <Field
                    name="name"
                    required={true}
                    component={TextInput}
                    label={`Название`}
                    style={{width: 200}}
                />
                <Field
                    name='type'
                    required={true}
                    component={SelectInput}
                    options={Types}
                    label={`Тип`}
                    style={{width: 250}}
                />
        </Fragment>
    )
}

const Campaign = ({i, campaigns, operators }) =>(
    <TabPanel 
        panels={[
            {label: 'Расписание', element: <SchedulePanel campaigns={campaigns} rows={rows} i={i} /> },
            {label: 'Операторы', element: <OperatorsPanel operators={operators} campaigns={campaigns} i={i}/>}
        ]}
    />
)


const List = ({campaigns, setSelected, index}) => (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell><b>Название</b></TableCell>
                    <TableCell><b>Статус</b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {campaigns&&campaigns.map((row, i) => (
                    <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => setSelected(i)}
                        style={{
                            cursor: 'pointer', 
                            backgroundColor : i===index ? '#d6eafb' : ''
                        }}
                    >
                        <TableCell style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">{row.status==="Disabled" ? 'Заблокирован': 'Активен'}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

const SchedulePanel = ({rows, i, campaigns}) => (
    <TableContainer>
        <Table size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
                <TableCell align="left">День</TableCell>
                <TableCell>Старт</TableCell>
                <TableCell>Конец</TableCell>
                {time.map( (k, p) => 
                    <TableCell key={k.id}>{k.id/60}</TableCell>
                )}
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row, j) => (
                <TableRow key={j}>
                    <TableCell>
                        {row}
                    </TableCell>
                    <TableCell>
                        <Field
                            name={`campaigns[${i}].schedule[${j+1}].start`}
                            required={true}
                            component={SelectInput}
                            options={time}
                            style={{width: 60}}
                        />
                    </TableCell>
                    <TableCell>
                        <Field
                            name={`campaigns[${i}].schedule[${j+1}].end`}
                            required={true}
                            component={SelectInput}
                            options={time}
                            style={{width: 60}}
                        />
                    </TableCell>
                    {time.map( (k, p) => {
                        let dis = false;
                        if(campaigns[i].schedule[j+1] === undefined){
                            dis = true;
                        }else if( k.id<campaigns[i].schedule[j+1].start || k.id>=campaigns[i].schedule[j+1].end){
                            dis = true
                        }
                        return <TableCell key={p}>
                            {/* {(k.id>=campaigns[i].schedule[j+1].start || k.id<campaigns[i].schedule[j+1].end)&& */}
                                <Field
                                    style={{width: 25}}
                                    name={`campaigns[${i}].schedule[${j+1}].needed[${p+7}]`}
                                    required={true}
                                    component={TextInput}
                                    disabled={dis}
                                />
                            {/* } */}
                        </TableCell>
                    })}
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
)

const OperatorsPanel = ({operators, campaigns, i}) => (
    <Field 
        name={`campaigns[${i}].operators`}
        component={OperatorsInput}
        operatorsList={operators}
        campaign={campaigns[i]}
        style={{width: 400}}
    />
)

const CampaignsFunc = ({formValues, change, operators, isSaving, invalid, ...props}) =>{
    const [selected, setSelected] = useState(0);
    const [newCapmaign, setNewCapmaign] = useState(false);
    const [operatorsList, setOperatorsList] = useState(false);
    const campaigns = formValues.campaigns;

    const selectCampaign = (i) => {
        setSelected(i)
    }

    const openOperatorsList = () => {
        setOperatorsList(!operatorsList);
    }

    const open = () => {
        setNewCapmaign(!newCapmaign);
    }

    const save = async (campaign) => {
        if(campaign){
            campaign.project = formValues._id;
            campaign.status = 'Active';
            campaign.operators = [];
            campaign.queues = [];
            campaign.schedule = [];
            const { status, data } = await provider(
                CREATE,
                '/campaigns',
                { data: campaign }
            );
            if(status===200){
                campaigns.push(data);
                change('campaigns', campaigns);
                await provider(
                    UPDATE,
                    '/projects',
                    {  id: formValues._id,
                        data: formValues
                    }
                );
                setNewCapmaign(!newCapmaign);
            }
        }
    }
    

    const blockCampaign = async (index) => {
        change(`campaigns[${index}].status`, "Disabled");
    }

    const unBlockCampaign = async (index) => {
        change(`campaigns[${index}].status`, "Active");
    }

    const block = () => {
        if(campaigns[selected].status === 'Active'){
            blockCampaign(selected);
        }else{
             unBlockCampaign(selected);
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            <CardElement
                width={30}
                action={<AddButton  handleAddClick={open} label="Добавить"/>}
                content={<List campaigns={campaigns} setSelected={selectCampaign} index={selected}/>}
            />
            {campaigns&&campaigns.map((el, index) => (
                <Fragment key={index}>
                    {index===selected&&
                        <CardElement
                            width={70}
                            action={<BlockButton handleBlockClick={block} status={el.status}/>}
                            content={
                                <Campaign 
                                    el={el} 
                                    i={index}
                                    isSaving={isSaving}
                                    invalid={invalid}
                                    openOperatorsList={openOperatorsList}
                                    operatorsList={operatorsList} 
                                    operators={operators}
                                    campaigns={campaigns}
                                />
                            }
                        />
                    }
                </Fragment>
            ))}
            {newCapmaign&&
                <Modal 
                    title="Добавить компанию"
                    open={newCapmaign} 
                    handleSaveClick={save} 
                    handleCloseClick={open} 
                    fields={<NewCampaignForm {...props}/>} 
                />
            }
        </div>
    );

}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('project')(state);
    return { 
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'project',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    })
);

export default enhance(CampaignsFunc);