import React from 'react';
import { AutocompleteInput, Filter } from '../_Components';

const ReportFilter = ({filterDefaultValues = {}, projects, ...props }) => (  
        <Filter filterValues={filterDefaultValues} {...props}>
            <AutocompleteInput 
                source="project"
                label={`Проект`}
                options={projects}
                alwaysOn
                style={{width: 250}}
            />
            {/* <SelectInput 
                source="status"
                label={`Статус`}
                options={[{id: null, name: 'Все'},{id: 'Active', name: 'Активные'},{id: 'Disabled', name: 'Заблокированные'},]}
                alwaysOn
            /> */}
        </Filter>
);

export default  ReportFilter;