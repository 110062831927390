import React, { Fragment, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { withStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const styles = {
    tab: {
        display: 'flex',
        overflowY: 'auto',
        '& .MuiBox-root': {
            padding: '8px',
            width: '100%',
            fontSize: '0.75rem',
        }
    }
};

const  TabPanel = ({ children, value, index, ...other }) => (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
);

const TabPanelElement = ({panels, classes, isLoading, ...props}) => {
    const [tabIndex, setTabIndex] = useState(0);

    const changePanel = (event, newValue) => {
        setTabIndex(newValue);
    }

    return(
        <Fragment>
            <AppBar position="static">
                <Tabs 
                    value={tabIndex}
                    onChange={changePanel}
                    indicatorColor='secondary'
                    scrollButtons="auto"
                    textColor="inherit"
                >
                    {panels.map((panel, index) => (
                        <Tab key={index} disabled={isLoading} label={panel.label} value={index} />
                    ))}
                </Tabs>
            </AppBar>
            {panels.map((panel, index) => (
                <TabPanel key={index} value={tabIndex} index={index} className={classes.tab}>
                    {panel.element}
                </TabPanel>
            ))}
        </Fragment>
    )
}

const mapStateToProps = (state, props) =>{
    const { fetch } = state;
    return {
        isLoading: !!fetch.loading
    }
  }
const enhance = compose(
    connect(mapStateToProps),
    withStyles(styles),
);

export default enhance(TabPanelElement)