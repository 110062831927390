import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {
    withStyles,
  
} from '@material-ui/core';


import { UPDATE, GET_ONE, GET_LIST, provider, history } from '../_utils';

import { TextInput, SelectInput } from '../_Components'

import Actions from './components/ActionsEdit';

const styles = theme => ({
    root: {
        width: '100%',
    },
    row: {
        display: 'flex',
        paddingBottom: '1em'
    },
    name: {
        flex: '40em',
        display: 'inline-flex',
        marginRight: '2em'
    },
    date: {
        width: '25em',
        // display: 'inline-flex',
        // marginRight: '2em'
    },
    street: {
        flex: '60em',
        display: 'inline-flex',
        marginRight: '2em'
    },
    issue: {
        flex: '100em',
        display: 'inline-flex',
        marginRight: '2em'
    },
    house: {
        flex: '10em',
        display: 'inline-flex',
        marginRight: '2em'
    },
});

const submit = async ( values, action, props ) =>{
    const { formValues, id } = props
    const { status } = await provider(
        UPDATE,
        '/users',
        {  
            id,
            data: formValues
        }
    );
    if(status===200){
        history.goBack()
    }
}


class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
        };
    }

    componentDidMount(){
        const { id } = this.props;
        this.fetchData(id)
    }

    async fetchData(id) {
        const { initialize } = this.props
        provider( GET_ONE, '/users', id)
            .then(({ data }) => {
                if(data){
                    this.setState({status: data.status})
                    initialize(data);
                }
            });
    }

    async roles (operator) {
        const { data } = await provider( 
            GET_LIST,
            '/roles', 
            {
                filter: { },
                sort: { field: 'name', order: "ASC" },
                pagination: { page:1, perPage: 999 },
            }
        );
        if(data){
            const roles = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                roles.push({id: element._id, name: element.label})
            }
            this.setState({ roles });
        }
    }


    block = async () => {
        const { formValues, id } = this.props
        formValues.status = "Disabled"
        const { status } = await provider(
            UPDATE,
            '/users',
            {  id: id,
                data: formValues
            }
        );
        if(status===200){
            history.goBack()
        }
    }

    newToken = async () => {
        const { formValues, id } = this.props
        var buf = new Buffer(16);
        for (var i = 0; i < buf.length; i++) {
            buf[i] = Math.floor(Math.random() * 256);
        }
        formValues.token = buf.toString('hex');
       await provider(
            UPDATE,
            '/users',
            {  id: id,
                data: formValues
            }
        );
    }

  

    unBlock = async () => {
        const { formValues, id } = this.props
        formValues.status = "Active"
        const { status } = await provider(
            UPDATE,
            '/users',
            {  
                id: id,
                data: formValues
            }
        );
        if(status===200){
            history.goBack()
        }
    }

    render() {
        const { isSaving, status } = this.state;
        const { classes, roles } = this.props;
        return (
            <Fragment>
                <Card>
                    <CardHeader
                        classes={{ root: classes.cardHeaderMain }}
                        action={<Actions status={status} block={this.block} unBlock={this.unBlock} newToken={this.newToken}/>}
                    />
                    <CardContent>
                        <div className={classes.row}>
                            <Field
                                className={classes.name}
                                name="last_name"
                                label={`Фамилия`}
                                disabled={isSaving}
                                component={TextInput}
                            />
                            <Field
                                className={classes.name}
                                name="first_name"
                                label={`Имя`}
                                disabled={isSaving}
                                component={TextInput}
                            />
                           
                        </div>
                    </CardContent>
                    <CardContent>
                        <div className={classes.row}>
                            <Field
                                className={classes.name}
                                name="email"
                                label={`E-mail`}
                                disabled={isSaving}
                                component={TextInput}
                            />
                            <Field
                                className={classes.name}
                                name="role"
                                label={`Роль`}
                                options={roles}
                                disabled={isSaving}
                                component={SelectInput}
                            />
                        </div>
                    </CardContent>
                    <CardContent>
                        <div className={classes.row}>
                            <Field
                                className={classes.name}
                                name="token"
                                label={`Токен`}
                                disabled={true}
                                component={TextInput}
                            />
                        </div>
                    </CardContent>
                </Card>
            </Fragment>
        );
    }
}


const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('userForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps),
    reduxForm({
        form: 'userForm',
        // enableReinitialize: true,
        destroyOnUnmount: true,
        onSubmit: submit,
        validate: (values) => {
            const errors = {
                name: {}
            };
            if (!values.name) {
                errors.name = {
                    first: 'Обазятельное поле',
                    last: 'Обазятельное поле'
                }
            }
            if(values.name){
                if (!values.name.first) {
                    errors.name.first = 'Обазятельное поле'
                } 
                if (!values.name.last) {
                    errors.name.last = 'Обазятельное поле'
                } 
            }
            if (!values.email) {
                errors.email = 'Обазятельное поле'
            } 
            if (!values.role) {
                errors.role = 'Обазятельное поле'
            } 
            return errors;
        },
    })
)(UserEdit);