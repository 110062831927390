import React from 'react';
import { render } from 'react-dom';
import {  Provider  } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';

import { Notifier, Confirm, Auth, Reset } from './_Components'

import { history, store } from './_utils';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
    },
    typography: {
        // fontFamily: 'Montserrat',
        fontStyle: "normal"
    },
    palette: {
        primary: {
            light: '#0a2b61',
            main: '#0a2b61'
        },
        secondary: {
            main: '#f44336'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    // padding: '0px !important',
                    marginRight: 20,
                    marginBottom: 8,
                    textTransform: 'unset !important',
                    borderRadius: 'unset !important',
                    width: 158.94,
                    height: 31.64
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginRight: 16
                }
            }
        },
        MuiSelect:{
            styleOverrides: {
                select: {
                    height: '1.4375em'
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "#05a1cd",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 0,
                    fontSize: '0.75rem'
                },
                head: {
                    backgroundColor: "#172b61",
                    color: '#ffff',
                    paddingLeft: 8
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: 240
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem'
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: '#0a2b61'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 'unset !important',
                    boxShadow: 'unset !important',
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: '#0a2b61',
                    fontSize: '0.75rem'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '0.75rem'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem',
                    minHeight: 32
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 32
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    fontSize: '0.75rem',
                }
            }
        }
       
    }
}, ruRU);

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route exact path='/login' component={Auth}/>
                    <Route exact path='/reset' component={Reset}/>
                    <Route path='/' component={App}/>
                </Switch>
            </Router>
            <Notifier />
            <Confirm />
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();