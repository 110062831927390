import React from 'react';
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose';
import { withStyles } from '@mui/styles';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CloseIcon from '@mui/icons-material/Close';

import { Labeled } from '../../_Components';

import Users from './QuickSuggestionsField';

const style = {
    list: {
        height: 320,
        overflowY: 'auto',
        paddingTop: 0,
        paddingBottom: 0
    },
    listItem: {
        padding:'0px 0px 0px 8px !important',
        borderBottom: '1px #bfbdbd solid'
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0
    },
    listItemTextPrymary: {
        fontSize: '0.75rem !important'
    },
    error: {
        color: 'red'
    },
    iconTrash: {
        width: '0.75rem !important',
        height: '0.75rem !important',
        float: 'right',
        color: 'red',
        marginLeft: 8,
        cursor: 'pointer'
    },
};

const UsersInput =
    (
        {
            input: { value, onChange },
            meta: { touched, error },
            usersList,
            usersState,
            onUserDelete,
            onUserAdd,
            classes,
            ...props
        }
    ) => (
        <Labeled {...props}>
            <Users users={value} usersList={usersList} onAdd={onUserAdd}/>
            <List
                // className={classes.list}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {usersState.map( (u, index) =>{
                    let user = usersList.find( o => o._id === u);
                    let terminated= false;
                    if(user){
                        if(user.status==="Disabled"){
                            terminated = true
                        }
                    }
                    return <ListItem style={{ borderBottom: '1px #bfbdbd solid', paddingBottom: 0, paddingTop: 0 }} key={index}>
                                <ListItemText 
                                    style={{color: `${terminated&& 'red'}`}}
                                    // classes={{ primary: !terminated ? classes.listItemTextPrymary : classes.error }}
                                    primary={user&&user.fullname}
                                    secondary={user&&user.email}
                                />
                                <ListItemSecondaryAction>
                                    <CloseIcon className={classes.iconTrash} onClick={()=>onUserDelete(u)}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                })}
            
            </List>
        </Labeled>
)

export default compose(
    withStyles(style),
    withState("usersState", "setUsersState", []),
    withPropsOnChange(
        ['input'],
        ({ input: { value }, meta: { dirty }, setUsersState }) => {
            if (dirty) {
                return;
            }
            if (!value) {
                return;
            }
            setUsersState(value);
        }
    ),
    withHandlers({
        onUserDelete: ({ input: { value, onChange }, setUsersState, ...props }) => (user) => {
            const index = value.indexOf(user);
            if(index >= 0) {
                value.splice(index, 1);
                onChange(value)           
                setUsersState(value);
                onChange(value);
            }
        },
        onUserAdd: ({ input: { value, onChange }, setUsersState, ...props }) => (operator, clearSelection) => {
            value.unshift(operator.id);
            setUsersState(value);
            onChange(value);
            clearSelection();
        },
    })
)(UsersInput);