import React, {  Fragment, useState } from 'react';

import {TableElement} from '../../_Components';
import Phones from './QuickSuggestionsPhone';

const header = [
    {
        value: 'specialistName',
        label: 'Сотрудник',
    },
    {
        value: 'departmentName',
        label: 'Отдел',
    },
    {
        value: 'phone',
        label: 'Телефон',
    },
];

const phonebookList = (list) => {
    const newList = [];
    for (let i = 0; i < list.length; i++) {
        const department = list[i];
        if(department.phonebook){
            for (let j = 0; j < department.phonebook.length; j++) {
                const phone = department.phonebook[j];
                newList.push({
                    specialist: phone._id,
                    specialistName: phone.name,
                    department: department._id,
                    departmentName: department.name,
                    phone: phone.phone,
                    blind: false
                })
                
            }
        }
    };
    return newList;
}

const phonesList = (value, list) =>{
    const newList = [];
    for (let i = 0; i < value.length; i++) {
        const el = value[i];
        const department = list.find( o => o._id === el.department);
        if(department){
            let specialist = department.phonebook.find( o => o._id === el.specialist);
            newList.push({
                specialist: specialist._id,
                specialistName: specialist.name,
                phone: specialist.phone,
                department: department._id,
                departmentName: department.name,
                blind: value.blind
            })
        }   
    }
    return newList;
}

const PhonesInput =
    (
        {
            input: { value, onChange },
            list,
        }
    ) =>{
        const [rows, setRows] = useState(phonesList(value, list));
        const [phonebook] = useState(phonebookList(list))
        // const classes = useStyles();
        const onPhoneDelete = (index) => {
            value.splice(index, 1);
            onChange(value)           
            setRows(phonesList(value, list));
        };

        const onPhoneAdd = (phone, clearSelection) => {
            value.unshift({blind: phone.blind, department: phone.department, specialist: phone.specialist});
            onChange(value);
            setRows(phonesList(value, list));
            clearSelection();
        };

        return(
            <Fragment>
                <Phones phones={value} list={phonebook} onAdd={onPhoneAdd}/>
                <TableElement 
                    header={header} 
                    rows={rows} 
                    remove={true}
                    removeFunc={onPhoneDelete}
                />
            </Fragment>
        )
    } 

export default PhonesInput;