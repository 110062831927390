import React from "react";
import { Button } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const DeleteButton = ({handleDeleteClick, isSaving, label, ...props}) => {
    return(
        <Button
            color="secondary" 
            variant="contained"
            size="small"
            onClick={ () => handleDeleteClick()}
            disabled={isSaving}
            endIcon={<DeleteOutlineIcon />}
            {...props}
        >
             {label || "Удалить"}    
        </Button>
    )
}

export default DeleteButton;