import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@mui/styles';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HelpIcon from '@mui/icons-material/Help';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';

import { CardElement } from '../_Components';

const styles = {
    main: {
        flex: '1',
        border: "1px solid rgba(24,28,33,.06)",
        borderRadius: '.25rem'
    },
    body: {
        fontSize: 20,
        opacity: 1,
        // textAlign: 'center',
    },
    lineF: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        fontSize: 17,
        display: 'inline-flex',
        width: '100%'
    },
    value: {
        textAlign: 'left',
        width: '50%'
    },
    lineS: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        width: '100%',
        fontSize: '0.75rem'
    },
    status: {
        textAlign: 'left',
        width: '20%'
    }
};

const Header = ({ total, talk, notReady, rest, work, other, classes}) =>{
    return (
            <div className={classes.main}>
                <CardElement 
                    content={
                        <div className={classes.body} >
                            <div className={classes.lineF}>
                                <div className={classes.value}>{total}</div>
                                <div style={{color: '#00bcd4', width:'50%', textAlign: 'right'}}><PeopleAltIcon/></div>
                            </div>
                            <div className={classes.lineS}>
                                    <span className={classes.status} style={{color: '#4caf50'}}>
                                        <PhoneInTalkIcon fontSize="1rem" />
                                        {talk}
                                    </span>
                                    <span className={classes.status} style={{color: '#FF9800'}}>
                                        <FreeBreakfastIcon fontSize="1rem"/>
                                        {rest}
                                    </span>
                                    <span className={classes.status} style={{color: '#f44336'}}>
                                        <HighlightOffIcon fontSize="1rem"/>
                                        {notReady}
                                    </span>
                                    <span className={classes.status} style={{color: '#3f51b5'}}>
                                        <HelpIcon fontSize="1rem"/>
                                        {work}
                                    </span>
                                    <span className={classes.status} style={{color: '#f44336'}}>
                                        <PhonelinkOffIcon fontSize="1rem"/>
                                        {other}
                                    </span>
                            </div>
                        </div>
                    }
                />
            </div>
    );
} 

const enhance = compose(
    withStyles(styles)
);

export default enhance(Header);
