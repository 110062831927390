import React from "react";
import PropTypes from 'prop-types';

import { Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

const SaveButton = ({ handleSaveClick, label, isSaving, ...props }) => {
    return(
        <Button
            color="primary" 
            size="small"
            variant="contained"
            onClick={() => handleSaveClick()}
            disabled={isSaving}
            endIcon={<SaveIcon />}
            {...props}
        >
            {label ? label: 'Сохранить'}
        </Button>
    )
}


SaveButton.propTypes = {
    handleSaveClick: PropTypes.func
};

export default SaveButton;