import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {compose} from 'recompose';
import { connect } from 'react-redux';
import { getFormValues, submit, isInvalid } from 'redux-form'

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import TokenIcon from '@mui/icons-material/Token';
import { Button } from '@material-ui/core';

import { history, confirm } from '../../_utils';

const styles = theme =>({
    button: {
        marginLeft: theme.spacing(1),
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
    success: {
        backgroundColor: "#009688d4",
        color: "#ffffff",
        border: "1px solid #009688",
        '&:hover': {
            border: '1px solid #009688',
            backgroundColor: '#009688',
        }
    },
    delete: {
        backgroundColor: '#f44336de',
        color: "#ffffff",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#f44336',
        }
    },
})


class ActionsEdit extends Component {
    close = () =>{
        const { formValues } = this.props;
        if(formValues){
            return confirm({
                        title: "Закрыть?",
                        message: "Элемент не сохранен! Закрыть?",
                        confirmLabel: 'Закрыть',
                        onConfirm: this.goBack,
                    });
        }
        return history.goBack();
    }

    goBack() {
        return history.goBack();
    }
    
    render(){
        const { 
            classes, 
            dispatch,
            block,
            unBlock,
            newToken,
            status,
            isSaving,
            invalid
        } = this.props;
        return(
            <Fragment>
                 <Button                                                         
                    className={classes.button}                                                    
                    size="small"
                    color="primary"
                    variant="outlined"
                    startIcon={<TokenIcon />}
                    disabled={isSaving || invalid}
                    onClick={newToken}
                >
                    Новый токен
                </Button>
                {status==="Active" ?
                    <Button                                                         
                        className={classes.button}   
                        classes={{
                            root: classes.delete
                        }}                                                    
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<BlockIcon />}
                        disabled={isSaving || invalid}
                        onClick={block}
                    >
                        Заблокировать
                    </Button>
                    :
                    <Button                                                         
                        className={classes.button}        
                        classes={{
                            root: classes.success
                        }}  
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<CheckIcon />}
                        disabled={isSaving || invalid}
                        onClick={unBlock}
                    >
                        Разблокировать
                    </Button>
                }
                <Button                                                         
                    className={classes.button}                                                     
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={isSaving || invalid}
                    onClick={() => dispatch(submit('userForm'))}
                >
                    Сохранить
                </Button>
                <Button                                                         
                    className={classes.button}   
                    classes={{
                        root: classes.close
                    }}                                                   
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={this.close} 
                >
                    Закрыть
                </Button>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    const { fetch } = state;
    return { 
        formValues: getFormValues('userForm')(state),
        invalid: isInvalid('userForm')(state),
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false
    };
};

export default  compose(
    connect(mapStateToProps),
    withStyles(styles),
)(ActionsEdit);