import React, { Fragment, useEffect, useState } from 'react';

import { 
    TextField,
    StatusField,
    Labeled,
    CardElement,
    Row,
    EditButton,
    CloseButton
} from '../_Components';

import {GET_ONE, provider, history, permissions } from '../_utils';

// import Actions from './components/Actions';

const OperatorShow = ({ id }) => {
    const [data, setData] = useState({});
    const [isMounted, setIsMounted] = useState(false);
    const permission = permissions();
    const permissionsComponent = ['admin', 'supervisor'];
    useEffect(() => {
        const get = async () => {
            const result = await provider( 
                GET_ONE,
                '/users', 
                id
            );
            if(result.status===200){
               setData(result.data);
               setIsMounted(true);
            }
        }
        if(!isMounted){
            get();
        }
    }, [id, isMounted]);

    const close = () =>{
        return history.push('/users');
    }

    const edit = () =>{
        return history.push(`${history.location.pathname.replace('show', 'edit')}`);
    }

    return (
        <CardElement 
            style={{paddingLeft: 16, paddingTop: 16}}
            action={
                <Fragment>
                    {(permission&&permissionsComponent.indexOf(permission)>=0)&&
                        <EditButton  handleEditClick={edit}/>
                    }
                    <CloseButton handleCloseClick={close}/>
                </Fragment>
            }
            content= {
                <Fragment>
                    <Row>
                        <Labeled label="ФИО">
                            <TextField value={data.fullname || null}/>
                        </Labeled>
                        <Labeled label="E-mail">
                            <TextField value={data.email || null}/>
                        </Labeled>
                    </Row>
                    <Row>
                        <Labeled label="Роль">
                            <TextField value={data.roleLabel || null}/>
                        </Labeled>
                        <Labeled label="Статус">
                            <StatusField value={data.status || null}/>
                        </Labeled>
                    </Row>
                    <Row>
                        <Labeled label="Токен">
                            <TextField value={data.token || null}/>
                        </Labeled>
                    </Row>
                </Fragment>
            }
        />
    )
}

export default OperatorShow;
