import React from 'react';
import {compose} from 'recompose';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import {
    AppBar,
    Toolbar,
    IconButton,
    LinearProgress
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import Responsive from './responsive';
import logo from '../_img/logo.png';

const style = theme => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        flexGrow: 1,
        width: '100%',
        position: 'fixed',
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#fbb725',
        }
    },
    toolbarRoot: {
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: 48,
        backgroundColor: theme.palette.primary.main,
    },
    img: {
        padding: 3,
        width: 95,
        filter: 'brightness(0.01) invert(1)'
    },
    title: {
        flexGrow: 1,
    },
});

const MenuAppBar = ({ classes, isLoading, onOpen }) => (
    <div className={classes.root}>
        <AppBar position="static">
            <Toolbar
                classes={{
                    root: classes.toolbarRoot
                }}
            >   
                <div>
                    
                    <Responsive
                        xsmall={
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={() => onOpen()}
                                color="inherit"
                                classes={{
                                    root: classes.buttonRoot
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        }
                        small={
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={onOpen}
                                color="inherit"
                                classes={{
                                    root: classes.buttonRoot
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        }
                        medium={null}
                    />
                </div>
                <div className={classes.title}>
                    <img className={classes.img} src={logo} alt="Логотип"/>
                </div>
                
            </Toolbar>
            {isLoading&&
                <LinearProgress color="primary" />
            }
        </AppBar>
    </div>
);

const mapState = (state) => {
    const { fetch } = state;
    const isLoading = fetch ? (fetch.loading>0 ? true : false) : false;
    return { 
        isLoading,
    }
}

export default compose(
    withStyles(style),
    connect(mapState),
    withRouter,
    // withStyles(styles),
)(MenuAppBar);    