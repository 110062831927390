export const Options= {
    FieldsTypesOptions : [
            {
                id: 'input',
                name: 'Ввод'
            },
            {
                id: 'number',
                name: 'Цифры'
            },
            {
                id: 'select',
                name: 'Выбор'
            },
            {
                id: 'text',
                name: 'Текст'
            },
            {
                id: 'phone',
                name: 'Телефон'
            },
            {
                id: 'email',
                name: 'E-mail'
            },
            {
                id: 'name',
                name: 'Имя'
            },
            {
                id: 'date',
                name: 'Дата'
            },
            {
                id: 'boolean',
                name: 'Да/Нет'
            },
            {
                id: 'radio',
                name: 'Радио'
            },
            {
                id: 'related',
                name: 'Связаное'
            },
            {
                id: 'integration',
                name: 'Интеграция'
            },
            {
                id: 'uid',
                name: 'UID'
            },
            {
                id: 'call',
                name: 'Результат звонка'
            }, 
            {
                id: 'transfer',
                name: 'Результат перевода'
            }, 
            {
                id: 'callcenter',
                name: 'Call-Center'
            },
            {
                id: 'operator',
                name: 'Оператор'
            }
    ],
    TempateTypeOptions: [
        {
            id: 'email',
            name: 'E-mail'
        },
        {
            id: 'telegram',
            name: 'Telegram'
        }
    ],
    OperatorOptions: [
        {
            id: 'FullName',
            name: 'ФИО'
        },
        {
            id: 'Login',
            name: 'Оперторский номер'
        }
    ],
    CallCenterOptions: [
        {
            id: 'SessionID',
            name: 'ID звонка'
        },
        {
            id: 'Address',
            name: 'Тел. звонящего'
        },
        {
            id: 'DateConnected',
            name: 'Дата'
        },
        {
            id: 'TimeConnected',
            name: 'Время'
        },
        {
            id: 'TalkTime',
            name: 'Длительность'
        },
        {
            id: 'Connected',
            name: 'Соеденено'
        },
        {
            id: 'Busy',
            name: 'Занято'
        },
        {
            id: 'NoAnswer',
            name: 'Нет ответа'
        },
        {
            id: 'Wait',
            name: 'Ожидал'
        },
        {
            id: 'Hold',
            name: 'Удержание'
        },
        {
            id: 'Record',
            name: 'Запись'
        },
        {
            id: 'Queue',
            name: 'Очередь'
        },
    ],
    ResultOptions: [
        {
            id: 'result',
            name: 'Результат'
        },
        {
            id: 'task',
            name: 'Тема'
        },
        {
            id: 'sub_task',
            name: 'Подтема'
        },
        {
            id: 'description',
            name: 'Описание'
        }
    ]
};
