import React from 'react';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Skeleton from '@mui/material/Skeleton';

import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import HelpIcon from '@mui/icons-material/Help';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';

import { Labeled } from '../../../_Components';
import TimeField from './Time';

const Satatuses = ({source, record, ...props}) =>{
    return(
        <div className={props.className}>
            {record ? 
                <Labeled className={props.className} label={props.label}>
                    <span style={{display: 'flex', paddingRight: '5%'}}>
                        <LocalAtmIcon style={{color: '#4CAF50'}}/>
                        <TimeField source={`${source}.paid`} color='#4CAF50' record={record} {...props}/>
                        <FreeBreakfastIcon style={{marginLeft: 'auto', color: '#FF9800'}}/>
                        <TimeField source={`${source}.rest`} color='#FF9800' record={record} {...props}/>
                        <HelpIcon style={{marginLeft: 'auto', color: '#3f51b5'}}/>
                        <TimeField source={`${source}.work`} color='#3f51b5' record={record} {...props}/>
                        <HighlightOffIcon style={{marginLeft: 'auto', color: '#F44336'}}/>
                        <TimeField source={`${source}.notReady`} color='#F44336' record={record} {...props}/>
                        <PhonelinkOffIcon style={{marginLeft: 'auto', color: '#607d8b'}}/>
                        <TimeField source={`${source}.other`} color='#607d8b' record={record} {...props}/>
                    </span>
                </Labeled>
            : 
                <div style={{padding: '16px 0px 0px 20px'}}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rect" height={36} style={{padding: '0 20px'}}/>
                </div>
            }
        </div>
    )
}

export default (Satatuses);