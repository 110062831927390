import React from 'react';
import { withStyles } from '@mui/styles';

import moment from 'moment';

const styel ={
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        flexDirection: 'column'
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
};

const DateField = ({ classes, style, ...props}) =>{
    let { value } = props;
    if(!value){
        return <div></div>;
    }
    if(props.format){
        value = moment(value).format(props.format);
    }else{
        value = moment(value).format("DD.MM.YYYY");
    }
    return (
        <div className={classes.main} style={style}>
            <div className={classes.div}>
                {value}
            </div>
        </div>
)}

export default withStyles(styel)(DateField);
