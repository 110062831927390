import React, { Fragment } from "react";
import PropTypes from 'prop-types';

import { Button } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';

const BlockButton = ({handleBlockClick, status, isSaving, ...props}) => {
    return(
        <Fragment>
            {status==="Active" ?
                <Button                                                         
                    size="small"
                    color="secondary"
                    variant="contained"
                    endIcon={<BlockIcon />}
                    disabled={isSaving}
                    onClick={() => handleBlockClick()}
                    {...props}
                >
                    Заблокировать
                </Button>
            :
                <Button                                                         
                    // className={classes.button}        
                    // classes={{
                    //     root: classes.success
                    // }}  
                    size="small"
                    color="primary"
                    variant="contained"
                    endIcon={<CheckIcon />}
                    disabled={isSaving}
                    onClick={() => handleBlockClick()}
                >
                    Разблокировать
                </Button>
            }
        </Fragment>
    )
}

BlockButton.propTypes = {
    handleBlockClick: PropTypes.func,
    isSaving: PropTypes.bool
};

export default BlockButton;