import React from 'react';
// import PropTypes from 'prop-types';
// import { compose } from 'recompose';
// import { reduxForm, getFormValues } from 'redux-form';
// import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';

import {  SaveButton, CloseButton } from '../../_Components';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GeneratorModal = ({open, handleClose, handleSaveClick, handleCloseClick, isSaving, isGenerated}) => {
    const save = () => handleSaveClick();
    return(
        <Dialog
            maxWidth={'md'}
            fullWidth={true}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogTitle>Генерация отчетов</DialogTitle>
            <DialogContent>
                {(!isSaving&&!isGenerated)&&
                    <div>Нажмите "Запустить" для генерации отчетов</div>
                }
                {isSaving&&
                    <LinearProgress />
                }
                {isGenerated&&
                    <div>Отчеты сгенерированы. Закройте окно и оновите список.</div>
                }
            </DialogContent>
            <DialogActions>
                <SaveButton handleSaveClick={save} label={'Запустить'}/>
                <CloseButton handleCloseClick={handleCloseClick} />
            </DialogActions>
        </Dialog>
    )
}

export default GeneratorModal;