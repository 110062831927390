import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import { withStyles } from '@mui/styles';
import Downshift from "downshift";
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Clear from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import AddButton from './AddButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3)
  },
  container: {
    // flexGrow: 1,
    // position: 'absolute',
    // width: '25%'
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: theme.spacing(38),
    overflowY: 'auto'
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  gutters: {
    padding: '0px 5px'
  },
  multiline:{
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    // fontWeight: 600
  },
  secondary: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    color: '#0a529f'
  },
  secondaryError: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    color: 'red',
    paddingTop: 8
  }
});

function renderInput(inputProps, submitProps) {
  const { InputProps, classes, ref, ...other } = inputProps;
  const handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        if(!submitProps.error.used && !submitProps.error.errorMessage){
          submitProps.onAdd(submitProps.selectedItem, submitProps.clearSelection);
        }
      }
  }
  return (
    <TextField
      style={{width: '80%'}}
      onKeyPress={handleKeyPress}
      variant="standard"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

const checkError = (campaign, suggestion ) => {
  return {}
  // if(!suggestion){
  //   return {}
  // }
  // const used = campaign.operators.indexOf(suggestion._id)>=0;
  // let errorMessage = null;
  // if(used){
  //   errorMessage =  'Оператор включен в проект';
  // }
  // return { used, errorMessage }
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem, classes }) {
  const isHighlighted = highlightedIndex === index;
  const label = selectedItem ? `${selectedItem.specialistName}  ${selectedItem.departmentName} ${selectedItem.phone}` : null;
  const isSelected = (label || '').indexOf(suggestion.label) > -1;
  const {used, errorMessage } = checkError(suggestion);
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.specialist}
      disabled={used||!!errorMessage}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 400 : 200,
        fontSize: '0.75rem',
        lineHeight: 0.75
      }}
    >
      <ListItem 
        button
        classes={{
          gutters: classes.gutters,
        }}
        disabled={used||!!errorMessage}
      >
          <ListItemText 
            classes={{
              multiline: classes.multiline,
              primary: classes.primary,
              secondary: !!errorMessage ? classes.secondaryError : classes.secondary
            }}
            primary={`${suggestion.specialistName} ${suggestion.departmentName} ${suggestion.phone}`}  
            secondary={errorMessage} 
          />
       </ListItem>
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value, suggestions, selectedItem) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const result = inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        if(!suggestion.specialistName){
          suggestion.specialistName = '';
        }
        try {
            const specialistName = suggestion.specialistName.toLowerCase().trim().search(inputValue)>=0;
            const departmentName = suggestion.departmentName.toString().toLowerCase().trim().search(inputValue)>=0;
            const phone = suggestion.phone.toString().toLowerCase().trim().search(inputValue)>=0;

            const keep = count < 20 && ( (specialistName || departmentName || phone)) ;
            if (keep) {
              count += 1;
            }
            return keep;
        }
        catch(e){
          return false;
        }
      });
  return result;
}

function QuickSuggestionsField(props) {
  const { classes, campaign, onAdd, list } = props;
  return (
    <div className={classes.root}>
      < Downshift
        itemToString={item => (item ? `${item.specialistName} ${item.departmentName} ${item.phone}` : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          clearSelection,
        }) =>{
          const {used, errorMessage } = checkError(campaign, selectedItem);
          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: false,
                classes,
                InputProps: getInputProps({
                  placeholder: 'Добавить телефон',
                }),
              },{
                  onAdd: onAdd,
                  clearSelection: clearSelection,
                  selectedItem: selectedItem,
                  error: {
                    used, errorMessage
                  }
              })}
              <IconButton 
                size="small" 
                style={{color: selectedItem&&'red'}} 
                disabled={!selectedItem}  
                onClick={clearSelection} 
                aria-label="Очистить"
              >
                <Clear size="small" />
              </IconButton>
              <IconButton 
                size='small' 
                // style={{color: (selectedItem&&!!!used&&!!!errorMessage )&&'#0a529f'}} 
                disabled={ !selectedItem  } //|| !!used || !!errorMessage
                onClick={()=> onAdd(selectedItem, clearSelection)} 
                aria-label="Добавить телефон"
              >
                  <ArrowForwardIcon fontSize="small"/>
              </IconButton>
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue, list, selectedItem,).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        campaign,
                        itemProps: getItemProps({ item: suggestion}),
                        highlightedIndex,
                        selectedItem,
                        classes
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        } 
      </Downshift>
      <div className={classes.divider} />
    </div>
  );
}

QuickSuggestionsField.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  var recordLiveValues = getFormValues('card')(state);
  return { formValues: recordLiveValues};
};

const enhance = compose(
  withStyles(styles),
  connect(
      mapStateToProps
  )
);

export default enhance(QuickSuggestionsField);