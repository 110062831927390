
import React from 'react';
import { withStyles } from '@mui/styles';

import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import Skeleton from '@mui/material/Skeleton';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Grid,
    Paper
} from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { Labeled } from '../../../_Components';

const styles = {
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    icon: {
        top: 133,
        position: 'relative',
        padding: 5
    }
}

const QueueListLeft = ({classes, items, handleToggle}) =>{
    // let record = {search: {}};
    // const search = '';
    return(
        <Paper className={classes.paper} style={{width: '100%'}}>
            {/* <TextInput record={record} source="searchLeft" label=" " className={classes.searchInput}/> */}
            <List dense component="div" role="list">
                {items.map(value => {
                    const labelId = `transfer-list-item-${value.id}-label`;
                    // if(value.Name.toLowerCase().trim().search(search ? search.toLowerCase().trim() : '')>=0){
                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)} style={{padding: 5}}>
                            {value.type === 'Inbound'&&
                                <ListItemIcon>
                                    <CallReceivedIcon style={{color: '#59af50'}} />
                                </ListItemIcon>
                            }
                            {value.type === 'Outbound'&&
                                <ListItemIcon>
                                    <CallMadeIcon style={{color: '#55bdd4'}}/>
                                </ListItemIcon>
                            }
                            <ListItemText id={labelId} primary={`${value.name}`} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} />
                        </ListItem>
                    )
                    // };
                })}
                <ListItem />
            </List>
        </Paper>
    )
};

 const QueueListRight = ({classes, items, handleToggle}) =>{
    // let record = {search: {}};
    // const search = '';
    return(
        <Paper className={classes.paper} style={{width: '100%'}}>
            {/* <TextInput record={record} source="searchRight" label=" " className={classes.searchInput}/> */}
            <List dense component="div" role="list">
                {items.map(value => {
                    const labelId = `transfer-list-item-${value.id}-label`;
                    // if(value.Name.toLowerCase().trim().search(search ? search.toLowerCase().trim() : '')>=0){
                        return (
                            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)} style={{padding: 5}}>
                                {value.type === 'Inbound'&&
                                    <ListItemIcon>
                                        <CallReceivedIcon style={{color: '#59af50'}} />
                                    </ListItemIcon>
                                }
                                {value.type === 'Outbound'&&
                                    <ListItemIcon>
                                        <CallMadeIcon style={{color: '#55bdd4'}}/>
                                    </ListItemIcon>
                                }
                              <ListItemText id={labelId} primary={`${value.name}`} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} />
                            </ListItem>
                        )
                    // };
                })}
                <ListItem />
            </List>
        </Paper>
    )
};

const Queues = ({classes, source, left, right, handleToggle, ...props}) => (
    <div className={props.className}>
        {(left && right) ?
            <div style={{display: 'flex'}}>
                <Labeled label="Доступные очереди">
                    <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                        <Grid item style={{width: '100%'}}><QueueListLeft items={left} classes={classes} handleToggle={handleToggle}/></Grid>
                    </Grid>
                </Labeled>
                <SyncAltIcon className={classes.icon}/>
                <Labeled label="Включен в очереди">
                    <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                        <Grid item style={{width: '100%'}}><QueueListRight items={right} classes={classes} handleToggle={handleToggle}/></Grid>
                    </Grid>
                </Labeled>
            </div>
        : 
            <div style={{display: 'flex'}}>
                <div style={{padding: '16px 0px 0px 0px', width: '100%'}}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rect" height={242} style={{padding: '0 20px'}}/>
                </div>
                <SyncAltIcon className={classes.icon}/>
                <div style={{padding: '16px 0px 0px 0px', width: '100%'}}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rect" height={242} style={{padding: '0 20px'}}/>
                </div>
            </div>
        }
    </div>
)

export default withStyles(styles)(Queues);