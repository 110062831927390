import React from 'react';
import { withStyles } from '@mui/styles';

const style = {
    labeledMain: {
        width: '100%',
        marginTop: 8,
        marginBottom: 0,
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    labeledDiv: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        padding: '8px 0 4px',
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle',
        fontSize: '0.75rem'
    },
    labeledLabel: {
        position: 'relative',
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        color: '#0b2b61',
        padding: 0,
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1
    }
};

const Labeled = ({ classes, style, children, ...props}) =>(
    <div className={classes.labeledMain} style={style}>
        <label className={classes.labeledLabel}>
            {props.label}
        </label>
        <div className={classes.labeledDiv}>
            {children}
        </div>
    </div>
)


export default withStyles(style)(Labeled);
