export default class Utils {
	static  options () {
		return {
				places: [ 
					{id: 'minsk', name: 'Минск'},
					{id: 'dabrabyt', name: 'Банк "Дабрабыт"'} 
				],
				scheduleChange: [
					{ name: "Изменение", id: "change"},
					{ name: "Отмена", id: "cencel"},
					{ name: "Больничный", id: "ill"},
					{ name: "Прогул", id: "absenteeism"}
				],
				departments: [
					{ id: 'Inbound', name: 'Входящие'},
					{ id: 'Outstaffing', name: 'Аутстафинг'},
					{ id: 'Outbound', name: 'Исходящие'},
					{ id: 'Finance', name: 'Финансы'},
					{ id: 'Administaration', name: 'Администрация'}
				],
				scheduleType: [
					{name: "Дневная 1", id: "day1"},
					{name: "Дневная 2", id: "day2"},
					{name: "2/2", id: "shift"},
					{name: "Вечерняя", id: "evening"},
					{name: "Ночная", id: "nigth"}
				],
				statuses: [
					{ id: 'Active', name: 'Активен' },
					{ id: 'Terminated', name: 'Уволен' }
				],
				reportsStatus: [
				    { id: 'Advance', name: 'Подготовлен' },
				    { id: 'Approved', name: 'Утвержден' },
				    { id: 'Accepted', name: 'Подписан' },
				    { id: 'Cenceled', name: 'Анулирован' }
				],
				operatorsTypes: [
					{ id: 'operator', name: 'Оператор' },
					{ id: 'leader', name: 'Супервизор' },
					{ id: 'staf', name: 'Администрация' },
					{ id: 'senior', name: 'Старший' },
					{ id: 'test', name: 'Обучение' }
				],
				contractTypes: [
					{ id:'Agreement',  name: 'Подряд' },
					{ id:'Contract', name: 'Контракт' }
				],
			}
	}

}