import React from 'react';

import Responsive from '../../_Components/responsive'

import Desktop from './Desktop';
import Mobile from './Mobile';
import Tablet from './Tablet';

const Inbound = ({ items = {}, statistics = [], ...props }) =>{
    return(
        <Responsive 
            xsmall={
                <Mobile 
                    items={items} 
                    statistics={statistics} 
                    {...props}
                />
            }
            small={
                <Tablet 
                    items={items} 
                    statistics={statistics} 
                    {...props}
                />
            }
            medium={
                <Desktop 
                    items={items} 
                    statistics={statistics} 
                    {...props}
                />
            }
        />
    );
} 

export default Inbound;
