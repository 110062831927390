import React from "react";
import { Button } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyButton = ({handleCopyClick, isSaving, label, ...props}) => (
    <Button
        color="primary" 
        size="small"
        variant="contained"
        endIcon={<ContentCopyIcon />}
        onClick={() => handleCopyClick()}
        {...props}
    >
        {label || "Добавить"}
    </Button>
)

export default CopyButton;