import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, Field } from 'redux-form';
import compose from 'recompose/compose';

import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import logo from '../_img/logo.png';
// import Background from '../img/bg_synevo.jpg';

import { UPDATE, provider } from '../_utils';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: `url(${Background})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#0a529f'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
        flexDirection: 'column'
    },
    reset: {
        paddingTop: '1em'
    }

});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) =>{
    return(
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
    )
}

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.submit = this.submit.bind(this);
        this.setLoginForm = this.setLoginForm.bind(this);
    }
    
    async submit({username}){
        const { status } = await provider(
            UPDATE,
            '/reset',
            {
                data: {
                    username
                }
            }
        );
        if(status === 200 ){
            this.setLoginForm();
        }
    }

    setLoginForm(){
        const { history } = this.props;
        history.push("/login")
    }

    render(){
        const { classes, handleSubmit, isLoading } = this.props;
        return (
            <div className={classes.main}>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                            <img src={logo} alt="Логотип"/>
                    </div>
                    <form onSubmit={handleSubmit(this.submit)}>
                        <div className={classes.hint}>
                            Новый пароль быдет выслан<br/>
                            на Ваш e-mail
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="username"
                                    component={renderInput}
                                    label={`E-mail`}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={isLoading}
                                className={classes.button}
                                fullWidth
                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                Сбросить
                            </Button>
                            <Link 
                                component="button"
                                variant="body2" 
                                onClick={this.setLoginForm}
                                className={classes.reset}
                            >
                                Войти
                            </Link>
                        </CardActions>
                    </form>
                </Card>
            </div>
        );
    }
}

const mapState = (state) => {
    const { fetch} = state;
    const isLoading = !!fetch.loading>0 || false;
    var recordLiveValues = getFormValues('reset')(state);
    return { formValues: recordLiveValues, isLoading }
}

const enhance = compose(
    connect( mapState),
    reduxForm({
        form: 'reset',
        validate: (values) => {
            const errors = {};
            if (!values.username) {
                errors.username = "Не верный e-mail";
            }
            return errors;
        },
    }),
    withStyles(styles)
);

const EnhancedReset = enhance(Reset);


export default EnhancedReset;
