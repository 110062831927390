import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

import moment from 'moment';

import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';

import { DateInput, TextInput, SelectInput, CardElement, SaveButton, CloseButton, DeleteButton, Row, AutocompleteInput } from '../../_Components';

import {
    UPDATE,
    DELETE,
    CREATE,
    GET_ONE,
    provider,
    confirm
} from '../../_utils';

import Utils from '../utils';

const { places, depatments, scheduleType, scheduleChange } = Utils.options();


const submit = async ( data ) =>{
    console.log(data);
}

const renderSchedules = ({ fields, meta: { error, submitFailed } }) =>{
    const formatTime = value => {
        return moment().startOf('day').add(value, 'minutes').format("HH:mm");
    }
    const parseTime = value => {
        return moment(value, "HH:mm").diff(moment().startOf('day'), 'minutes');
    }
    return(
        <Fragment>
            {fields.map((schedule, index) =>(
                <div key={index}>
                    <Field
                        name={`${schedule}.start`}
                        required={true}
                        component={TextInput}
                        label={`C`}
                        parse={ (value) => parseTime(value)}
                        format={ (value) => formatTime(value)}
                        type="time"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        style={{width: 80, paddingRight: 20}}
                    />
                    <Field
                        name={`${schedule}.end`}
                        required={true}
                        component={TextInput}
                        label={`По`}
                        parse={ (value) => parseTime(value)}
                        format={ (value) => formatTime(value)}
                        type="time"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        style={{width: 80, paddingRight: 20}}
                    />
                </div>
            ))}
        </Fragment>
    )
}

class DialogSchedule extends Component {
    state = {};

    componentDidMount(){
        const { item, initialValues, initialize } = this.props;
        if(item){
            this.fetchData(item.id)
        }else{
            initialize(initialValues);
        }
    }

    componentDidUpdate (prev) { 
        if(prev&&prev.formValues&&prev.formValues.operator!==this.props.formValues.operator){
            const operator = this.props.operators.find( o => o.id===this.props.formValues.operator);
            if(operator){
                const values = {
                    place: operator.place,
                    department: operator.department
                };
                if(operator.schedule){
                    values.type = operator.schedule.type;
                }
                this.setForm(values);
            }
        }
    }

    async fetchData(id){
        const { data } = await provider(GET_ONE, '/schedule', id);
        this.setForm(data);
    }

    setForm = (values) => {
        Object.getOwnPropertyNames(values).map( key =>{
            return this.props.change(key, values[key]);
        });
    }

    submit = () =>{
        const { item, formValues } = this.props;
        const newData = {
            operator: formValues.operator,
            date: moment(formValues.date),
            type: formValues.type,
            place: formValues.place,
            department: formValues.department,
            change: formValues.change,
            description: formValues.description,
            schedules: []
        }
        let scheduleDate = moment(formValues.date).clone().format("YYYY-MM-DD");
        for (var i = 0; i < formValues.schedules.length; i++) {
            let el = formValues.schedules[i];
            const start = el.start;
            const end = el.end;
            newData.schedules.push({date: scheduleDate, start: start, end: end});
        }
        if(!item){
            provider( CREATE, '/schedule', { data: newData })
                .then(({ data }) => {
                    this.props.onSave(data);
                });
        }else{
            provider( UPDATE, '/schedule', { id: item.id, data: newData })
                .then(({ data }) => {
                    this.props.onSave(data);
                });
        }
        
    }

    handleDelete = () =>{
        const { item } = this.props;
        this.props.onCancel();
        confirm({title: 'Удалить?', message: "Вы действительно хотите удалить?", confirmLabel: "Удалить"}, () => {
            provider( DELETE, '/schedule', item.id)
            .then(({data}) => {
                this.props.onDelete(item.id);
            });
        })
    }

    render() {
        const { onCancel, operators, loading, item } = this.props;
        return (
            <Fragment>
                <CardElement 
                    style={{paddingLeft: 16, paddingTop: 64}}
                    action={
                        <Fragment>
                            <SaveButton handleSaveClick={this.submit}/>
                            {(item&&item.id)&&
                                <DeleteButton handleDeleteClick={this.handleDelete}/>
                            }
                            <CloseButton handleCloseClick={onCancel} />
                        </Fragment>
                    }
                    content={
                        !loading ? 
                            <Fragment>
                                <Row style={{paddingTop: 16}}>
                                    <Field
                                        name="date"
                                        required={true}
                                        component={DateInput}
                                        label={`Дата`}
                                        style={{width: 180}}
                                    />
                                    <Field
                                        name="operator"
                                        options={operators}
                                        required={true}
                                        component={AutocompleteInput}
                                        label={`Оператор`}
                                        style={{width: 440}}
                                    />
                                </Row>
                                <Row>
                                    <Field
                                        name="place"
                                        options={places}
                                        required={true}
                                        component={SelectInput}
                                        label={`Площадка`}
                                        style={{width: 180}}
                                    />
                                    <Field
                                        name="department"
                                        options={depatments}
                                        required={true}
                                        component={SelectInput}
                                        label={`Отдел`}
                                        style={{width: 180}}
                                    />
                                    <Field
                                        name="type"
                                        required={true}
                                        options={scheduleType}
                                        component={SelectInput}
                                        label={`Тип смены`}
                                        style={{width: 180}}
                                    />
                                </Row>
                                <div><label>Смена</label></div>
                                <Row>
                                    <FieldArray name="schedules" component={renderSchedules}/>
                                </Row>
                                {(item&&item.id)&&
                                    <Row>
                                        <Field
                                            name="change"
                                            options={scheduleChange}
                                            required={true}
                                            component={SelectInput}
                                            label={`Изменение`}
                                            style={{width: 180}}
                                        />
                                        <Field
                                            name="description"
                                            required={true}
                                            component={TextInput}
                                            label={`Описание`}
                                            style={{width: 400}}
                                        />
                                    </Row>
                                }
                            </Fragment>
                        :
                            <Fragment>
                                <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                    <Skeleton variant="text" height={48} animation="wave"/>
                                </div>
                                <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                    <Skeleton variant="text" height={48} animation="wave"/>
                                </div>
                                <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                    <Skeleton variant="text" width={43} height={21} animation="wave"/>
                                </div>
                                <div style={{padding: '0px 0px 0px 50px', width: '95%'}}>
                                    <Skeleton variant="text" width={180} height={48} animation="wave"/>
                                </div>
                                <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                    <Skeleton variant="text" height={48} animation="wave"/>
                                </div>
                            </Fragment>
                    }
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const { initialValues, operators }  = props;
    const operator = operators.find(  o => o.id === initialValues.operator);
    const values = {
        date: moment(),
        schedules: [{
                start: 990,
                end: 1260
        }]
    };
    if(operator){
        values.place =  operator.place;
        values.department = operator.department;
        if(operator.schedule){
            values.type = operator.schedule.type;
            values.schedules[0]={
                start: 990,
                end: 1260
            }
        }
    }
    const formValues = getFormValues('scheduleEdit')(state);
    return { 
        initialValues: {
                operator: operator ? operator.id : null, 
                ...values, 
                ...initialValues
            },
        formValues: formValues,
        loading: !!fetch.loading
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'scheduleEdit',
        onSubmit: submit,
        validate: (values) => {
            const errors = {};
            // if (!values.operator) {
            //     errors.operator = "Обязательное поле";
            // }
            // if (!values.place) {
            //     errors.place = "Обязательное поле";
            // }
            // if (!values.date) {
            //     errors.date = "Обязательное поле";
            // }
            // if (!values.department) {
            //     errors.department = "Обязательное поле";
            // }
            // if (!values.type) {
            //     errors.type = "Обязательное поле";
            // }
            // if (!values.change) {
            //     errors.change = "Обязательное поле";
            // }
            // if (!values.description) {
            //     errors.description = "Обязательное поле";
            // }
            return errors;
        },
        destroyOnUnmount: true
    }),
);

export default enhance(DialogSchedule);