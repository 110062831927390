import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardActions from '@material-ui/core/CardActions';
// import Button from '@material-ui/core/Button';
// import IconCancel from '@mui/icons-material/Cancel';
// import SaveIcon from '@mui/icons-material/Save';

import {
    StatusesField,
    OccField,
    UtField,
    TalkAllField,
    TalkInboundField,
    TalkOutboundField,
    ScheduleField,
    // ProjectsCard,
    QueuesCard
} from './fields';

import {
    GET_ONE,
    GET_LIST,
    provider,
} from '../../_utils';
import { CardElement, CloseButton, SaveButton } from '../../_Components';
// import { Save } from '@mui/icons-material';

export const styles = {
    // root: {
    //     margin: 'auto',
    // },
    // card: {
    //     height: 'calc(100vh - 48px)', 
    //     overflow: 'auto', 
    //     width: 850
    // },
    // paper: {
    //     width: 200,
    //     height: 230,
    //     overflow: 'auto',
    // },
    // login: {
    //     display: 'inline-block',
    //     width: '15%'
    // },
    // name: {
    //     display: 'inline-block',
    //     width: '50%'
    // },
    // places: {
    //     display: 'inline-block',
    //     width: '15%'
    // },
    // department: {
    //     display: 'inline-block',
    //     width: '15%',
    //     marginRight: 'unset'
    // },
    // start: {
    //     display: 'inline-block',
    //     width: '25%',
    // },
    status: {
        display: 'inline-block',
        width: '70%',
    },
    occ: {
        display: 'inline-block',
        width: '10%'
    },
    ut: {
        display: 'inline-block',
        width: '10%'
    },
    talktimeAll: {
        display: 'inline-block',
        width: '25%',
    }, 
    talktimeIn: {
        display: 'inline-block',
        width: '37%',
    }, 
    talktimeOut: {
        display: 'inline-block',
        width: '38%',
    },
    schedule: {
        display: 'inline-block',
        width: '100%',
        '& #schedule':{
            display: 'inline-flex',
            width: '100%',
            marginLeft: 50
        },
    },
    // change: {
    //     width: '20%',
    //     display: 'inline-block'
    // },
    // description: {
    //     width: '45%',   
    //     display: 'inline-block',
    // },
    // projects: {
    //     display: 'inline-block',
    //     width: '35%',
    //     '& tr':{
    //         height: 25,
    //         '& span': {
    //             fontSize: '0.675rem'
    //         },
    //         '& td': {
    //             padding: '0 0',
    //         }
    //     }
    // },
    queues: {
        display: 'inline-block',
        width: '100%',
    },
    // searchInput: {
    //     margin: 0,
    //     '& div': { margin: 0 }
    // },
    // action:{
    //     display: 'flex',
    //     position: 'absolute',
    //     bottom: 0,
    //     width: 'calc(100% - 32px)',
    //     margin: '8px 16px',
    //     padding: 0
    // },
    // cencelButton: {
    //     marginLeft: 'auto !important'
    // }
    
};

class DialogAgent extends Component {
    state = {
        item: null,
        checked: [],
    };
    componentDidMount() {
        let agentData;
        const agent = this.getAgent();
        agent.then( data => {
            agentData = data;
            this.setState({loading: false, item: data})
            return this.getAgentData()
        })
        .then( agentInfo => {
            agentData.queues = agentInfo.Queues;
            return this.getQueues();
        })
        .then( result => {
            const queues = result.data;
            let left = [];
            let right = [];                
            for (var i = 0; i < queues.length; i++) {
                let queue = queues[i];
                const flag = agentData.queues.find( q => q === queue.id);
                if(!flag){
                    left.push(queue)
                }else{
                    right.push(queue)
                }
            }
            left.sort( (a,b) => a.name-b.name);
            right.sort( (a,b) => a.name-b.name);
            this.setState({left, right});
        })
    }

    async getAgent(){
        const { agent } = this.props;
        const { data } = await provider(GET_ONE, '/dashboard/agent', agent.id);
        if(data){
            return data;
        }else{
            return null;
        }
    }

    async getAgentData(){
        const { agent } = this.props;
        const { data } = await provider(GET_ONE, '/infra/agent', agent.id);
        if(data){
            return data;
        }else{
            return null;
        }
    }

    async getQueues() {
        const queues =  await provider(
            GET_LIST,
            '/infra/queues',
            {
                filter: { },
                sort: { field: 'login', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
            }
        );
        return queues;
    }

    not = (a, b) => {
        return a.filter(value => b.id!==value.id);
    }

    handleToggle = value => () => {
        let { left, right } = this.state;
        const leftIndex = left.find( l => l.id===value.id);
        if(leftIndex){
            right = right.concat([value]);
            left = this.not(left, value);
        }else{
            left = left.concat([value]);
            right = this.not(right, value);
        }
        left.sort( (a,b) => a.name-b.name);
        right.sort( (a,b) => a.name-b.name);
        return this.setState({left, right});
    };

    save = () => {
        const { onSave } = this.props;
        const { right } =this.state;
        onSave(right);
    }

    render() {
        const {classes, onCancel, agent={} } = this.props;
        const { item, left, right } =this.state;
        if(!agent){
            return <div />
        }
        return (
            <CardElement
                style={{paddingLeft: 16, paddingTop: 64}}
                title={`${agent.login} ${agent.name}`}
                action={
                    <Fragment>
                        <SaveButton handleSaveClick={this.save}/>
                        <CloseButton onClick={onCancel}/>
                    </Fragment>
                }
                content={
                    <Fragment>
                        <ScheduleField label="Смена" record={item} className={classes.schedule} />
                        <StatusesField label="Статусы" record={item} source="statistic"  className={classes.status}/>
                        <OccField label="OCC" record={item} className={classes.occ}/>
                        <UtField label="UT" record={item} className={classes.ut}/>
                        <TalkAllField label="Разговоры все" record={item} source="statistic"  className={classes.talktimeAll}/>
                        <TalkInboundField label="Входящие" record={item} source="statistic"  className={classes.talktimeIn}/>
                        <TalkOutboundField label="Исходящие" record={item} source="statistic"  className={classes.talktimeOut}/>
                        {/* <ProjectsCard record={item}  className={classes.projects}/> */}
                        <QueuesCard left={left} right={right} handleToggle={this.handleToggle} className={classes.queues} />
                    </Fragment>
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('agent')(state);
    return { form: recordLiveValues};
};

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(DialogAgent);