import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

import compose from 'recompose/compose';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import './dateRange.css'; // theme css file

import EventIcon from '@mui/icons-material/Event';


const style = {
    calendarLabelDate: {
        marginRight: 4,
        marginLeft: 4,
        // fontSize: '.75rem',
    },
    calendarLabelDateDisabled: {
        marginRight: 4,
        marginLeft: 4,
        fontSize: '.85rem',
        color: '#00000061',
    },
    calendar: {
        paddingRight: 8,
        paddingBottom: 0,
    },
    calendarDisabled: {
        paddingRight: 8,
        paddingBottom: 0,
        color: '#00000061',
    },
    calendarElement: {
        borderBottom: '2px solid #012B62',
        marginRight: 24,
        display: 'flex',
        fontSize: '.75rem',
        width: 90
    },
    calendarElementDasabled: {
        borderBottom: '1px solid #00000061',
        borderBottomStyle: 'dotted',
        marginRight: 24,
        display: 'flex',
        color: '#00000061'
    },
    calendaeComponent: {
        fontWeight: 400,
        lineHeight: '1.4375em',
        boxSizing: 'border-box',
        cursor: 'text',
        display: 'inline-flex',
        position: 'relative',
       
    },
    calendarRow: {
        display: 'flex',
        alignItems: 'flex-end',
        cursor: 'pointer'
    },
    calendarMain: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    calendarLabel: {
        color: '#0b2b61',
        position: 'relative',
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        lineHeight: '1.4375em',
        padding: 0,
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    calendarLabelDisabled: {
        color: '#00000061',
        position: 'relative',
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        top: 0,
        left: 0,
        lineHeight: '1.4375em',
        padding: 0,
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    }
  };

const formatInterval = ({startDate, endDate}) => {
    return {
        startDate: startDate ? moment(startDate).startOf('day') : moment().startOf('day'), 
        endDate: endDate ? moment(endDate).endOf('day') : moment().endOf('day'), 
    }
}

const  DateRangeInput = ({classes, ...props}) =>{
    const { input: { value, onChange }, maxDate, isLoading } = props;
    const {startDate, endDate} = value;

    const [ranges, setRanges] = React.useState({startDate: startDate ? new Date(startDate) : new Date(), endDate: endDate ? new Date(endDate) : new Date(), key: 'selection', color: '#012B62'});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [datesPicked, setDatesPicked] = React.useState(0);
    const handleClick = (event) => {
        if(!isLoading){
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
            setAnchorEl(null);
    };

    const selectRange= (range) => {
        console.log(range);
        setDatesPicked(datesPicked + 1);
        if (datesPicked % 2 !== 0) {
            setRanges(range);
            setAnchorEl(null);
            onChange(formatInterval(range));
        } else {
            setRanges(range);
        }
        
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div className={classes.calendarMain}>
            <label className={isLoading ? classes.calendarLabelDisabled : classes.calendarLabel}>
                Интервал
            </label>
            <div className={classes.calendaeComponent}>
                <div onClick={handleClick} className={classes.calendarRow}>
                    <EventIcon  className={isLoading ? classes.calendarDisabled : classes.calendar}/>
                    <div className={isLoading ? classes.calendarElementDasabled : classes.calendarElement}>
                        <span className={isLoading ? classes.calendarLabelDateDisabled : classes.calendarLabelDate}>С</span>
                        <span>{moment(ranges.startDate).format("DD.MM.YYYY")}</span>
                    </div>
                    <div className={isLoading ? classes.calendarElementDasabled : classes.calendarElement}>
                        <span className={isLoading ? classes.calendarLabelDateDisabled : classes.calendarLabelDate}>По</span>
                        <span>{moment(ranges.endDate).format("DD.MM.YYYY")}</span>
                        {/* <EventIcon /> */}
                    </div>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateRange
                    locale={locales["ru"]}
                    ranges={[ranges]}
                    months={2}
                    maxDate={maxDate ? new Date(maxDate) : undefined }
                    direction="horizontal"
                    onChange={(item) => selectRange(item.selection)}
                    showSelectionPreview={true}
                    showMonthAndYearPickers={false}
                    showDateDisplay={false}
                    color="#032b62"
                />
            </Popover>
        </div>
    )
}


const mapStateToProps = (state) =>{
    const { fetch } = state;
    return {
        isLoading: !!fetch.loading
    }
  }
  
  export default compose(
    connect(mapStateToProps),
    withStyles(style)
  )(DateRangeInput);

  

