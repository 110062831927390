import React, { Component } from 'react';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';

import { SelectInput, TextInput, Filter } from '../_Components';

const styles = theme => ({
    search: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    email: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    status: {
        display: 'flex',
        width: theme.spacing(25),
        marginRight: 20
    },
});

class OperatorsFilter extends Component {

    render(){
        const { classes, filterDefaultValues = {}, projects, ...props } = this.props;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <TextInput source="fullname" label={`Имя`} alwaysOn className={classes.search}/>
                <TextInput source="email" label={`E-mail`} alwaysOn className={classes.email}/>
                <SelectInput 
                    source="status"
                    label={`Статус`}
                    options={[{id: null, name: 'Все'},{id: 'Active', name: 'Активные'},{id: 'Terminated', name: 'Заблокированные'},]}
                    alwaysOn
                    className={classes.status}
                />
            </Filter>
        );
    }
}

export default  compose(
    withStyles(styles),
)(OperatorsFilter);