import React from 'react';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';

const style = {
    drawerPaper:{
        // width: 584,
        paddingTop: 64,
        maxHeight: 'calc(100vh - 64px)',
    }
};

const DrawerElement = ({classes, content, handleClose, isMatch }) => {
    return ( 
        <Drawer
            // variant="persistent"
            open={isMatch}
            anchor="right"
            onClose={handleClose}
            classes={{
                paper: classes.drawerPaper,
            }}
            sx={{
                width: '30%',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    // paddingTop: 64,
                    width: 584,
                },
            }}
        >
            {content}
    </Drawer>
    )
}

export default withStyles(style)(DrawerElement);