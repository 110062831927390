
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@mui/material/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

import { Labeled } from '../../../_Components';

const styles = {
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    }
}
const ProjectList = ({classes, items}) => (
    <Paper className={classes.paper} style={{width: '100%'}}>
      <List dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${value}-label`;
          return (
            <ListItem key={index} role="listitem" button style={{padding: 5}}>
                <ListItemText id={labelId} primary={`${value.name}`} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
);

const Project = ({classes, source, record ={}, ...props}) =>(
    <div className={props.className}>
        {record ?
            <Labeled label="Проекты" style={{width: '95%'}}>
                <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                    <Grid item style={{width: '100%'}}><ProjectList items={record.data.campaigns} classes={classes} {...props}/></Grid>
                </Grid>
            </Labeled>
        : 
            <div style={{padding: '16px 0px 0px 20px', width: '95%'}}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={242} style={{padding: '0 20px'}}/>
            </div>
        }
    </div>
)

export default withStyles(styles)(Project);