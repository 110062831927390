import React, { Component } from 'react';
import compose from 'recompose/compose';
import { Route } from 'react-router';
// import { createStyles, withStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';

import { 
    List, 
    TextField, 
    SelectField,
    StatusField
} from '../_Components';
import {GET_LIST, provider } from '../_utils';

// import Show from './Show';

// const styles = theme =>
//     createStyles({
//         root: {
//             display: 'flex',
//             height: '100%'
//         },
//         list: {
//             flexGrow: 1,
//             transition: theme.transitions.create(['all'], {
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//             marginRight: 0,
//         },
//         listWithDrawer: {
//             marginRight: 584,
//         },
//         drawerPaper:{
//             width: 584,
//             marginTop: theme.spacing(7)
//         }
// });

class UsersList extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            cards: [],
            filter: props.formValues || {}
        };
    }

    componentDidMount(){
        this._isMounted = true;
        this.getCards();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    async getCards () {
        const { data } = await provider( 
            GET_LIST,
            '/cards', 
            {
                filter: { },
                sort: {  },
                pagination: { page: 1, perPage: 1000 },
            }
        );
        if(this._isMounted){
            this.setState({
                cards: data.map( c => ({ id: c._id, name: `${c.project} - ${c.name}`}))
            });
        }
    }

    render() {
        // const { classes } = this.props;
        const { cards } = this.state;
        return (
            <Route path="/queues/:id/:action">
                    {({ match }) => {
                        // const isMatch = !!(
                        //     match &&
                        //     match.params
                        // );
                        return (
                            <div>
                                <List
                                    // classes={{root: (isMatch)&&classes.listWithDrawer}}
                                    refresh={true}
                                    creat={true}
                                    show={false}
                                    basePath='infra/queues/list'
                                    pathToShow={`/show`}
                                    // filters={<Filter roles={roles} />}
                                    match={match}
                                    // filterDefaultValues={filter}
                                    perPage={25} 
                                    sort={{ field: 'name', order: 'ASC' }} 
                                >
                                    <TextField
                                        notSort={true}
                                        source="name"
                                        label="Имя"
                                    />
                                    <SelectField
                                        notSort={true}
                                        options={[{id: 'Inbound', name: 'Входящие'}, {id: 'Outbound', name: 'Исходящие'}]}
                                        source="type"
                                        label="Тип"
                                    />
                                    <StatusField 
                                        notSort={true}
                                        source="enabled"
                                        label="Сататус"
                                    />
                                     <TextField
                                        notSort={true}
                                        source="agents"
                                        label="Опреаторов"
                                    />
                                     <SelectField
                                        notSort={true}
                                        source="url"
                                        options={cards}
                                        label="Карточка"
                                    />
                                    
                                </List>
                                {/* <Drawer
                                    variant="persistent"
                                    open={isMatch}
                                    anchor="right"
                                    onClose={this.handleClose}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    {(isMatch&&match.params.action === 'show') ? (
                                        <div style={{padding: 0}}>
                                            <Show id={match.params.id} cards={cards}/>
                                        </div>
                                    ) : null}
                                </Drawer> */}
                            </div>
                        );
                    }}
                </Route>
            
        );
    }
}

export default compose(
    // withStyles(styles),
)(UsersList);
