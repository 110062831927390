import React, { Component } from 'react';
import compose from 'recompose/compose';

import { withStyles } from '@mui/styles';


import { SelectInput, TextInput, Filter } from '../_Components';

const styles = theme => ({
    search: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    email: {
        display: 'flex',
        width: theme.spacing(30),
        marginRight: 20
    },
    status: {
        display: 'flex',
        width: theme.spacing(25),
        marginRight: 20
    },
});

class OperatorsFilter extends Component {

    render(){
        const { classes, filterDefaultValues = {}, projects, ...props } = this.props;
        return (  
            <Filter filterValues={filterDefaultValues} {...props}>
                <TextInput 
                    source="name"
                    label={`Название`}
                    alwaysOn
                />
                <SelectInput 
                    source="status"
                    label={`Статус`}
                    options={[{id: 'Active', name: 'Активные'},{id: 'Disabled', name: 'Заблокированные'},]}
                    alwaysOn
                    className={classes.status}
                />
            </Filter>
        );
    }
}

export default  compose(
    withStyles(styles),
)(OperatorsFilter);