import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import {  TextInput, SelectInput, Modal } from '../../_Components';
import { CREATE, provider, history } from '../../_utils';

const types =  [
    {
        id: 'Support',
        name: 'Поддержка',
    },
    {
        id: 'Sales',
        name: 'Продажи',
    },
    {
        id: 'Survey',
        name: 'Опрос',
    },
];

const CreatScript = ({ openCreat, handleCloseCreat }) => {
    const save = async (data) => {
        const result = await provider(CREATE, '/scripts', { data });
        if(result.status === 200 ){
            let path = `${history.location.pathname}/${result.data._id}`;
            history.push(path)
        }
    };
    return  (
        <Modal 
            width='md'
            title="Добавить карточку"
            open={openCreat} 
            handleSaveClick={save} 
            handleCloseClick={handleCloseCreat} 
            fields={
                <Fragment>
                    <Field
                        name="name"
                        required={true}
                        component={TextInput}
                        label={`Название`}
                        style={{width: 300}}
                    />
                    <Field
                        name="type"
                        options={types}
                        required={true}
                        component={SelectInput}
                        label={`Тип`}
                        style={{width: 180}}
                    />
                </Fragment>
            } 
        />
    )
}

export default CreatScript;