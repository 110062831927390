import React from 'react';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CallEndIcon from '@mui/icons-material/CallEnd';
import DialerSipIcon from '@mui/icons-material/DialerSip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckIcon from '@mui/icons-material/Check';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import CallMergeIcon from '@mui/icons-material/CallMerge';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
// import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import HelpIcon from '@mui/icons-material/Help';

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export default class Utils {
	static secondsToTime (totalSeconds) {
		const hours = Math.floor(totalSeconds / 3600);
	    const mmss = new Date(totalSeconds * 1000).toISOString().substr(13, 6);
		return hours>10 ? `${hours}${mmss}` : `0${hours}${mmss}`;
	}

	static getSessionState (code){
		const sessionState = {
							1073741824:{
	                          	label: "Регистрация обращения",
	                          	color: "#FF9800",
	                          	icon: <CallReceivedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741825: {
	                          	label: "Звонок завершен",
	                          	color: "#F44336",
	                          	icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
	                        },
	                        1073741826:  {
	                          	label: "Вошел в очередь",
	                          	color: "#FF9800",
	                          	icon: <CallReceivedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741827:  {
	                          	label: "Покинул очередь",
	                          	color: "#F44336",
	                          	icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
	                        },
	                        1073741828:  {
	                          	label: "Восстановлен",
	                          	color: "#F44336",
	                          	icon: <CallMissedOutgoingIcon style={{color: "#4CAF50", fontSize: 20}}/>
	                        },
	                        1073741829:  {
	                          	label: "Ожидание",
	                          	color: "#FF9800",
	                          	icon: <PhonePausedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741830:  {
	                          	label: "Waiting Stopped",
	                          	color: "#FF9800",
	                          	icon: <PhoneInTalkIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741831:  {
	                          	label: "Звонок распределен оператору",
	                          	color: "#8bc34a",
	                          	icon: <SettingsPhoneIcon style={{color: "#8bc34a", fontSize: 20}}/>
	                        },
	                        1073741832:  {
	                          	label: "Unloaded",
	                          	color: "#F44336",
	                          	icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
	                        },
	                        1073741833:  {
	                          	label: "Время для исходящего звонка назначено",
	                          	color: '#4CAF50',
	                          	icon: <PhoneForwardedIcon style={{color: '#4CAF50', fontSize: 20}}/>
							},
							1073741834: {
								label: "Абонент занят",
								color: "#FF9800",
								icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
							},
							1073741835:  {
								label: "Абонент не отвечает",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
						  	},
							1073741836:  {
	                          	label: "Звонок задержан",
	                          	color: '#FF9800',
	                          	icon: <PhonePausedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741837:  {
	                          	label: "Соединен с оператором",
	                          	color: '#4CAF50',
	                          	icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
	                        },
	                        1073741838:  {
	                          	label: "Звонок отсоединен от оператора",
	                          	color: "#F44336",
	                          	icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741839:  {
	                          	label: "Список участников изменен или обновлен",
	                          	color: "#F44336",
								icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
	                        },
	                        1073741840:  {
	                          	label: "Идентификатор исходящего звонка",
	                          	color: '#3f51b5',
	                          	icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741841:  {
		                        label: "Удержание",
		                        color: "#FF9800",
		                        icon: <PhonePausedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741842: { 
	                          	label: "Unhold",
	                          	color: '#4CAF50',
	                          	icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
	                        },
	                        1073741843:  {
	                          	label: "ForceEnded",
	                          	color: "#FF9800",
	                          	icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741844:  {
	                          	label: "Оставлено голосовое сообщение",
	                          	color: "#FF9800",
	                          	icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741845:  {
								label: "Оставлен номер для обратного звонка",
								color: "#FF9800",
								icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741846: { 
								label: "Абонент ответил",
								color: '#4CAF50',
								icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
						  	},
							1073741847:{
	                          	label: "АОН",
	                          	color: "#FF9800",
	                          	icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741848:{
								label: "Факс",
								color: "#FF9800",
								icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741849:{
								label: "Обработано автоинформатором",
								color: "#FF9800",
								icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
							},
							1073741850:  {
								label: "Разъединен",
								color: "#FF9800",
								icon: <CallEndIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
	                        1073741851:  {
								label: "Набор по номеру 1",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
	                        1073741852:  {
								label: "Набор по номеру 2",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
	                        1073741853:  {
								label: "Набор по номеру 3",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
	                        1073741854:  {
								label: "Набор по номеру 4",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741855:  {
								label: "Набор по номеру 5",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741856:  {
								label: "Набор по номеру 6",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741857:  {
								label: "Набор по номеру 7",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741858:  {
								label: "Набор по номеру 8",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741859:  {
								label: "Набор по номеру 9",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741860:  {
								label: "Набор по номеру 10",
								color: '#3f51b5',
								icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
	                        1073741861: { 
								label: "Соеденен",
								color: '#4CAF50',
								icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
							},
							1073741862:  {
								label: "Неверно задан номер",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
							1073741863:  {
								label: "Неверно задан номер",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
							1073741864:  {
								label: "Unknown Outbound Module Error",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
						  	},
	                        1073741865:  {
	                          	label: "Исходящий звонок запрещен",
	                          	color: "#F44336",
	                          	icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
	                        },
	                        1073741866:  {
								label: "Ошибка исходящего звонка",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
						  	},
	                        1073741867:  {
	                          	label: "Попытка дозвона прекращена по команде оператора",
	                          	color: "#F44336",
	                          	icon: <PhoneMissedIcon style={{color: "#F44336", fontSize: 20}}/>
							},
							1073741868:  {
								label: "Временно недоступен",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
							1073741869:  {
								label: "Human Detected",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							  },
							1073741870:  {
								label: "Все линии заняты",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
							1073741871:  {
								label: "Ожидание завершено",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
							1073741872: { 
								label: "Заявка проинициализирована",
								color: '#4CAF50',
								icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
							},  
	                        1073741873:  "Информация о заявке пришла на рабочее место оператора",
	                        1073741874:  "Информация о заявке ушла с рабочего места оператора",
	                        1073741875:  "Послан запрос на назначение времени исх. звонка",
	                        1073741876:   {
								label: "Предварительный просмотр отменен",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
	                        1073741877:  { 
								label: "Начало исходящего звонка по первому номеру",
								color: '#4CAF50',
								icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
							},
	                        1073741878:  "Ожидание оператора",
	                        1073741879:  {
	                          	label: "Начат предварительный просмотр",
	                          	color: "#3f51b5",
	                          	icon: <PhoneForwardedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741880: {
	                          	label:  "Звонок отменен после пред. просмотра",
	                          	color: "#F44336",
	                          	icon: <PhoneMissedIcon style={{color: "#F44336", fontSize: 20}}/>
	                        },
	                        1073741881: {
								label:  "Вышло время отведенное для предварительного просмотра",
								color: "#F44336",
								icon: <PhoneMissedIcon style={{color: "#F44336", fontSize: 20}}/>
						  	},
	                        1073741882:   {
								label:  "Предварительный просмотр не состоялся",
								color: "#F44336",
								icon: <PhoneMissedIcon style={{color: "#F44336", fontSize: 20}}/>
						  	},
							1073741883:  {
								label: "Консультативный звонок",
								color: "#4CAF50",
								icon: <PhoneInTalkIcon style={{color: "#FF9800", fontSize: 20}}/>
						  	},
							1073741884:  {
								label: "Линия отключена",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
	                        1073741885:  {
								label: "Пропущен звонок для предварительного просмотра",
								color: "#F44336",
								icon: <CallEndIcon style={{color: "#F44336", fontSize: 20}}/>
							},
	                        1073741886: "Обработка",
	                        1073741887: "Успешный исходящий звонок",
	                        1073741888: "Исходящий звонок не дождался оператора",
	                        1073741889: "Разъединен внутренним абонентом",
	                        1073741890: "Разъединен внешним абонентом",
							1073741891: "Разъединен системой",
							1073741892: "Разъединен по собственной инициативе",
							1073741893: "Распределение заявки отложено, агент занят",
							1073741894: "Распределение заявки отложено из-за ошибки",
							1073741895: "Автоответчик",
							1073741896: "Автоответчик, разъединен оператором",
							1073741897: "Сервис недоступен",
							1073741898: "Постобработка",

	                        1073741899:  {
		                        label: "Постобработка завершена",
		                        color: "#FF9800",
		                        icon: <PhonePausedIcon style={{color: "#FF9800", fontSize: 20}}/>
	                        },
	                        1073741900: { 
	                          	label: "Инициализация исходящей заявки",
	                          	color: '#4CAF50',
	                          	icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
							},
							1073741901:	"Исходящая заявка импортирована",
	                        1073741902:  {
	                          	label: "Соеденен с вн.",
	                          	color: '#4CAF50',
	                          	icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
							},
							1073741904:	"DNCNumber",
							1073741905:	"Preview Delayed",
							1073741903:	"В работе",
	                        1073741906:  "Out of Licenses",
	                        1073741907:  "Detection Abandoned",
	                        1073741908:  "DbgSessionEnded",
	                        1073741909:  "DbgDial",
	                        1073741910:  "DbgSetUnloadFailed",
	                        1073741911:  "DbgPending",
	                        1073741912:  "DbgWaiting",
	                        1073741913:  "DbgDistributed",
	                        1073741914:  "DbgDistribFinished",
	                        1073741915:  "DbgDeleted",
	                        1073741916:  "DbgUnloaded",
	                        1073741917:  "DbgScheduled",
	                        1073741918:  "Multidial Started",
	                        1073741919:  "Multidial Failed",
	                        1073741920:  "Multidial Succeeded",
							1073741921:  { 
								label: "Соеденен с внешним",
								color: '#4CAF50',
								icon: <PhoneInTalkIcon style={{color: "#4CAF50", fontSize: 20}}/>
							},
	                        1073741922:  "Alert Off",
	                        1073741923:  "Alert On",
	                       
	    }
    	return sessionState[code];

	}

	static getAgentState (state) {
		const agentState = {
					'536870912': { 
						value: 'Logoff', 
						label: 'Отключен',
						class: "default",
                        icon: <DirectionsRunIcon />
					},
					'536870913': { 
						value: 'Logon', 
						label: 'Начало сеанса',
						color: "#FF9800",
                        icon: <ExitToAppIcon style={{color: '#FF9800', fontSize: 20}}/>
					},
					'536870914': { 
						value: 'Ready', 
						label: 'Готов',
						color: "#000000",
						// color: "#4CAF50",
                        icon: <CheckIcon style={{color: '#4CAF50'}}/>
					},
					'536870915': { 
						value: 'Not Ready', 
						label: 'Не готов',
						color: "#F44336",
                        icon: <HighlightOffIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870916': { 
						value: 'Wrapup', 
						label: 'Пауза после обработки звонка',
						color: "#8bc34a",
	                    icon: <HourglassEmptyIcon style={{color: '#8bc34a', fontSize: 20}}/>
					},
					'536870917': { 
						value: 'No Answer', 
						label: 'Не отвечает',
						color: "#F44336",
                        icon: <CallMissedIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870918': { 
						value: 'Work', 
						label: 'Работает',
						color: "#3f51b5",
                        icon: <HelpIcon style={{color: '#3f51b5', fontSize: 20}}/>
					},
					'536870919': { 
						value: 'Rest', 
						label: 'Отдыхает',
						color: "#FF9800",
	                    icon: <FreeBreakfastIcon style={{color: '#FF9800', fontSize: 20}}/>
					},
					'536870920': {
						value: 'Preview Missed',
						label: 'Проигнорирован предварительный просмотр',
						color: "#F44336",
                        icon: <CallMissedOutgoingIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870921': {
						value: 'Outbound Dialing Forbidden',
						label: 'Нет прав для исходящих звонков',
						color: "#F44336",
                        icon: <CallMissedOutgoingIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870922': { 
						value: 'Inbound', 
						label: 'Входящие звонки',
						color: "#03A9F4",
                        icon: <CallReceivedIcon style={{color: '#03A9F4', fontSize: 20}}/>
					},
					'536870923': { 
						value: 'Outbound', 
						label: 'Исходящие звонки',
						color: '#03A9F4',
                        icon: <PhoneForwardedIcon style={{color: '#03A9F4', fontSize: 20}}/>
					},
					'536870924': { 
						value: 'Busy', 
						label: 'Занят',
						color: "#F44336",
                        icon: <HighlightOffIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870925': { 
						value: 'Processing', 
						label: 'Обработка звонка',
						color: "#FF9800",
	                    icon: <HourglassEmptyIcon style={{color: '#FF9800', fontSize: 20}}/>
					},
					'536870926': {
						value: 'Preview Ignored',
						label: 'Предварительный просмотр не обработан',
						color: "#F44336",
                        icon: <CallMissedOutgoingIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870927': {
						value: 'Connection Lost',
						label: 'Разорвано сетевое соединение клиента с сервером',
						color: "#F44336",
                        icon: <PhonelinkOffIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870928': {
						value: 'E-mail Reading',
						label: 'Оператор читает электронную почту',
						color: "03A9F4",
                        icon: <MailOutlineIcon  style={{color: '#03A9F4', fontSize: 20}}/>
					},
					'536870929': {
						value: 'No Predictive License',
						label: 'Нет лицензии на предиктивный обзвон',
						color: "#F44336",
                        icon: <HighlightOffIcon style={{color: '#F44336', fontSize: 20}}/>
					},
					'536870930': { 
						value: 'Phone Error', 
						label: 'Телефон не работает',
						color: "#F44336",
                        icon: <DialerSipIcon style={{color: '#F44336', fontSize: 20}}/>
					}

        };
        return agentState[state];
	}


}