import React from 'react';
import { withStyles } from '@mui/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from "downshift";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Clear from '@mui/icons-material/Clear';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const style = {
  root: {
    flexGrow: 1,
    paddingLeft: 24
  },
  container: {
    // flexGrow: 1,
    // position: 'absolute',
    // width: '25%'
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginTop: 8,
    left: 0,
    right: 0,
    maxHeight: 304,
    overflowY: 'auto'
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: 16,
  },
  gutters: {
    padding: '0px 5px'
  },
  multiline:{
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    // fontWeight: 600
  },
  secondary: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    color: '#0a529f'
  },
  secondaryError: {
    fontSize: '0.75rem',
    lineHeight: 0.5,
    color: 'red',
    paddingTop: 8
  }
};

const renderInput = (inputProps, submitProps) => {
  const { InputProps, classes, ref, ...other } = inputProps;
  const handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        if(!submitProps.error.used && !submitProps.error.errorMessage){
          submitProps.onAdd(submitProps.selectedItem, submitProps.clearSelection);
        }
      }
  }
  return (
    <TextField
      style={{width: '80%'}}
      onKeyPress={handleKeyPress}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

const checkError = (campaign, suggestion ) => {
  if(!suggestion){
    return {}
  }
  const used = campaign.operators.indexOf(suggestion._id)>=0;
  let errorMessage = null;
  if(used){
    errorMessage =  'Оператор включен в проект';
  }
  return { used, errorMessage }
}

const renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem, campaign, classes }) => {
  const isHighlighted = highlightedIndex === index;
  const label = selectedItem ? `${selectedItem.code} ${selectedItem.name}` : null;
  const isSelected = (label || '').indexOf(suggestion.label) > -1;
  const {used, errorMessage } = checkError(campaign, suggestion);
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      disabled={used||!!errorMessage}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 400 : 200,
        fontSize: '0.75rem',
        lineHeight: 0.75
      }}
    >
      <ListItem 
        button
        classes={{
          gutters: classes.gutters,
        }}
        disabled={used||!!errorMessage}
      >
          <ListItemText 
            classes={{
              multiline: classes.multiline,
              primary: classes.primary,
              secondary: !!errorMessage ? classes.secondaryError : classes.secondary
            }}
            primary={`${suggestion.login} ${suggestion.fullname}`}  
            secondary={errorMessage} 
          />
       </ListItem>
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const getSuggestions = (value, suggestions, selectedItem) => {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const result = inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        if(!suggestion.fullname){
          suggestion.fullname = '';
        }
        try {
            const fullname = suggestion.fullname.toLowerCase().trim().search(inputValue)>=0;
            const login = suggestion.login.toString().toLowerCase().trim().search(inputValue)>=0;
            const active = suggestion.status==='Active';
            const department = suggestion.department!=="Administaration";
            const keep = count < 20 && ( (fullname || login) && active && department) ;
            if (keep) {
              count += 1;
            }
            return keep;
        }
        catch(e){
          return false;
        }
      });
  return result;
}

const QuickSuggestionsField = ({classes, campaign, onAdd, operatorsList, ...props}) => (
  <div className={classes.root}>
    < Downshift
      itemToString={item => (item ? `${item.login} ${item.fullname}` : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        clearSelection,
      }) =>{
        const {used, errorMessage } = checkError(campaign, selectedItem);
        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: false,
              classes,
              InputProps: getInputProps({
                placeholder: 'Добавить оператора',
              }),
            },{
                onAdd: onAdd,
                clearSelection: clearSelection,
                selectedItem: selectedItem,
                error: {
                  used, errorMessage
                }
            })}
            <IconButton 
              size="small" 
              style={{color: selectedItem&&'red'}} 
              disabled={!selectedItem}  
              onClick={clearSelection} 
              aria-label="Очистить"
            >
              <Clear size="small" />
            </IconButton>
            <IconButton 
              size='small' 
              // style={{color: (selectedItem&&!!!used&&!!!errorMessage )&&'#0a529f'}} 
              disabled={ !selectedItem  } //|| !!used || !!errorMessage
              onClick={()=> onAdd(selectedItem, clearSelection)} 
              aria-label="Добавить оператора"
            >
                <ArrowForwardIcon fontSize="small"/>
            </IconButton>
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue, operatorsList, selectedItem,).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      campaign,
                      itemProps: getItemProps({ item: suggestion}),
                      highlightedIndex,
                      selectedItem,
                      classes
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      } 
    </Downshift>
    <div className={classes.divider} />
  </div>
);

const mapStateToProps = (state) => {
  var recordLiveValues = getFormValues('project')(state);
  return { formValues: recordLiveValues};
};

const enhance = compose(
  withStyles(style),
  connect(mapStateToProps)
);

export default enhance(QuickSuggestionsField);