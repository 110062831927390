import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { 
    List, 
    StatusField, 
    TextField, 
} from '../_Components';

import {GET_LIST, provider } from '../_utils';

import Filter from './Filter';

const ReportList = ({formValues}) => {
    const [projects, setProjects] = useState([]);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        const getProjects = async  () => {
            const { data, status } = await provider( 
                GET_LIST,
                '/projects', 
                {
                    filter: {status: 'Active' },
                    sort: { field: 'name', order: "ASC" },
                    pagination: { page:1, perPage: 999 },
                }
            );
            if(status===200){
                setProjects(data);
                setIsMounted(true)
            }
        }

        if(!isMounted){
            getProjects();
        }
    },[isMounted])

    return (
        <List
            refresh={true}
            clear={true}
            creat={false}
            show={true}
            // actions={<ExportButton reports={reports}/>}
            filters={<Filter form="reportsForm" projects={projects}/>}
            filterDefaultValues={formValues}
            perPage={25}
            deletedField='deleted'
            sort={{ field: 'time', order: 'ASC' }} 
        >   
            <TextField
                notSort={true}
                source="name"
                label="Название"
            />
            <TextField
                notSort={true}
                source="project"
                label="Проект"
            />
            <StatusField
                notSort={true}
                source="status"
                label="Статус"
            />
        </List>
    );
}

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('reportsForm')(state);
    return { 
        formValues: recordLiveValues || { status: 'Active' },
    };
};


export default compose(
    connect(mapStateToProps),
)(ReportList);
