import React from "react";
import PropTypes from 'prop-types';

import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CloseButton = ({handleCloseClick, isSaving, ...props}) => {
    return(
        <Button
            color="secondary" 
            variant="outlined"
            size="small"
            onClick={ () => handleCloseClick()}
            disabled={isSaving}
            endIcon={<CloseIcon />}
            {...props}
        >
                Закрыть
        </Button>
    )
}

CloseButton.propTypes = {
    handleCloseClick: PropTypes.func,
};

export default CloseButton;