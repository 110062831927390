import React, { useState } from 'react';
import { withStyles } from '@mui/styles';

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const style = {
    iconTrash: {
        width: '1rem !important',
        height: '1rem !important',
        float: 'right',
        color: 'red',
        paddingRight: 8,
        cursor: 'pointer'
      },
    editIcon: {
        verticalAlign: 'middle',
        color: '#0a2b61',
        paddingRight: 8,
        float: 'right',
        fontSize: '1.25rem !important',
        width: '1rem !important',
        height: '1rem !important',
        marginTop: 6,
        cursor: 'pointer'
    }
};

const RowElement = ({classes, rows, pointer, rowClick, edit, editFunc,remove, delClick, selected, header}) =>{
    return (
        rows.map((row, index) => (
            <TableRow key={index} style={{backgroundColor : selected===index ? '#d6eafb' : '', cursor : pointer ? 'pointer' : ''}} onClick={() => rowClick(index)}>
                {header.map((cell, i) =>(
                    <TableCell key={i}>
                        {(cell.value&&cell.options)&&
                            cell.options.find(o => o.id === row[cell.value])&& cell.options.find(o => o.id === row[cell.value]).name
                        }
                        {(cell.value&&!cell.options)&&
                            row[cell.value]
                        }
                    </TableCell>
                ))}
                {edit&&<TableCell><EditIcon className={classes.editIcon} onClick={()=> editFunc(row, index)} /></TableCell>}
                {remove&&<TableCell><CloseIcon className={classes.iconTrash} onClick={()=>delClick(index)}/> </TableCell>}
            </TableRow>
        ))
    )
} 

const TableElement = ({classes, header, rows, edit, editFunc, remove, removeFunc, onSelect, selected}) => {
    const [pointer] = useState(onSelect ? true: false);
    const delClick = (index) =>{
        return removeFunc(index);
    }

    const rowClick = (index) => {
        if(onSelect){
            onSelect(index);
        }
    }

    return ( 
        <TableContainer>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        {header.map((cell, i) =>(<TableCell key={i} style={{width: cell.width ? `${cell.width}%`: ''}}><b>{cell.label}</b></TableCell>))}
                        {edit&&<TableCell style={{width: `5%`}}></TableCell>}
                        {remove&&<TableCell style={{width: `5%`}}></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <RowElement classes={classes} header={header} pointer={pointer} rows={rows} edit={edit} editFunc={editFunc} remove={remove} delClick={delClick} rowClick={rowClick} selected={selected}/>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default withStyles(style)(TableElement);