import React, { Fragment, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues, Field } from 'redux-form';

import { TextField, DateField, List, StatusField, AddButton, TextInput, Modal } from '../_Components';

import {CREATE, provider, history } from '../_utils';
import Filter from './Filter';
// import ListActions from './components/ListActions';


const ListActions = () => {
    const [openCreat, setOpenCreate] = useState(false);

    const handleClickOpen = () => {
        setOpenCreate(true);
    }

    const handleCloseCreat = () => {
        setOpenCreate(false);
    }

    const save = async (data) => {
        const result = await provider(CREATE, '/projects', { data });
        if(result.status === 200 ){
            let path = `${history.location.pathname}/${result.data._id}`;
            history.push(path)
        }
    };


    return (
        <Fragment>
            <AddButton  
                label="Создать"
                handleAddClick={handleClickOpen}
            />
            {openCreat&&
                <Modal 
                    // width='md'
                    title="Добавить проект"
                    open={openCreat} 
                    handleSaveClick={save} 
                    handleCloseClick={handleCloseCreat} 
                    fields={
                        <Field
                            name="name"
                            required={true}
                            component={TextInput}
                            label={`Название`}
                            style={{width: 400}}
                        />
                    } 
                />
            }
        </Fragment>
    );
}

const ProjectList = ({ formValues, perPage, page }) =>{
   
    return(
        <List
            refresh={true}
            clear={true}
            show={true}
            create={true}
            actions={<ListActions />}
            filters={<Filter form="projectsListForm"/>}
            filterDefaultValues={formValues}
            perPage={perPage}
            page={page}
            deletedField='deleted'
            sort={{ field: 'time', order: 'ASC' }} 
        >
            <TextField
                source="name"
                label="Название"
            />
            <DateField
                format="DD.MM.YYYY HH:mm"
                source="updateAt"
                label="Дата / Время"
            />
            <StatusField
                notSort={true}
                source="status"
                label="Статус"
            />
            <TextField
                source="created"
                label="Пользователь"
            />
        </List>
    )
} ;

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('projectsListForm')(state);
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { status: 'Active' } ,
    };
};


export default compose(
    connect(mapStateToProps),
)(ProjectList);
