import React, { useEffect, useState } from "react";
import { withStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors
  } from '@dnd-kit/core';

    import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

  import {
    arrayMove,
    useSortable,
    SortableContext,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';
  
  import { CSS } from '@dnd-kit/utilities'

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import { confirm } from '../../_utils';


const style = {
  iconTrash: {
    width: '1rem !important',
    height: '1rem !important',
    float: 'right',
    color: 'red',
    paddingRight: 8,
    cursor: 'pointer'
  },
  editIcon: {
    verticalAlign: 'middle',
    color: '#0a2b61',
    paddingRight: 8,
    float: 'right',
    fontSize: '1.25rem !important',
    width: '1rem !important',
    height: '1rem !important',
    marginTop: 6,
    cursor: 'pointer'
  }
};

const RowDragHandleCell = ({ rowId }) => {
    const { attributes, listeners } = useSortable({
      id: rowId,
    })
    return (
      // Alternatively, you could set these attributes on the rows themselves
      <DragIndicatorIcon {...attributes} {...listeners}/>
    )
  }


const DraggableRow = ({ classes, row, header, index, edit, editFunc, remove, removeFunc }) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
        id: row.key
      });


    const style = {
        transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
    }
  
    return (
      // connect row ref to dnd-kit, apply important styles
      <TableRow ref={setNodeRef} style={style}>
            <TableCell><RowDragHandleCell rowId={row.key}/></TableCell>
            {header.map((cell, i) =>(<TableCell key={i}>{cell.value&&row[cell.value]}</TableCell>))}
            {edit&&<TableCell><EditIcon className={classes.editIcon} onClick={()=> editFunc(row, index)} /></TableCell>}
            {remove&&<TableCell><CloseIcon className={classes.iconTrash} onClick={()=>removeFunc(index)}/> </TableCell>}
      </TableRow>
    )
  }

const Dragable = ({classes, header, rows, sort, edit, editFunc, remove, removeFunc, style}) => {
    const [dataIds, setDataIds] = useState(rows.map(row => row.key));
    const [data, setData] = useState(rows);

    useEffect(() => {
        setDataIds(rows.map(row => row.key));
        setData(rows);
    }, [rows]);

    const handleDragEnd = (event) => {
        const { active, over } = event;
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        const newData = arrayMove(rows, oldIndex, newIndex);
        setData(newData);
        sort(data);
    };
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )
    return(
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
        >
            <TableContainer>
                <Table size={'small'} style={style}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '5%'}}></TableCell>
                            {header.map((cell, i) =>(<TableCell key={i} style={{width: cell.width ? cell.width: ''}}><b>{cell.label}</b></TableCell>))}
                            {edit&&<TableCell style={{width: `5%`}}></TableCell>}
                            {remove&&<TableCell style={{width: `5%`}}></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                            {data.map((row, index) => (
                                <DraggableRow classes={classes} key={index} index={index} row={row} header={header} remove={remove} removeFunc={removeFunc}/>
                            ))}
                        </SortableContext>
                    </TableBody>
                </Table>
            </TableContainer>
        </DndContext>
    );
}


export default withStyles(style)(Dragable);