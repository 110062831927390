import React, { Fragment  } from 'react';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import EventIcon from '@mui/icons-material/Event';
import { Link } from 'react-router-dom';

import { history } from '../../_utils';

const ShowFilter = ({ curDate,nextDate, prevDate, addPeriod, subtractPeriod, create }) => (  
    <Fragment>
        <Link to={`/schedule/${prevDate}/show`}>
            <IconButton color="default" onClick={subtractPeriod} aria-label="Влево">
                <KeyboardArrowLeftIcon />
            </IconButton>
        </Link>
        <IconButton color="default" onClick={ () =>  create() } aria-label="Календарь">
            <PersonAddIcon />
        </IconButton>
        <IconButton color="default" onClick={ () => history.push('/schedule') } aria-label="Календарь">
            <EventIcon />
        </IconButton>
        {/* <IconButton color="default" onClick={ () => history.push(`/schedule/${curDate}/breaks`) } aria-label="Календарь">
            <FreeBreakfastIcon />
        </IconButton> */}
        <Link to={`/schedule/${nextDate}/show`}>
            <IconButton color="default" onClick={addPeriod} aria-label="Вправо">
                <KeyboardArrowRightIcon />
            </IconButton>
        </Link>
    </Fragment>
);

export default ShowFilter;
