import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import {compose} from 'recompose';

import Dashboard from '../dashboard';
import Scripts from '../scripts';
import Projects from '../projects';
import Cards from '../cards';
import Reports from '../reports';
// import Calls from '../calls';
import Schedule from '../schedule';
import Operators from '../operators';
import Visor from '../visor';
import Queues from '../queues';
import Users from '../users';
import Cabinets from '../cabinets';

import NotFound from './404';

const style = (theme) => ({
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      overflow: 'auto',
      padding: theme.spacing(1),
      position: 'absolute',
      height: `calc(100vh - ${theme.spacing(10)})`,
      [theme.breakpoints.up('xs')]: {
        width: `calc(100% - ${theme.spacing(2)})`,
      },
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${theme.spacing(12)})`,
        paddingLeft: theme.spacing(11),
        paddingTop: theme.spacing(9),
      }
    }
  });


const Main = ({classes}) =>{
  return(
    <main className={classes.content}>
      <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/schedule' component={Schedule.list} />
          <Route exact path='/schedule/create' component={Schedule.create} />
          <Route exact path='/schedule/:date/show' component={Schedule.show} />
          <Route exact path='/schedule/breaks' component={Schedule.breaks} />
          <Route exact path='/reports' component={Reports.list} />
          <Route exact path='/reports/:id' component={Reports.show} />
          <Route exact path='/reports/:id/:call' component={Reports.show} />
          {/* <Route exact path='/calls' component={Calls.list} /> */}
          <Route exact path='/cards/:id' component={Cards.edit} />
          <Route exact path='/operators' component={Operators.list} />
          <Route exact path='/operators/:id/show' component={Operators.list} />
          <Route exact path='/operators/:id/edit' component={Operators.list} />
          <Route exact path='/operators/reports' component={Operators.reports} />
          <Route exact path='/operators/reports/:id/show' component={Operators.reports} />
          <Route exact path='/operators/reports/:id/edit' component={Operators.reports} />
          <Route exact path='/visor' component={Visor.list} />
          <Route exact path='/scripts' component={Scripts.list} />
          <Route exact path='/scripts/:id' component={Scripts.edit} />
          <Route exact path='/projects' component={Projects.list} />
          <Route exact path='/projects/:id' component={Projects.edit} />
          <Route exact path='/queues' component={Queues.list} />
          <Route exact path='/queues/:id/show' component={Queues.list} />
          <Route exact path='/users' component={Users.list} />
          <Route exact path='/users/:id/show' component={Users.list} />
          <Route exact path='/users/:id/edit' component={Users.list} />
          <Route exact path='/users/create' component={Users.list} />
          <Route exact path='/cabinets' component={Cabinets.list} />
          <Route exact path='/cabinets/:id' component={Cabinets.list} />
          <Route path="*" component={NotFound} />
      </Switch>
    </main>
  );
}


export default compose(
    withStyles(style),
    connect(),
    withRouter,
)(Main);