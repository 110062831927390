import React, { Fragment, useState } from 'react';
import { compose } from 'recompose';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { AddButton, TabPanel, QueuesInput, TrasferSortableInput, TextInput, SelectInput, SwitchInput, Row, CardElement} from '../../_Components';
import { UPDATE, CREATE, provider } from '../../_utils';
import { Modal } from '../../_Components';

const fieldsList = [
    {value: 'result',  label: 'Результат'},
    {value: 'task',  label: 'Тема'},
    {value: 'sub_task',  label: 'Подтема'},
]

const NewReportForm = (props) => (
    <Fragment>
        <Field
            name="name"
            label={`Название`}
            component={TextInput}
        />
        <Field
            name="settings.tarif"
            label={`Тарификация`}
            options={[{id: 'seconds',name:'Посекундная'},{id: 'minutes',name:'Поминутная'}]}
            component={SelectInput}
        />
    </Fragment>
)


const Report = ({ i, isSaving, reports, fields, queues, campaigns }) =>(
        <TabPanel 
            panels={[
                {label: 'Настройки', element: <Settings i={i} campaigns={campaigns} isSaving={isSaving} reports={reports} queuesList={queues} /> },
                // {label: 'Очереди', element: <QueuesPanel  i={i} isSaving={isSaving} reports={reports} queues={queues} />},
                // {label: 'Главная', element: <Dashboard  i={i} isSaving={isSaving}/>},
                {label: 'Отчет на экране', element: <ReportTable  i={i} isSaving={isSaving} fields={fields}/>},
                {label: 'Отчет Excel', element: <ReportExport  i={i} isSaving={isSaving} fields={fields}/>},
            ]}
        />
)


const List = ({reports, setSelected, index}) => (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell><b>Название</b></TableCell>
                    <TableCell><b>Статус</b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {reports&&reports.map((row, i) => (
                    <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => setSelected(i)}
                        style={{
                            cursor: 'pointer', 
                            backgroundColor : i===index ? '#d6eafb' : ''
                        }}
                    >
                        <TableCell style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">{row.status==="Disabled" ? 'Заблокирован': 'Активен'}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

const Settings = ({i, campaigns, reports, queuesList}) =>(                
    <Fragment>
        <Row>
            <Field
                name={`reports[${i}].status`}
                label={`Статус`}
                options={[{id: 'Active', name: 'Активен'},{id: 'Diasbled', name: 'Заблокирован'}]}
                component={SelectInput}
            />
            <Field
                name={`reports[${i}].name`}
                label={`Название`}
                style={{width: 240}}
                component={TextInput}
            />
            <Field
                name={`reports[${i}].settings.campaign`}
                label={`Компания`}
                options={campaigns}
                component={SelectInput}
            />
        </Row>
        <Row>
            <Field
                name={`reports[${i}].settings.tarif`}
                label={`Тарификация`}
                options={[{id: 'seconds',name:'Посекундная'},{id: 'minutes',name:'Поминутная'}]}
                component={SelectInput}
            />
            <Field
                name={`reports[${i}].settings.outbound`}
                label={`Исходящий`}
                style={{width: 240}}
                component={SwitchInput}
            />
            <Fragment>
                {reports[i].settings.outbound&&
                    <Field
                        name={`reports[${i}].settings.prefix`}
                        label={`Префикс`}
                        component={TextInput}
                        style={{width: 50}}
                    />
                }
            </Fragment>
            <Field
                name={`reports[${i}].settings.visor`}
                label={`Прослушка`}
                style={{width: 240}}
                component={SwitchInput}
            />
            <Field
                name={`reports[${i}].settings.export`}
                label={`Экспорт`}
                style={{width: 240}}
                component={SwitchInput}
            />
            <Field
                name={`reports[${i}].settings.download`}
                label={`Скачивание`}
                style={{width: 240}}
                component={SwitchInput}
            />
        </Row>
        <Row> 
            <div style={{width: '60%', display: 'inline-grid'}}>
                <p>Параметры</p>
                <div>
                    <Field
                        name={`reports[${i}].settings.dashboard.sl`}
                        label={`Service Lavel`}
                        component={SwitchInput}
                    />
                    <Field
                        name={`reports[${i}].settings.dashboard.abandoned`}
                        label={`Потеряные`}
                        component={SwitchInput}
                    />
                    <Field
                        name={`reports[${i}].settings.dashboard.connected`}
                        label={`Отвеченые`}
                        component={SwitchInput}
                    />
                    <Field
                        name={`reports[${i}].settings.dashboard.talktime`}
                        label={`Время разговора`}
                        component={SwitchInput}
                    />
                    <Field
                        name={`reports[${i}].settings.dashboard.held`}
                        label={`Время удержания`}
                        component={SwitchInput}
                    />
                </div>
                <p>Статистика</p>
                <div>
                    <Field
                        name={`reports[${i}].settings.dashboard.statistics.result`}
                        label={`Результат`}
                        component={SwitchInput}
                    />
                    <Field
                        name={`reports[${i}].settings.dashboard.statistics.task`}
                        label={`Тема`}
                        component={SwitchInput}
                    />
                    <Field
                        name={`reports[${i}].settings.dashboard.statistics.trasfer`}
                        label={`Перевод звонка`}
                        component={SwitchInput}
                    />
                </div>
            </div>
            <Field 
                name={`reports[${i}].settings.queues`} 
                component={QueuesInput}
                queuesList={queuesList}
                style={{
                    width: '40%',
                }}

            />
        </Row>
    </Fragment>
)

const ReportTable = ({ i, isSaving, fields = []}) => (
    <Field
        name={`reports[${i}].report.report`}
        avalible={fields}
        label={`Отчет`}
        disabled={isSaving}
        component={TrasferSortableInput}
    />
)

const ReportExport = ({ i, isSaving, fields = []}) => (
    <Field
        name={`reports[${i}].report.xls`}
        avalible={fields}
        label={`Excel`}
        disabled={isSaving}
        component={TrasferSortableInput}
    />
)

const ReportsFunc = ({formValues, change, blockReports, unBlockReports,  isSaving, invalid, queues, ...props}) =>{
    const [selected, setSelected] = useState(0);
    const [newReport, setNewReport] = useState(false);
    const [operatorsList, setOperatorsList] = useState(false);
    const [campaigns] = useState(formValues.cards.map(card => ({id: card._id, name: card.name})));
    const { reports, fields } = formValues;
    
    const open = () => {
        setNewReport(!newReport);
    }

    const save = async (report) => {
        if(report){
            report.project = formValues._id;
            report.status = 'Active'
            const { status, data } = await provider(
                CREATE,
                '/reports',
                { data: report }
            );
            if(status===200){
                reports.push(data);
                change('reports', reports);
                await provider(
                    UPDATE,
                    '/projects',
                    {  id: formValues._id,
                        data: formValues
                    }
                );
                setNewReport(!newReport);
            }
        }
    }

    const selectReport = (i) => {
        setSelected(i)
    }

    const openOperatorsList = () => {
        setOperatorsList(!operatorsList);
    }

    return (
        <div style={{ height: 'calc(100vh - 200px)', display: 'flex' }}>
            <CardElement
                width={45}
                action={<AddButton  handleAddClick={open} label="Добавить отчет"/>}
                content={<List reports={reports} setSelected={selectReport} index={selected}/>}
            />
            {reports&&reports.map((el, index) => (
                <Fragment key={index}>
                    {index===selected&&
                        <CardElement
                            content={ 
                                <Report 
                                    el={el} 
                                    i={index} 
                                    blockCampaign={blockReports} 
                                    unBlockCampaign={unBlockReports}
                                    isSaving={isSaving}
                                    invalid={invalid}
                                    openOperatorsList={openOperatorsList}
                                    fields={fieldsList.concat(fields)}
                                    reports={reports}
                                    queues={queues}
                                    // operators={operators}
                                    campaigns={campaigns}
                                />
                            }
                        />
                    }
                </Fragment>
            ))}
            {newReport&&
                <Modal 
                    // width='lg'
                    title="Добавить отчет"
                    open={newReport} 
                    handleSaveClick={save} 
                    handleCloseClick={open} 
                    fields={<NewReportForm {...props}/>} 
                />
            }
        </div>
    );

}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('project')(state);
    return { 
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'project',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    })
);

export default enhance(ReportsFunc);