import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';

import { 
    TextField, 
    Labeled,
    SelectField,
    DateField,
    CardElement,
    TimeField,
    Row,
    EditButton,
    CloseButton
} from '../_Components';

import {GET_ONE, provider, history } from '../_utils';
// import Actions from './components/Actions';
// import PaySheetHeader from './components/PaySheetHeader';
// import PaySheetData from './components/PaySheetData';
import Utils from './components/utils';
const { places, statuses, operatorsTypes, contractTypes, departments } = Utils.options();

const PaySheetHeader = ({data}) => {
    return (
        <Fragment>
                <Row>
                    <Labeled label="Тип">
                        <SelectField options={operatorsTypes} value={data.operator ? data.operator.type : "-"}/>
                    </Labeled>
                    <Labeled label="Статус">
                        <SelectField options={statuses} value={data.operator ? data.operator.status : "-"}/>
                    </Labeled>
                    <Labeled label="Отдел">
                        <SelectField options={departments} value={data.operator ? data.operator.department : "-"}/>
                    </Labeled>
                    <Labeled label="Площадка">
                        <SelectField options={places} value={data.operator ? data.operator.place : "-"}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="Договор">
                        <SelectField options={contractTypes} value={data.operator&&data.operator.contract ? data.operator.contract.type : "-"}/>
                    </Labeled>
                    <Labeled label="Договор">
                        <TextField value={data.operator&&data.operator.contract ? data.operator.contract.no : "-"}/>
                    </Labeled>
                    <Labeled label="Дата окончания">
                        <DateField value={data.operator&&data.operator.contract ? moment(data.operator.contract.termitated) : "-"}/>
                    </Labeled>
                </Row>
        </Fragment>
    );
}

const PaySheetData = ({data}) => {
    return (
        <Fragment>
            {data.statistic&&
                <Fragment>
                    <Row>
                        <Labeled label="Тариф">
                            <TextField value={data.salary.name}/>
                        </Labeled>
                    </Row>
                    <Row>
                        <Labeled label="Общее время">
                            <TimeField value={data.statistic.totalTime ? data.statistic.totalTime : 0}/>
                        </Labeled>
                        <Labeled label="В линии">
                            <TimeField value={data.statistic.paid ? data.statistic.paid : 0}/>
                        </Labeled>
                        <Labeled label="В разговоре">
                            <TimeField value={data.statistic.talkTime ? data.statistic.talkTime : 0}/>
                        </Labeled>
                        <Labeled label="В работаю">
                            <TimeField value={data.statistic.work ? data.statistic.work : 0}/>
                        </Labeled>
                    </Row>
                    <Row>
                        <Labeled label="Не готов">
                            <TimeField value={data.statistic.notReady ? data.statistic.notReady : 0}/>
                        </Labeled>
                        <Labeled label="OCC">
                            <TextField value={data.statistic.occPercent ? `${data.statistic.occPercent.toFixed(2)}%` : `0.00%`}/>
                        </Labeled>
                        <Labeled label="Звонков">
                            <TextField value={data.statistic.calls ? `${data.statistic.calls}` : `0`}/>
                        </Labeled>
                    </Row>
                </Fragment>
            }
        </Fragment>
    );
}

const PaySheet = ({id}) => {
    const [data, setData] = useState({});
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        const get = async () => {
            const { data, status } = await provider( 
                GET_ONE,
                '/operators/reports/', 
                id
            );
            if(status === 200 ){
                setData(data);
                setIsMounted(true);
            }
        }
    
        if(!isMounted){
            get();
        }
    }, [id, isMounted]);

    // async getStatistics (login, startDate, endDate) {
    //     const { data } =  await provider(
    //         GET_LIST,
    //         `/infra/statistics/agents/${login}`,
    //         {
    //             filter: { startDate, endDate },
    //             sort: { field: 'date', order: 'ASC' },
    //             pagination: { page: 1, perPage: 1000 },
    //         }
    //     );
    //     this.setState({
    //         statistics: data
    //     });
        
    // }

    const close = () =>{
        return history.goBack();
    }

    const edit = () =>{
        return history.push(`${history.location.pathname.replace('show', 'edit')}`);
    }

    return (
        <CardElement
            action={
                <Fragment>
                    <EditButton handleEditClick={edit}/>
                    <CloseButton handleCloseClick={close}/>
                </Fragment>
            }
            content={
                <Fragment>
                    <p>{(data&&data.operator) ? data.operator.login : ''} {data.operator ? data.operator.fullname : ''}`</p>
                    <PaySheetHeader data={data}/>
                    <PaySheetData data={data}/>
                    {(data&&data.detail)&&
                        <Fragment>
                            <Row>
                                <Labeled label="В линии" >
                                    <TextField value={data.detail.paidTime ? `${data.detail.paidTime.time.toFixed(2)} мин x ${data.detail.paidTime.tarif} = ${data.detail.paidTime.paid.toFixed(2)} р` : `0 р`}/>
                                </Labeled>
                                <Labeled label="В разговоре" >
                                    <TextField value={data.detail.talkTime ? `${data.detail.talkTime.time.toFixed(2)} мин x ${data.detail.talkTime.tarif} = ${data.detail.talkTime.paid.toFixed(2)} р` : `0 р`}/>
                                </Labeled>
                            </Row>
                            <Row>
                                <Labeled label="% премиии" >
                                    <TextField value={data.detail.premia ? `${data.detail.premia.percent}%`: `0%`}/>
                                </Labeled>
                                <Labeled label="Премиальные" >
                                    <TextField value={data.detail.premia ? data.detail.premia.paid.toFixed(2) : `0 р`}/>
                                </Labeled>
                                <Labeled label="ИТОГО" >
                                    <TextField value={data.summa ? `${data.summa.toFixed(2)} р` : `0 р`}/>
                                </Labeled>
                            </Row>
                        </Fragment>
                    }
                </Fragment>
            }
        />
    )
}

export default PaySheet;
