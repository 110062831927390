import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {compose} from 'recompose';

const Actions = ({actions}) => {
    return(
        <Fragment>
            {actions.map((action, index) => (
                <Fragment key={index}>
                    {action}
                </Fragment>
            ))}
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    const { fetch } = state;
    return { 
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false
    };
};

export default  compose(
    connect(mapStateToProps),
)(Actions);
