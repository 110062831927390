import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import LinearProgress from '@mui/material/LinearProgress';

import IconButton from '@mui/material/IconButton';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

import Utils from '../utils';

const styles = theme => ({
    root: {
        borderBottom: '1px rgba(0, 0, 0, 0.12) solid !important',
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 5,
        paddingRight: 5,
        height: 35
    },
    main: {
        verticalAlign: 'top',
        boxShadow: 'unset',
        overflow: 'auto',
        flexGrow: 1,
        flexBasis: '25%',
    },
    card: {
        overflow: 'inherit !important',
        // textAlign: 'right',
    },
    cardTitle: {
        color: '#ffff !important',
        fontSize: '1rem !important',
        // fontWeight: 600
    },
    cardHeader: {
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
        backgroundColor: '#05a1cd !important'
    },
    cardContent: {
        // flex: 0,
        // paddingLeft: theme.spacing(2)
    },
    cardAction: {
        // flex: '1 1 auto',
        marginTop: 0
    },
    cardIcon: {
        color: '#ffff !important',
        width: '34px !important', 
        height: '34px !important',
        padding: '0px !important',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        paddingRight: 0,
    },
    headerItemText: {
        paddingRight: 0,
        color: '#ffff'
    },
    actionSyle: {
        zIndex: 2,
        float: 'right',
        padding: 0
    },
    firstLine: {
        margin: 0,
        height: 17,
        fontSize: 11,
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1.5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    secondLine: {
        fontSize: '0.6rem',
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.46429em',
        margin: 0,
        display: 'block',
        height: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
    
});

const Statistic = ({sl, abandonedPercent, answered, talkTime}) => {

    return(
        <span>
            <SignalCellularAltIcon style={{fontSize: '0.7rem', color: '#31708f', paddingLeft: 5}} />
                <span style={{paddingLeft: 5}}>{sl.toFixed(2)}%</span>
            <PhoneCallbackIcon style={{fontSize: '0.7rem', color: '#59af50', paddingLeft: 5}}/>
                <span style={{paddingLeft: 5}}>{answered} </span>
            <PhoneMissedIcon style={{fontSize: '0.7rem', color: '#ff5722', paddingLeft: 5}}/> 
                <span style={{paddingLeft: 5}}>{abandonedPercent.toFixed(2)}% </span>
            <PhoneInTalkIcon style={{fontSize: '0.7rem', color: '#31708f', paddingLeft: 5}}/>
                <span style={{paddingLeft: 5}}>{Utils.secondsToTime(talkTime)}</span>
        </span>

    )
}

const Inbound = ({ items = {}, statistics = [], marginLeft = 0, showInbound, paddingTop = 0, classes, showDetail, detail }) => (
    <div className={classes.main} style={{marginLeft, paddingTop}}>
        <Card className={classes.card}>
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                    root: classes.cardHeader,
                    content: classes.cardContent,
                    action: classes.cardAction
                }}
                action={
                    <IconButton className={classes.cardIcon} onClick={() => showDetail('inbound') }>
                        {detail==='inbound' ?
                                <ChevronLeftIcon />
                            :
                                <ChevronRightIcon />
                        }
                    </IconButton>
                }
                title="Входящие"
                subheader={
                    detail==='inbound' &&
                        <div className={classes.listItemText} style={{width: '100%'}}>
                            <div className={classes.firstLine}>
                                <div className={classes.headerItemText} style={{width: '25%', display: 'inline-block'}}>
                                    Проект
                                </div>
                                <div className={classes.headerItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center'}}>
                                    Всего
                                </div>
                                <div className={classes.headerItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center'}}>
                                    SL
                                </div>
                                <div className={classes.headerItemText} style={{width: '6%', display: 'inline-block', textAlign: 'center'}}>
                                    Отвечено
                                </div>
                                <div className={classes.headerItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center'}}>
                                    Время
                                </div>
                                <div className={classes.headerItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center'}}>
                                    Ср.
                                </div>
                                <div className={classes.headerItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center'}}>
                                    Потеряно
                                </div>
                                <div className={classes.headerItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center'}}>
                                    AR
                                </div>
                                <div className={classes.headerItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center'}}>
                                    Ср.
                                </div>
                                <div className={classes.headerItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center'}}>
                                    На линии
                                </div>
                                <div className={classes.headerItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center'}}>
                                    Свободно
                                </div>
                                <div className={classes.headerItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center'}}>
                                    Соеденено
                                </div>
                                <div className={classes.headerItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center'}}>
                                    Ожидает
                                </div>
                            </div>
                        </div>
                }
            />
           
            {items&&items.inbounds ? 
                <List style={{paddingTop: 0, paddingBottom: 0}}>
                    {items.inbounds.map(record =>{
                        // let backgroundColor = 'none';
                        let color = '';
                        let backgroundColor = 'none'

                        if(record.sessions.connected > 0 && record.sessions.wating<=0){
                            backgroundColor = '#e8f5e9';
                            color = "#3c763d"
                        }else if(record.sessions.connected >= 0 && record.sessions.wating===1){
                            backgroundColor = '#fff8e1';
                            color = "#8a6d3b"
                        }else if(record.sessions.connected >= 0 && record.sessions.wating>1){
                            backgroundColor = '#ffebee';
                            color = "#ec6859"
                        }
                        const statistic = statistics ? statistics.find( s => s.id === record.id) : null;
                        return (
                            <ListItem
                                button
                                className={classes.root}
                                key={record.id}
                                onClick={ () => showInbound(record)}
                                style={{ backgroundColor: backgroundColor }}
                            >   
                                {detail!=='inbound' ?
                                    <div className={classes.listItemText} style={{width: '100%'}}>
                                        <span className={classes.firstLine}>
                                            <span className={classes.listItemText} style={{width: '80%', display: 'inline-flex', color: color}}>
                                                {record.name}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                                {record.online}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                                {record.available}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                                {record.sessions.connected}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                                {record.sessions.wating}
                                            </span>
                                        </span>
                                        <p className={classes.secondLine}>
                                            { statistic ? <Statistic sl={statistic.sl}  abandonedPercent={statistic.abandonedPercent} answered={statistic.answered} talkTime={statistic.talkTime} /> :  ``}
                                        </p>
                                    </div>
                                    :
                                    <div className={classes.listItemText} style={{width: '100%', paddingLeft: 16, paddingRight: 34}}>
                                        <span className={classes.firstLine}>
                                            <span className={classes.listItemText} style={{width: '25%', display: 'inline-flex'}}>
                                                {record.name}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {statistic ? statistic.count+statistic.abandoned : 0} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {statistic ? `${statistic.sl}%` : '-'} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '6%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {statistic ? statistic.count : 0} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {Utils.secondsToTime(statistic ? statistic.talktime : 0)} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {Utils.secondsToTime(statistic ? (statistic.count > 0 ? statistic.talktime/statistic.count : 0) : 0)} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {statistic ? statistic.abandoned : 0} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '5%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {statistic ? `${statistic.ar}%` : '-'} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {/* {Utils.secondsToTime(statistic ? (statistic.abandoned=== 0 ? 0 : statistic.atime/statistic.abandoned) : 0)} */}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {record.online}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {+record.available}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {record.sessions.connected}
                                            </span>
                                            <span className={classes.listItemText} style={{width: '7%', display: 'inline-block', textAlign: 'center', color: color}}>
                                                {record.sessions.wating}
                                            </span>
                                        </span>
                                    </div>
                                }
                            </ListItem>
                        )
                    } )}
                </List>
                :
                <LinearProgress />
            }
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles)
);

export default enhance(Inbound);
