import React, { Fragment, useState } from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import {  TextInput, SelectInput, CardElement, AddButton, Modal } from '../../_Components';


const Modules = [
    {id: 'lead', name: 'Лиды'},
    { id: 'contact', name: 'Контакт'},
    {id: 'deal', name: 'Сделка'}
];

const CRM_FIELDS = [
    {
        id: 'TITLE',
        name: 'Название лида'
    },
    {
        id: 'NAME',
        name: 'Имя контакта'
    },
    {
        id: 'COMPANY_TITLE',
        name: 'Название компании'
    },
    {
        id: 'SOURCE_ID',
        name: 'Идентификатор источника'
    },
    {
        id: 'STATUS_ID',
        name: 'Идентификатор  статуса лида'
    },
    {
        id: 'COMMENTS',
        name: 'Комментарии'
    },
    {
        id: 'ASSIGNED_BY_ID',
        name: 'Ответственный'
    },
    {
        id: 'PHONE',
        name: 'Телефон'
    },

]

const style = {
    iconEdit: {
        color: '#0b2b60',
        cursor: 'pointer'
    },
    card: {
        flex: 'auto',
        height: 'calc(100vh - 210px)',
        width: '50%',
        overflow: 'auto !important'
    },
    row: {
        display: 'flex',
        paddingBottom: '1em'
    },
    field:{
        width: '16rem'
    },
    service: {
        width: '0.9rem !important',
        height: '0.9rem !important',
        marginLeft: 4,
        color: '#0b2b61'
    }
};

const List = ({list, fields, classes, setSelected, leadFields,  contactFields, dealFields, index}) =>{
    const findModule = (module) => Modules.find(o => o.id === module);
    const findFields = (module, crm) =>  {
        let option = CRM_FIELDS.find( o => o.id === crm) ? leadFields.find( o => o.id === crm).name : '-';;
        switch (findModule(module).id) {
            case "lead":
                option = leadFields.find( o => o.id === crm) ? leadFields.find( o => o.id === crm).name : '-';
                break;
            case "contact":
                option = contactFields.find( o => o.id === crm) ? leadFields.find( o => o.id === crm).name : '-';;
                break;
            case "company":
                option = dealFields.find( o => o.id === crm) ? leadFields.find( o => o.id === crm).name : '-';;
                break;
            default:
                break;
        }
        return option;
    }
    
    return(
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Поле</b></TableCell>
                        <TableCell><b>Модуль</b></TableCell>
                        <TableCell><b>Поле CRM</b></TableCell>
                        <TableCell><b>Значение</b></TableCell>
                        <TableCell classes={{ root: classes.cel}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list&&list.map((row, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={() => setSelected(i)}
                            style={{
                                cursor: 'pointer', 
                                backgroundColor : i===index ? 'antiquewhite' : ''
                            }}
                        >
                            <TableCell classes={{ root: classes.cel}} style={{color: row.deleted ? 'red' : ''}} component="th" scope="row">
                                {fields.find(f => f.value === row.field)&&fields.find(f => f.value === row.field).label}
                            </TableCell>
                            <TableCell component="th" scope="row">{findModule(row.module)&&findModule(row.module).name}</TableCell>
                            <TableCell component="th" scope="row">{findModule(row.module)&&findFields(row.module, row.crm)}</TableCell>
                            <TableCell component="th" scope="row">{row.default}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
)
}; 

const Edit = ({classes, index, fields, ...props}) => {
    const { formValues, leadFields,  contactFields, dealFields} = props;
    const isForm = index>=0;
    // const [type, setType] = useState(index ? formValues.fields[index].type : null);
    const fieldsArray  = fields.map( f => ({ id: f.value, name: f.label}));
    return (
        <Fragment>
            <div className={classes.row}>
                <Field
                    name={isForm ? `integration[${index}].field` : `field`}
                    required={true}
                    options={fieldsArray}
                    component={SelectInput}
                    label={`Поле`}
                    className={classes.field}
                />
                <Field
                    name={isForm ? `integration[${index}].module` : `module`}
                    required={true}
                    options={Modules}
                    component={SelectInput}
                    label={`Модуль`}
                    className={classes.field}
                />
            </div>
            <div className={classes.row}>
                {((formValues&&formValues.integration&&formValues.integration[index]&&formValues.integration[index].module==="lead") || (formValues&&formValues.module==="lead"))&&
                    <Field
                        name={isForm ? `integration[${index}].crm` : `crm`}
                        required={true}
                        options={leadFields}
                        component={SelectInput}
                        label={`Поле CRM`}
                        className={classes.field}
                    />
                }
                {((formValues&&formValues.integration&&formValues.integration[index]&&formValues.integration[index].module==="contact") || (formValues&&formValues.module==="contact"))&&
                    <Field
                        name={isForm ? `integration[${index}].crm` : `crm`}
                        required={true}
                        options={contactFields}
                        component={SelectInput}
                        label={`Поле CRM`}
                        className={classes.field}
                    />
                }
                {((formValues&&formValues.integration&&formValues.integration[index]&&formValues.integration[index].field==="company") || (formValues&&formValues.module==="company"))&&
                    <Field
                        name={isForm ? `integration[${index}].crm` : `crm`}
                        required={true}
                        options={dealFields}
                        component={SelectInput}
                        label={`Поле CRM`}
                        className={classes.field}
                    />
                }
               
                <Field
                    name={isForm ? `integration[${index}].default` : `default`}
                    required={true}
                    component={TextInput}
                    label={`Значение`}
                    className={classes.field}
                />
            </div>
            
        </Fragment>
    )
}

const Integration = ({classes, formValues, change, project, ...props}) =>{
    const [open, setOpen] = useState(false);
    const [editField, setEditField] = useState(0);
    const { combobox, fields } = project;
    const { integration } = formValues;
    const comboboxes = combobox.map( el => {return { id: el._id, name: el.name} });

    const setSelected = (index) => setEditField(index);

    const openEdit = () => setOpen(true);

    const closeEdit = () => setOpen(false);

    const save = ( form ) => {
        const { integration } = formValues;
        integration.push(form);
        change(`integration`, integration);
        setEditField(integration.length-1);
        closeEdit();
    }

    return (
        <div style={{ height: 'calc(100vh - 200px)', display: 'flex' }}>
            <CardElement 
                height={'calc(100vh - 210px)'}
                width={60}
                action={<AddButton  handleAddClick={openEdit} label="Добавить поле"/>}
                content={<List 
                    classes={classes} 
                    index={editField} 
                    list={integration} 
                    fields={fields} 
                    comboboxes={comboboxes} 
                    formValues={formValues} 
                    setSelected={setSelected} 
                    {...props}
                />}
            />
            {integration.map( (field, index) => (
                <CardElement 
                    key={index}
                    height={'calc(100vh - 210px)'}
                    width={40}
                    style={{display: index!==editField ? 'none' : '' }}
                    content={
                        <Edit 
                            index={index} 
                            classes={classes} 
                            fields={fields} 
                            comboboxes={comboboxes} 
                            formValues={formValues} 
                            {...props}
                        />
                    }
                />
            ))}
            {open&&
                <Modal 
                    title="Добавить поле"
                    open={open} 
                    handleSaveClick={save} 
                    handleCloseClick={closeEdit} 
                    fields={<Edit classes={classes} comboboxes={comboboxes} fields={fields}  {...props}/>} 
                />
            }
        </div>
    );

}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('card')(state);
    const { fetch } = state;
    return { 
        formValues,
        sLoading: !!fetch.loading
    }
};

const enhance = compose(
    connect(mapStateToProps),
    withStyles(style),
    reduxForm({
        form: 'card',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
);

export default enhance(Integration);