import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { TextInput, SelectInput, SwitchInput, ModalButtons } from '../../_Components';

import { Options } from '../../_utils/_constants';


const typesArray = ["input", "number", "select", "text", "phone", "email", "name", "boolean", "radio", "integration"]


const styles = theme => ({
    row: {
        display: 'flex',
        paddingBottom: '1em'
    },
    settings: {
        display: 'inline-flex',
        marginRight: '2em'
    },
    radio: {
        paddingRight: 30
    },
    field: {
        display: 'inline-flex',
        marginRight: '2em'
    },
    block: {
        height: 'calc(100vh - 260px)',
        display: 'flex'
    },
    combobox: {
        width: '22em',
        // display: 'inline-flex',
        marginRight: 32,
    },
    options: {
        // display: 'inline-flex',
        width:' 55em',
    },
    button: {
        marginBottom: 16
    },
    iconEdit: {
        fontSize: '1.2rem !important',
        float: 'right',
        cursor: 'pointer'
    },
    iconTrash: {
        fontSize: '1.2rem !important',
        float: 'right',
        color: 'red',
        marginLeft: 8,
        cursor: 'pointer',
    },
    label: {
        width: 280,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
   
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TypeOptions = Options.FieldsTypesOptions;

class AddCol extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initial: false,
            fieldsArray: [],
        };
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    close = () => {
        const { close } = this.props;
        this.setState({ initial: false}, () => close() );
    }

    save = () => {
        const { save, formValues } = this.props;
        this.setState({ initial: false}, () => save(formValues) );
    }

    render() {
        const { open, classes, fields, formValues, items } = this.props;
        return(
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Добавить поле</DialogTitle>
                <DialogContent>
                     { this._isMounted&&
                        <Fragment>
                            <div className={classes.row}>
                                <Field
                                    name={`field`}
                                    required={true}
                                    component={SelectInput}
                                    options={fields.filter(e => items.map(i => i.i).indexOf(e.id)<0).filter( f => typesArray.indexOf(f.type)>=0)}
                                    label={`Поле`}
                                    className={classes.settings}
                                    style={{width: 300}}
                                />
                                <Field
                                    name={`field`}
                                    component={TextInput}
                                    label={`Значение`}
                                    disabled={true}
                                    className={classes.settings}
                                    style={{width: 300}}
                                />
                                <SelectInput 
                                    label={`Тип`}
                                    value={formValues.field ? fields.find( el => el.id===formValues.field).type : ''}
                                    className={classes.settings}
                                    style={{width: 300}}
                                    disabled={true}
                                    options={TypeOptions}
                                />
                            </div>
                            <div className={classes.row}>
                                <Field
                                    name={`required`}
                                    required={true}
                                    component={SwitchInput}
                                    label={`Обязательное`}
                                    className={classes.radio}
                                    style={{paddingRight: 300}}
                                />
                                <Field
                                    name={`readonly`}
                                    required={true}
                                    component={SwitchInput}
                                    label={`Только чтение`}
                                    className={classes.radio}
                                    style={{width: 300}}
                                />
                                <Field
                                    name={`crm`}
                                    required={true}
                                    component={SwitchInput}
                                    label={`Поле CRM`}
                                    className={classes.radio}
                                    style={{width: 300}}
                                />
                                {formValues.field&&fields.find( el => el.id===formValues.field).type==='phone'&&
                                    <Field
                                        name={`callPhone`}
                                        required={true}
                                        component={SwitchInput}
                                        label={`Тел. звонящего`}
                                        className={classes.radio}
                                        style={{width: 300}}
                                    />
                                }
                                {formValues.field&&fields.find( el => el.id===formValues.field).type==='name'&&
                                    <Field
                                        name={`full_name`}
                                        required={true}
                                        component={SwitchInput}
                                        label={`Полное имя`}
                                        className={classes.radio}
                                        style={{width: 300}}
                                    />
                                }
                            </div>
                        </Fragment>
                     }

                </DialogContent>
                <DialogActions>
                    <ModalButtons handleSaveClick={this.save} handleCloseClick={this.close}/>
                </DialogActions>
            </Dialog>
        )
    }
}



const mapStateToProps = (state, props) => {
    const initialValues = {
        field: null,
        callPhone: false,
        crm: false,
        delete: false,
        full_name: false,
        last_name: false,
        readonly: false,
        required: false,
        second_name: false,
    }
    const formValues = getFormValues('cardAddCol')(state);
    return { 
        initialValues: {
            ...initialValues
        },
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'cardAddCol',
        destroyOnUnmount: true, // <------ preserve form data
        forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    }),
    withStyles(styles)
);

export default enhance(AddCol);