import React, { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';

import { Options } from '../../_utils/_constants';
import { SelectInput, TextInput, AddButton, SortableList, CardElement, Dragable, Modal } from '../../_Components';

const itemsFields = [
    {
        value: 'name'
    },
    {
        value: 'type',
        label: 'Тип'
    }
];

const header = [
    {
        label: 'Название',
        width: '40%',
        value: 'fieldComponent'
    },
    {
        label: 'Значение',
        width: '40%',
        value: 'valueComponent'
    },
    {
        label: '',
        width: '3%'
    }
];


const dataArray = (data, project, activeNodekey) => {
    const rows = data.map((item, key) => {
        const el = {
            key: key+1,
            fieldComponent: 
                <Field
                    name={`template[${activeNodekey}].fields[${key}].field`}
                    required={true}
                    component={TextInput}
                    style={{width: '90%'}}
                />,
            valueComponent:
                <Field
                    name={`template[${activeNodekey}].fields[${key}].value`}
                    component={SelectInput}
                    options={project.fields.map( o => { return {id: o.value, name: o.label } }) }
                    style={{width: '90%'}}
                />,
            ...item
        };
        return el;
    });
    return rows;
}

const NewTamplateForm = () => (
    <div style={{display: 'flex', paddingBottom: '1em'}}>
        <Field
            name={`name`}
            required={true}
            component={TextInput}
            style={{display: 'inline-flex', marginRight: '2em', width: 300}}
            label={`Название`}
        />
        <Field
            name={`type`}
            required={true}
            component={SelectInput}
            options={Options.TempateTypeOptions}
            style={{display: 'inline-flex', marginRight: '2em', width: 300}}
            label={`Тип`}
        />
    </div>
)

const Tamplates = ({formValues: { template }, project, change}) => {
    const [activeNodekey, setActiveNodekey] = useState(template.length>0 ? 0 : null);
    const [open, setOpen] = useState(false);


    const setActive = (index) => {
        setActiveNodekey(index);
    }

    const addTemplate = (item) => {
        console.log(item);
        template.push({...item, fields: []});
        change(`template`, template);
        setActiveNodekey(template.length-1);
        openNewTamplateDialog();
    }

    const openNewTamplateDialog = () =>{
        setOpen(!open)
    }

    const sort = (fields) => {
        change(`template[${activeNodekey}].fields`, fields);
    }

    const removeItem = (key) => {
        const { fields } = template[activeNodekey];
        const newArray = [];
        fields.map( (p,i) => {
            if(i!==key) newArray.push(p);
            return p;
        });
        change(`template[${activeNodekey}].fields`, newArray);
    }

    const addItem = () => {
        const { fields } = template[activeNodekey];
        const newArray = fields.map( p => p );
        newArray.push({});
        change(`template[${activeNodekey}].fields`, newArray);
    }
    
    return(
        <div style={{ height: 'calc(100vh - 260px)', display: 'flex' }}>
            <CardElement 
                width={25}
                action={<AddButton  handleAddClick={openNewTamplateDialog} label={'Новый шаблон'}/>}
                content={
                    <SortableList 
                        items={template}
                        itemsFields={itemsFields}
                        useDragHandle={true} 
                        setActiveNode={setActive} 
                        activeNodekey={activeNodekey}
                        sortable={false}
                    />      
                }
            />
            {open&&<Modal 
                title={`Новый шаблон`}
                open={open} 
                handleSaveClick={addTemplate} 
                handleCloseClick={openNewTamplateDialog} 
                fields={<NewTamplateForm />}
            />}
            <CardElement 
                width={75}
                content={
                    template.map( (item, key) => {
                        if(key!==activeNodekey) return null;
                        return (
                            <div key={key}>
                                <div>
                                    <Field
                                        name={`template[${activeNodekey}].name`}
                                        required={true}
                                        component={TextInput}
                                        label={`Название`}
                                        style={{width: 300}}
                                    />
                                    <Field
                                        name={`template[${activeNodekey}].type`}
                                        component={SelectInput}
                                        label={`Тип`}
                                        options={Options.TempateTypeOptions}
                                        style={{width: 300}}
                                    />
                                    {template[activeNodekey].type==='email'&&
                                        <Field
                                            name={`template[${activeNodekey}].subject`}
                                            component={SelectInput}
                                            label={`Тема из поля`}
                                            options={project.fields.map( o => { return {id: o.value, name: o.label } }) }
                                            style={{width: 300}}
                                        />
                                    }
                                </div>
                                <Dragable 
                                    style={{ width: 600}}
                                    header={header} 
                                    rows={dataArray(item.fields, project, activeNodekey)} 
                                    edit={false}
                                    sort={sort}
                                    remove={true}
                                    removeFunc={removeItem}
                                />
                                <AddButton  handleAddClick={addItem}/>
                            </div>
                        )
                    })}
            />
            
        </div>
    );
}

const mapStateToProps = (state) => {
    const formValues = getFormValues('card')(state);
    const { fetch } = state;
    return { 
        formValues,
        isLoading: !!fetch.loading
    }
};

export default  compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'card',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
)(Tamplates);