import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@mui/styles';

import Responsive from '../../_Components/responsive'

import Desktop from './Desktop';
import Mobile from './Mobile';
import Tablet from './Tablet';


const styles = theme => ({
    mainDesktop: {
        verticalAlign: 'top',
        boxShadow: 'unset',
        overflow: 'auto',
        flexGrow: 1,
        flexBasis: '40%',
    },
    mainMobile: {
        verticalAlign: 'top',
        boxShadow: 'unset',
        overflow: 'auto',
        flexGrow: 1,
        flexBasis: '40%',
    }
});

let anchorEl = null;
let currentAgent = {
    backgroundColor: null,
    login: null
};

const handleClick = (event, login, backgroundColor) => {
    anchorEl = event.currentTarget;
    currentAgent = { login, backgroundColor};
};

const handleClose = () => {
    anchorEl = null;
    currentAgent = {
        backgroundColor: null,
        login: null
    };
};

const Agents = ({ items = {}, statistics = [], changeState, showAgent, classes, ...props }) => (
    <Responsive 
        xsmall={
            <div className={classes.mainDesktop}>
                <Mobile 
                    items={items} 
                    statistics={statistics} 
                    changeState={changeState} 
                    showAgent={showAgent} 
                    handleClick={handleClick} 
                    handleClose={handleClose} 
                    currentAgent={currentAgent} 
                    anchorEl={anchorEl} 
                    {...props}
                />
            </div>
        }
        small={
            <div className={classes.mainDesktop}>
                <Tablet 
                    items={items} 
                    statistics={statistics} 
                    changeState={changeState} 
                    showAgent={showAgent} 
                    handleClick={handleClick} 
                    handleClose={handleClose} 
                    currentAgent={currentAgent} 
                    anchorEl={anchorEl} 
                    {...props}
                />
            </div>
        }
        medium={
            <div className={classes.mainDesktop}>
                <Desktop 
                    items={items} 
                    statistics={statistics} 
                    changeState={changeState} 
                    showAgent={showAgent} 
                    handleClick={handleClick} 
                    handleClose={handleClose} 
                    currentAgent={currentAgent} 
                    anchorEl={anchorEl} 
                    {...props}
                />
            </div>
        }
    />
    
);

const enhance = compose(
    withStyles(styles)
);

export default enhance(Agents);
