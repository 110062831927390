import React from "react";
import PropTypes from 'prop-types';
import { Button } from "@mui/material";

import AddCircleIcon from '@mui/icons-material/AddCircle';

const AddButton = ({handleAddClick, label, ...props}) => (
    <Button
        color="primary" 
        size="small"
        variant="outlined"
        endIcon={<AddCircleIcon />}
        onClick={() => handleAddClick()}
        {...props}
    >
        {label || "Добавить"}
    </Button>
)

AddButton.propTypes = {
    handleAddClick: PropTypes.func
};

export default AddButton;