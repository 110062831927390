import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';

import arrayMove from 'array-move';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

const style = {
    ul: {
        padding: 0
    },
    li: {
        borderBottom: '1px dotted #b2b2b2',
        listStyle: 'none',
        background: 'white',
        marginBottom: 1,
        display: 'flex'
    },
    liActive: {
        backgroundColor: '#d6eafb',
        borderBottom: '1px dotted #b2b2b2',
        // color: '#ffff',
        listStyle: 'none',
        background: 'white',
        marginBottom: 1,
        display: 'flex'
    },
    handleIcon: {
        verticalAlign: 'middle',
        paddingLeft: 10,
        cursor: 'ns-resize'
    },
    label: {
        verticalAlign: 'middle',
        paddingLeft: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '85%',
        display:' block',
        fontSize: '0.75rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        width: '100%',
        cursor: 'pointer'
    },
    sortableHelper: {
        zIndex: '10000 !important'
    },
    editIcon: {
        verticalAlign: 'middle',
        color: '#0a2b61',
        paddingRight: 8,
        float: 'right',
        fontSize: '1rem !important'
    },
    iconButton: {
       padding: '0 !important'
    },
}

const DragHandle = SortableHandle(({classes}) => 
    <span>
        <DragHandleIcon className={classes.handleIcon} />
    </span>
);

const SortableItem = SortableElement(({ classes, nodeIndex, value, handleClick, activeNodekey, sortable, itemsFields, edit, handleEditClick}) =>(
    <li className={ activeNodekey===nodeIndex ? classes.liActive : classes.li}   >
        {sortable&&<DragHandle classes={classes}/>}
        <div 
            onClick={ ()=> handleClick(nodeIndex) }
            className={classes.label} 
            style={{color: `${value.deleted ? 'red': ''}`}}
        >
            {itemsFields.map( (field, i) =>{
                if(i===0){
                    return <div key={i}>{field.label&&<i>`{field.label}: `</i>}{value[field.value]}</div>
                }else if(i===1){ 
                    return <div key={i}>{field.label&&<i>`{field.label}: `</i>}{value[field.value]}</div>;
                }else{
                    return <div key={i}><i>{field.label&&<i>`{field.label}: `</i>}{value[field.value]}</i></div>
                }
            })}
        </div>
        {edit&&
            <IconButton className={classes.iconButton} onClick={() => handleEditClick(value, nodeIndex)} >
                <EditIcon size="small" classes={{root: classes.editIcon}}/>
            </IconButton> 
        }
    </li>
));

const SortableList = SortableContainer(({
    useDragHandle = true, 
    items, activeNodekey, 
    handleClick, 
    sortable, 
    itemsFields, 
    classes, 
    edit, 
    handleEditClick, 
}) => {
    return (
        <ul className={classes.ul}>
            {items.map((value, index) =>   
                <SortableItem 
                    classes={classes} 
                    key={`item-${index}`} 
                    activeNodekey={activeNodekey}  
                    handleClick={handleClick} 
                    index={index} 
                    nodeIndex={index} 
                    value={value}
                    sortable={sortable}
                    itemsFields={itemsFields}
                    edit={edit}
                    handleEditClick={handleEditClick}
                />
            )}
        </ul>
    )
});

const SortableComponent = ({classes, items, onSortabe, setActiveNode, ...props}) => {
    const [itemsState, setItemsState] =  useState(items);

    useEffect(() => {
        setItemsState(items);
    }, [items]);

    const onSortEnd = ({oldIndex, newIndex}) => {
        setItemsState(arrayMove(itemsState, oldIndex, newIndex));
        if(onSortabe){
            onSortabe(arrayMove(itemsState, oldIndex, newIndex), newIndex);
        }
    };

    const handleClick = (index) => {
        setActiveNode(index);
    }
    
    return <SortableList helperClass={classes.sortableHelper} classes={classes} items={itemsState} onSortEnd={onSortEnd} handleClick={handleClick} {...props}/>
}

export default withStyles(style)(SortableComponent);