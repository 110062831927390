import React from 'react';
import { withStyles } from '@mui/styles';

const style = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
        whiteSpace: 'nowrap'
    },
    div: {
        textAlign: 'center',
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
    seccess: {
        color: '#4CAF50',
        fontWeight: 600
    },
    fail: {
        color: '#F44336',
        fontWeight: 600
    }
};

const SuccessField = ({ classes, style, ...props}) =>{
    let value = ""
    switch (props.value){
        case "success":
            value = <div className={classes.seccess}>
                        Успешный
                    </div>
            break
        case "fail":
            value = <div className={classes.fail}>
                        Не успешный
                    </div>
            break
        default:
            value = "-"
    }
    return(
        <div className={classes.main} style={style}>
            <div className={classes.div}>
               {value}
            </div>
        </div>
    )
}

export default withStyles(style)(SuccessField);
