import React from 'react';
import Utils from '../utils';

const styles = {
	campaign:{
	    height: 20,
	    textAlign: 'end',
		paddingRight: 10,
	    paddingLeft: 10,
	    fontSize: 11,
	    borderLeft: '1px solid black',
	    borderTop: '1px solid black',
	    boxSizing: 'border-box',
	    zIndex: 6,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
    	whiteSpace: 'nowrap'
	}
}

export function customItemRenderer(props) {
  	const {item, ...rest} = props;
    const { content } = Utils.itemContent(item.data);
  	return <div style={{textAlign: 'center', color: item.text}} {...rest}> {content} </div>;
}

export function customGroupRenderer(props) {
  const {group, rowIndex, ...rest} = props;
  return (
    <span data-group-index={group.id} {...rest}>
    	<span>{group.data.name}</span>
    </span>
  );
}

export function customTitleRenderer(props) {
    const { data, onRowCampaignClick } = props;
    let  backgroundColor = 'white';
    if(data.selected){
      backgroundColor = 'antiquewhite';
    }
    const { campaign } = styles;
    return <div style={{backgroundColor, ...campaign}} onClick={e => onRowCampaignClick(e, data.key)}> {data.label} </div>;
}