import React, { Fragment, useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import moment from 'moment';

import { TextField, DateField, List, TimeField } from '../_Components';

import {
    GET_LIST,
    // ABORT,
    provider,
    history
} from '../_utils';

import Filter from './Filter';
// import ListActions from './components/ListActions';
import Player from '../_Components/Player'


const fetchData = async (setQueues) => {
    const { status, data } = await provider(
        GET_LIST,
        '/infra/queues',
        {
            filter: {},
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: 1, perPage: 10000 },
        }
    );
    if(status === 200){
        setQueues(data);
    }
}

const VisorList =  ({ formValues, perPage, page, dispatch }) => {
    const [isMounted, setIsMounted] = useState(false);
    const [queues, setQueues] = useState([]);
    useEffect(() => {
        if(!isMounted){
            setIsMounted(true);
            fetchData(setQueues);
        }
    }, [isMounted]);
    return (
        <Fragment>
            <List
                refresh={true}
                dispatch={dispatch}
                creat={false}
                show={false}
                play={true}
                basePath='visor'
                filters={<Filter queues={queues} form="visorForm"/>}
                filterDefaultValues={formValues}
                perPage={perPage}
                page={page} 
                sort={{ field: 'StartTime', order: 'ASC' }} 
            >
                <DateField
                    source="callTime"
                    label="Дата"
                    format="DD.MM.YYYY HH:mm:ss"
                />
                <TextField
                    source="address"
                    label="Телефон"
                />
                <TextField
                    source="agent"
                    label="Оператор"
                />
                <TimeField
                    source="duration"
                    label="Длит."
                />
                <TextField
                    source="queue"
                    label="Очередь"
                />
            </List>
            <Player />
        </Fragment>
    );
}


const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('visorForm')(state);
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { startDate: moment().startOf('day'), endDate: moment().endOf('day') },
    };
};

export default compose(
    connect(mapStateToProps)
)(VisorList);
