import React from 'react';
import { withStyles } from '@mui/styles';

import {Card, CardHeader, CardContent} from '@mui/material';

const style = {
    card: {
        flex: 'auto',
        // height: 'calc(100vh - 210px)',
        // overflow: 'auto !important'
    },
    cardHeader: {
        padding:' 8px 16px 0px 0px'
    },
    cardContent: {
        // height: 'calc(100vh - 320px)',
        overflow: 'auto',
        position: 'relative',
        padding: '0px 8px 0px 8px !important'
    },
    cardHeaderTitile: {
        width: 'fit-content',
        fontSize: '1rem'
    },
    cardHeaderAction: {
        flex: 'auto',
        width: '-webkit-fill-available'
    }
};

const CardElement = ({classes, action, content, title, width, height, style }) => {
    return ( 
            <Card style={{width: `${width ? width : '100'}%`,  ...style}} className={classes.card}>
                {(title||action)&&
                    <CardHeader
                        className={classes.cardHeader}
                        action={action}
                        title={title}
                        style={{paddingBottom: 0}}
                    />
                }
                <CardContent className={classes.cardContent} style={{paddingTop: 0, paddingBottom: 0, height: `${height ? height : '100%'}`}}>
                    {content}
                </CardContent>
            </Card>
    )
}

export default withStyles(style)(CardElement);