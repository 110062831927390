import React, { Fragment, useState } from 'react';
import { Button } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

import SettingsModal from './SettingsModal';

const SettingsButton = (props) => { 
    const [showDialog, setShowDialog] = useState(false);


    const openSettings = () => {
        setShowDialog(true);
    }

    const handleClose = (val) => {
        props.refresh(val)
        setShowDialog(false);
    }

    return (
        <Fragment>
            <Button    
                color="primary" 
                size="small"
                variant="contained"
                disabled={props.isLoading}
                endIcon={<SettingsIcon />}
                onClick={openSettings} 
            >
                Настройки
            </Button>
            {showDialog&&
                <SettingsModal 
                    handleCloseClick={handleClose} 
                    id={props.report._id}
                    showDialog={showDialog} 
                />
            }
        </Fragment>
    )
}

export default SettingsButton;
