import React from 'react';

import { TextField, DateField, List } from '../_Components';
import Filter from './Filter';
import ListActions from './components/ListActions';

const ScriptsList = () => (
    <List
        refresh={true}
        show={false}
        filters={<Filter />}
        filterDefaultValues={{}}
        actions={<ListActions />}
        perPage={25} 
        sort={{ field: 'updateAt', order: 'DESC' }}
    >
        <TextField
            source="name"
            label="Имя"
        />
        <TextField
            source="status"
            label="Состояние"
        />
        <DateField
            format="DD.MM.YYYY HH:mm"
            source="updateAt"
            label="Дата/Время "
        />
        <TextField
            source="user"
            label="Пользователь"
        />
    </List>
)

export default ScriptsList;
