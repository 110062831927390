import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
// import TimelineIcon from '@mui/icons-material/Timeline';
// import Switch from '@material-ui/core/Switch';
// import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';


import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Drawer from '@mui/material/Drawer';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

import {
    UPDATE,
    GET_ONE,
    provider
} from '../_utils';

import Responsive from '../_Components/responsive'



import AgentSettings from './agents/AgentSettings';
// import AgentSettingsMobile from './agents/AgentSettingsMobile';
import InboundSettings from './inbounds/InboundSettings';

import Header from './Header';
import AgentsStatus from './AgentsStatus';
import Agents from './agents/Agents';
import Inbound from './inbounds/Inbound';
import Outbound from './outbounds/Outbound';

import Utils from './utils';

const times = [];
for (let t = 420; t < 1380; t+=5) {
    const time = moment().startOf('day').add(t, 'minutes').format("HH:mm");
    times.push(time);
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            poolingCount: 0,
            delay: 1000,
            agents: [],
            inbound: [],
            outbound: [],
            showInbound: false,
            showOutbound: false,
            showAgent: false,
            showAll: false,
            detail: null,
            statistics: {
                agents: { },
                inbound: { },
                outbound: { }
             },
            options: {
                title: {
                    text: null
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                legend:{
                    enabled: false,
                },
                chart: {
                    height: 100,
                    type: 'area'
                },
                xAxis: {
                    categories: times,
                    allowDecimals: false,
                    labels: {
                        enabled: false
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    allowDecimals: false,
                    labels: {
                        enabled: false
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y:,.0f}</b>'
                },            
                series: [{
                        name: 'Входящие',
                        data: [],
                        color: '#00bcd4'
                    },
                    {
                        name: 'Принятые',
                        data: [],
                        color: '#52b676b0'
                    },
                    {
                        name: 'Потеряные',
                        data: [],
                        color: '#ff57219e'
                    }
                ]
            }
        };
        // this.connect = this.connect.bind(this);
        this.getData = this.getData.bind(this);
        this.getStatistics = this.getStatistics.bind(this);
        this.changeAgentState = this.changeAgentState.bind(this);
        this.closeAgent = this.closeAgent.bind(this);
        this.closeInbound = this.closeInbound.bind(this);
        this.closeOutbound = this.closeOutbound.bind(this);
        this.handleAgentSettings = this.handleAgentSettings.bind(this);
        this.handleInboundSettings = this.handleInboundSettings.bind(this);
        this.showAgent = this.showAgent.bind(this);
        this.showInbound = this.showInbound.bind(this);
        this.showOutbound = this.showOutbound.bind(this);
        this.showDetail = this.showDetail.bind(this);
        this.changeAll = this.changeAll.bind(this);

    }
    // token = localStorage.getItem('token');
    // socketProtocol = (window.location.protocol === 'https:' ? 'wss:' : 'ws:');
    // ws = new WebSocket(`${this.socketProtocol}//${window.location.hostname}:10012/`,[], {headers: { 'Authorization': this.token }})
    // ws = new WebSocket(`ws://192.168.89.90:4067`)

    componentDidMount() {
        this.getStatistics();
        this.interval = setInterval(this.getData, this.state.delay);
        this.interval2 = setInterval(this.getStatistics, 60000);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.delay!==this.state.delay){
            clearInterval(this.interval);
            clearInterval(this.interval2);
            this.interval = setInterval(this.getData, this.state.delay);
            this.interval2 = setInterval(this.getStatistics, 60000);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.interval);
        clearTimeout(this.interval2);
    }

    // connect() {
    //     this.ws.onopen = (socket) => {
    //         console.log('connected', 'warning');
    //         this.ws.send(JSON.stringify(state));
    //     }

    //     this.ws.onmessage = evt => {
    //         const result = JSON.parse(evt.data);
    //         this.setState({
    //             ...result
    //         })
    //     }

    //     this.ws.onclose = () => {
    //         console.log('disconnected');
    //     }
    //     this.ws.onerror = function(err) {
    //         console.error('Socket encountered error: ', err.message, 'Closing socket');
    //     };
    // }

    
    async getData(){
        const { data } = await provider( GET_ONE,'/dashboard' );
        if( data ){
            this.setState({
                ...data
            });
        }
    }

    async getStatistics() {
        const { data } = await provider( GET_ONE,'/dashboard/statistics' );
        if(data){
            const { options } = this.state;
            const { intervals, agents, inbound, outbound } = data;
            for (let i = 0; i < times.length; i++) {
                const element = times[i];
                const index = intervals.findIndex( el => moment(el.name).format("HH:mm") === element);
                if(index<0){
                    options.series[0].data[i] = null;
                    options.series[1].data[i] = null;
                    options.series[2].data[i] = null;

                }else{
                    options.series[0].data[i] = intervals[index].total;
                    options.series[1].data[i] = intervals[index].answered;
                    options.series[2].data[i] = intervals[index].abandoned;
                }
                
            }
            this.setState({options, statistics: {agents, inbound, outbound } });
        }
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async changeAgentState(agent, state){
        const { label } = Utils.getAgentState(state);
        await provider(
            UPDATE,
            '/infra/agent/state',
            { id: agent,
              data: { state, label }
            }
        );
    }

    async handleAgentSettings (right){
        let queues = [];
        for (var i = 0; i < right.length; i++) {
            queues.push(right[i].id);
        }
        await provider(UPDATE, '/infra/agent/settings', { id: this.state.currentAgent.id, data: {queues} })
            .then(({ data }) => {
                this.closeAgent();
            })
            .catch(error => {
                // showNotification(error.message, 'error');
            })
            .finally(() => {
                // fetchEnd();
            });
    }

    async handleInboundSettings (right){
        let agents = [];
        for (var i = 0; i < right.length; i++) {
            agents.push(right[i].login);
        }
        await provider(UPDATE, '/infra/inbound/settings', { id: this.state.currentInbound.id, data: { agents } })
            .then(({ data }) => {
                this.closeInbound();
            })
            .catch(error => {
                // showNotification(error.message, 'error');
            })
            .finally(() => {
                // fetchEnd();
            });
    }

    closeAgent() {
        this.setState({showAgent: false, currentAgent: null});
    }

    closeInbound() {
        this.setStateAsync({showInbound: false, currentInbound: null});
    }

    closeOutbound() {
        this.setStateAsync({showOutbound: false, currentOutbound: null});
    }

    showAgent(agent) {
        if(agent.stateCode !== 536870912){
            this.setStateAsync({showAgent: true, currentAgent: agent});
        }
    }
    
    changeAll(e,value){
        this.setState({showAll: value});
    }

    showInbound(queue) {
        this.setStateAsync({showInbound: true, currentInbound: queue});
    }

    showOutbound(queue) {
        this.setStateAsync({showOutbound: true, currentOutbound: queue});
    }

    showDetail (card){
        const { detail } = this.state;
        this.setState({detail: detail===card ? null : card});
    }

    render() {
        const {
            showAgent,
            showInbound,
            detail,
            data
        } = this.state;
        const {...props} = this.props;

        const agents = this.state.statistics.agents ? this.state.statistics.agents.total : null;
        const inbound = this.state.statistics.inbound ? this.state.statistics.inbound.total : null;
        const outbound = this.state.statistics.outbound ? this.state.statistics.outbound.total : null;
        const inboundTalktime = inbound ? inbound.talkTime : 0;
        const outboundTalktime = outbound ? outbound.talkTime: 0;
        const talktime = inboundTalktime+outboundTalktime;
        const inboundCount = inbound ? inbound.answered : 0;
        const abandonedCount = inbound ? inbound.abandoned : 0;
        const outboundCount = outbound ? outbound.answered : 0;
        const count = inboundCount+outboundCount;
        const sl = inbound ? `${inbound.sl.toFixed(2)}%` : '-';
        const ar = inbound ? `${inbound.abandonedPercent.toFixed(2)}%` : '-';
        // const tta = inbound&&inbound.total ? Utils.secondsToTime(inbound.total.tta) : Utils.secondsToTime(0);
        const occ = agents&&agents.occPercent ? `${agents.occPercent.toFixed(2)}%` : '-';
        const ut = agents&&agents.utPercent ? `${agents.utPercent.toFixed(2)}%` : '-';
        const paid = agents ? agents.paid : 0;
        const totalTime = agents ? agents.totalTime : 0;
        const date = data ? data.date : '-';
        const currentDate = moment().format("YYYY-MM-DD");
        if(data&&data.inbounds){
            data.inbounds = _.orderBy(data.inbounds, ['sessions.wating', 'sessions.connected', 'online'], ['desc', 'desc', 'desc']);
        }
        if(data&&data.outbounds){
            data.outbounds = _.orderBy(data.outbounds, ['sessions.connected', 'sessions.dialing', 'requests',  'online' ], ['desc', 'desc', 'desc', 'desc']);
        }
        let total = 0,
        talk = 0,
        rest = 0,
        notReady = 0,
        work = 0,
        other = 0;
        if(data&&data.agents){
            for (let index = 0; index <  data.agents.length; index++) {
                const record =  data.agents[index];
                total++;
                const state = record.sessions.length > 0 ? Utils.getSessionState(record.sessions[0].state) : (record.stateCode ?  Utils.getAgentState(record.stateCode) : {} );
                if(record.talking > 0 || state.value==="Wrapup" || state.value==="Processing"){
                    talk++;
                }else if(state.value==="Rest"){
                    rest++;
                }else if(state.value==="Not Ready"){
                    notReady++;
                }else if(state.value==="Work"){
                    work++;
                }else{
                    other++;
                }
            }
        }
        return (
                <Fragment>
                    <div style={{display: 'inline'}}> 
                        <Link to={`schedule/${currentDate}/show`}>
                            <IconButton style={{color: '#099687'}} aria-label="Календарь">
                              <QueryBuilderIcon />
                            </IconButton>
                        </Link>
                        {/* <Link to={`schedule/${currentDate}/breaks`}>
                            <IconButton style={{color: '#fc9b00'}} aria-label="Перерывы">
                                <FreeBreakfastIcon />
                            </IconButton>
                        </Link> */}
                        <span style={{float: 'right', fontSize: 11}}> {`${date}`} </span>
                    </div>
                        <Responsive 
                            xsmall = {
                                <Fragment>
                                    <AgentsStatus total={total} talk={talk} rest={rest} notReady={notReady} work={work} other={other} />
                                    <div style={{display: 'flex'}}>
                                        <Header label="Уровень обслуживания" icon={<SignalCellularAltIcon />} color="#607d8b" value={sl} />
                                        <Header label="Потеряные" icon={<PhoneMissedIcon />}  color="#ff5722" value={ar} secondValue={abandonedCount}/>
                                    </div> 
                                    <div style={{display: 'flex'}}>
                                        <Header label="Загрузка" icon={<RecordVoiceOverIcon />} color="#009688" value={occ} secondValue={Utils.secondsToTime(paid)}/>
                                        <Header label="Утилизация"  icon={<TimelapseIcon/>} color="#3f51b5" value={ut} secondValue={Utils.secondsToTime(totalTime)}/>
                                    </div> 
                                    <div style={{display: 'flex'}}>
                                        <Header label="ВЫЗ." color="#31708f" icon={<PhoneInTalkIcon />} value={count} secondValue={Utils.secondsToTime(talktime)}/>
                                        <Header label="Вх." color="#59af50" icon={<PhoneCallbackIcon />} value={inboundCount} secondValue={Utils.secondsToTime(inboundTalktime)}/>
                                        <Header label="Исх." color="#00bcd4" icon={<PhoneForwardedIcon />} value={outboundCount} secondValue={Utils.secondsToTime(outboundTalktime)}/>
                                    </div>
                                    <div>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={this.state.options}
                                            allowChartUpdate={true}
                                            immutable = { false }
                                            updateArgs = { [true, true, true] }
                                        />
                                    </div>
                                    <div style={{paddingTop: '1em'}}>
                                        {(!!!detail || detail==='agent')&&
                                            <Agents showDetail={this.showDetail} detail={detail} items={data} changeState={this.changeAgentState} showAgent={this.showAgent} statistics={this.state.agents.agents}/>
                                        }
                                    </div>
                                    <div style={{paddingTop: '1em'}}>
                                        {(!!!detail || detail==='inbound')&&
                                            <Inbound showDetail={this.showDetail} detail={detail} items={data} statistics={this.state.inbound.queues} showInbound={this.showInbound} marginLeft='1em'/>
                                        }
                                    </div>
                                    <div style={{paddingTop: '1em'}}>
                                        {(!!!detail || detail==='outbound')&&
                                            <Outbound showDetail={this.showDetail} detail={detail} items={data} statistics={this.state.outbound.queues} showOutbound={this.showOutbound} marginLeft='1em'/>
                                        }
                                    </div>
                                </Fragment>
                            }
                            small = {
                                <Fragment>
                                    <div style={{display: 'flex'}}>
                                        <Header label="Уровень обслуживания" icon={<SignalCellularAltIcon />} color="#607d8b" value={sl} />
                                        <Header label="Потеряные" icon={<PhoneMissedIcon />}  color="#ff5722" value={ar} secondValue={abandonedCount}/>
                                        <Header label="Загрузка" icon={<RecordVoiceOverIcon />} color="#009688" value={occ} secondValue={Utils.secondsToTime(paid)}/>
                                        <Header label="Утилизация"  icon={<TimelapseIcon/>} color="#3f51b5" value={ut} secondValue={Utils.secondsToTime(totalTime)}/>
                                    </div> 
                                    <div style={{display: 'flex'}}>
                                        <AgentsStatus total={total} talk={talk} rest={rest} notReady={notReady} work={work} other={other} />
                                        <Header label="ВЫЗОВОВ" color="#31708f" icon={<PhoneInTalkIcon />} value={count} secondValue={Utils.secondsToTime(talktime)}/>
                                        <Header label="Входящих" color="#59af50" icon={<PhoneCallbackIcon />} value={inboundCount} secondValue={Utils.secondsToTime(inboundTalktime)}/>
                                        <Header label="Исходящих" color="#00bcd4" icon={<PhoneForwardedIcon />} value={outboundCount} secondValue={Utils.secondsToTime(outboundTalktime)}/>
                                    </div> 
                                    <div>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={this.state.options}
                                            allowChartUpdate={true}
                                            immutable = { false }
                                            updateArgs = { [true, true, true] }
                                        />
                                    </div>
                                    <div style={{paddingTop: '1em'}}>
                                        {(!!!detail || detail==='agent')&&
                                            <Agents showDetail={this.showDetail} detail={detail} items={data} changeState={this.changeAgentState} showAgent={this.showAgent} statistics={this.state.agents.agents}/>
                                        }
                                    </div>
                                    <div style={{paddingTop: '1em'}}>
                                        {(!!!detail || detail==='inbound')&&
                                            <Inbound showDetail={this.showDetail} detail={detail} items={data} statistics={this.state.inbound.queues} showInbound={this.showInbound} marginLeft='1em'/>
                                        }
                                    </div>
                                    <div style={{paddingTop: '1em'}}>
                                        {(!!!detail || detail==='outbound')&&
                                            <Outbound showDetail={this.showDetail} detail={detail} items={data} statistics={this.state.outbound.queues} showOutbound={this.showOutbound} marginLeft='1em'/>
                                        }
                                    </div>
                                </Fragment>
                            }
                            medium = {
                                <Fragment>
                                    <div style={{display: 'flex'}}>
                                            <AgentsStatus total={total} talk={talk} rest={rest} notReady={notReady} work={work} other={other} />
                                            <Header label="Уровень обслуживания" icon={<SignalCellularAltIcon />} color="#607d8b" value={sl} />
                                            <Header label="Потеряные" icon={<PhoneMissedIcon />}  color="#ff5722" value={ar} secondValue={abandonedCount}/>
                                            <Header label="Загрузка" icon={<RecordVoiceOverIcon />} color="#009688" value={occ} secondValue={Utils.secondsToTime(paid)}/>
                                            <Header label="Утилизация"  icon={<TimelapseIcon/>} color="#3f51b5" value={ut} secondValue={Utils.secondsToTime(totalTime)}/>
                                            <Header label="ВЫЗОВОВ" color="#31708f" icon={<PhoneInTalkIcon />} value={count} secondValue={Utils.secondsToTime(talktime)}/>
                                            <Header label="Входящих" color="#59af50" icon={<PhoneCallbackIcon />} value={inboundCount} secondValue={Utils.secondsToTime(inboundTalktime)}/>
                                            <Header label="Исходящих" color="#00bcd4" icon={<PhoneForwardedIcon />} value={outboundCount} secondValue={Utils.secondsToTime(outboundTalktime)}/>
                                    </div>
                                    <div>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={this.state.options}
                                            allowChartUpdate={true}
                                            immutable = { false }
                                            updateArgs = { [true, true, true] }
                                        />
                                    </div>
                                    <div style={{display: 'flex', height: 'calc(95vh - 270px)', paddingTop: '1em'}}>
                                        {(!!!detail || detail==='agent')&&
                                            <Agents showDetail={this.showDetail} detail={detail} items={data} changeState={this.changeAgentState} showAgent={this.showAgent} statistics={this.state.statistics.agents.agents}/>
                                        }
                                        {(!!!detail || detail==='inbound')&&
                                            <Inbound showDetail={this.showDetail} detail={detail} items={data} statistics={this.state.statistics.inbound.queues} showInbound={this.showInbound} marginLeft='1em'/>
                                        }
                                        {(!!!detail || detail==='outbound')&&
                                            <Outbound showDetail={this.showDetail} detail={detail} items={data} statistics={this.state.statistics.outbound.queues} showOutbound={this.showOutbound} marginLeft='1em'/>
                                        }
                                    </div>
                                </Fragment>
                            }
                        />
                    <Drawer
                        id="agent"
                        open={showAgent}
                        anchor="right"
                        onClose={this.handleClose}
                        sx={(theme) => ({
                            width: '50%',
                            flexShrink: 0,
                            [theme.breakpoints.up('md')]: {
                                '& .MuiDrawer-paper': {
                                    width: '50%',
                                  },
                            },
                            [theme.breakpoints.up('sx')]: {
                                '& .MuiDrawer-paper': {
                                    width: '100%',
                                  },
                            },
                        })}
                    >   
                        <AgentSettings onCancel={this.closeAgent} onSave={this.handleAgentSettings} agent={this.state.currentAgent} {...props}/>
                    </Drawer>
                    <Drawer
                        id="agent"
                        open={showInbound}
                        anchor="right"
                        onClose={this.handleClose}
                        sx={(theme) => ({
                            width: '50%',
                            flexShrink: 0,
                            [theme.breakpoints.up('md')]: {
                                '& .MuiDrawer-paper': {
                                    width: '50%',
                                  },
                            },
                            [theme.breakpoints.up('sx')]: {
                                '& .MuiDrawer-paper': {
                                    width: '100%',
                                  },
                            },
                        })}
                    >  
                        <InboundSettings onCancel={this.closeInbound} onSave={this.handleInboundSettings} queue={this.state.currentInbound} {...props}/>
                    </Drawer>
                </Fragment>
        );
    }
}

export default Dashboard;
