
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import {
    List,
    ListItem,
    ListItemText,
    Grid,
    Paper
} from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { Labeled } from '..';

const style = {
    root: {
        margin: 'auto',
    },
    paper: {
        height: 230,
        overflow: 'auto',
        width: '100%'
    },
    icon: {
        top: 133,
        position: 'relative',
        padding: 5
    },
    main: {
        width: '100%'
    },
    label: {
        flex: '40em'
    },
    item: {
        overflow: 'hidden', 
        whiteSpace: 'nowrap', 
        textOverflow: 'ellipsis'
    }
};

const ListLeft = ({classes, items, fields, handleToggle}) =>{
    return(
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((value,key) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={key} role="listitem" button onClick={handleToggle(value)} style={{padding: 5}}>
                            <ListItemText 
                                id={labelId} 
                                primary={`${fields.find(el => el._id===value) ? fields.find(el => el._id===value).name : value}`} 
                                className={classes.item}
                            />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Paper>
    )
};

const ListRight = ({classes, items, fields, handleToggle}) =>{
    return(
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((value, key) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={key} role="listitem" button onClick={handleToggle(value)} style={{padding: 5}}>
                            <ListItemText 
                                id={labelId} 
                                primary={`${fields.find(el => el._id===value) ? fields.find(el => el._id===value).name : value}`} 
                                className={classes.item}
                            />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Paper>
    )
};

const leftArray = (avalible = [], right = []) => {
    const left = [];
    for (let index = 0; index < avalible.length; index++) {
        const element = avalible[index];
        if(!right.find( r => r===element._id)){
            left.push(element._id);
        }
    }
    return left;
}

const not  = (a, b) => {
    return a.filter(value => b!==value);
}

const GridInput = ({classes, input, avalible}) => {
    const [right, setRight] = useState(input.value);
    const [left, setLeft] = useState(leftArray(avalible, input.value));

    const handleToggle = value => () => {
        const leftIndex = left.find( l => l===value);
        let newRight = [];
        let newLeft = [];
        if(leftIndex){
            newRight = right.concat([value]);
            newLeft = not(left, value);
        }else{
            newRight = not(right, value);
            newLeft = left.concat([value]);
        }
        setRight(newRight);
        setLeft(newLeft);
        input.onChange(newRight);
        return;
    };

    return(
        <div className={classes.main}>
            {(left && right) ?
                <div style={{display: 'flex'}}>
                    <Labeled label="Доступные" className={classes.label}>
                        <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                            <Grid item className={classes.main}>
                                <ListLeft items={left} fields={avalible} classes={classes} handleToggle={handleToggle}/>
                            </Grid>
                        </Grid>
                    </Labeled>
                    <SyncAltIcon className={classes.icon}/>
                    <Labeled label="Выбранные" className={classes.label}>
                        <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                            <Grid item className={classes.main}>
                                <ListRight items={right} fields={avalible} classes={classes} handleToggle={handleToggle}/>
                            </Grid>
                        </Grid>
                    </Labeled>
                </div>
            : 
                <div style={{display: 'flex'}}>
                    <div style={{padding: '16px 0px 0px 0px', width: '100%'}}>
                        <Skeleton variant="text" />
                        <Skeleton variant="rect" height={242} style={{padding: '0 20px'}}/>
                    </div>
                    <SyncAltIcon className={classes.icon}/>
                    <div style={{padding: '16px 0px 0px 0px', width: '100%'}}>
                        <Skeleton variant="text" />
                        <Skeleton variant="rect" height={242} style={{padding: '0 20px'}}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default withStyles(style)(GridInput);