import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';

import { Labeled } from '../../../_Components';

const formatTime = (value) => {
    return moment(value).format("HH:mm");
}

const Schedule = ({source, record ={}, ...props}) =>(
    <div className={props.className}>
        {record ?
            <Labeled className={props.className} label={props.label}>
                {record.schedule&&
                    <span style={{display: 'flex'}}>
                        {/* {record.schedule.schedules.map( (schedule, i)=>( */}
                            <div id="schedule">
                                <span style={{ width: '15%'}}>
                                    <Labeled label="C" style={{marginTop: 0}}>
                                        <span style={{marginLeft: 10}}>{formatTime(record.schedule.start)}</span>
                                    </Labeled>
                                </span>
                                <span style={{ width: '15%'}}>
                                    <Labeled label="До" style={{marginTop: 0}}>
                                        <span style={{marginLeft: 10 }}>{formatTime(record.schedule.end)}</span>
                                    </Labeled>
                                </span>
                                {/* {i===0&&
                                    <span  style={{ width: '70%', display: "flex"}}>
                                        <Labeled label="Изменение" style={{width: '35%', marginTop: 0}}>
                                            <span style={{marginLeft: 10}}>{record.data.schedule.change}</span>
                                        </Labeled>
                                        <Labeled label="Коментарий" style={{width: '65%', marginTop: 0}}>
                                            <span style={{marginLeft: 10}}>{record.data.schedule.description}</span>
                                        </Labeled>
                                    </span>
                                } */}
                            </div>
                        {/* ))} */}
                    </span>
                }
            </Labeled>
        : 
            <div style={{padding: '16px 0px 0px 20px'}}>
                <Skeleton variant="text" />
                <Skeleton variant="rect"  height={71} style={{padding: '0 20px'}}/>
            </div>
        }
    </div>
)

export default (Schedule);