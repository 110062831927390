import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';

const TextInput = ({dispatch, input: { ...inputProps }, meta: { touched, error } = {}, isLoading, label, disabled, style, ...props}) => {
  return(
      <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            style={style}
            fullWidth
            focused
            label={label}
            disabled={isLoading || disabled}
            variant="standard"
      />
  )
}

const mapStateToProps = (state, props) =>{
  const { fetch } = state;
  return {
      isLoading: !!fetch.loading
  }
}

export default compose(
  connect(mapStateToProps),
)(TextInput);

