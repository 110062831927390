import React, { Fragment, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Button } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import ExportModal from './ExportModal';

const ExportButton = ({formValues, report, isLoading }) => {
    const { name, project } = report;
    const [showDialog, setShowDialog] = useState(false);

    const generateFileName = () => {
        return `${project.name}_${name}_${formValues.interval.startDate.format("DD.MM.YYYY")}-${formValues.interval.endDate.format("DD.MM.YYYY")}`
    }
    const openExport = async () => {
        setShowDialog(true);
    }

    const handleClose = () => {
        setShowDialog(false)
    }

    return (
        <Fragment>
            <Button    
                color="primary" 
                size="small"
                variant="contained"
                endIcon={<GetAppIcon />}
                disabled={isLoading}
                onClick={openExport} 
            >
                Экспорт в Excel
            </Button>
            {showDialog&&
                <ExportModal handleCloseClick={handleClose} initialValues={{fileName: generateFileName(), ...formValues}} showDialog={showDialog} fileName={generateFileName()}/>
            }
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('filterForm')(state);
    return { formValues: recordLiveValues};
};

export default compose(
    connect(mapStateToProps)
)(ExportButton);
