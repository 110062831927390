import React, {  Fragment } from 'react';
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose';
import { withStyles } from '@mui/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CloseIcon from '@mui/icons-material/Close';

import Operators from './QuickSuggestionsField';

const style = {
    list: {
        height: 320,
        overflowY: 'auto',
        paddingTop: 0,
        paddingBottom: 0
    },
    listItem: {
        padding:'0px 0px 0px 8px !important',
        borderBottom: '1px #bfbdbd solid'
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0
    },
    listItemTextPrymary: {
        fontSize: '0.75rem !important'
    },
    error: {
        fontSize: '0.75rem !important',
        color: 'red'
    },
    iconTrash: {
        width: '0.75rem !important',
        height: '0.75rem !important',
        float: 'right',
        color: 'red',
        marginLeft: 8,
        cursor: 'pointer'
    },
};

const OperatorsInput =
    (
        {
            input: { value, onChange },
            meta: { touched, error },
            campaign,
            operatorsList,
            operatorsState,
            onOperatorDelete,
            onOperatorAdd,
            classes
        }
    ) =>(
        <Fragment>
            <Operators campaign={campaign} operators={value} operatorsList={operatorsList} onAdd={onOperatorAdd}/>
            <List
                className={classes.list}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {operatorsState.map( (op, index) =>{
                    let operator = operatorsList.find( o => o._id === op);
                    let text = op;
                    let terminated= false;
                    // console.log(operator);
                    if(operator){
                        text = `${operator.login} ${operator.fullname}`
                    }
                    if(operator.status==="Terminated"){
                        terminated = true
                    }
                    return <ListItem classes={{ root: classes.listItem}} key={index}>
                                <ListItemText 
                                    className={classes.listItemText} 
                                    classes={{
                                        multiline: classes.multiline,
                                        primary: !terminated ? classes.listItemTextPrymary : classes.error,
                                        }}
                                    primary={text} 
                                />
                                <ListItemSecondaryAction>
                                    <CloseIcon className={classes.iconTrash} onClick={()=>onOperatorDelete(op)}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                })}
            
            </List>
        </Fragment>
)

export default compose(
    withState("operatorsState", "setOperatorsState", []),
    withStyles(style),
    withPropsOnChange(
        ['input'],
        ({ input: { value }, meta: { dirty }, setOperatorsState }) => {
        if (dirty) {
            return;
        }
        if (!value) {
            return;
        }
        setOperatorsState(value);
        }
    ),
    withHandlers({
        onOperatorDelete: ({ input: { value, onChange }, setOperatorsState, ...props }) => (operator) => {
            const index = value.indexOf(operator);
            value.splice(index, 1);
            onChange(value)           
            setOperatorsState(value);
            onChange(value);
        },
        onOperatorAdd: ({ input: { value, onChange }, setOperatorsState, ...props }) => (operator, clearSelection) => {
            value.unshift(operator.id);
            setOperatorsState(value);
            onChange(value);
            clearSelection();
        },
    })
)(OperatorsInput);