import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { Labeled } from '../../../_Components';
import TimeField from './Time';

const TalkAll = ({source, record ={}, ...props}) =>(
    <div className={props.className}>
        {record ?
            <Labeled className={props.className} label={props.label}>
                <span style={{display: 'flex', paddingRight: '15%'}}>
                    <PhoneInTalkIcon style={{color: '#31708f'}}/>
                    <span style={{marginLeft: 10, color: '#31708f' }}>{record[source].calls}</span>
                    <ScheduleIcon style={{marginLeft: 'auto', color: '#31708f'}}/>
                    <TimeField source={`${source}.talkTime`} color='#31708f' record={record} {...props}/>
                </span>
            </Labeled>
        : 
            <div style={{padding: '0 20px'}}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={36} style={{padding: '0 20px'}}/>
            </div>
        }
    </div>
)

export default (TalkAll);