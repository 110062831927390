import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';
import _ from 'lodash';

import CardComponent from '../components/Card';
import ColSettings from '../components/ColSettings';
import { 
    SelectInput, 
    SwitchInput, 
    TextInput, 
    SortableList, 
    CardElement, 
    AddButton, 
    CopyButton,
    DeleteButton,
    Modal, 
    GridInput 
} from '../../_Components';
// import PhonesList from '../components/PhonesList';
import PhonesInput from '../components/PhonesField';
import AddAction from '../components/AddAction';



const styles = {
    row: {
        display: 'flex',
        borderBottom: '1px solid',
        marginTop: 16
    },
    taskRow: {
        display: 'flex',
        borderBottom: '1px solid',
        marginTop: 8,
        paddingBottom: 16
    },
    actionTaskRow: {
        display: 'flex',
        marginTop: 8,
        // paddingBottom: 20
    },
    actionTaskButton: {
        marginBottom: 0
    },
    resultElement: {
        display: 'inline-flex',
        marginRight: '2em',
        width: '25%'
    },
    radio: {
        paddingRight: 30,
        flex: 'auto'
    },
    buttonGroup: {
        marginTop: 11,
        flex: '0 0 auto',
        alignSelf: 'flex-start'
    }
};

const itemsFields = [
    {
        value: 'resultLabel'
    },
    {
        value: 'taskLabel'
    },
    {
        value:'subTaskLabel'
    }
];

const ListForm = ({list}) => (
    <Field
        name="list"
        required={true}
        component={GridInput}
        label={``}
        avalible={list}
    />
)

const PhonePanel = ({list}) => (
    <Field 
        name={`phones`}
        component={PhonesInput}
        list={list}
    />
)


class CardActions extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        props.formValues.actions.map((action) => {
            action.resultLabel = (props.result.options.find( (el) => el.value===action.result)) ? props.result.options.find( (el) => el.value===action.result).label : '-';
            action.taskLabel = (props.task.options.find( (el) => el.value===action.task))? props.task.options.find( (el) => el.value===action.task).label : '-';
            action.subTaskLabel = (action.sub_task&&props.task.options.find( (el) => el.value===action.task).sub) ? props.task.options.find( (el) => el.value===action.task).sub.find( (e) => e.value===action.sub_task).label : '-';
            return action;
        });
        this.state = {
            activeNode: null,
            selectedIndex: 0,
            activePath: null,
            activeOptions: null,
            activePathOptions: null,
            activeNodekey: 0,
            optionsData: [],
            openEdit: false,
            openOptionEdit: false,
            openSubsEdit: false,
            editCombobox: null,
            editOption: null,
            editOptionKey: null,
            expanded: null,
            changedResult: '',
            changedTask: '',
            subs: [],
            openEmails: false,
            openTelegram: false,
            addAction: false,
            width: 800
        };


        this.setActiveNode = this.setActiveNode.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.selectCol = this.selectCol.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.onСhangeResult = this.onСhangeResult.bind(this);
        this.onСhangeTask = this.onСhangeTask.bind(this);
        this.closeEmailsList = this.closeEmailsList.bind(this);
        this.saveEmails = this.saveEmails.bind(this);
        this.addActionDialog = this.addActionDialog.bind(this);
        this.saveAction = this.saveAction.bind(this);
        this.closeTelegramList = this.closeTelegramList.bind(this);
        this.saveTelegram = this.saveTelegram.bind(this);
        this.closePhonesList = this.closePhonesList.bind(this);
        this.savePhones = this.savePhones.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.copyAction = this.copyAction.bind(this);
        this.findCombobox = this.findCombobox.bind(this);
        this.openEmailsList = this.openEmailsList.bind(this);
        this.openTelegramList = this.openTelegramList.bind(this);
        this.openPhonesList = this.openPhonesList.bind(this);
    }

    onСhangeResult(state,{ props: { value } }) {
        this.setState({ changedResult: value})
    }

    onСhangeTask(state,{ props: { value } }){
        const { task } = this.props;
        let subs = task.options.find( t => t.value===value) ? task.options.find( t => t.value===value).sub : [];
        this.setState({ changedTask: value, subs})
    }

    onDragStart(layout,element){
        this.selectCol(element.i);
    }

    setActiveNode = (index) => {
        const { options } = this.props.formValues.actions[index];
        this.setState({ optionsData: options, activeNodekey: index, expanded: null, selectedIndex: 0 })
    }
    selectCol(index) {
        const { formValues: { actions } } = this.props;
        const { activeNodekey } = this.state
        const i = _.findIndex(actions[activeNodekey].cols, {field: index});
        this.setState({selectedIndex: i});
    }

    addItem(item) {
        const { activeNodekey } = this.state;
        const { formValues: { actions }, change } = this.props;
        const { cols } = actions[activeNodekey]
        cols.push({
            ...item,
            col : item.x,
            row : item.y,
            sizeX : item.w,
            sizeY: item.h,
        });
        change(`actions[${activeNodekey}].cols`, cols);
        this.setState({ selectedIndex: cols.length-1 });
    }

    updateItems(items) {
        const { activeNodekey } = this.state;
        const { formValues: { actions }, change } = this.props;
        const { cols } = actions[activeNodekey]
        const newItems = items.map( (item,i) => {
            const el = cols[`${i}`];
            return {
                ...el,
                col : item.x,
                row : item.y,
                sizeX : item.w,
                sizeY: item.h,
            }
        });
        change(`actions[${activeNodekey}].cols`, newItems);
    }


    removeItem(i) {
        const { activeNodekey } = this.state;
        const { formValues: { actions }, change } = this.props;
        const { cols } = actions[activeNodekey]
        cols.splice(i,1);
        change(`actions[${activeNodekey}].cols`, cols);
    }

    openEmailsList() {
        this.setState({ openEmails: true });
    }

    closeEmailsList() {
        this.setState({ openEmails: false });
    }
   
    saveEmails(form) {
        const { list } = form;
        const { activeNodekey } = this.state;
        const { change } = this.props;
        this.setState({ openEmails: false },
           () => change(`actions[${activeNodekey}].settings.emails`, list)
        );
    }

    openTelegramList() {
        this.setState({ openTelegram: true });
    }

    closeTelegramList() {
        this.setState({ openTelegram: false });
    }

    saveTelegram(form) {
        const { list } = form;
        const { activeNodekey } = this.state;
        const { change } = this.props;
        this.setState({ openTelegram: false },
           () => change(`actions[${activeNodekey}].settings.telegramChats`, list)
        );
    }

    openPhonesList() {
        this.setState({ openPhones: true });
    }

    closePhonesList() {
        this.setState({ openPhones: false });
    }
   
    savePhones({phones}) {
        const { activeNodekey } = this.state;
        const { change } = this.props;
        this.setState({ openPhones: false },
           () => change(`actions[${activeNodekey}].settings.transfer_phones`, phones)
        );
    }

    deleteAction(index) {
        const { formValues: { actions }, change } = this.props;
        actions.splice(index, 1);
        this.setState( { activeNodekey: index===actions.length ? index-1 : index }, 
            () => change(`actions`, actions)
         );
    }

    copyAction(action) {
        this.setState({ copyFields: action, addAction: true});
    }

    addActionDialog() {
        this.setState( {addAction: !this.state.addAction});
    }

    saveAction(values){
        const { formValues, change, result, task } = this.props;
        const { actions } = formValues;
        values.resultLabel = (result.options.find( (el) => el.value===values.result)) ? result.options.find( (el) => el.value===values.result).label : '-';
        values.taskLabel = (task.options.find( (el) => el.value===values.task))? task.options.find( (el) => el.value===values.task).label : '-';
        values.subTaskLabel = (values.sub_task&&task.options.find( (el) => el.value===values.task).sub) ? task.options.find( (el) => el.value===values.task).sub.find( (e) => e.value===values.sub_task).label : '-';
        actions.push({...values});
        this.setState( {addAction: false}, 
           () => change(`actions`, actions)
        );
    }

    findCombobox(item) {
        const { fields, comboboxes } = this.props;
        const field = fields.find(el => el.id===item.field);
        const combobox = comboboxes.find(el => el._id===field.combobox);
        const options = [ 
                {
                id: undefined,
                name: '-'
            }
        ];
        if(combobox){
            combobox.options.map(el => options.push({id: el.value, name: el.label}))
        }
        return options;
    }

    updateLayoutElements=()=>{//применяю размер поля элементов
        // if(this.state.width !== this.ref.current.clientWidth - 25) this.setState({width: this.ref.current.clientWidth - 25 });
    }
    componentDidMount(){
        window.addEventListener('resize', this.updateLayoutElements);
        this.updateLayoutElements();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLayoutElements);
    }


    render() {
        const { activeNodekey, selectedIndex, openEmails, openTelegram, openPhones, addAction, copyFields } = this.state;
        const { formValues, result, classes, fields, task, queues, isLoading, project } = this.props;
        return(
            <Fragment>
              
                {addAction&&<AddAction open={addAction} close={this.addActionDialog} save={this.saveAction}  copyFields={copyFields} results={result.options} tasks={task.options} />}
                <div style={{  display: 'flex' }} > {/* height: 'calc(100vh - 260px)', */}
                    <CardElement
                        width={25}
                        height={'calc(100vh - 185px)'}
                        action={<AddButton handleAddClick={this.addActionDialog} label={'Новое действие'}/>}
                        content={
                            <SortableList 
                                items={ formValues.actions || []}
                                itemsFields={itemsFields}
                                useDragHandle={true} 
                                setActiveNode={this.setActiveNode} 
                                activeNodekey={activeNodekey}
                                sortable={false}
                            />
                        }

                    />
                        {!isLoading&&
                            formValues.actions.map( (action, key) => {
                                if(key!==activeNodekey) return <div key={key} ></div>;
                                return  (
                                    <CardElement
                                        width={75}
                                        key={key}
                                        height={'calc(100vh - 185px)'}
                                        content={
                                            <Fragment>
                                                <div ref={this.ref}>
                                                    <div className={classes.actionTaskRow}>
                                                        <CopyButton className={classes.actionTaskButton} label={`Копировать`} onClick={() => this.copyAction(action)}/>
                                                        <DeleteButton className={classes.actionTaskButton} label={`Удалить`} onClick={() => this.deleteAction(key)}/>
                                                    </div>
                                                    <div className={classes.taskRow}>
                                                        <Field
                                                            name={`actions[${key}].result`}
                                                            label={`Результат`}
                                                            component={SelectInput}
                                                            className={classes.resultElement}
                                                            options={result.options.map( o => { return { id: o.value, name: o.label }})}
                                                        />
                                                        <Field
                                                            name={`actions[${key}].task`}
                                                            label={`Тема`}
                                                            component={SelectInput}
                                                            className={classes.resultElement}
                                                            options={task.options.map( o => { return { id: o.value, name: o.label }})}
                                                        />
                                                        {(task.options.find( t => t.value===formValues.actions[activeNodekey].task)&&task.options.find( t => t.value===formValues.actions[activeNodekey].task).sub&&task.options.find( t => t.value===formValues.actions[activeNodekey].task).sub.length>0)&&
                                                                <Field
                                                                name={`actions[${key}].sub_task`}
                                                                label={`Подтема`}
                                                                component={SelectInput}
                                                                className={classes.resultElement}
                                                                options={task.options.find( t => t.value===formValues.actions[activeNodekey].task).sub.map( o => { return { id: o.value, name: o.label }})}
                                                            />
                                                        }
                                                         
                                                    </div>
                                                    <CardComponent
                                                        width = {this.state.width}
                                                        maxRows={3}
                                                        readOnly={true}
                                                        key={key}
                                                        actions={true}
                                                        elements={action.cols} 
                                                        fields={fields}
                                                        selectedIndex={selectedIndex}
                                                        activeNodekey={activeNodekey}
                                                        addItem={this.addItem}
                                                        selectCol={this.selectCol}
                                                        removeEvent={this.removeItem}
                                                        updateItems={this.updateItems}
                                                        onDragStart={this.onDragStart} //выбираем элемент по этому событию
                                                    />
                                                </div>
                                                <div style={{  display: 'flex' }}>{/* height: 'calc(100vh - 260px)', */}
                                                    <CardElement 
                                                        content={
                                                            <Fragment>

                                                            {/* <div className={classes.row}>
                                                                <Field
                                                                    name={`actions[${key}].settings.callback`}
                                                                    label={`Назначить перезвон`}
                                                                    component={SwitchInput}
                                                                    className={classes.settings}
                                                                />
                                                                {formValues.actions[key].settings.callback&&
                                                                    <Field
                                                                        name={`actions[${key}].settings.callbackQueue`}
                                                                        label={`Очередь`}
                                                                        component={SelectInput}
                                                                        className={classes.settings}
                                                                        options={queues}
                                                                    />
                                                                }
                                                            </div> */}
                                                            <div className={classes.row}>
                                                                <Field
                                                                    name={`actions[${key}].settings.email`}
                                                                    label={`Отправить E-mail`}
                                                                    component={SwitchInput}
                                                                    className={classes.radio}
                                                                />
                                                                {formValues.actions[key].settings.email&&
                                                                    <Fragment>
                                                                        <Field
                                                                            name={`actions[${key}].settings.template`}
                                                                            label={`Шаблон`}
                                                                            component={SelectInput}
                                                                            className={classes.settings}
                                                                            options={formValues.template.filter( f => f.type==='email')}
                                                                        />
                                                                        <AddButton 
                                                                            label={formValues.actions[key].settings.emails.length>0 
                                                                                ? 
                                                                                `Выбрано ${formValues.actions[key].settings.emails.length}`
                                                                                :
                                                                                'Добавить E-mail'
                                                                            }
                                                                            handleAddClick={this.openEmailsList}
                                                                        />
                                                                        {openEmails&&
                                                                            <Modal
                                                                                title={`Добавить E-mail`}
                                                                                open={openEmails} 
                                                                                handleSaveClick={this.saveEmails} 
                                                                                handleCloseClick={this.closeEmailsList}
                                                                                initial={{list : formValues.actions[key].settings.emails}}
                                                                                fields={<ListForm list={project.settings.list.email}/>} 
                                                                            />
                                                                        }
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className={classes.row}>
                                                                <Field
                                                                    name={`actions[${key}].settings.telegram`}
                                                                    label={`Отправить в Telegram`}
                                                                    component={SwitchInput}
                                                                    className={classes.radio}
                                                                    style={{paddingRight: 300}}
                                                                />
                                                                {formValues.actions[key].settings.telegram&&
                                                                    <Fragment>
                                                                        <Field
                                                                            name={`actions[${key}].settings.templateTelegram`}
                                                                            label={`Шаблон`}
                                                                            component={SelectInput}
                                                                            className={classes.settings}
                                                                            options={formValues.template.filter( f => f.type==='telegram')}
                                                                        />
                                                                        <AddButton 
                                                                            label={formValues.actions[key].settings.telegramChats.length>0
                                                                                ?
                                                                                `Выбрано ${formValues.actions[key].settings.telegramChats.length}`
                                                                                :
                                                                                'Добавить Чат'
                                                                            }
                                                                            handleAddClick={this.openTelegramList}
                                                                        />
                                                                        {openTelegram&&
                                                                            <Modal
                                                                                title={`Добавить Telegram чат`}
                                                                                open={openTelegram} 
                                                                                handleSaveClick={this.saveTelegram} 
                                                                                handleCloseClick={this.closeTelegramList}
                                                                                initial={{list : formValues.actions[key].settings.telegramChats}}
                                                                                fields={<ListForm list={project.settings.list.telegram}/>} 
                                                                            />
                                                                        }
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                           
                                                            <div className={classes.row}>
                                                                <Field
                                                                    name={`actions[${key}].settings.transfer`}
                                                                    label={`Перевод звонка`}
                                                                    component={SwitchInput}
                                                                    className={classes.radio}
                                                                    style={{paddingRight: 300}}
                                                                />
                                                                {formValues.actions[key].settings.transfer&&
                                                                    <Fragment>
                                                                        <Field
                                                                            name={`actions[${key}].settings.transfer_prefix`}
                                                                            label={`Префикс`}
                                                                            component={TextInput}
                                                                            options={queues}
                                                                            style={{width: 60}}
                                                                        />
                                                                        <AddButton 
                                                                            label= {formValues.actions[key].settings.telegramChats.length>0
                                                                                ?
                                                                                `Выбрано ${formValues.actions[key].settings.transfer_phones.length}`
                                                                                :
                                                                                'Добавить'
                                                                            }
                                                                            handleAddClick={this.openPhonesList}
                                                                        />
                                                                        {openPhones&&
                                                                            <Modal
                                                                                width="lg"
                                                                                style={{height: 'inherit'}}
                                                                                title={`Добавить телефон`}
                                                                                open={openPhones} 
                                                                                handleSaveClick={this.savePhones} 
                                                                                handleCloseClick={this.closePhonesList}
                                                                                initial={{phones : formValues.actions[key].settings.transfer_phones}}
                                                                                fields={<PhonePanel list={formValues.transfer.departments}/>} 
                                                                            />
                                                                        }
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className={classes.row}>
                                                                <Field
                                                                    name={`actions[${key}].settings.create`}
                                                                    label={`Создать сущность`}
                                                                    component={SwitchInput}
                                                                    className={classes.radio}
                                                                    style={{paddingRight: 300}}
                                                                />
                                                            </div>
                                                            </Fragment>
                                                        }
                                                    />
                                                     {action.cols.map((el, i) => (
                                                        <Fragment key={i}>
                                                            {selectedIndex===i&&
                                                                <CardElement 
                                                                    title={'Свойства поля'}
                                                                    content={
                                                                        <ColSettings 
                                                                            key={i} 
                                                                            items={action.cols} 
                                                                            path={`actions[${key}].cols`}  
                                                                            item={el} 
                                                                            selectedIndex={selectedIndex} 
                                                                            fields={fields} 
                                                                            combobox={this.findCombobox(el)}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            </Fragment>
                                        }
                                    />
                                )
                        })
                    }
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('card')(state);
    const { fetch } = state;
    return { 
        formValues,
        isLoading: !!fetch.loading
    }
};

export default  compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'card',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
    withStyles(styles),
)(CardActions);