import List from './List';
import Show from './Show';
// import Breaks from './Breaks';


export default {
    list: List,
    show: Show,
    // breaks: Breaks
};
