import React from 'react';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import {  ModalButtons } from '../../_Components';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({open, handleClose, handleSaveClick, handleCloseClick, fields, isSaving, title, formValues, width, style,  ...props}) => {
    const save = () => handleSaveClick(formValues);
    return(
        <Dialog
            maxWidth={width}
            fullWidth={true}
            sx={{ '& .MuiDialog-paper': { ...style } }}
            style={style}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                    {fields &&
                        React.cloneElement(fields, {
                            ...props,
                            formValues,
                            context: 'form',
                        })
                    }
            </DialogContent>
            <DialogActions>
                <ModalButtons handleSaveClick={save} handleCloseClick={handleCloseClick} isSaving={isSaving}/>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state, props) => {
    const { initial } = props;
    const formValues = getFormValues('modalForm')(state);
    return { 
        formValues,
        initialValues: initial ? initial : {}
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'modalForm',
        // destroyOnUnmount: true, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
);

export default enhance(Modal);