import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { 
    TextField,
    StatusField,
    Labeled,
} from '../_Components';

import {GET_ONE, provider} from '../_utils';

import Actions from './components/Actions';

const styles = theme =>
    createStyles({
        row: {
            display: 'flex',
            paddingBottom: '1em'
        },
        cardContent: {
            padding: '0 16px'
        }
});

class OperatorShow extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            filter: props.formValues || {}
        };
    }

    componentDidMount(){
        this._isMounted = true;
        if(this.props.id){
            this.get(this.props.id);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    componentDidUpdate(prevProps){
        if (this.props.id !== prevProps.id) {
            this.get(this.props.id);
        }
    }

    async get (id) {
        const { data } = await provider( 
            GET_ONE,
            '/infra/queues',
            id
           
        );
        if(this._isMounted){
            this.setState({
                data
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { data } = this.state;
        return (
            <Fragment>
                <Card className={classes.cardStyleFull}>
                    <CardHeader
                        action={ <Actions />}
                        title={` ${data ? data.DisplayName : ''}`}
                    />
                   
                    <CardContent classes={{ root: classes.cardContent }} >
                        {data&&
                            <Fragment>
                                <div className={classes.row}>
                                    <Labeled label="ФИО" className={classes.flex20}>
                                        <TextField value={data.fullname || null}/>
                                    </Labeled>
                                    <Labeled label="E-mail" className={classes.flex20}>
                                        <TextField value={data.email || null}/>
                                    </Labeled>
                                </div>
                                <div className={classes.row}>
                                    <Labeled label="Роль" className={classes.flex20}>
                                        <TextField value={data.roleLabel || null}/>
                                    </Labeled>
                                    <Labeled label="Статус" className={classes.flex20}>
                                        <StatusField value={data.status || null}/>
                                    </Labeled>
                                </div>
                                <div className={classes.row}>
                                    <Labeled label="Токен" className={classes.flex20}>
                                        <TextField value={data.token || null}/>
                                    </Labeled>
                                </div>
                            </Fragment>
                        }
                    </CardContent>
                </Card>
            </Fragment>
            
        );
    }
}


export default compose(
    withStyles(styles),
)(OperatorShow);
