import React, { Fragment, useEffect, useState } from 'react';
import { Route } from 'react-router';

import { 
    List, 
    TextField, 
    StatusField,
    Drawer
} from '../_Components';
import {GET_LIST, provider, history } from '../_utils';

import Filter from './Filter';
import Show from './Show';
// import Create from './Create'
import Edit from './Edit'

const statuses = [
    {id: 'Active', name: 'Активен'},
    {id: 'Disabled', name: 'Заблокирован'},
]

const UsersList = () => {
    const [isMounted, setIsMounted] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const getRoles = async () => {
            const { data, status } = await provider(
                GET_LIST,
                '/roles',
                {
                    filter: {},
                    sort: { field: 'name', order: 'ASC' },
                    pagination: { page: 1, perPage: 10000 },
                }
            );
            if(status===200 ){
                setRoles( data.map( d => {return { id: d.id, name: d.label }}));
                setIsMounted(true);
            }
        }
        if(!isMounted){
            getRoles();
        }
    }, [isMounted])

    const handleClose = () => {
        history.push('/users')
    }

    return (
        <Route path="/users/:id/:action">
            {({ match }) => {
                const isMatch = !!(
                    match &&
                    match.params
                );
                return (
                    <Fragment>
                        <List
                            refresh={true}
                            creat={true}
                            show={true}
                            basePath='users'
                            pathToShow={`/show`}
                            filters={<Filter roles={roles} />}
                            match={match}
                            // filterDefaultValues={filter}
                            perPage={25} 
                            sort={{ field: 'updateAt', order: 'DESC' }} 
                        >
                            <TextField
                                notSort={true}
                                source="fullname"
                                label="Имя"
                            />
                            <TextField
                                notSort={true}
                                source="email"
                                label="E-mail"
                            />
                            <TextField
                                notSort={true}
                                source="role"
                                label="Роль"
                            />
                            <StatusField
                                notSort={true}
                                source="status"
                                label="Статус"
                            />
                        </List>
                        {(isMatch&&match.params.action === 'show') ? (
                            <Drawer
                                isMatch={isMatch}
                                handleClose={handleClose}
                                content={isMatch ?  <Show id={match.params.id} roles={roles} statuses={statuses}/> : null}
                            />
                            
                        ) : null}
                        {(isMatch&&match.params.action === 'edit') ? (
                            <div style={{padding: 0}}>
                                <Drawer
                                    isMatch={isMatch}
                                    handleClose={handleClose}
                                    content={isMatch ? <Edit id={match.params.id} roles={roles}/> : null}
                                />
                                
                            </div>
                        ) : null}
                    </Fragment>
                );
            }}
        </Route>
        
    );
}

export default UsersList;
