import React, { Component } from 'react';
import { withStyles, createStyles } from '@mui/styles';

import { connect } from 'react-redux';
import {compose} from 'recompose';

import * as FileSaver from 'file-saver';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CloudDownload } from '@mui/icons-material';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './player.css';

import {GET_ONE, provider } from '../../_utils';

let openPlayerFn;

export const styles = theme => createStyles({
    main: {
        paddingTop: 8
    },
    card: {
        borderRadius: '4px 4px 0px 0px'
    },
    root: {
        padding: '0px 0px 0px 16px'
    },
    title: {
        fontSize: 14
    },
    action: {
        margin: 0
    }

})

class Player extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            id: null,   
            src: null,
            show: false,
            recordName: null
        };
        this.fetchStream = this.fetchStream.bind(this);
        this.close = this.close.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        openPlayerFn = this.fetchStream;
    }

    componentDidUpdate(prev){
        if((this.props.player.record&&!prev.player.record) || (this.props.player.record&&prev.player.record&&this.props.player.record.id!==prev.player.record.id)){
            this.fetchStream();
        }
    }

    close() {
        const { dispatch } = this.props;
        dispatch({type: 'ACTIVE_PLAY', payload: {id: null, record: null, state: null} });
        this.setState({src: null, recordName: null, show: false});
    }

    async fetchStream() {

        const { dispatch , player} = this.props;
        const { record } = player;
        dispatch({type: 'ACTIVE_PLAY', payload : { id: record.id, status: 'load', record } });
        this.setState({src: null, show: true, recordName: `${record.Address} - ${record.Agent}`});
        const result = await provider(GET_ONE, '/visor', record.requestId);
        if(this._isMounted){
            this.setState({src: `/api/play/${result.data.fileName}`})
        }
    }

    onPause(){
        const { dispatch, player } = this.props;
        dispatch({type: 'ACTIVE_PLAY', payload : { id: player.record.id, record: player.record, status: 'pause' } });
    }

    onPlay(){

        const { dispatch, player } = this.props;
        dispatch({type: 'ACTIVE_PLAY', payload : { id: player.record.id, status: 'play', record: player.record } });
    }

    download(){
        const { player } = this.props;
        const file = player.record.MediaFileName;
        FileSaver.saveAs(`${window.location.origin}/v1/download/`+file, file)
    }

    render() {
        const { classes } = this.props;
        const { src, show, recordName} = this.state;
        if(!show){
            return null;
        }
        return (
            <div className={classes.main}>
                <Card classes={{
                    root: classes.card
                }}>
                    <CardHeader
                        classes={{
                            root: classes.root,
                            title: classes.title,
                            action: classes.action
                        }}
                        title={recordName}
                        action={ 
                            <div>
                                <IconButton style={{color: '#00529f', borderRadius: 4, fontSize: 14}} onClick={() => this.download()} size='small' aria-label="Скачать">
                                    <CloudDownload style={{marginRight: 8}}/> Скачать
                                </IconButton>
                                <IconButton style={{color: 'red'}} onClick={() => this.close()} size='small' aria-label="Закрыть">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        }
                    />
                </Card>
                <AudioPlayer 
                    autoPlay 
                    src={src} 
                    showLoopControl={false}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                /> 
            </div>
        );
    }
}


const mapStateToProps = (state, props) =>{
    const { fetch, player } = state;
    return {
        player,
        isLoading: !!fetch.loading
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Player);


export function openPlayer(obj) {
    openPlayerFn(obj);
}