import React, {useState} from 'react';
// import { Snackbar, SnackbarContent } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

let openSnackbarFn;

const Notifier = (props) => {
  const [open, setOpen] = useState(false); 
  const [message, setMessage] = useState('');
  const [type, setType] = useState('info');

  const openSnackbar = ({message, type}) => {
    setOpen(true);
    setMessage(message);
    setType(type);
  };


  const handleSnackbarClose = () => {
    setOpen(false);
    setMessage();
  };

  openSnackbarFn = openSnackbar;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      // TransitionComponent={Slide}
      autoHideDuration={3000}
      open={open}
      onClose={handleSnackbarClose}
      ContentProps={{
        'aria-describedby': 'snackbar-message-id',
      }}
    >
       <Alert
          onClose={handleSnackbarClose}
          severity={`${type}`}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
    </Snackbar>
  )
}


export default Notifier;

export function openSnackbar(obj) {
  openSnackbarFn(obj);
}