import React from 'react';
import { TextField, InputLabel } from '@mui/material';

const formatValue = (value) =>{
  console.log(value);
  return value.replace(/[^0-9]/g,"");
}

const TimeInput = ({ input, meta: { touched, error } = {}, style, label }) => {
  const onChangeHour = (event) => {
    const num = formatValue(event.target.value);
    const hours = Math.floor(input.value*60 / 3600);
    const mmss = new Date(input.value*60 * 1000).toISOString().substr(13, 6);
    const hhmmss =  `${hours}${mmss}`.split(':');
    hhmmss[0]=num;
    const minutes = +hhmmss[0]*60+(+hhmmss[1])+(+hhmmss[2]/60);
    input.onChange(+minutes);
  }

  const onChangeMinute = (event) => {
    const num = formatValue(event.target.value);
    const hours = Math.floor(input.value*60 / 3600);
    const mmss = new Date(input.value*60 * 1000).toISOString().substr(13, 6);
    const hhmmss =  `${hours}${mmss}`.split(':');
    hhmmss[1]=num.substr(0, 2);
    const minutes = +hhmmss[0]*60+(+hhmmss[1])+(+hhmmss[2]/60);
    input.onChange(+minutes);

  }

  const onChangeSeconds = (event) => {
    const num = formatValue(event.target.value);
    const hours = Math.floor(input.value*60 / 3600);
    const mmss = new Date(input.value*60 * 1000).toISOString().substr(13, 6);
    const hhmmss =  `${hours}${mmss}`.split(':');
    hhmmss[2]=num.substr(0, 2);
    const minutes = +hhmmss[0]*60+(+hhmmss[1])+(+hhmmss[2]/60);
    input.onChange(+minutes);
  }

  const hours = Math.floor(input.value*60 / 3600);
  const mmss = new Date(input.value*60 * 1000).toISOString().substr(14, 5);
  const ms =  `${mmss}`.split(':');

  return(
    <div style={{ minWidth: 120, ...style}}>
      <InputLabel shrink htmlFor="select-multiple-native">
          {label}
      </InputLabel>
      <TextField
            style={{display: 'inline-flex', width: 30, marginRight: 0}}
            error={!!(touched && error)}
            helperText={touched && error}
            value={hours}
            variant="standard"
            onChange={onChangeHour}
            fullWidth
      />
      <span style={{display: 'inline-flex', width: 5, marginTop: 8}}>:</span>
      <TextField
            style={{display: 'inline-flex', width: 30, marginRight: 0}}
            error={!!(touched && error)}
            helperText={touched && error}
            value={ms[0]}
            variant="standard"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={onChangeMinute}
            fullWidth
      />
      <span style={{display: 'inline-flex', width: 5, marginTop: 8}}>:</span>
      <TextField
            style={{display: 'inline-flex', width: 30, marginRight: 0}}
            value={ms[1]}
            error={!!(touched && error)}
            helperText={touched && error}
            variant="standard"
            onChange={onChangeSeconds}
            fullWidth
      />
    </div>
  )
}

export default TimeInput;
