import React from 'react';
import { withStyles } from '@mui/styles';

const style = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column'
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
};

const TextField = ({classes, style, ...props}) =>(
    <div className={classes.main} style={style}>
        <div className={classes.div}>
            {props.value}
        </div>
    </div>
);

export default withStyles(style)(TextField);
