import React, {useState, useEffect} from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, isInvalid } from 'redux-form';
import _ from 'lodash';

import {GET_ONE, GET_LIST, UPDATE, provider} from '../../_utils';

import ActionsCard from './Actions';
import CardEdit from './CardEdit';
import Integration from './Integration';
// import Processing from './Processing';
import Settings from './Settings';
import Templates from './Templates';


import { TabPanel, Actions, SaveButton, CloseButton, CardElement } from '../../_Components';
import { history } from '../../_utils';


const CardDataEdit = ({match, initialize, initialized, formValues, invalid, isSaving}) => {
    const [data, setData] = useState(null);
    const [project, setProject] = useState(null);
    const [dealFields, setDealFields] = useState(null);
    const [leadFields, setLeadFields] = useState(null);
    const [contactFields, setContactFields] = useState(null);
    const [fields, setFields] = useState(null);
    const [scripts, setScripts] = useState(null);
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        const  fetchData = async () => {
            const { data } = await provider( 
                    GET_ONE,
                    '/cards', 
                    match.params.id
            );
            setData(data);
            getProject(data);
            initialize(data)
        }
    
        const  getProject = async (data) => {
            const project = await provider( 
                GET_ONE,
                '/projects',
                data.project
            );
            const fieldOptions = project.data.fields.map( el => { return {id: el.value, name: el.label, type: el.type, combobox: el.combobox }});
            setProject(project.data);
            setFields(fieldOptions);
            if(project.data.settings.integration.type==="Bitrix"){
                getBitrixLeadFields(data.project);
                getBitrixContactsFields(data.project);
                getBitrixDealFields(data.project);
            }
        }
    
        const getBitrixLeadFields = async (project) => {
            const BitrixLeadField = await provider(
                GET_ONE,
                '/bitrix/fields/lead',
                project
            );
            setLeadFields(_.map(BitrixLeadField.data, (l, key) => ({id: key, name: l.title!==key ? l.title : l.formLabel })));
        }
        
        const getBitrixDealFields = async (project) =>{
            const BitrixDealFields = await provider(
                GET_ONE,
                '/bitrix/fields/deal',
                project
            );
            setDealFields(_.map(BitrixDealFields.data, (l, key) => ({id: key, name: l.title!==key ? l.title : l.formLabel })));
        }

        const getBitrixContactsFields = async (project) => {
            const BitrixContactsFields = await provider(
                GET_ONE,
                '/bitrix/fields/contact',
                project
            );
            setContactFields(_.map(BitrixContactsFields.data, (l, key) => ({id: key, name: l.title!==key ? l.title : l.formLabel })));
        }

        const getScripts = async  () => {
            const Scripts = await provider( 
                GET_LIST,
                '/scripts',
                {}
            );
            setScripts(Scripts.data);
        }

        const getQueues = async () => {
            const Queues = await provider(
                GET_LIST,
                '/infra/queues',
                {
                    filter: {},
                    sort: { field: 'name', order: 'ASC' },
                    pagination: { page: 1, perPage: 10000 },
                }
            );
            setQueues(Queues.data);
        }
        if(!initialized){
            fetchData();
            getScripts();
            getQueues();
        }
    }, [match,initialize, initialized]);


    const save = async () => {
        provider( UPDATE, '/cards/', { id: formValues._id, data: formValues })
    }

    const close = () =>{
        return history.goBack();
    }

    const panels = [
        {
            label: 'Настройки',
            element: <Settings 
                        comboboxes={project && project.combobox.filter( ( el ) => el.deleted === false )}
                        scripts={scripts}
                        queues={queues}
                        phoneFields={project && project.fields.filter( ( el ) => el.type === 'phone' )}
                        integration={project && project.settings.integration}
                    />
        },
        {
            label: 'Карточка',
            element: <CardEdit
                        data={data}
                        fields={fields}
                    />
        },
        {
            label: 'Действия',
            element: <ActionsCard 
                        style={{width: '100%'}} 
                        fields={fields}
                        project={project}
                        comboboxes={project && project.combobox.filter( ( el ) => el.deleted === false )}
                        result={(project&&data) && project.combobox.find( ( el ) => el._id === data.result )}
                        task={(project&&data) && project.combobox.find( ( el ) => el._id === data.task )}
                        queues={queues}
                    />
        },
        {   
            label: 'Интеграция',
            element: <Integration project={project} leadFields={leadFields} contactFields={contactFields} dealFields={dealFields} />
        },
        {
            label: 'Шаблоны',
            element: <Templates project={project}/>
        },
        // {
        //     label: 'Обработка',
        //     element: <Processing project={project} leadFields={leadFields} contactFields={contactFields} dealFields={dealFields}/>
        // }
    ];

    const actions =[
        <SaveButton isSaving={isSaving || invalid} handleSaveClick={save} />,
        // <BlockButton isSaving={isSaving || invalid} handleBlockClick={disableProject} status={formValues ? formValues.status : 'Disabled'}/>,
        <CloseButton isSaving={isSaving || invalid} handleCloseClick={close} />
    ]

    return (
        <CardElement
            title={formValues&&formValues.name}
            action={<Actions actions={actions}/>}
            content={<TabPanel panels={panels} />}
        />
    )
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('card')(state);
    const { fetch } = state;
    return { 
        formValues,
        isSaving: fetch ? (fetch.creat>0 ? true : false) : false,
        invalid: isInvalid('card')(state),
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'card',
    })
);

export default enhance(CardDataEdit);


