import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import '../Edit/style.css';

import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';

import { Button } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';


import GridLayout from "react-grid-layout";
import _ from "lodash";

import AddCol from './AddCol';


const styles = {
    row: {
        display: 'flex',
        paddingBottom: '1em'
    },
    settings: {
        display: 'inline-flex',
        marginRight: '2em'
    },
    radio: {
        paddingRight: 30
    },
    button: {
        // float: 'right',
        marginBottom: 10,
    },
    selectedCol: {
        background: '#0b2b61',
        '& .textOfElement': {
            color: '#fff'
        }
    },
    icon: {
        cursor: 'pointer', 
        width: '0.75em', 
        marginTop: 6
    },
    ul: {
        padding: 0
    },
    li: {
        border: '1px solid',
        listStyle: 'none',
        background: 'white',
        marginBottom: 1,
        cursor: 'pointer'
    },
    liActive: {
        backgroundColor: '#0a2b61',
        color: '#ffff',
        border: '1px solid',
        listStyle: 'none',
        background: 'white',
        marginBottom: 1,
        cursor: 'pointer'
    },
    handleIcon: {
        verticalAlign: 'middle',
        paddingLeft: 10,
    },
    deleteIcon: {
        verticalAlign: 'middle',
        color: 'red',
        paddingRight: 8,
        float: 'right',
        fontSize: '1.25rem !important'
    },
    editIcon: {
        verticalAlign: 'middle',
        color: '#0a2b61',
        paddingRight: 8,
        float: 'right',
        fontSize: '1.25rem !important'
    },
    addIcon: {
        verticalAlign: 'middle',
        color: '#009688',
        paddingRight: 8,
        float: 'right',
        fontSize: '1.25rem !important'
    },
    label: {
        verticalAlign: 'middle',
        paddingLeft: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '85%',
        display:' block',
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: '1.43',
        letterSpacing: '0.01071em'
    },
    script: {
        display: 'flex',
    },
    list: {
        flex: 'auto',
        height: 'calc(100vh - 240px)',
        width: '56.5%'
    },
    edit: {
        flex: 'auto',
        marginLeft: '1em',
        height: 'calc(100vh - 240px)',
        width: '44%'
    },
    editor: {
        '& .rdw-editor-main' : {
            height: '70%',
            borderRadius: '2px',
            border: '1px solid #F1F1F1'
        }
    },
    cardHeader: {
        padding:' 8px 16px 0px 0px'
    },
    cardContent: {
        height: 'calc(100vh - 320px)',
        overflow: 'auto',
        position: 'relative',
        padding: '0px 8px 0px 8px'
    },
    cardHeaderTitile: {
        width: 'fit-content'
    },
    cardHeaderAction: {
        flex: 'auto',
        width: '-webkit-fill-available'
    },
    actionButtons: {
        display: 'flex',
    },
    panelRoot: {
        padding: '0 8px 0 0',
        maxHeight: 24,
        minHeight: 'unset',
        '&.Mui-expanded': {
            minHeight: 'unset',
        }
    },
    panelContent: {
        display: 'block'
    },
    required: {
        ckolor: 'red'
    }

}

class CardComponent extends React.PureComponent {
    static defaultProps = {
        className: "layout",
        width: 700,
        cols: 12,
        autoSize: false,
        rowHeight: 25,
        maxRows: 4,
        marginTop: 0
    };

    constructor(props) {
        super(props);
        this.state = {
            oldlayout: null,
            openNew: false,
            items: props.elements.map((el, index) => {
                return{
                    i: el.field,
                    x: el.col,
                    y: el.row,
                    w: el.sizeX,
                    h: el.sizeY,
                    maxH: 1,
                }
            })
        };

        this.onAddItem = this.onAddItem.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.addCol = this.addCol.bind(this);
        this.closeAddColl = this.closeAddColl.bind(this);
        this.updateItems = this.updateItems.bind(this);
       // this.onDragStart = this.onDragStart.bind(this);


    }

    createElement(el, index, fields) {
        const { classes, selectedIndex } = this.props;
        const removeStyle = {
            position: "absolute",
            right: "2px",
            top: 0,
            cursor: "pointer"
        };
        //const i = el.add ? "+" : el.i;
        const i = el.i;
        const name = fields.find( (e) => e.id===i) ? fields.find( (e) => e.id===i).name : 'Новый елемент';
        const required = fields.find( (e) => e.id===i) ? (fields.find( (e) => e.id===i).required ? true : false) : false;
        return (
            <div 
                key={i} 
                data-grid={el} 
                className={selectedIndex===index ? classes.selectedCol : null} 
            >
                <div
                    className="hideelement"
                    style={removeStyle}
                    onClick={this.onRemoveItem.bind(this, i)}

                >
                   {/* &times;*/}
                </div>
                {/* <div className="textOfElement">{i}</div> */}
                <div className="textOfElement">{name}{required ? <span className={classes.required}>*</span> : ''}</div>
            </div>
        );
    }

    componentDidUpdate() {
        const { items } = this.state;
        const { formValues, actions, activeNodekey, selectedIndex } = this.props;
        const el = items[selectedIndex];
        if(el){
            if(actions){
                if(el.i!==formValues.actions[activeNodekey].cols[selectedIndex].field){
                    items[selectedIndex].i = formValues.actions[activeNodekey].cols[selectedIndex].field;
                    this.updateItems(items)
                }
            }else{
                if(el.i!==formValues.cols[selectedIndex].field){
                    items[selectedIndex].i = formValues.cols[selectedIndex].field;
                    this.updateItems(items)
                }
            }
        }
    }

    updateItems(items){
        this.setState(items)
    }

    onLayoutChange(layout){
        const { formValues, change, activeNodekey, actions } = this.props;
        const { items } = this.state;
        const newItems =  items.map( (el,i) => {
            const item = layout[`${i}`];
            return {
                ...el,
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
            }
        });
        const newCols = newItems.map( (item) => {
            const el = actions ?  formValues.actions[activeNodekey].cols.find( el => el.field=== item.i) : formValues.cols.find( el => el.field=== item.i);
            return {
                ...el,
                col : item.x,
                row : item.y,
                sizeX : item.w,
                sizeY: item.h,
            };
        });
        if(actions){
            change(`actions[${activeNodekey}]cols`, newCols);
        }else{
            change('cols', newCols);
        }
        this.setState({
            items: newItems
        });
    };

    addCol() {
        this.setState({openNew: true});
    }

    closeAddColl() {
        this.setState({openNew: false});
    }

    onAddItem(newElement) {
        const { addItem } = this.props
        if(this.state.oldlayout) for (const oldlayoutElement of this.state.oldlayout) { //ограничение добавления элементов больше чем количество рядов
            if(oldlayoutElement.y === this.props.maxRows - 1 && oldlayoutElement.x + oldlayoutElement.w > this.props.cols - 5) return;
        }
        const { items } = this.state;
        const newItem = {
                i: newElement.field,
                x: (items.length * 2) % 12,
                y: Infinity, // puts it at the bottom
                w: 2,
                h: 1,
                maxH: 1,
        }
        // newElement.x = (items.length * 2) % 12;
        // newElement.y = Infinity;
        // newElement.w = 2;
        // newElement.h = 1;
        this.setState({
            // Add a new item. It must have a unique key!
            items: this.state.items.concat(newItem),
            openNew: false
        }, addItem(newElement));
    }

    onRemoveItem(index) {
        const { formValues, actions, activeNodekey, change, selectCol, selectedIndex } = this.props;
        const { items } = this.state;
        const i = _.findIndex(items, {i: index});
        const { cols } = actions ? formValues.actions[activeNodekey] : formValues;
        const k = _.findIndex(cols, {field: index});
        items.splice(i,1);
        cols.splice(k,1)
        this.setState({items}, () =>  {
            if(actions){
                change(`actions[${activeNodekey}]cols`, cols);
            }else{
                change('cols', cols);
            }
            if(selectedIndex===i){
                selectCol(0);
            }
            return
        });
    }

    render() {
        const styleGrid ={
            width: this.props.width + 'px',
            height: (this.props.rowHeight + 10) * this.props.maxRows + 10 + 'px',
            marginTop: this.props.marginTop
        };
        const { classes, fields } =  this.props;
        const { items, openNew } = this.state;
        return (
                <React.Fragment>
                     <Button                                                         
                        className={classes.button}                                                     
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                        onClick={() => this.addCol()}
                    >
                        Добавить поле
                    </Button>
                        <GridLayout style={styleGrid}
                            classes={classes}
                            {...this.props}
                            onLayoutChange={this.onLayoutChange}
                            // onDragStart={this.onDragStart}
                        >
                                {_.map(items, (el, index) => this.createElement(el, index, fields))}
                        </GridLayout>
                    {openNew&&
                        <AddCol open={openNew} items={items} close={this.closeAddColl} save={this.onAddItem} fields={fields}/>
                    }
                </React.Fragment>

        );
    }
}


const mapStateToProps = (state, props) => {
    const formValues = getFormValues('card')(state);
    const { fetch } = state;
    return { 
        formValues,
        isLoading: !!fetch.loading
    }
};

export default  compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'card',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
    withStyles(styles),
)(CardComponent);