import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import { Labeled } from '../../../_Components';

const Occ = ({record, ...props}) =>(
    <div className={props.className}>
        {record ?
            <Labeled className={props.className} label={props.label}>
                <span>
                    {record&&record.statistic ? record.statistic.occPercent.toFixed(2) : 0}%
                </span>
            </Labeled>
         : 
            <div style={{padding: '16px 0px 0px 20px'}}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={36} style={{padding: '0 20px'}}/>
            </div>
        }
    </div>
)

export default (Occ);