import React, { Component } from 'react';
import { withStyles } from '@mui/styles';

import { compose } from 'recompose';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { SelectInput, ModalButtons } from '../../_Components';

const styles = theme => ({
    row: {
        display: 'flex',
        paddingBottom: '1em'
    },
    settings: {
        display: 'inline-flex',
        marginRight: '2em'
    },
    radio: {
        paddingRight: 30
    },
    field: {
        display: 'inline-flex',
        marginRight: '2em'
    },
    block: {
        height: 'calc(100vh - 260px)',
        display: 'flex'
    },
    combobox: {
        width: '22em',
        // display: 'inline-flex',
        marginRight: 32,
    },
    options: {
        // display: 'inline-flex',
        width:' 55em',
    },
    button: {
        marginBottom: 16
    },
    close: {
        color: "#f44336",
        border: "1px solid #f4433663",
        '&:hover': {
            border: '1px solid #f44336',
            backgroundColor: '#ff000008',
        }
    },
})


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



class AddAction extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    close = () => {
        const { close } = this.props;
        this.setState({ initial: false}, () => close(false) );
    }

    save = () => {
        const { save, formValues } = this.props;
        this.setState({ initial: false}, () => save(formValues) );
    }
    render() {
        const { open, results, tasks, classes, formValues } = this.props;
        return(
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth='md'
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Новое действие</DialogTitle>
                <DialogContent>
                    { this._isMounted&&
                        <div className={classes.row}>
                            <Field
                                name={`result`}
                                required={true}
                                component={SelectInput}
                                options={results.map( o => { return { id: o.value, name: o.label }})}
                                label={`Результат`}
                                className={classes.settings}
                                style={{width: 300}}
                            />
                            <Field
                                name={`task`}
                                required={true}
                                component={SelectInput}
                                options={tasks.map( o => { return { id: o.value, name: o.label }})}
                                label={`Тема`}
                                className={classes.settings}
                                style={{width: 300}}
                            />
                            {(formValues.task&&tasks.find( t => t.value===formValues.task).sub.length>0)&&
                                 <Field
                                    name={`sub_task`}
                                    required={true}
                                    component={SelectInput}
                                    options={tasks.find( t => t.value===formValues.task).sub.map( o => { return { id: o.value, name: o.label }})}
                                    label={`Подтема`}
                                    className={classes.settings}
                                    style={{width: 300}}
                                />
                            }
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <ModalButtons handleSaveClick={this.save} handleCloseClick={this.close}/>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, props) => {
    const copyFields = props.copyFields || {
        cols: [],
        settings: {
            activity: false,
            activity_subject: [],
            callback: false,
            create: false,
            email: false,
            emails: [],
            phones: [],
            sendCustomer: false,
            sms: false,
            telegram: false,
            telegramChats: [],
            transfer: false,
            transfer_phones: []
        } 
    };
    const initialValues = {
        ...copyFields,
        task: null,
        sub_task: null,
        result: null,
    }
    const formValues = getFormValues('cardAddAction')(state);
    return { 
        initialValues: {
            ...initialValues
        },
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'cardAddAction',
        destroyOnUnmount: true, // <------ preserve form data
        forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    }),
    withStyles(styles)
);
export default enhance(AddAction);