import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Toolbar, Menu, MenuItem, CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';

const style = theme =>({
    toolbar: {
        display: 'block !important',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        gap: 22,
        height: 97,
        backgroundColor: theme.palette.background.default,
        borderBottom: '0.5px solid #BDBDBD',
        marginBottom: theme.spacing(1)
    },
    actionsButton: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
});

const ListToolbar = ({
        classes,
        filters,
        filterValues, // dynamically set via the UI by the user
        permanentFilter, // set in the List component by the developer
        actions,
        bulkActions,
        exporter,
        isLoading,
        fetchData,
        clearFilter,
        setCreate,
        creat,
        creatOption,
        refresh,
        menuItem,
        handleMenu,
        handleMenuClose,
        changeMenu,
        ...rest
    }) => (
       <Toolbar className={classes.toolbar}>
           <div style={{float: 'right'}}>
               {refresh&&
                   <Button 
                        className={classes.actionsButton}
                        color="primary" 
                        size="small"
                        variant="outlined"
                        endIcon={isLoading 
                            ? <CircularProgress size={20} thickness={2} />
                            : <RefreshIcon />
                        }
                        onClick={() => fetchData()}
                        disabled={isLoading}
                   > 
                       Обновить
                   </Button>
               }
               {(creat&&!creatOption)&&
                   <Button 
                        className={classes.actionsButton}
                        color="primary" 
                        size="small"
                        variant="contained"
                        endIcon={<AddIcon />}
                        disabled={isLoading}
                        onClick={() => setCreate()}
                   > 
                       Создать
                   </Button>
               }
               {(creat&&!!creatOption)&&
                   <Fragment>
                       <Button 
                            className={classes.actionsButton}
                            color="primary" 
                            size="small"
                            variant="contained"
                            endIcon={<AddIcon />}
                            disabled={isLoading}
                            onClick={handleMenu}
                       > 
                           Создать
                       </Button>
                       <Menu
                           id="simple-menu"
                           anchorEl={menuItem}
                           keepMounted
                           open={Boolean(menuItem)}
                           onClose={handleMenuClose}
                       >
                           {creatOption.map((e) =>
                               <MenuItem key={e.value} onClick={() => setCreate(e.value)}>{e.label}</MenuItem>
                           )}
                       </Menu>
                   </Fragment>
               }
               {actions &&
                   React.cloneElement(actions, {
                        ...rest,
                        className: classes.actionsButton,
                        bulkActions,
                        isLoading,
                        exporter,
                        filters,
                        filterValues,
                        permanentFilter,
                        ...actions.props,
               })}
           </div>
           {filters &&
               React.cloneElement(filters, {
                   ...rest,
                   filterValues,
                   context: 'form',
               })
           }
       </Toolbar>
);

ListToolbar.propTypes = {
    filters: PropTypes.element,
    permanentFilter: PropTypes.object,
    actions: PropTypes.element,
    bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default withStyles(style)(ListToolbar);
