import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'recompose';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';

import {  TextInput, SelectInput, RadioInput, SwitchInput, Modal, Row, CardElement, TableElement } from '../../_Components';
import { AddButton } from '../../_Components/Buttons';
import { Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { GET_ONE, provider } from '../../_utils'; //DELETE, UPDATE, , confirm 

const IntegationOptions = [{
    id: 'None',
    name: 'Нет',
},
{
    id: 'Bitrix',
    name: 'Bitrix',
},
{
    id: 'AmoCRM',
    name: 'AmoCRM',
}];

const EssenceOptions = [{
    id: 'lead',
    name: 'Лид',
},
{
    id: 'contact',
    name: 'Контакт',
},
{
    id: 'company',
    name: 'Компанию',
},
{
    id: 'company_contact',
    name: 'Контакт > Компанию'
}];

const AddEmailForm = () => (
    <Row>
        <Field
            name="name"
            required={true}
            component={TextInput}
            label={`Название`}
            style={{width: 200}}
        />
        <Field
            name='email'
            required={true}
            component={TextInput}
            label={`E-mail`}
            style={{width: 250}}
        />
    </Row>
)

const AddTelegramForm = () => (
    <Row>
        <Field
            name="name"
            required={true}
            component={TextInput}
            label={`Название`}
            style={{width: 200}}
        />
        <Field
            name='chat'
            required={true}
            component={TextInput}
            label={`ID`}
            style={{width: 250}}
        />
    </Row>
)

const ContactsList = ({title, onAddClick, onRemoveClick, onEditClick, list, header }) => (
    <CardElement
        width={40}
        style={{display: 'inline-grid'}}
        action={<AddButton handleAddClick={onAddClick} />}
        title={title}
        content={
            // list.length > 0 &&
                <TableElement 
                    header={header} 
                    rows={list} 
                    edit={true}
                    editFunc={onEditClick}
                    remove={true}
                    removeFunc={onRemoveClick}
                />
        }
    />
)

const Settings = ({ formValues, isLoading, change, ...props }) => {
    const [isChecking, setChecking] = useState(false)
    const [emailList, setEmailList] = useState([]);
    const [telegramList, setTelegramList] = useState([]);
    const [emailDialog, setEmailDialog] = useState(false);
    const [telegramDialod, setTelegramDialod] = useState(false);
    const [emailForm, setEmailForm] = useState(null);
    const [editEmailKey, setEditEmailKey] = useState(null);
    const [telegramForm, setTelegramForm] = useState(null);
    const [editTelegramKey, setEditTelegramlKey] = useState(null);

     useEffect(() => {
        if(formValues){
            setEmailList(formValues.settings.list.email);
            setTelegramList(formValues.settings.list.telegram);
        }
     }, [formValues])

    const removeEmail = (key) => {
        const newData = emailList;
        const newArray = [];
        newData.map( (p,i) => {
            if(i!==key) newArray.push(p);
            return p;
        });
        setEmailList(newArray);
        change(`settings.list.email`, newArray);
    }

    const removeTelegram = (key) => {
        const newData = telegramList;
        const newArray = [];
        newData.map( (p,i) => {
            if(i!==key) newArray.push(p);
            return p;
        });
        setTelegramList(newArray);
        change(`settings.list.telegram`, newArray);
    }

    const saveTelegram = (form) => {
        const newData = telegramList;
        if(editTelegramKey!==null){
            newData[editTelegramKey] = form;
            setTelegramList(newData);
        }else{
            newData.push({...form});
            setTelegramList(newData);
        }
        change(`settings.list.telegram`, newData);
        setTelegramDialod(!telegramDialod);
        setTelegramForm(null);
        setEditTelegramlKey(null);
        return;
    }

    const saveEmail = (form) => {
        const newData = emailList;
        if(editEmailKey!==null){
            newData[editEmailKey] = form;
            setEmailList(newData);
        }else{
            newData.push({...form});
            setEmailList(newData);
        }
        change(`settings.list.email`, newData);
        setEmailDialog(!emailDialog);
        setEmailForm(null);
        setEditEmailKey(null);
        return;
    }
   
    const editEmail = (form, key) => {
        setEditEmailKey(key);
        setEmailForm(form);
        setEmailDialog(!emailDialog);
    }

    const editTelegram = (form, key) => {
        setEditTelegramlKey(key);
        setTelegramForm(form);
        setTelegramDialod(!telegramDialod);
    }

    const openEmail = () => setEmailDialog(!emailDialog);
    const openTelegram = () => setTelegramDialod(!telegramDialod);

    const closeEmail = () => {
        setEmailDialog(false)
        setEmailForm(null);
    }

    const closeTelegram = () => {
        setTelegramDialod(false);
        setTelegramForm(null);
    }

    const checkIntegration = async () => {
        const { settings } = formValues;
        const { url,user,key } = settings.integration.settings;

        const { data } = await provider( 
            GET_ONE,
            '/bitrix/check', 
            `${url}/${user}/${key}`
        );
        if(data){
            setChecking(true);
        }
    }

    const emailHeader =  [
        {
            label: 'Имя',
            width: 20,
            value: 'name'
        },
        {
            label: 'E-mail',
            width: 20,
            value: 'email'
        }
    ];

    const telegramHeader =  [
        {
            label: 'Имя',
            width: 20,
            value: 'name'
        },
        {
            label: 'ID',
            width: 20,
            value: 'chat'
        }
    ];

    
    return(
        <div style={{ height: 'calc(100vh - 260px)' }}>
             {(!isLoading&&formValues)&&
                <Row>
                    <Field
                        name="name"
                        required={true}
                        component={TextInput}
                        label={`Название`}
                        style={{width: 300}}
                    />
                    <Field
                        name="settings.integration.type"
                        required={true}
                        component={SelectInput}
                        options={IntegationOptions}
                        label={`Интеграция`}
                        style={{width: 150}}
                    />
                </Row>
            }
            {(!isLoading&&formValues&&formValues.settings.integration.type!=='None')&&
                <Row>
                    <Field
                        name="settings.integration.settings.url"
                        required={true}
                        component={TextInput}
                        label={`Домен`}
                        style={{width: 300}}
                    />
                    <Field
                        name="settings.integration.settings.user"
                        required={true}
                        component={TextInput}
                        label={`ID пользователя`}
                        style={{width: 150}}
                    />
                    <Field
                        name="settings.integration.settings.key"
                        required={true}
                        component={TextInput}
                        label={`Ключ`}
                        style={{width: 300}}
                    />
                    {!isChecking
                    ?
                        <Button
                            color="primary" 
                            size="small"
                            variant="contained"
                            onClick={checkIntegration}
                            disabled={isLoading}
                            endIcon={<SaveIcon />}
                        >
                            Проверить
                        </Button>
                    :
                        <Button
                            color="primary" 
                            size="small"
                            variant="contained"
                            onClick={checkIntegration}
                            disabled={true}
                            endIcon={<SaveIcon />}
                        >
                            Успешно
                        </Button>
                    }
                </Row>
             }
            {(!isLoading&&formValues&&formValues.settings.integration.type!=='None')&&
                <Row>
                    <Field
                        name="settings.integration.settings.essence"
                        required={true}
                        component={RadioInput}
                        options={EssenceOptions}
                        label={`Создавать сущность`}
                        style={{width: 300}}
                    />
                    <Field
                        name="settings.integration.settings.create"
                        required={true}
                        component={SwitchInput}
                        label={`Авто. создавать сущность (если отсутствует)`}
                        style={{width: 300}}
                    />
                </Row>
            }
            {(!isLoading&&formValues)&&
                <Fragment>
                    <ContactsList
                        title='Списки E-mail'
                        onEditClick={editEmail}
                        onAddClick={openEmail} 
                        onRemoveClick={removeEmail}
                        list={emailList}
                        header={emailHeader}
                    />
                    <ContactsList
                        title='Списки Telegram'
                        onEditClick={editTelegram}
                        onAddClick={openTelegram} 
                        onRemoveClick={removeTelegram}
                        list={telegramList}
                        header={telegramHeader}
                    />
                </Fragment>
            }
            {emailDialog&&
                <Modal 
                    title="Добавить e-mail"
                    open={emailDialog} 
                    handleSaveClick={saveEmail} 
                    handleCloseClick={closeEmail} 
                    fields={<AddEmailForm />} 
                    initial={emailForm}
                />
            }
            {telegramDialod&&
                <Modal 
                    title="Добавить Telegram Chat"
                    open={telegramDialod} 
                    handleSaveClick={saveTelegram} 
                    handleCloseClick={closeTelegram} 
                    fields={<AddTelegramForm />}
                    initial={telegramForm}
                />
            }
        </div>
    )

}

const mapStateToProps = (state) => {
    const formValues = getFormValues('project')(state);
    return { 
        formValues,
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'project',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
);

export default enhance(Settings);