import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import Drawer from '@mui/material/Drawer';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import queryString from 'query-string';

import moment from 'moment';

import Filter from './components/ListFilter';
import ListTimeline from './ListTimeline';
import EditSchedule from './components/EditSchedule';

import { CardElement } from '../_Components';
import {
    GET_LIST,
    provider,
    history
} from '../_utils';

// import './style.css';

class Schedule extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        const { filter } = queryString.parse(history.location.search);
        const {
                interval = {
                    startDate: moment().startOf('day'), 
                    endDate:  moment().startOf('day').add(6,'days'),
                },
                empty = false,
                operator = null
            } = filter ? JSON.parse(filter) : {};
        this.state = {
            interval,
            empty,
            operator,
            operators: [],
            editOpen: false,
            createOpen: false
        };
        this.fetchData = this.fetchData.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchOperator();
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps.filterValues)!==JSON.stringify(this.props.filterValues)){
            if(prevProps.filterValues){
                this.refresh({...this.props.filterValues})
            }else{
                this.refresh({...this.props.filterValues})
            }
        }
    }

    async fetchData({interval, empty}){
        const { data } = await provider(
            GET_LIST,
            '/schedule',
            {
                filter: { startDate: interval.startDate, endDate: interval.endDate, empty },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        if(this._isMounted&&data){
            data.map( d => d.label = `${d.login} ${d.fullname}`)
            this.setState({ data: data });
        }
    }

    async fetchOperator(){
        const { data } = await provider(
            GET_LIST,
            '/operators',
            {
                filter: { type: {$in: ["operator", "leader", "senior", "test"]}},
                sort: { field: 'login', order: 'DESC' },
                pagination: { page: 1, perPage: 10000 },
            }
        );
        const operators = [];
        if(this._isMounted&&data){
            data.map( e => {
                const inArray = operators.find(o => o._id === e._id) || operators.find(o => `${e.login} ${e.fullname}` === o.name);
                return (e.login&&e.login.length>0&&!inArray) && operators.push({ 
                    name: `${e.login} ${e.fullname}`,
                    id: e._id,
                    _id: e._id,
                    department: e.department,
                    place: e.place,
                    status: e.status,
                })
            })
        }
        this.setState({ operators });
    }

    refresh (values){
        this.setState({...values});
        this.fetchData({...values});
    }
    
    handleClose = () => {
        this.setState({ 
            selectedOperator: null,
            selectedDate: null,
            selectedItem: null,
            editOpen: false,
            createOpen: false
        });
    }

    handleSave = (data) => {
        const {
            interval,
            endDate,
            empty,
            operator
        } = this.state;
        this.setState({ 
            selectedOperator: null,
            selectedDate: null,
            selectedItem: null,
            editOpen: false
        });
        this.refresh({
            interval,
            endDate,
            empty,
            operator
        });
    }

    handleDelete = (id) => {
        const {
            interval,
            empty,
            operator,
        } = this.state;
        this.setState({ 
            selectedOperator: null,
            selectedDate: null,
            selectedItem: null,
            editOpen: false
        });
        this.refresh({
            interval,
            empty,
            operator
        });
    }

    setCreate = () => {
        this.setState({createOpen: true})
    }

    render() {
        const { filterValues } = this.props;
        const { 
            data,
            interval,
            operator,
            empty,
            operators,
            selectedDate,
            selectedOperator,
            selectedItem,
            editOpen,
            createOpen
        } = this.state;
                
        const handleRowDoubleClick = (operator, date) => {
            this.setState({selectedOperator: operator, selectedDate: date, selectedItem: null, editOpen: true});
        }
        const handleItemDoubleClick = (item, operator) => {
            this.setState({selectedOperator: operator._id, selectedDate: null, selectedItem: item, editOpen: true});
        }

        return (
            <CardElement
                title="Расписание"
                action={ 
                    <Filter 
                        initialValues={{interval, empty, operator}} 
                        operators={operators} 
                        setCreate={this.setCreate}
                        onSubmit={this.refresh} 
                    />
                }
                content={
                    <Fragment>
                        <ListTimeline 
                            operators={operators}
                            startDate={interval.startDate}
                            endDate={interval.endDate}
                            data={data}
                            filterValues={filterValues}
                            handleRowClick={handleRowDoubleClick}
                            handleRowDoubleClick={handleRowDoubleClick}
                            handleItemDoubleClick={handleItemDoubleClick}
                        />
                        <Drawer
                            open={editOpen}
                            anchor="right"
                            onClose={this.handleClose}
                        >
                            <EditSchedule
                                item={selectedItem}
                                operators={operators}
                                initialValues={{operator: selectedOperator, date: selectedDate}}
                                onCancel={this.handleClose}
                                onSave={this.handleSave}
                                onDelete={this.handleDelete}
                            />
                        </Drawer>
                        <Drawer
                            open={createOpen}
                            anchor="right"
                            onClose={this.handleClose}
                        >
                            <EditSchedule
                                item={selectedItem}
                                operators={operators}
                                initialValues={{operator: selectedOperator, date: selectedDate}}
                                onCancel={this.handleClose}
                                onSave={this.handleSave}
                                onDelete={this.handleDelete}
                            />
                        </Drawer>
                    </Fragment>
                }
            />
        );
    }
}

const mapState = (state) => {
    const values = getFormValues('schedule_list')(state);
    return { 
        filterValues: values,
    }
}

export default compose(
    connect(mapState),
)(Schedule);
