import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { Filter, DateInput, AutocompleteInput, TextInput } from '../_Components';

const VisorFilter = ({ queues = [], filterDefaultValues = {}, formValues = {}, ...props}) => (  
    <Filter filterValues={filterDefaultValues} form="visorFilter" {...props}>
        <DateInput
            source="startDate"
            label={`С`}
            alwaysOn
            // minDate={ new Date(moment(formValues.endDate).subtract(30, 'days'))}
            maxDate={new Date(formValues.endDate)}
            // className={classes.date}
        />
        <DateInput
                source="endDate"
                label={`По`}
                alwaysOn
                minDate={new Date(formValues.startDate)}
                maxDate={ new Date() }
        />
        <TextInput 
            source="phone"
            label={`Телефон`}
            alwaysOn
        />
        <AutocompleteInput 
            source="queue"
            label={`Очереди`}
            options={queues}
            style={{ width: 250 }}
            alwaysOn
        />
    </Filter>
);



const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
    // withStyles(styles),
)(VisorFilter);