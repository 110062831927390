import React from 'react';

import { SelectInput, TextInput, Filter } from '../_Components';


const OperatorsFilter = ({filterDefaultValues = {}, projects, ...props }) => {
    return (  
        <Filter filterValues={filterDefaultValues} {...props}>
            <TextInput source="fullname" label={`Имя`} alwaysOn/>
            <TextInput source="login" label={`Номер`} alwaysOn/>
            <SelectInput 
                source="status"
                label={`Статус`}
                options={[{id: null, name: 'Все'},{id: 'Active', name: 'Активные'},{id: 'Terminated', name: 'Заблокированные'},]}
                alwaysOn
            />
        </Filter>
    );
}

export default  OperatorsFilter;