import moment from 'moment';

export default class Utils {
	static itemContent (element) {
		let date = moment(element.schedules[0].date).startOf("day");
	    let start = date.clone().add(element.schedules[0].start, 'minutes');
	    let end = date.clone().add(element.schedules[0].end, 'minutes');
	    let content = `${start.format("HH:mm")} - ${end.format("HH:mm")}`;
	    let color;
	    let text="#000000";
	    if(element.change){
	    	switch (element.change) {
	        	case 'ill':
	                color="#fff176";
	                text="#000000"; //a52a2a
	                content = "Больнич.";
	                break;
	            case 'cencel':
	                color="#9e9e9e"
	                text="#3e3f40";
	                content = "Отменен";
	                break;
	            case 'absenteeism':
	                color="#ff5722"
	                text="#000000"
	                content = "Прогул";
	                break;
	            case 'change':
	                color+="border-bottom: 1px solid red;"
	                switch (element.type) {
	                  	case 'day1':
	                    	color="#8dc350"
	                    	break;
	                  	case 'day2':
	                    	color="#4caf50"
	                    	break;
	                  	case 'shift':
	                    	color="#90caf9"
	                    	break;
	                  	case 'evening':
	                    	color="#00bcd4"
	                    	break;
	                  	case 'nigth':
	                    	color="#673ab7"
	                    	break;
	                    default:
	            			color="#8dc350";
	                }
	                break;
	            default:
	            	color="#9C27B0";
	        }
	    }else{
	        switch (element.type) {
				case 'day1':
					color="#8dc350"
					break;
				  case 'day2':
					color="#4caf50"
					break;
				  case 'shift':
					color="#90caf9"
					break;
				  case 'evening':
					color="#00bcd4"
					break;
				  case 'nigth':
					color="#673ab7"
					break;
				default:
					color="#8dc350";
	        }
	    };
	    return {date: date, color: color, text: text, content: content, operator: element.operator, start: start, end: end};
	}

	static groupContent (element) {
	    return {element};
	}

	static buildAllCells (date, elements) {
		const startHour = parseInt(date.format("H"));
		const v = new Map();
		let planned = {};
		for (var i = startHour; i < 24; i++) {
		  	planned[i]=0;
		  	let h = moment(date.clone().startOf('day')).add(i, 'hours');
		    for (var j = 0; j < elements.length; j++) {
		      	if(elements[j].start<=h && elements[j].end>h && (!elements[j].status || elements[j].status === 'change')){
		       		planned[i]++;
		      	}
		   	}
		}

	  	for (let i = startHour; i < 24; i += 1) {
		    v.set(i, `${planned[i]}`);
	  	}
	  	return v;
	}

	static buildNeededCells (date, element, schedule) {
		const startHour = parseInt(date.format("H"));
		const v = new Map();
	  	const needed =  element.schedule.needed;
	  	for (let i = startHour; i < 24; i += 1) {
		    let  start = moment(date.clone().startOf('day')).add(i, 'hours');
		    let  end = moment(date.clone().startOf('day')).add(i+1, 'hours');
		    let planned = 0;
		    const operatorsList = schedule.filter( s => element.operators.indexOf(s.operator)>=0);
		    for (var j = 0; j < operatorsList.length; j++) {
		    	const value = operatorsList[j];
			    if(value.start<=start && value.end>=end && (!value.status || value.status === 'change')){
			       planned++;
			    }
		    }
		    let backgroundColor = "#ffebee";
			let color = "#ff0202"
		    if(needed[i]) {
		      if(planned/needed[i]>=0.8){
		        backgroundColor = "#e8f5e9";
				color = "#8bc34a"
		      }else if(planned/needed[i]<0.8 && planned/needed[i]>=0.5){
		        backgroundColor = "#fff8e1";
		        color = "#ff9800";

		      }
		    }
		    
		    v.set(i, {
		      label: needed[i] ? `${planned}/${needed[i]}` : '-',
		      operators: element.operators,
		      queues: element.queues,
		      style: {
		        backgroundColor: needed[i] ? backgroundColor : "#ffffff",
		        color: needed[i] ? color: "#ffffff",
		        cursor: 'pointer'
		      },
		    })
	  	};
	  	return v;
	}

	static rowLayers (groups, startDate, endDate ) {
		let rows = [];
		for (let i = 0; i < groups.length; i += 1) {
              let curDate = startDate.clone();
              while (curDate.isSameOrBefore(endDate)) {
                const dayOfWeek = Number(curDate.format('d')); // 0 -> 6: Sun -> Sat
                let color = '';
                if (dayOfWeek % 6 === 0) {
                  color = '#e6f7fe';
                } else {
                  color = '#fffdec';
                }

                rows.push({
                  start: curDate.clone(),
                  end: curDate.clone().add(1,'day'),
                  style: {backgroundColor: color},
                  rowNumber: i
                });
                curDate.add(1,'day');
            }
        }
        return rows;
	}

	static  options () {
		return {
				places: [ {id: 'minsk', name: 'Минск'} ],
				scheduleChange: [{ name: "Изменение", id: "change"},
					             { name: "Отмена", id: "cencel"},
					             { name: "Больничный", id: "ill"},
					             { name: "Прогул", id: "absenteeism"}],
		        depatments: [{id: 'Inbound', name: 'Входящие'},
		                     {id: 'Outbound', name: 'Исходящие'},
		                     {id: 'Finance', name: 'Финансы'}],
				scheduleType: [
					{name: "Дневная 1", id: "day1"},
					{name: "Дневная 2", id: "day2"},
					{name: "2/2", id: "shift"},
					{name: "Вечерняя", id: "evening"},
					{name: "Ночная", id: "nigth"}
				]
			}
	}

}