Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.rowItemsRenderer = rowItemsRenderer;
exports.rowLayerRenderer = rowLayerRenderer;
exports.getNearestRowHeight = getNearestRowHeight;
exports.getMaxOverlappingItems = getMaxOverlappingItems;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Render all items in a row
 * @external {moment} http://momentjs.com/
 * @param  {Object[]} items List of items to render for this row
 * @param  {moment} vis_start The visible start of the timeline
 * @param  {moment} vis_end The visible end of the timeline
 * @param  {number} total_width pixel width of the timeline
 */
function rowItemsRenderer(items, vis_start, vis_end, total_width, itemHeight, itemRenderer) {
  var selectedItems = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : [];

  var start_end_min = vis_end.diff(vis_start, 'minutes');
  var pixels_per_min = total_width / start_end_min;
  var filtered_items = _lodash2.default.sortBy(_lodash2.default.filter(items, function (i) {
    // if end not before window && start not after window
    return !i.end.isBefore(vis_start) && !i.start.isAfter(vis_end);
  }), function (i) {
    return -i.start.unix();
  }); // sorted in reverse order as we iterate over the array backwards
  var displayItems = [];
  var rowOffset = 0;
  while (filtered_items.length > 0) {
    var lastEnd = null;
    for (var i = filtered_items.length - 1; i >= 0; i--) {
      if (lastEnd === null || filtered_items[i].start >= lastEnd) {
        var item = _lodash2.default.clone(filtered_items[i]);
        item.rowOffset = rowOffset;
        displayItems.push(item);
        filtered_items.splice(i, 1);
        lastEnd = item.end;
      }
    }
    rowOffset++;
  }
  return _lodash2.default.map(displayItems, function (i) {
    var color = i.color;

    var Comp = itemRenderer;
    var top = itemHeight * i['rowOffset'];
    var item_offset_mins = i.start.diff(vis_start, 'minutes');
    var item_duration_mins = i.end.diff(i.start, 'minutes');
    var left = Math.round(item_offset_mins * pixels_per_min);
    var width = Math.round(item_duration_mins * pixels_per_min)-1;
    var compClassnames = 'rct9k-items-inner';
    var outerClassnames = 'rct9k-items-outer item_draggable';
    var style = { backgroundColor: color };
    var isSelected = selectedItems.indexOf(Number(i.key)) > -1;

    if (isSelected) {
      compClassnames += ' rct9k-items-selected';
      outerClassnames += ' rct9k-items-outer-selected';
      style = {};
    }

    return _react2.default.createElement(
      'span',
      {
        key: i.key,
        'data-item-index': i.key,
        className: outerClassnames,
        style: { left: left, width: width, top: top, backgroundColor: 'transparent' } },
      _react2.default.createElement(Comp, { key: i.key, item: i, className: compClassnames, style: style })
    );
  });
}

/**
 * Render row layers
 * @param  {Object[]} layers List of layers to render for this row
 * @param  {moment} vis_start The visible start of the timeline
 * @param  {moment} vis_end The visible end of the timeline
 * @param  {number} total_width pixel width of the timeline
 * @param  {number} itemHeight The layer height in px
 */
function rowLayerRenderer(layers, vis_start, vis_end, total_width, itemHeight) {
    var start_end_min = vis_end.diff(vis_start, 'minutes');
    var pixels_per_min = total_width / start_end_min;
    var filtered_items = _lodash2.default.sortBy(_lodash2.default.filter(layers, function (i) {
        return !i.end.isBefore(vis_start) && !i.start.isAfter(vis_end);
    }), function (i) {
        return -i.start.unix();
    }); // sorted in reverse order as we iterate over the array backwards
    var displayItems = [];
    var rowOffset = 0;
    while (filtered_items.length > 0) {
        var lastEnd = null;
        for (var i = filtered_items.length - 1; i >= 0; i--) {
        if (lastEnd === null || filtered_items[i].start >= lastEnd) {
            var item = _lodash2.default.clone(filtered_items[i]);
            item.rowOffset = rowOffset;
            displayItems.push(item);
            filtered_items.splice(i, 1);
            lastEnd = item.end;
        }
        }
        rowOffset++;
    }
    return _lodash2.default.map(displayItems, function (i) {
        var style = i.style,
            rowNumber = i.rowNumber;

        var top = itemHeight * i['rowOffset'];
        var item_offset_mins = i.start.diff(vis_start, 'minutes');
        var item_duration_mins = i.end.diff(i.start, 'minutes');
        var left = Math.round(item_offset_mins * pixels_per_min);
        var width = Math.round(item_duration_mins * pixels_per_min);
        var height = itemHeight - (rowNumber === 0 ? 2 : 1); // for border
        var outerClassnames = 'rct9k-row-layer';
        return _react2.default.createElement('div', {
          key: 'r-' + rowNumber + '-' + i.start.unix(),
          'data-item-index': i.key,
          className: outerClassnames,
          style: _extends({}, style, { left: left, width: width-1, top: top, height: `${height}px` })
        });
    });
}
/**
 * Gets the row number for a given x and y pixel location
 * @param  {number} x The x coordinate of the pixel location
 * @param  {number} y The y coordinate of the pixel location
 * @param  {Object} topDiv Div to search under
 * @returns {number} The row number
 */
function getNearestRowHeight(x, y) {
  var topDiv = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : document;

  var elementsAtPixel = document.elementsFromPoint(x, y);
  var targetRow = _lodash2.default.find(elementsAtPixel, function (e) {
    var inDiv = topDiv.contains(e);
    return inDiv && e.hasAttribute('data-row-index');
  });
  return targetRow ? targetRow.getAttribute('data-row-index') : 0;
}

/**
 * Use to find the height of a row, given a set of items
 * @param  {Object[]} items List of items
 * @returns {number} Max row height
 */
function getMaxOverlappingItems(items) {
  var max = 0;
  var sorted_items = _lodash2.default.sortBy(items, function (i) {
    return -i.start.unix();
  });

  while (sorted_items.length > 0) {
    var lastEnd = null;
    for (var i = sorted_items.length - 1; i >= 0; i--) {
      if (lastEnd === null || sorted_items[i].start >= lastEnd) {
        lastEnd = sorted_items[i].end;
        sorted_items.splice(i, 1);
      }
    }
    max++;
  }
  return Math.max(max, 1);
}