import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@mui/styles';

import { CardElement } from '../_Components';

const styles = {
    main: {
        flex: '1',
        border: "1px solid rgba(24,28,33,.06)",
        borderRadius: '.25rem'
    },
    body: {
        fontSize: 20,
        opacity: 1,
        paddingLeft: 8
    },
    lineF: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        fontSize: 17,
        display: 'inline-flex',
        width: '100%'
    },
    value: {
        textAlign: 'left',
        width: '50%'
    },
    icon: {
        textAlign: 'right',
        width: '50%'
    },
    lineS: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        width: '100%',
        fontSize: '0.75rem'
    }
};

const Header = ({ value, secondValue, color, label, style, classes, icon }) =>{
    return (
            <div className={classes.main}>
                <CardElement
                    style={{paddingLeft: 4, paddingRight: 4, ...style}}
                    content={
                        <div className={classes.body} >
                            <div className={classes.lineF}>
                                <div className={classes.value}>{value}</div>
                                <div className={classes.icon} style={{color: color}}>{icon}</div>
                            </div>
                            <div className={classes.lineS}><span className={classes.value}>{label}</span> <b className={classes.icon}>{secondValue}</b></div>
                        </div>
                    }
                />
            </div>
    );
} 

const enhance = compose(
    withStyles(styles)
);

export default enhance(Header);
