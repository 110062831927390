import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';
import { Filter, TextInput, SelectInput } from '../_Components';

const ProjectFilter = ({ filterDefaultValues = {},  ...props }) => (  
    <Filter filterValues={filterDefaultValues} {...props}>
        <TextInput 
            source="name"
            label={`Название`}
            alwaysOn
        />
        <SelectInput 
            source="status"
            label={`Статус`}
            options={[{id: 'Active', name: 'Активные'},{id: 'Disabled', name: 'Заблокированные'},]}
            alwaysOn
        />
    </Filter>
);



const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('projectsListForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
)(ProjectFilter);