import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TextField, Autocomplete } from '@mui/material';


const AutocompleteInput = ({ input, meta: { error, touched } = {}, isLoading, options = [], dispatch, ...props }) => {
    const onChange = (event, value) => {
        input.onChange(value ? value.id : null)
    }
    options.map(o => o.key=o.id);
    return(
        <Autocomplete
            getOptionLabel={
                option => {
                    return  option ? option.name : ""
                }
            }
            loading={isLoading}
            loadingText="Загрузка"
            renderInput={params =>{
                return (
                    <TextField 
                        {...params} 
                        label={props.label}
                        error={!!(touched && error)}
                        helperText={touched && error}
                        fullWidth 
                        disabled={isLoading || options.length===0}
                        style={{marginTop: 0, minWidth: 250, paddingBottom: 0}}
                        variant="standard"
                        focused
                    />
                )
            }}
            onChange={onChange}
            options={options}
            sx={{ fontSize: '14px', paddingTop: '4px' }}
            {...props}
        />
          
    )
}
  

const mapStateToProps = (state, props) =>{
    const { fetch } = state;
    return {
        isLoading: !!fetch.loading
    }
  }
  
  export default compose(
    connect(mapStateToProps),
  )(AutocompleteInput);