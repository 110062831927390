import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Switch, FormControlLabel } from '@mui/material';

const SwitchInput = ({input, isLoading, disabled, ...props}) => (
  <FormControlLabel
    value="top"
    control={
      <Switch checked={!!input.value}
        onChange={(event, value) => {
            return input.onChange(value)
        }}
        value={input.value}
        color="primary"
        disabled={isLoading || disabled}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    }
    label={props.label}
    labelPlacement="top"
  />
 
)


const mapStateToProps = (state, props) =>{
  const { fetch } = state;
  return {
      isLoading: !!fetch.loading
  }
}

export default compose(
  connect(mapStateToProps),
)(SwitchInput);