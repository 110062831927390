import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';

import { InputLabel, Select , LinearProgress} from '@mui/material';

const style = {
  option: {
    height: 'auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '4px 0 4px'
  }
};

const MultipleInput = ({classes, input, ...props }) => {
  const onChange = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    input.onChange(value ? value : [])
  }
  return(
    <Fragment>
      {props.options 
      ?
        <div {...props}>
          <InputLabel shrink htmlFor="select-multiple-native">
            {props.label}
          </InputLabel>
          <Select
            multiple
            native
            value={input.value}
            inputProps={{
              id: 'select-multiple-native',
            }}
            onChange={onChange}
            style={{height: 180}}
          >
            {props.options.sort( (a,b) => {
                var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
                if (nameA < nameB)
                  return -1;
                if (nameA > nameB)
                  return 1;
                return 0;
              }).map((item, key)=>(
                <option className={classes.option} key={key} value={item.id}>
                  {item.name}
                </option>
            ))}
          </Select>
        </div>
        
      :
        <LinearProgress {...props} style={{ marginTop: 24 }}/>
      }
    </Fragment>
  )

}

export default withStyles(style)(MultipleInput);
