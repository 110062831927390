import React from 'react';
import { withStyles } from '@mui/styles';

const style = {
    row:{
        display: 'flex',
        paddingBottom: 16
    }
};

const Row = ({classes, children}) => (
    <div className={classes.row}>
        {Array.isArray(children) 
            ?
                children.map( (child, i) => React.cloneElement(child, {key: i}))
            :
                React.cloneElement(children)
        }
    </div>
)

export default withStyles(style)(Row);