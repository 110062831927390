import React, { useState } from 'react';
import FileSaver from 'file-saver';
import { Field } from 'redux-form';

import { TextInput, Modal } from '../../_Components';
import { authHeader, notify } from '../../_utils';

const ExportModal = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const save = async (formValues) => {
        setIsSaving(true);
        const { handleCloseClick } = props;
        const response = await fetch('/api/client/export', {
            credentials: 'same-origin',
            method: 'post',
            responseType: 'arraybuffer',
            headers: {...authHeader(), 'Content-Type': 'application/json'},
            body: JSON.stringify({...formValues })
        })
        setIsSaving(false);
        if(response.ok){
            response.arrayBuffer().then(function(buffer) {
                var blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                FileSaver.saveAs(blob, `${formValues.fileName}.xlsx`);
                handleCloseClick();
            });
        }else{
            notify({message: "Ошибка при экспорте отчета", type: 'error'});
        }
    }


    const handleCloseClick = () => {
       props.handleCloseClick();
    }

    return(
        <Modal
            title={`Экспорт отчета`}
            open={props.showDialog} 
            handleSaveClick={save} 
            handleCloseClick={handleCloseClick}
            initial={props.initialValues}
            isSaving={isSaving}
            fields={
                <Field
                    name="fileName"
                    label={`Название файла`}
                    disabled={isSaving}
                    component={TextInput}
                    style={{width: '100%'}}
                />
            } 
        />
    )
}
export default ExportModal;