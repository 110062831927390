
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';

import Skeleton from '@mui/material/Skeleton';
import {
    List,
    ListItem,
    ListItemText,
    Grid,
    Paper
} from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { Labeled } from '..';

const style = {
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    icon: {
        top: 133,
        position: 'relative',
        padding: 5
    },
    main: {
        width: '100%'
    },
    primary: {
        fontSize: '1em !important',
    },
    item: {
        paddingBottom: '0 !important',
        paddingTop: '0 !important',
        borderBottom: '1px dotted #b2b2b2'
    }
};

const Item = ({classes, value, labelId}) => (
    <ListItemText 
        classes={{
            primary: classes.primary,
        }}
        id={labelId} 
        primary={`${value.label || value.value}`} 
        style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '1em'}} 
    />
)
const ListLeft = ({classes, items, handleToggle}) =>{
    return(
        <Paper className={classes.paper} style={{width: '100%'}}>
            <List dense component="div" role="list">
                {items.map(value => {
                    const labelId = `transfer-list-item-${value.value}-label`;
                    return (
                        <ListItem key={value.value} role="listitem" onClick={handleToggle(value)} classes={{root: classes.item}}>
                            <Item classes={classes} value={value} labelId={labelId}/>
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Paper>
    )
};

const ListRight = ({classes, items, handleToggle}) =>{
    return(
        <Paper className={classes.paper} style={{width: '100%'}}>
            <List dense component="div" role="list">
                {items.map(value => {
                    const labelId = `transfer-list-item-${value.value}-label`;
                        return (
                            <ListItem key={value.value} role="listitem" onClick={handleToggle(value)} classes={{root: classes.item}}>
                              <Item classes={classes} value={value} labelId={labelId}/>
                            </ListItem>
                        )
                })}
                <ListItem />
            </List>
        </Paper>
    )
};

const FieldsTrasferInput = ({classes, input, avalible}) => {
    const [left, setLeft] = useState(avalible.filter( el => !input.value.find( r => r.value===el.value)));
    const [right, setRight] = useState(input.value);

    const not = (a, b) => {
        return a.filter(value => b.value!==value.value);
    }

    const handleToggle = value => () => {
        const leftIndex = left.find( l => l.value===value.value);
        let newRight = [];
        let newLeft = [];
        if(leftIndex){
            newRight = right.concat([value]);
            newLeft = not(left, value);
        }else{
            newLeft = left.concat([value]);
            newRight = not(right, value);
        }
        input.onChange(newRight);
        setLeft(newLeft);
        setRight(newRight);
        return;
    };

    return(
        <div className={classes.main}>
            {(left && right) ?
                <div style={{display: 'flex'}}>
                    <Labeled label="Доступные" style={{flex: '40em'}}>
                        <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                            <Grid item style={{width: '100%'}}>
                                <ListLeft items={left} classes={classes} handleToggle={handleToggle}/>
                            </Grid>
                        </Grid>
                    </Labeled>
                    <SyncAltIcon className={classes.icon}/>
                    <Labeled label="Выбранные"  style={{flex: '40em'}}>
                        <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                            <Grid item style={{width: '100%'}}>
                                <ListRight items={right} fields={avalible} classes={classes} handleToggle={handleToggle}/>
                            </Grid>
                        </Grid>
                    </Labeled>
                </div>
            : 
                <div style={{display: 'flex'}}>
                    <div style={{padding: '16px 0px 0px 0px', width: '100%'}}>
                        <Skeleton variant="text" />
                        <Skeleton variant="rect" height={242} style={{padding: '0 20px'}}/>
                    </div>
                    <SyncAltIcon className={classes.icon}/>
                    <div style={{padding: '16px 0px 0px 0px', width: '100%'}}>
                        <Skeleton variant="text" />
                        <Skeleton variant="rect" height={242} style={{padding: '0 20px'}}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default withStyles(style)(FieldsTrasferInput);