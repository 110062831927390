import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { AppBar, Menu, Main } from './_Components';

import { history } from './_utils';

class App extends Component {
    state = {
        done: false,
        selectedMenu: {path: 'dashboard'},
        title: '',
        url: window.location.href,
        menuOpen: false
    }

    componentDidMount(){
        if(!this.props.token){
            history.push('/login')
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(!this.props.token){
            history.push('/login')
        }
    }

    onOpen = () => {
        const  { menuOpen } = this.state;
        this.setState( { menuOpen: !menuOpen });
    }

    render(){
        const { title, menuOpen } = this.state;
        return (
            <Fragment>
                <AppBar title={title} onOpen={this.onOpen} />
                <Menu open={menuOpen} onSelect={this.onOpen}/>
                <Main />
            </Fragment>
        );
    }
}

const tokenToProps = () => {
    let token = localStorage.getItem('token');
    let permission = localStorage.getItem('permission');
    return { token, permission };
}

export default compose(
    connect(null, tokenToProps),
)(App);