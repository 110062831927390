import React, { Fragment, useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Button } from '@mui/material';

import { UPDATE, GET_ONE, GET_LIST, provider, history } from '../_utils';

import { TextInput, SelectInput, Row, CardElement, BlockButton, SaveButton, CloseButton } from '../_Components'


const UserEdit = ({id, initialize, initialized, formValues}) => {
    const [roles, setRoles] = useState([]);
    const getRoles = async () => {
        const { data, status } = await provider( 
            GET_LIST,
            '/roles', 
            {
                filter: { },
                sort: { field: 'name', order: "ASC" },
                pagination: { page:1, perPage: 999 },
            }
        );
        if(status === 200){
            const rolesArray = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                rolesArray.push({id: element._id, name: element.label})
            }
            setRoles(rolesArray);
        }
    };
    

    useEffect(() => {
        const fetchData = async () => {
            const {data, status} = await provider( GET_ONE, '/users', id);
            if(status === 200){
                initialize(data);
                getRoles();
            }
        }

        if(!initialized){
            fetchData();
        }

    },[id, initialize, initialized]);

    const block = async () => {
        formValues.status = formValues.status ==='Active' ? "Disabled" : "Active";
        const { status } = await provider(
            UPDATE,
            '/users',
            {  
                id,
                data: formValues
            }
        );
        if(status===200){
            history.goBack()
        }
    }

    const close = () => {
        history.push('/users');
    }

    const save = async () => {
        const { status } = await provider(
            UPDATE,
            '/users',
            {  
                id,
                data: formValues
            }
        );
        if(status===200){
            history.goBack()
        }
    }

    const newToken = async () => {
        var buf = new Buffer(16);
        for (var i = 0; i < buf.length; i++) {
            buf[i] = Math.floor(Math.random() * 256);
        }
        
        formValues.token = buf.toString('hex');
        await provider(UPDATE,
            '/users',
            {  
                id: id,
                data: formValues
            }
        );
    }

    return(
        <CardElement
            style={{paddingLeft: 16, paddingTop: 16}}
            action={
                <Fragment>
                    <BlockButton handleBlockClick={block} status={formValues&&formValues.status}/>
                    <SaveButton handleSaveClick={save} />
                    <CloseButton handleCloseClick={close}/>
                </Fragment>
            }
            content={
                <Fragment>
                    <Row>
                        <Field
                            name="last_name"
                            label={`Фамилия`}
                            component={TextInput}
                        />
                        <Field
                            name="first_name"
                            label={`Имя`}
                            component={TextInput}
                        />
                        
                    </Row>
                    <Row>
                        <Field
                            name="email"
                            label={`E-mail`}
                            component={TextInput}
                        />
                        <Field
                            name="role"
                            label={`Роль`}
                            options={roles}
                            component={SelectInput}
                        />
                    </Row>
                    <Button variant="contained" color="primary" onClick={newToken}>Новый токен</Button>
                    <Row>
                        <Field
                            name="token"
                            label={`Токен`}
                            disabled={true}
                            component={TextInput}
                            style={{width: 450}}
                        />
                    </Row>
                </Fragment>
            }
        />
    )

}

const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('userForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'userForm',
        // enableReinitialize: true,
        destroyOnUnmount: true,
        validate: (values) => {
            const errors = {
                name: {}
            };
            if (!values.name) {
                errors.name = {
                    first: 'Обазятельное поле',
                    last: 'Обазятельное поле'
                }
            }
            if(values.name){
                if (!values.name.first) {
                    errors.name.first = 'Обазятельное поле'
                } 
                if (!values.name.last) {
                    errors.name.last = 'Обазятельное поле'
                } 
            }
            if (!values.email) {
                errors.email = 'Обазятельное поле'
            } 
            if (!values.role) {
                errors.role = 'Обазятельное поле'
            } 
            return errors;
        },
    })
)(UserEdit);