import React from 'react';
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from "downshift";


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { Labeled } from '../../_Components';

import {Clear, Close } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const style = theme =>({
    list: {
        overflowY: 'auto',
        paddingTop: 0,
        paddingBottom: 0
    },
    listItem: {
        padding:'0px 0px 0px 8px !important',
        borderBottom: '1px #bfbdbd solid'
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0
    },
    listItemTextPrymary: {
        fontSize: '0.75rem !important'
    },
    error: {
        fontSize: '0.75rem !important',
        color: 'red'
    },
    iconInbounds: {
        width: '0.75rem !important',
        height: '0.75rem !important',
        float: 'right',
        color: '#03a9f4',
        marginLeft: 8,
    },
    iconOutbounds: {
        width: '0.75rem !important',
        height: '0.75rem !important',
        float: 'right',
        color: '#ff9800',
        marginLeft: 8,
    },
    root: {
      flexGrow: 1,
    },
    container: {
      // flexGrow: 1,
      // position: 'absolute',
      // width: '25%'
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      maxHeight: theme.spacing(38),
      overflowY: 'auto'
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
    gutters: {
      padding: '0px 5px !important'
    },
    multiline:{
      marginTop: 0,
      marginBottom: 0
    },
    primary: {
      fontSize: '1em !important',
      lineHeight: '0.75 !important',
    },
    secondary: {
      fontSize: '0.75rem',
      lineHeight: 0.5,
      color: '#0a529f'
    },
    secondaryError: {
      fontSize: '0.75rem',
      lineHeight: 0.5,
      color: 'red',
      paddingTop: 8
    },
    iconTrash: {
      width: '0.75rem !important',
      height: '0.75rem !important',
      float: 'right',
      color: 'red',
      marginLeft: 8,
      cursor: 'pointer'
    },
    iconAdd: {
        width: '0.75rem !important',
        height: '0.75rem !important',
        float: 'right',
        color: '#03a9f4',
        marginLeft: 8,
    },
});

const renderInput = (inputProps, submitProps) => {
    const { InputProps, classes, ref, ...other } = inputProps;
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          if(!submitProps.error.used && !submitProps.error.errorMessage){
            submitProps.onAdd(submitProps.selectedItem, submitProps.clearSelection);
          }
        }
    }
    return (
      <TextField
        style={{width: '75%'}}
        onKeyPress={handleKeyPress}
        fullWidth
        focused
        variant="standard"
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
}
  
const checkError = (queues, suggestion ) => {
    if(!suggestion){
      return {}
    }
    const used = queues.inbounds.indexOf(suggestion._id)>=0;
    let errorMessage = null;
    if(used){
      errorMessage =  'Очередь добавлена';
    }
    return { used, errorMessage }
}
  
const renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem, queues, classes }) => {
    const isHighlighted = highlightedIndex === index;
    const label = selectedItem ? `${selectedItem.code} ${selectedItem.name}` : null;
    const isSelected = (label || '').indexOf(suggestion.label) > -1;
    const {used, errorMessage } = checkError(queues, suggestion);
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        disabled={used||!!errorMessage}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 400 : 200,
          fontSize: '1em',
          lineHeight: 0.75
        }}
      >
        <ListItem 
          button
          classes={{
            gutters: classes.gutters,
          }}
          disabled={used||!!errorMessage}
        >
            <ListItemText 
              classes={{
                multiline: classes.multiline,
                primary: classes.primary,
                secondary: !!errorMessage ? classes.secondaryError : classes.secondary
              }}
              primary={`${suggestion.name}`}  
              secondary={errorMessage} 
            />
         </ListItem>
      </MenuItem>
    );
  }
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};
  
const getSuggestions = (value, suggestions) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    const result = inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          if(!suggestion.name){
            suggestion.name = '-';
          }
          try {
              const name = suggestion.name.toLowerCase().trim().search(inputValue)>=0;
              const keep = count < 20 && ( name ) ;
              if (keep) {
                count += 1;
              }
              return keep;
          }
          catch(e){
            return false;
          }
        });
    return result;
}
  
const QuickSuggestionsField = (props) => {
    const { classes, queues, onAdd, queuesList } = props;
    return (
      <div className={classes.root}>
        < Downshift
          itemToString={item => (item ? `${item.name}` : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
            clearSelection,
          }) =>{
            const {used, errorMessage } = checkError(queues, selectedItem);
            return (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: false,
                  classes,
                  InputProps: getInputProps({
                    placeholder: 'Добавить очередь',
                  }),
                },{
                    onAdd: onAdd,
                    clearSelection: clearSelection,
                    selectedItem: selectedItem,
                    error: {
                      used, errorMessage
                    }
                })}
                <IconButton 
                  size="small" 
                  disabled={!selectedItem}  
                  onClick={clearSelection} 
                  aria-label="Очистить"
                >
                  <Clear size="small" className={classes.iconTrash}/>
                </IconButton>
                <IconButton 
                  size='small' 
                  
                  // style={{color: (selectedItem&&!!!used&&!!!errorMessage )&&'#0a529f'}} 
                  disabled={ !selectedItem  } //|| !!used || !!errorMessage
                  onClick={()=> onAdd(selectedItem, clearSelection)} 
                  aria-label="Добавить очередь"
                >
                    <ArrowForwardIcon fontSize="small" className={classes.iconAdd}/>
                </IconButton>
                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions(inputValue, queuesList, selectedItem,).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          queues,
                          itemProps: getItemProps({ item: suggestion}),
                          highlightedIndex,
                          selectedItem,
                          classes
                        }),
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            )}
          } 
        </Downshift>
        <div className={classes.divider} />
      </div>
    );
  }
  
QuickSuggestionsField.propTypes = {
    classes: PropTypes.object.isRequired,
};

  
const QueuesInput =
    ({
      classes,
      input: { value, onChange },
      meta: { touched, error },
      report,
      queuesList,
      queuesState,
      onQueueDelete,
      onQueueAdd,
      style
    }) => (
    <Labeled label="Очереди" style={{flex: '40em', ...style}}>
        <QuickSuggestionsField classes={classes} report={report} queues={value} queuesList={queuesList} onAdd={onQueueAdd}/>
        <List
            className={classes.list}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {queuesState.inbounds.map( (q, index) =>{
                let queue = queuesList.find( o => o.id === q);
                let text = '-';
                if(queue){
                    text = `${queue.name}`
                }
                return <ListItem classes={{ root: classes.listItem}} key={index}>
                            <ListItemIcon>
                                <CallReceivedIcon className={classes.iconInbounds}/>
                            </ListItemIcon>
                            <ListItemText 
                                className={classes.listItemText} 
                                classes={{
                                    multiline: classes.multiline,
                                    primary: classes.listItemTextPrymary
                                    }}
                                primary={text} 
                            />
                            <ListItemSecondaryAction>
                                <Close className={classes.iconTrash} onClick={()=>onQueueDelete(q)}/>
                            </ListItemSecondaryAction>
                        </ListItem>
            })}
            {queuesState.outbounds.map( (q, index) =>{
                let queue = queuesList.find( o => o.id === q);
                let text = '-';
                if(queue){
                    text = `${queue.name}`
                }
                return <ListItem classes={{ root: classes.listItem}} key={index}>
                            <ListItemIcon>
                                <CallMadeIcon className={classes.iconOutbounds}/>
                            </ListItemIcon>
                            <ListItemText 
                                className={classes.listItemText} 
                                classes={{
                                    multiline: classes.multiline,
                                    primary: classes.listItemTextPrymary
                                }}
                                primary={text} 
                            />
                            <ListItemSecondaryAction>
                                <Close className={classes.iconTrash} onClick={()=>onQueueDelete(q)}/>
                            </ListItemSecondaryAction>
                        </ListItem>
            })}
        
        </List>
    </Labeled>
)

export default compose(
    withStyles(style),
    withState("queuesState", "setQueuesState", {inbounds: [], outbounds: []}),
    withPropsOnChange(
        ['input'],
        ({ input: { value }, meta: { dirty }, setQueuesState }) => {
            if (dirty) {
                return;
            }
            if (!value) {
                return;
            }
            setQueuesState(value);
        }
    ),
    withHandlers({
        onQueueDelete: ({ input: { value, onChange }, setQueuesState, ...props }) => (queue) => {
            const { inbounds, outbounds } = value;
            const indexInbounds = inbounds.indexOf(queue);
            const indexOutbounds = outbounds.indexOf(queue);
            if(indexInbounds>=0){
                value.inbounds.splice(indexInbounds, 1);
            }
            if(indexOutbounds>=0){
                value.outbounds.splice(indexOutbounds, 1);
            }
            setQueuesState(value);
            onChange(value);
        },
        onQueueAdd: ({ input: { value, onChange }, setQueuesState, ...props }) => (queue, clearSelection) => {
            if(queue.type==='Inbound'){
                value.inbounds.unshift(queue.id);
            }
            if(queue.type==='Outbound'){
                value.outbounds.unshift(queue.id);
            }
            setQueuesState(value);
            onChange(value);
            clearSelection();
        },
    })
)(QueuesInput);