import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import LinearProgress from '@mui/material/LinearProgress';

// import Utils from '../utils';

const styles = theme => ({
    root: {
        borderBottom: '1px rgba(0, 0, 0, 0.12) solid !important',
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 5,
        paddingRight: 5,
        height: 35,
    },
    main: {
        verticalAlign: 'top',
        boxShadow: 'unset',
        overflow: 'auto',
        flexGrow: 1,
        flexBasis: '25%',
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
    },
    cardTitle: {
        color: '#ffff;',
        fontSize: '0.8rem;',
        fontWeight: 600
    },
    cardHeader: {
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
        backgroundColor: '#f29804 !important'
    },
    cardContent: {
        flex: 0,
        paddingLeft: theme.spacing(2)
    },
    cardAction: {
        flex: '1 1 auto',
        marginTop: 0
    },
    cardIcon: {
        color: '#ffff !important',
        width: '34px !important', 
        height: '34px !important',
        padding: '0px !important',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        paddingRight: 0,
    },
    actionSyle: {
        zIndex: 2,
        float: 'right',
        padding: 0
    },
    firstLine: {
        margin: 0,
        display: 'block',
        height: 17,
        fontSize: 11,
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: 1.5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    secondLine: {
        fontSize: '0.6rem',
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.46429em',
        margin: 0,
        display: 'block',
        height: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
    
});

const Outbound = ({ items = {}, statistics = [], paddingTop = 0, classes }) => (
    <div className={classes.main} style={{paddingTop}}>
        <Card className={classes.card}>
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                    root: classes.cardHeader,
                    content: classes.cardContent,
                    action: classes.cardAction
                }}
                title="Исходящие"
            />
            {items&&items.outbounds ? 
                <List style={{paddingTop: 0, paddingBottom: 0}}>
                    {items.outbounds.map(record =>{
                        let backgroundColor = 'none';
                        let color = '';
                        if(record.sessions.connected > 0){
                            backgroundColor = '#dff0d8';
                            color = "#3c763d"
                        }else if(record.sessions.dialing>0){
                            backgroundColor = '#fffae1';
                            color = "#8a6d3b"
                        // }else if(record.stateDuration>60 && record.state.value==='Ready'){
                        //     backgroundColor = '#fffae1';
                        //     color = "#8a6d3b"
                        // }else{
                        //     backgroundColor="unset";
                        }
                        // console.log(statistics);
                        // const statistic = statistics.find( s => s.id === record.id);
                        return (
                            <ListItem
                                button
                                className={classes.root}
                                key={record.id}
                                style={{ backgroundColor: backgroundColor}}
                            >   
                                <div className={classes.listItemText} style={{width: '100%'}}>
                                    <span className={classes.firstLine}>
                                        <span className={classes.listItemText} style={{width: '65%', display: 'inline-flex'}}>{record.name}</span>
                                        <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                            {record.online}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                            {record.available}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '5%', display: 'inline-flex', color: color}}>
                                            {record.sessions.connected}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '7%', display: 'inline-flex', color: color}}>
                                            {record.sessions.dialing}
                                        </span>
                                        <span className={classes.listItemText} style={{width: '10%', display: 'inline-flex', color: color}}>
                                            {record.sessions.requests}
                                        </span>
                                    </span>
                                    <p className={classes.secondLine}>
                                        {/* { statistic ? `Всего: ${statistic.count} Время: ${Utils.secondsToTime(statistic.talktime)}` :  ``} */}
                                    </p>
                                </div>
                            </ListItem>
                        )
                    } )}
                </List>
                :
                 <LinearProgress />
            }
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles)
);

export default enhance(Outbound);
