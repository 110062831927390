import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TextField, MenuItem } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const MultiSelectInput = ({ dispatch, isLoading,input, meta: { touched, error } = {}, ...props } ) => (
  <Fragment>
    {!isLoading
      ?
        <TextField
          {...input}
          error={!!(touched && error)}
          helperText={touched && error}
          {...props}
          select
          variant="standard"
          focused
          options={props.options}
          SelectProps={{
            multiple: true,
            // value: formState.userRoles,
            // onChange: this.handleFieldChange
          }}
        >
            {(props.options)
              ? 
              props.options.map((item, key)=>(
                <MenuItem disabled={item.disabled} key={key} value={item.id ? item.id : item._id}>{item.name}</MenuItem>
              ))
              :
              [].map((item, key)=>(
                <MenuItem disabled={item.disabled} key={key} value={item.id ? item.id : item._id}>{item.name}</MenuItem>
              ))
            }
        </TextField>
      :
        <Skeleton
          style={{
            height: 40
          }}
          {...props}
          variant="text" 
          animation='wave'
        />
    } 
  </Fragment>
);

const mapStateToProps = (state, props) =>{
  const { fetch } = state;
  return {
      isLoading: !!fetch.loading
  }
}

export default compose(
  connect(mapStateToProps),
)(MultiSelectInput);