import React, { Fragment, useState } from 'react';
import { compose } from 'recompose';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { connect } from 'react-redux';

import { AddButton, CardElement, Modal, Row, TextInput, SelectInput, SwitchInput, TableElement  } from '../../_Components';
import { Options } from '../../_utils';

const { FieldsTypesOptions, OperatorOptions, CallCenterOptions, ResultOptions} = Options;

const CallValue = [
    {
        id: 'value',
        name: 'Значение'
    },
    {
        id: 'label',
        name: 'Надпись'
    }
];

const TrasferValue = [
    {
        id: 'status',
        name: 'Статус'
    },
    {
        id: 'number',
        name: 'Номер'
    },

];

const header = [
    {
        label: 'Название',
        width: 40,
        value: 'label'
    },
    {
        label: 'Служебное',
        width: 10,
        value: 'service',
        options: [{
            id: false,
            name: ''
        },
        {
            id: true,
            name: 'Да'
        }]
    },
    {
        label: 'Обязательное',
        width: 15,
        value: 'require',
        options: [{
            id: false,
            name: ''
        },
        {
            id: true,
            name: 'Да'
        }]
    },
    {
        label: 'Значение',
        width: 20,
        value: 'value'
    },
    {
        label: 'Тип',
        width: 15,
        value: 'type',
        options: FieldsTypesOptions
    },
];


const Edit = ({ index, fields, ...props}) => {
    const { formValues, comboboxes } = props;
    const isForm = index>=0;
    const [type, setType] = useState(index ? formValues.fields[index].type : null);
    // const [field, setField] = useState(index&&formValues.fields[index].call ? formValues.fields[index].call.field : null);
    const fieldsArray  = fields.map( f => ({ id: f.value, name: f.label}));
    const onselect =(event, newValue, previousValue, name) => {
        setType(newValue);
    }
    // const onselectCall =(event, newValue, previousValue, name) => {
    //     setField(newValue);
    // }

    // console.log(type, field);

    return (
        <Fragment>
            <Row>
                <Field
                    name={isForm ? `fields[${index}].require` : `require`}
                    required={true}
                    component={SwitchInput}
                    label={`Обязательное`}
                />
                <Field
                    name={isForm ? `fields[${index}].service` : `service`}
                    required={true}
                    component={SwitchInput}
                    label={`Служебное`}
                />
                <Field
                    name={isForm ? `fields[${index}].deleted` : `deleted`}
                    required={true}
                    component={SwitchInput}
                    label={`Не актив.`}
                />
            </Row>
            <Row>
                <Field
                    name={isForm ? `fields[${index}].label` : `label`}
                    required={true}
                    component={TextInput}
                    label={`Надпись`}
                    style={{width: 250}}
                />
                <Field
                    name={isForm ? `fields[${index}].value` : `value`}
                    required={true}
                    component={TextInput}
                    label={`Значение`}
                    style={{width: 250}}
                />
            </Row>
            <Row>
                <Field
                    name={isForm ? `fields[${index}].type` : `type`}
                    required={true}
                    component={SelectInput}
                    options={FieldsTypesOptions}
                    label={`Тип`}
                    onChange={(event, newValue, previousValue, name)=>onselect(event, newValue, previousValue, name)}
                />
                <Fragment>
                    {type==='select'&&
                        <Field
                            name={isForm ? `fields[${index}].combobox` : `combobox`}
                            required={true}
                            component={SelectInput}
                            options={comboboxes}
                            label={`Справочник`}
                        />
                    }
                    {type==='related'&&
                        <Field
                            name={index ? `fields[${index}].related.field` : `related.field`}
                            required={true}
                            component={SelectInput}
                            options={fieldsArray}
                            label={`Поле`}
                            style={{width: 250}}
                        />
                    }
                    {type==='integration'&&
                        <span>
                            <Field
                                name={index ? `fields[${index}].api.param` : `api.param`}
                                required={true}
                                component={TextInput}
                                label={`Парметр`}
                                style={{width: 100}}
                            />
                            <Field
                                name={index ? `fields[${index}].api.method` : `api.method`}
                                required={true}
                                component={TextInput}
                                label={`Метод`}
                                style={{width: 100}}
                            />
                        </span>
                    }
                    {type==='callcenter'&&
                        <Field
                            name={index ? `fields[${index}].callcenter` : `callcenter`}
                            required={true}
                            component={SelectInput}
                            options={CallCenterOptions}
                            label={`Параметр`}
                            style={{width: 250}}
                        />
                    }
                    {type==='operator'&&
                        <Field
                            name={index ? `fields[${index}].operator` : `operator`}
                            required={true}
                            component={SelectInput}
                            options={OperatorOptions}
                            label={`Параметр`}
                            style={{width: 250}}
                        />
                    }
                    {type==='call'&&
                        <Field
                            name={index ? `fields[${index}].call.field` : `call.field`}
                            required={true}
                            component={SelectInput}
                            options={ResultOptions}
                            label={`Параметр`}
                            // onChange={(event, newValue, previousValue, name)=>onselectCall(event, newValue, previousValue, name)}
                            style={{width: 250}}
                        />
                    }
                    {type==='transfer'&&
                        <Field
                            name={index ? `fields[${index}].transfer` : `transfer`}
                            required={true}
                            component={SelectInput}
                            options={TrasferValue}
                            label={`Параметр`}
                            style={{width: 250}}
                        />
                    }
                </Fragment>
            </Row>
            {type==='related'&&
                <Row>
                    <Field
                        name={index ? `fields[${index}].related.value` : `related.value`}
                        required={true}
                        component={SelectInput}
                        options={[
                            {
                                id: 'value',
                                name: 'Значение'
                            },
                            {
                                id: 'label',
                                name: 'Надпись'
                            },
    
                        ]}
                        label={`Значение`}
                        style={{width: 250}}
                    />
                </Row>
            }
            {/* {type==='integration'&&
                <Row >
                    <Field
                        name={index ? `fields[${index}].api.field` : `api.field`}
                        required={true}
                        component={SelectInput}
                        options={fieldsArray}
                        label={`Поле`}
                        style={{width: 250}}
                    />
                </Row>
            } */}
            {type==='call'&&
                <Row>
                    <Field
                        name={index ? `fields[${index}].call.value` : `call.value`}
                        required={true}
                        component={SelectInput}
                        options={CallValue}
                        label={`Значение ${type}`}
                        style={{width: 250}}
                    />
                </Row>
            }
            {type==='integration'&&
                <Row>
                    <Field
                        name={index ? `fields[${index}].api.value` : `api.value`}
                        required={true}
                        component={SelectInput}
                        options={[
                            {
                                id: 'value',
                                name: 'Значение'
                            },
                            {
                                id: 'label',
                                name: 'Надпись'
                            },
    
                        ]}
                        label={`Значение`}
                        style={{width: 250}}
                    />
                </Row>
            }
            <b>Праметры внешней интеграции</b>
            <Row>
                <Field
                    name={index ? `fields[${index}].external.label` : `external.label`}
                    required={true}
                    component={TextInput}
                    label={`Надпись`}
                    style={{width: 250}}
                />
            </Row>
            <Row>
                <Field
                    name={index ? `fields[${index}].external.module` : `external.module`}
                    required={true}
                    component={TextInput}
                    label={`Модуль`}
                    style={{width: 250}}
                />
                <Field
                    name={index ? `fields[${index}].external.field` : `external.field`}
                    required={true}
                    component={TextInput}
                    label={`Поле`}
                    style={{width: 250}}
                />
            </Row>
        </Fragment>
    )
}

const FieldsFunc = ({formValues, change, ...props}) =>{
    const [open, setOpen] = useState(false);
    const [editField, setEditField] = useState(0);
    const { combobox, fields } = formValues;
    const comboboxes = combobox.map( el => {return { id: el._id, name: el.name} });

    const setSelected = (index) => setEditField(index);

    const openEdit = () => setOpen(true);

    const closeEdit = () => setOpen(false);

    const save = ( form ) => {
        const { fields } = formValues;
        fields.push(form);
        change(`fields`, fields);
        setEditField(fields.length-1);
        closeEdit();
    }

    return (
        <Fragment>
            <CardElement
                width={55}
                height={`calc(100vh - 240px)`}
                style={{display: 'inline-grid', paddingRight: 16}}
                action={<AddButton  handleAddClick={openEdit} label="Добавить поле"/>}
                content={
                    <TableElement 
                        header={header} 
                        rows={fields}
                        onSelect={setSelected}
                        selected={editField}
                    />
                }
            />
            {fields.map( (field, index) => (
                <Fragment key={index}>
                    {index===editField&&
                        <CardElement
                            width={40}
                            style={{display: 'inline-grid'}}
                            content={
                                <Edit index={index} fields={fields} comboboxes={comboboxes} formValues={formValues} {...props}/>
                            }
                        />
                    }
                </Fragment>
            ))}
            {open&&
                <Modal 
                    title="Добавить поле"
                    open={open} 
                    handleSaveClick={save} 
                    handleCloseClick={closeEdit} 
                    fields={<Edit comboboxes={comboboxes} fields={fields}  {...props}/>} 
                />
            }
        </Fragment>
    );

}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('project')(state);
    return { 
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'project',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
);

export default enhance(FieldsFunc);