import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { Filter, TextInput } from '../_Components';

const ScriptFilter = ({filterDefaultValues = {},  ...props}) => (  
    <Filter filterValues={filterDefaultValues} {...props}>
        <TextInput 
            source="name"
            label={`Название`}
            alwaysOn
        />
    </Filter>
);

const mapStateToProps = (state) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default  compose(
    connect(mapStateToProps),
)(ScriptFilter);