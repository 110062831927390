// import './style.css';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import React, { Fragment} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';


import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import CardComponent from '../components/Card';
import ColSettings from '../components/ColSettings';
import { CardElement } from '../../_Components';


const styles = {
    list: {
        flex: 'auto',
        height: 'calc(100vh - 240px)',
        width: '56.5%'
    },
    cardContent: {
        height: 'calc(100vh - 320px)',
        overflow: 'auto',
        position: 'relative',
        padding: '0px 8px 0px 8px'
    }
}


class CardEdit extends React.PureComponent {
    static defaultProps = {
        className: "layout",
        /*width: 700,*/
        cols: 12,
        autoSize: false,
        rowHeight: 30,
        maxRows: 4

    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            selectedIndex: 0,
            selected: null,
            oldlayout: null,
            width: 800
        };

        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.selectCol = this.selectCol.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.onDragStart = this.onDragStart.bind(this);

    }

    selectCol(index) {
        const { formValues: { cols } } = this.props;
        const i = _.findIndex(cols, {field: index});
        this.setState({selectedIndex: i});
    }
    onDragStart(layout,element){
        this.selectCol(element.i);
    }
    addItem(item) {
        const { formValues: { cols }, change } = this.props;
        cols.push({
            ...item,
            col : item.x,
            row : item.y,
            sizeX : item.w,
            sizeY: item.h,
        });
        change('cols', cols);
        this.setState({ selectedIndex: cols.length-1 });
    }

    updateItems(items) {
        const { formValues, change } = this.props;
        if(formValues.cols.length===items.length){
            const newItems = items.map( (item,i) => {
                const el = formValues.cols.find( el => el.field=== item.i);
                return {
                    ...el,
                    col : item.x,
                    row : item.y,
                    sizeX : item.w,
                    sizeY: item.h,
                };
            });
            change('cols', newItems);
        }
    }

    removeItem(i) {
        const { formValues: { cols }, change } = this.props;
        cols.splice(i,1);
        change('cols', cols);
    }

    updateLayoutElements=()=>{//применяю размер поля элементов
        if(this.state.width !== this.ref.current.clientWidth - 25) this.setState({width: this.ref.current.clientWidth - 20 });
    }
    componentDidMount(){
        window.addEventListener('resize', this.updateLayoutElements);
        this.updateLayoutElements();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLayoutElements);
    }

    render() {
        const { fields, formValues, classes  } =  this.props;
        const  { selectedIndex } = this.state;
        return (
            <div style={{ display: 'flex' }}>
                {/* <CardElement 
                    width={65}
                    ref={this.ref}
                    content={
                        <CardComponent
                            width = {this.state.width}
                            elements={formValues.cols} 
                            // formValues={formValues}
                            fields={fields}
                            selectedIndex={selectedIndex}
                            addItem={this.addItem}
                            selectCol={this.selectCol}
                            removeEvent={this.removeItem}
                            updateItems={this.updateItems}
                            onDragStart={this.onDragStart} //выбираем элемент по этому событию
                        />
                    }
                /> */}
                <Card className={classes.list} ref={this.ref} >
                    <CardContent className={classes.cardContent}>
                        <CardComponent
                            width = {this.state.width}
                            elements={formValues.cols} 
                            // formValues={formValues}
                            fields={fields}
                            selectedIndex={selectedIndex}
                            addItem={this.addItem}
                            selectCol={this.selectCol}
                            removeEvent={this.removeItem}
                            updateItems={this.updateItems}
                            onDragStart={this.onDragStart} //выбираем элемент по этому событию
                        />
                    </CardContent>
                </Card>
                {formValues.cols.map((el, i) => (
                    <Fragment key={i}>
                        {selectedIndex===i&&
                             <CardElement 
                                width={35}
                                title='Свойство поля'
                                content={
                                    <ColSettings key={i} path="cols" items={formValues.cols} item={el} element={el} selectedIndex={selectedIndex} fields={fields} />
                                }
                            />
                        }
                    </Fragment>
                ))}
                {/* <Card className={classes.edit}>
                    <CardHeader classes={{ title: classes.cardHeaderTitile }} title='Свойство поля' />
                    <CardContent className={classes.cardContent}>
                        {(!isLoading&&!!formValues&!!fields)&&formValues.cols.map((el, i) => {
                            if(selectedIndex===i) return <ColSettings key={i} path="cols" items={formValues.cols} item={el} element={el} selectedIndex={selectedIndex} fields={fields} />
                            return '';
                        })} 
                    </CardContent>
                </Card> */}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues('card')(state);
    const { fetch } = state;
    return { 
        formValues,
        isLoading: !!fetch.loading
    }
};

export default  compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'card',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
    withStyles(styles),
)(CardEdit);