import React, { useState } from 'react';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';

import { SortableList, CardElement, TableElement, Modal, AddButton, TextInput } from '../../_Components';

const PhoneForm = () => (
    <div>
        <Field
            name="name"
            required={true}
            component={TextInput}
            label={`Имя`}
            style={{width: 200}}
        />
        <Field
            name='phone'
            required={true}
            component={TextInput}
            label={`Телефон`}
            style={{width: 250}}
        />
    </div>
);

const DepartmentForm = () => (
        <Field
            name="name"
            required={true}
            component={TextInput}
            label={`Имя`}
            style={{width: 200}}
        />
)

const NewPhoneBook = ({formValues, change}) => {
    const [data, setData] = useState(formValues.transfer ? formValues.transfer : {departments: []});
    const [rows, setRows] = useState(data.departments[0] ? data.departments[0].phonebook : null)
    const [activeNodekey, setActiveNodekey] = useState(data.departments[0] ? 0 : null);
    const [newDepartment, setNewDepartment] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [phoneForm, setPhoneForm] = useState(null);
    const [editPhoneKey, setEditPhoneKey] = useState(null);
    const [departmentForm, setDepartmentForm] = useState(null);
    const [editDepartmentKey, setEditDepartmentKey] = useState(null);

    const changeDepartment = (key) => {
        setActiveNodekey(key);
        setRows(data.departments[key].phonebook)
    }

    const editDeartment = (form, key) => {
        setEditDepartmentKey(key);
        setDepartmentForm(form);
        setNewDepartment(!newDepartment);
    }

    const saveDepartment = (form) => {
        const newData = data;
        if(editDepartmentKey){
            newData.departments[editDepartmentKey].name = form.name;
            setActiveNodekey(editDepartmentKey);
            setData(newData);
            setRows(newData.departments[editDepartmentKey].phonebook);
        }else{
            newData.departments.push({phonebook: [], ...form});
            setActiveNodekey(newData.departments.length-1);
            setData(newData);
            setRows(newData.departments[newData.departments.length-1].phonebook);
        }
        change('transfer', newData);
        editDeartment();
        return;
    }

    const editPhone = (form, key) => {
        setEditPhoneKey(key);
        setPhoneForm(form);
        setOpenEdit(!openEdit);
    }

    const savePhone = (form) => {
        const newData = data;
        if(editPhoneKey){
            newData.departments[activeNodekey].phonebook[editPhoneKey] = form;
        }else{
            newData.departments[activeNodekey].phonebook.push(form);
        }
        setData(newData);
        setRows(newData.departments[activeNodekey].phonebook);
        change('transfer', newData);
        editPhone();
    }
    
    const deletePhone = (key) => {
        const newData = data;
        const newArray = [];
        newData.departments[activeNodekey].phonebook.map( (p,i) => {
            if(i!==key) newArray.push(p);
            return p;
        });
        newData.departments[activeNodekey].phonebook = newArray;
        setData(newData);
        setRows(newArray);
        change('transfer', newData);
    }

    const itemsFields = [
        {
            value: 'name'
        }
    ];

    const header = [
        {
            label: 'Имя',
            width: 65,
            value: 'name'
        },
        {
            label: 'Телефон',
            width: 20,
            value: 'phone'
        }
    ];

    return(
        <div style={{ height: 'calc(100vh - 465px)', display: 'flex' }}>
            <CardElement
                width={35}
                action={<AddButton handleAddClick={editDeartment} label={'Новый отдел'}/>}
                content={
                    <SortableList 
                        items={data.departments}
                        itemsFields={itemsFields}
                        useDragHandle={true} 
                        setActiveNode={changeDepartment} 
                        activeNodekey={activeNodekey}
                        sortable={false}
                    />
                }

            />
            {data.departments[activeNodekey] 
                ?
                <CardElement 
                    width={65}
                    title={data.departments[activeNodekey].name}
                    action={<AddButton handleAddClick={editPhone}/>}
                    content={
                        <TableElement 
                            header={header} 
                            rows={rows} 
                            edit={true}
                            editFunc={editPhone}
                            remove={true}
                            removeFunc={deletePhone}
                        />
                    }
                />
                :
                <CardElement width={65} />
            }
            {newDepartment&&
                <Modal 
                    // width='lg'
                    title={`${departmentForm ? 'Редактировать отдел' : 'Создать отдел'}`}
                    open={newDepartment} 
                    handleSaveClick={saveDepartment} 
                    handleCloseClick={editDeartment} 
                    fields={<DepartmentForm />} 
                    initial={departmentForm}
                />
            }
            {openEdit&&
                <Modal 
                    // width='lg'
                    title={`${phoneForm ? 'Редактировать телефон' : 'Добавить телефон'}`}
                    open={openEdit} 
                    handleSaveClick={savePhone} 
                    handleCloseClick={editPhone} 
                    fields={<PhoneForm />}
                    initial={phoneForm}
                />
            }
        </div>
    )
   
}


const mapStateToProps = (state, props) => {
    const formValues = getFormValues('card')(state);
    const { fetch } = state;
    return { 
        formValues,
        isLoading: !!fetch.loading
    }
};

export default  compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'card',
        destroyOnUnmount: false, // <------ preserve form data
        forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    }),
)(NewPhoneBook);