import React, {Component, Fragment} from 'react';
import Drawer from '@mui/material/Drawer';
import Skeleton from '@mui/material/Skeleton';

import {
    GET_LIST,
    UPDATE,
    provider,
} from '../_utils';

import moment from 'moment';
import _ from 'lodash';

import Timeline from './components/timelineDay';
import { customGroupRenderer, customTitleRenderer } from './components/customRenderers';
import Filter from './components/ShowFilter';

import './styleDay.css';
import Utils from './utils';

import EditSchedule from './components/EditSchedule';

import 'moment/locale/ru';
import { CardElement } from '../_Components';
moment.locale('ru');

class ScheduleTimeline extends Component {
    constructor(props) {
        super(props);
        const { match } = props
        const startDate = moment(match.params.date).startOf('day').add(7,'hours');
        const endDate = moment(match.params.date).endOf('day');
        const nextDate = startDate.clone().add(1, 'day').format('YYYY-MM-DD');
        const prevDate = startDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
        let numDay = moment(startDate).day();
        numDay = numDay === 0 ? 7 : numDay;
        this.state = {
            selectedItems: [],
            snap: 60,
            editDialog: false,
            createDialog: false,
            startDate,
            endDate,
            numDay,
            nextDate,
            prevDate,
            message: '',
            groups: [],
            items: [],
            online: [],
            layers: [],
            statistics: []
        };
        this.reRender = this.reRender.bind(this);
        this.fetchSchedule = this.fetchSchedule.bind(this);
        this.fetchOperators = this.fetchOperators.bind(this);
        this.addPeriod = this.addPeriod.bind(this);
        this.subtractPeriod = this.subtractPeriod.bind(this);
        this.fetchStatistics = this.fetchStatistics.bind(this);
    }

    async componentDidMount() {
        const { startDate, endDate } = this.state;
        const operators = await this.fetchOperators();
        const projects = await this.fetchCampaigns();
        let online = [];
        // if(this.props.match.params.date===moment().format('YYYY-MM-DD')){
        //     online = await this.fetchOnline();
        // }
        const schedule  = await this.fetchSchedule(startDate, endDate);
        const nextDate = startDate.clone().add(1, 'day').format('YYYY-MM-DD');
        const curDate = startDate.clone().format('YYYY-MM-DD');
        const prevDate = startDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
        this.setState({ 
            schedule,
            operators, 
            projects,
            online,
            curDate,
            nextDate,
            prevDate,
            loading: false 
        }, () => {
            this.reRender(schedule);
        });

    }

    async fetchOperators() {
        this.setState({loading: true});
        const { data } = await provider(
            GET_LIST,
            '/operators',
            {
                filter: { status: 'Active', type: {$in: ["operator", "leader", "senior", "test"]}},
                sort: { field: 'login', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
            }
        );
        const operators = [];
        data.map( e => {
            return operators.push({ 
                name: `${e.login} ${e.fullname}`,
                id: e._id
            })
        })
        return operators;
    }

    async fetchOnline() {
        const { data } = await provider(
            GET_LIST,
            '/infra/agent/online',
            {
                filter: {},
                sort: {},
                pagination: { page: 1, perPage: 1000 },
            }
        );
        data.map( d => d.label = `${d.login} ${d.fullname}`)
        return data;
    }

    async fetchSchedule(startDate, endDate) {
        this.setState({loading: true});
        // await this.fetchStatistics(startDate);
        const { data } = await provider(
            GET_LIST,
            '/schedule',
            {
                filter: { startDate: startDate.clone().startOf('day'), endDate: endDate },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
            }
        );
        return data;
    }

    async fetchStatistics(startDate) {
        this.setState({loading: true});
        const { data } = await provider(
            GET_LIST,
            '/infra/statistics/agents',
            {
                filter: { startDate: startDate.clone().startOf('day'), endDate: startDate.clone().startOf('day'), "interval":"agent" },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
            }
        );
        this.setState({statistics: data});
    }

    async fetchCampaigns() {
        this.setState({loading: true});
        const { data } = await provider(
            GET_LIST,
            '/campaigns',
            {
                filter: { status: 'Active' },
                sort: { field: 'name', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
            }
        );
        return data;
    }

    reRender(data) {
        const { operators, projects, numDay, online, statistics } = this.state;
        const list = [];
        // const layers = [];
        const groups = [];
        // const colors = {
        //     success: '#ddedca',
        //     warning: '#fbf6be',
        //     danger: '#ff9882'
        // }
        data.sort( (a,b) => a.schedules[0].start-b.schedules[0].start);
        let g = 0;
        this.key= 0;
        for (let j = 0; j < data.length; j++) {
            const {color, text, content, operator, start, end} = Utils.itemContent(data[j]);
            let row = groups.find( g => g.operator === operator);
            if(!row){
                let operatorData = operators.find( o => o.id === operator);
                if(operatorData){
                    let onlineOperator = online.find( o => o.id === operatorData.login);
                    let statisticOperator = statistics.find( s => s.id === operatorData.login);
                    row = {
                        id: g, 
                        operator: operator, 
                        type: 'schedule',
                        title: `${g} ${operatorData.login} ${operatorData.fullname}`, 
                        online: !!onlineOperator,
                        stayOut: !statisticOperator && start<moment() && !(data[j].change || data[j].change==='change'),
                        data: operatorData,
                    };
                    // if(statisticOperator){
                        // const shiftStart = statisticOperator ? moment(statisticOperator.start, "DD.MM.YYYY HH:mm:ss") : start;
                        // const shiftEnd = statisticOperator ? moment(statisticOperator.start, "DD.MM.YYYY HH:mm:ss").add( statisticOperator.workTime, 'second') : end;
                        // let colorLayer = 'none';
                        // if(shiftStart<=start){
                        //     colorLayer = colors.success;
                        // }else if(shiftStart<=start.clone().add(3,'minutes')){
                        //     colorLayer = colors.warning;
                        // }else{
                        //     colorLayer = colors.danger;
                        // }
                        // layers.push({
                        //     rowNumber: g,
                        //     start: shiftStart,
                        //     end: shiftEnd,
                        //     style: {backgroundColor: colorLayer, border: 'none'}
                        // });
                    // }
                    groups.push(row);
                    g++;
                }
            }
            if(row){
                this.key += 1;
                list.push({
                    key: this.key,
                    type: 'schedule',
                    id: data[j]._id,
                    operator: operator,
                    title: content,
                    color,
                    text,
                    row: row.id,
                    start: start,
                    end: end,
                    status: data[j].change,
                    data: data[j]
                });
            }else{
                continue;
            }
        }

        var projectsGroup = {};
        for (var i = 0; i < projects.length; i++) {
            if(!projectsGroup[projects[i].type]){
                projectsGroup[projects[i].type] = [];
            };
            if(projects[i].schedule[numDay]){
                let el = {
                    selected: false,
                    schedule: projects[i].schedule[numDay],
                    key: projects[i]._id,
                    label: projects[i].name,
                    operators: projects[i].operators,
                    data: projects[i]
                }
                projectsGroup[projects[i].type].push(el);
            }
        };
        for( let key in projectsGroup){
            if(key){
                projectsGroup[key].sort(function(x,y){
                    return (x.schedule.start - y.schedule.start);
                });
            }
        }
        // this.forceUpdate();
        this.setState({items: list, groups, campaigns: projectsGroup.Inbound});
    }

    handleRowOperatorClick = (e, key) => {
        let newCampaigns = _.clone(this.state.campaigns);
        let newGroups = _.clone(this.state.groups);
        for (let i = 0; i < newCampaigns.length; i++) {
            newCampaigns[i].selected = false;
        }
        for (let j = 0; j < newGroups.length; j++) {
            newGroups[j].selected = false;
        }
        let operator = newGroups.find( e => e.operator === key);
        operator.selected = true;
        for (let i = 0; i < newCampaigns.length; i++) {
            let campaign = newCampaigns[i];
            if(campaign.operators.indexOf(key)>=0){
                campaign.selected = true;
            }
        }
        this.setState({campaigns: newCampaigns, groups: newGroups});
    }

    handleRowCampaignClick = (e, key) => {
        let newCampaigns = _.clone(this.state.campaigns);
        let newGroups = _.clone(this.state.groups);
        for (let i = 0; i < newCampaigns.length; i++) {
            newCampaigns[i].selected = false;
        }
        for (let j = 0; j < newGroups.length; j++) {
            newGroups[j].selected = false;
        }
        let campaign = newCampaigns.find( e => e.key === key);
        if(campaign){
            campaign.selected = true;
            for (let i = 0; i < newGroups.length; i++) {
                let operator = newGroups[i];
                if(campaign.operators.indexOf(operator.operator)>=0){
                    operator.selected = true;
                }
            }
        }
        this.setState({campaigns: newCampaigns, groups: newGroups});
    };

    async addPeriod() {
        let startDate = this.state.startDate.clone().add(1,'day');
        let endDate = this.state.endDate.clone().add(1,'day');
        let numDay = moment(startDate).day();
        numDay = numDay === 0 ? 7 : numDay;
        this.props.match.params.id = startDate.format("YYYY-MM-DD");
        const schedule  = await this.fetchSchedule(startDate, endDate);
        this.setState({ 
            schedule,
            startDate, 
            endDate, 
            numDay,
            loading: false 
        }, () => {
            this.reRender(schedule);
        });
    }

    async subtractPeriod() {
        let startDate = this.state.startDate.clone().subtract(1, 'day');
        let endDate = this.state.endDate.clone().subtract(1, 'day');
        let numDay = moment(startDate).day();
        numDay = numDay === 0 ? 7 : numDay;
        const schedule  = await this.fetchSchedule(startDate, endDate);
        this.setState({ 
            schedule,
            startDate, 
            endDate, 
            numDay,
            loading: false 
        }, () => {
            this.reRender(schedule);
        });
    }

    handleItemContextClick = (e, key) => {
        const {selectedItems} = this.state;
        if(selectedItems.length>0){
            const data = this.state.items.find( i => i.key === selectedItems[0]);
            if(data){
                const newData = _.clone(data.data);
                newData.schedules[0].start = data.start.diff(moment(data.start).clone().startOf('day'), 'minutes');
                newData.schedules[0].end = data.end.diff(moment(data.end).clone().startOf('day'), 'minutes');
                if(!newData.change){
                    newData.change = 'change';
                    newData.description = 'Изменения графика';
                }
                provider(UPDATE, '/schedule', { id: newData._id, data: newData })
                    .then(({ data }) => {
                        data.data = newData;
                        this.setState({ loading: false})
                    })
            }
        }
        let newSelection = selectedItems.slice();

        // If the item is already selected, then unselected
        const idx = selectedItems.indexOf(key);
        if (idx > -1) {
          // Splice modifies in place and returns removed elements
          newSelection.splice(idx, 1);
        } else {
          newSelection = [Number(key)];
        }

        this.setState({selectedItems: newSelection});
    }

    handleItemClick = (e, key) => {
        const {selectedItems} = this.state;
        if(selectedItems.length>0){
            const data = this.state.items.find( i => i.key === selectedItems[0]);
            if(data){
                const newData = _.clone(data.data);
                newData.schedules[0].start = data.start.diff(moment(data.start).clone().startOf('day'), 'minutes');
                newData.schedules[0].end = data.end.diff(moment(data.end).clone().startOf('day'), 'minutes');
                if(!newData.change){
                    newData.change = 'change';
                    newData.description = 'Изменения графика';
                }
                provider(UPDATE, '/schedule', { id: newData._id, data: newData })
                    .then(({ data }) => {
                        data.data = newData;
                        this.setState({ loading: false})
                    })
            }
            let newSelection = selectedItems.slice();
    
            // If the item is already selected, then unselected
            const idx = selectedItems.indexOf(key);
            if (idx > -1) {
              // Splice modifies in place and returns removed elements
              newSelection.splice(idx, 1);
            } else {
              newSelection = [Number(key)];
            }
    
            this.setState({selectedItems: newSelection});
        }
    }

    handleItemDoubleClick = (e, key) => {
        const { operators, items } = this.state;
        const item = items.find( e => e.key === key);
        const operator = operators.find( e => e.id === item.data.operator);
        this.setState({selectedOperator: operator.id, selectedDate: null, selectedItem: item, editDialog: true});

    }

    createClick = () => {
        this.setState({selectedOperator: null, selectedDate: this.state.startDate, selectedItem: null, editDialog: true});
    }

    handleItemHover = (e, rowNumber) => {
    }

    handleRowDoubleClick = (e, rowNumber, clickedTime, snappedClickedTime) => {
        // const day = snappedClickedTime.clone().subtract(9, 'hours').subtract(1, 'day').startOf('day').subtract(3, 'hours');
        // const operator = this.state.groups[rowNumber].data;
        // const newData = {
        //     date: day.format("YYYY-MM-DD HH:mm:ss"),
        //     type: operator.schedule ? (operator.schedule.type || null) : null,
        //     department: operator.department || null,
        //     place: operator.place || null,
        //     change: null,
        //     description: null,
        //     schedules: operator.schedule ? [{date: day.clone().add(3, 'hours').format("YYYY-MM-DD"), start: operator.schedule.start || 540, end: operator.schedule.start || 1080 }] : [{date: day.format("YYYY-MM-DD"), start: 540, end: 1080 }]
        // }
        // this.setState({selectedOperator: operator});
        // this.setState({selectedItem: {row: rowNumber, data: newData }});
        // this.setState({editDialog: true});
    }
    
    handleSave = (item) => {
        const { schedule } = this.state;
        const newData = _.clone(schedule);
        const index = newData.findIndex( i => i._id === item._id);
        if(index>=0){
            newData[index] = item;
        }else{
            newData.push(item);
        }
        this.reRender(newData);
        this.setState({editDialog: false, selectedOperator: undefined, selectedItem: undefined});
    };

    handleDelete = (item) => {
        const { schedule } = this.state;
        const newData = schedule.filter( e => e._id!==item);
        this.reRender(newData);
        this.setState({ 
            schedule: newData, 
            editDialog: false, 
            selectedOperator: undefined, 
            selectedItem: undefined 
        });
    }

    handleInteraction = (type, changes, items) => {
        function absorbChange(itemList, selectedItems) {
          itemList.forEach(item => {
            let i = selectedItems.find(i => {
              return i.key === item.key;
            });
            if (i) {
              item = i;
              let newData = _.clone(item.data);
              const {isStartTimeChange, timeDelta }  = changes;
              if(isStartTimeChange!== undefined){
                if(isStartTimeChange){
                    newData.schedules[0].start = newData.schedules[0].start+timeDelta;
                }else{
                    newData.schedules[0].end = newData.schedules[0].end+timeDelta;
                }
              }else{
                newData.schedules[0].start = newData.schedules[0].start+timeDelta;
                newData.schedules[0].end = newData.schedules[0].end+timeDelta;
              }
              const { content } = Utils.itemContent(newData);
              item.title = content;
            }
          });
        }

        switch (type) {
          case Timeline.changeTypes.dragStart: {
            return this.state.selectedItems;
          }
          case Timeline.changeTypes.dragEnd: {
            const newItems = _.clone(this.state.items);
            absorbChange(newItems, items);
            this.setState({items: newItems});
            break;
          }
          case Timeline.changeTypes.resizeStart: {
            return this.state.selectedItems;
          }
          case Timeline.changeTypes.resizeEnd: {
            const newItems = _.clone(this.state.items);

            // Fold the changes into the item list
            absorbChange(newItems, items);

            this.setState({items: newItems});
            break;
          }
          case Timeline.changeTypes.itemsSelected: {
            this.setState({selectedItems: _.map(changes, 'key')});
            break;
          }
          default:
            return changes;
        }
    };

    render() {
        const {
            selectedItems,
            selectedItem,
            startDate,
            endDate,
            items,
            groups,
            layers,
            curDate,
            nextDate,
            prevDate,
            campaigns,
            editDialog,
            selectedOperator,
            operators,
        } = this.state;
        return (
            <div>
                <CardElement 
                    action={
                        <Filter 
                            nextDate={nextDate} 
                            prevDate={prevDate}
                            curDate={curDate}
                            subtractPeriod={this.subtractPeriod}
                            addPeriod={this.addPeriod}
                            create={this.createClick}
                        />
                    }
                    content={
                        <Fragment>
                            {(groups.length>0&&items.length>0) ?
                                <Timeline
                                    items={items}
                                    groups={groups}
                                    campaigns={campaigns}
                                    startDate={startDate}
                                    endDate={endDate}
                                    rowLayers={layers}
                                    selectedItems={selectedItems}
                                    timelineMode={7}
                                    snapMinutes={15}
                                    layersInRow={layers}
                                    onItemClick={this.handleItemClick}
                                    onItemDoubleClick={this.handleItemDoubleClick}
                                    onItemHover={this.handleItemHover}
                                    onItemContextClick={this.handleItemContextClick}
                                    onRowOperatorClick={this.handleRowOperatorClick}
                                    onRowCampaignClick={this.handleRowCampaignClick}
                                    onRowDoubleClick={this.handleRowDoubleClick}
                                    onInteraction={this.handleInteraction}
                                    showCursorTime={true}
                                    // itemRenderer={customItemRenderer}
                                    groupRenderer={customGroupRenderer}
                                    groupTitleRenderer={customTitleRenderer}
                                />
                            :
                                <div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                    <div style={{padding: '0px 0px 0px 20px', width: '95%'}}>
                                        <Skeleton variant="text" height={48} animation="wave"/>
                                    </div>
                                </div>
                            }
                            <Drawer
                                open={editDialog}
                                anchor="right"
                                onClose={this.handleClose}
                            >   
                                <EditSchedule
                                    item={selectedItem}
                                    operators={operators}
                                    initialValues={{operator: selectedOperator, date: startDate}}
                                    onCancel={this.handleClose}
                                    onSave={this.handleSave}
                                    onDelete={this.handleDelete}
                                />
                            </Drawer>
                        </Fragment>
                    }
                />
            </div>
        );
    }

    handleClose = () => {
        this.setState({editDialog: false, selectedOperator: undefined, selectedItem: undefined});
    };
}

export default ScheduleTimeline;

