import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimerIcon from '@mui/icons-material/Timer';

import { Labeled } from '../../../_Components';
import TimeField from './Time';

const TalkInbound = ({source, record ={}, ...props}) =>(
    <div className={props.className}>
        {record ?
            <Labeled className={props.className} label={props.label}>
                <span style={{display: 'flex', paddingRight: '15%', color: '#59af50'}}>
                    <PhoneInTalkIcon/>
                    <span style={{marginLeft: 10}}>{record.statistic.inbound}</span>
                    <ScheduleIcon style={{marginLeft: 'auto', color: '#59af50'}}/>
                    <TimeField source={`${source}.inboundTime`} record={record} {...props}/>
                    <TimerIcon style={{marginLeft: 'auto'}}/>
                    <TimeField source={`${source}.inboundAv`} record={record} {...props}/>
                </span>
            </Labeled>
        : 
            <div style={{padding: '0 20px'}}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={36} style={{padding: '0 20px'}}/>
            </div>
        }
    </div>
)

export default (TalkInbound);