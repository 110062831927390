import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import compose from 'recompose/compose';
import moment from 'moment';

import { withStyles } from '@mui/styles';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { DateRangeInput, AutocompleteInput, SwitchInput } from '../../_Components';

const styles = theme => ({
    form: {
        display: 'flex',
    },
});

const  Filter = ({classes, handleSubmit, setCreate, operators, filterValues = {}, ...props }) => {
    const setFilters = (values) => {
        Object.getOwnPropertyNames(values).map( key =>{
            return props.change(key, values[key]);
        });
    }

    const zoomOut = () => {
        let currentMins = new Date(moment(filterValues.interval.endDate).add(1,'day').startOf('day').diff(moment(filterValues.interval.startDate).startOf('day'), 'minutes'));
        const startDate = new Date(moment(filterValues.interval.startDate));
        let forAdd = 0;
        let days = (currentMins/60)/24;
        if(days<=1){
            forAdd = 1440;
        }else if(days<=2){
            forAdd = 4320;
        }else if(days<=4){
            forAdd = 8640;
        }else if(days<=7){
            forAdd = 18720;
        }else if(days <=14){
            forAdd = moment(startDate).clone().add(1,'month').diff(startDate, 'minutes');
        }else if(days<=31){
            return;
        }
        let endDate = moment(startDate).clone().add(forAdd, 'minutes');
        setFilters({interval: {startDate: filterValues.interval.startDate, endDate: new Date (endDate)}, operator: filterValues.operator, empty: filterValues.empty});
    }

    const zoomIn = () => {
        let currentMins = new Date(moment(filterValues.interval.endDate).add(1,'day').startOf('day').diff(moment(filterValues.interval.startDate).startOf('day'), 'minutes'));
        const startDate = new Date(moment(filterValues.interval.startDate));
        let forAdd = 0;
        let days = (currentMins/60)/24;
        if(days<=2){
            forAdd = 0;
        }else if(days<=4){
            forAdd = 1440;
        }else if(days<=7){
            forAdd = 4320;
        }else if(days <=14){
            forAdd = 8640;
        }else if(days<=31){
            forAdd = 18720;
        }
        let endDate = moment(startDate).clone().add(forAdd, 'minutes');
        setFilters({interval: {startDate: filterValues.interval.startDate, endDate: new Date (endDate)}, operator: filterValues.operator, empty: filterValues.empty});
    }

    const addPeriod = () => {
        let startDate = new Date(moment(filterValues.interval.startDate).clone().add(1,'day'));
        let endDate = new Date(moment(filterValues.interval.endDate).clone().add(1,'day'));
        setFilters({interval: {startDate: new Date(startDate), endDate: new Date (endDate)}, operator: filterValues.operator, empty: filterValues.empty});
    }

    const subtractPeriod = () => {
        let startDate = moment(filterValues.interval.startDate).clone().subtract(1, 'day');
        let endDate = moment(filterValues.interval.endDate).clone().subtract(1, 'day');
        setFilters({interval: {startDate: new Date(startDate), endDate: new Date (endDate)}, operator: filterValues.operator, empty: filterValues.empty});
    }

    return (  
        // <Fragment>
            <form onSubmit={handleSubmit} className={classes.form}>
                <Field
                    name="interval"
                    component={DateRangeInput}
                    label={`С`}
                    // minDate={ new Date(moment(filterValues.endDate).subtract(30, 'days'))}
                    // maxDate={filterValues.endDate}
                    className={classes.input}
                />
                <Field
                    name="operator"
                    options={operators}
                    component={AutocompleteInput}
                    label={`Оператор`}
                    className={classes.input}
                    // style={{width: 450}}
                />
                <Field
                    name="empty"
                    component={SwitchInput}
                    label={`Пустые`}
                />
                    <Field
                    name="terminated"
                    component={SwitchInput}
                    label={`Уволеные`}
                />
                <IconButton color="default" onClick={subtractPeriod} aria-label="Влево">
                    <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton color="default" onClick={zoomIn} aria-label="Увеличить"  style={styles.rightFloat} >
                    <ZoomInIcon />
                    </IconButton>
                    <IconButton color="default" onClick={zoomOut} aria-label="Уменьшить"  style={styles.rightFloat} >
                    <ZoomOutIcon />
                    </IconButton>
                    <IconButton color="default" onClick={addPeriod} aria-label="Вправо" style={styles.rightFloat} >
                    <KeyboardArrowRightIcon />
                    </IconButton>
                    <IconButton color="default" onClick={setCreate}  style={styles.rightFloat} aria-label="Календарь">
                    <PersonAddIcon />
                    </IconButton>
            </form>
            // {/* <ListActions setCreate={setCreate} setFilters={this.setFilters} filterValues={filterValues}/> */}
        // </Fragment>
        
    );
}

const mapState = (state, props) => {
    const { form } = state;
    const initialValues = form.shedule_list ? form.shedule_list.initial : props.initialValues;
    const filterValues = getFormValues('schedule_list')(state);
    return { 
        initialValues,
        filterValues,
    }
}

const enhance = compose(
    connect( mapState ),
    reduxForm({
        form: 'schedule_list',
        validate: (values) => {
            const errors = {};
            // if (!values.username) {
            //     errors.username = "Не верный e-mail";
            // }
            // if (!values.password) {
            //     errors.password = "Поле не может быть пустым";
            // }
            return errors;
        },
    }),
    withStyles(styles)
);

export default enhance(Filter);
