import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Card,
  CardHeader,
  Typography
} from '@mui/material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Skeleton from '@mui/material/Skeleton';

// import { Card, CardHeader, Typography } from '@material-ui/core';

import ListToolbar from './ListToolbar';

import { 
  GET_LIST, 
  provider, 
  history, 
} from '../../_utils';



const style = theme =>({
  root: {
    width: '100%',
    overflow: 'auto',
    minHeight:' -webkit-fill-available'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table:{
    minWidth: 750,
    width: `calc(100% - ${theme.spacing(5)}) !important`,
    marginLeft: theme.spacing(2),
  },
  skeleton:{
    backgroundColor: '#bbd9ea',
    height: 35,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  skeletonRow:{
    display: 'flex',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  sortIcon: {
    fontSize: '1rem'
  },
  headerSize: {
    padding: theme.spacing(1),
    fontSize: '0.75rem'
  },
  notFound:{
    textAlign: 'center',
    paddingTop: 100,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  tableCell: {
    fontSize: '0.73rem'
  },
  sizeSmall: {
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
  },
  cardHeaderMain: {
    padding: '0 !important',
    paddingLeft: `${theme.spacing(4)} !important`,
  },
  row: {
    '&:hover': {
      backgroundColor: '#d6eafb'
    }
  }
});

const EnhancedTableHead = (props) => {
    const { classes, order, orderBy, onRequestSort, headCells = [], show, play } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
    const minWidthFunc = (type) => {
      switch (type) {
        case "datetime":
              return '80px'
            case "address":
              return '140px'
            case "time":
              return '80px'
            default:
              return '100px'

      }
    }
    return (
      <TableHead>
        <TableRow>
          <TableCell>
          </TableCell>
          {headCells.map((headCell, index) => (
            <TableCell
              classes={{
                root: classes.tableCell
              }}
              style={{minWidth: minWidthFunc(headCell.type)}}
              key={`${headCell.id}-${index}`}
              align={headCell.numeric ? 'right' : 'left'}
              // padding='default'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.notSort 
                ? 
                  <span><b>{headCell.label}</b></span>
                :
                  <TableSortLabel
                    classes = {{
                      icon: classes.sortIcon
                    }}
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                }
              </TableCell>
          ))}
          {!!show&&
            <TableCell>
            </TableCell>
          }
          {!!play&&
            <TableCell>
            </TableCell>
          }
        </TableRow>
      </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.array.isRequired,
};

const  fetchData = async (props, filterValues, orderBy, order, page, rowsPerPage, setData, setTotal) => {
  const { basePath } = props;
  const pathname = history.location.pathname.split('/');
  let path = pathname[1];
  if(basePath){
    path = basePath;
  }
  const { status, data, total } = await provider(
      GET_LIST,
      `/${path}`,
      {
          filter: { ...filterValues },
          sort: { field: orderBy, order },
          pagination: { page: page+1, perPage: rowsPerPage },
      }
  );
  if(status===200) {
    setData(data);
    setTotal(total);
  }
}

const EnhancedList = (props) => {
  const { 
    classes,
    children, 
    filters, 
    match, 
    filter, 
    isLoading, 
    creat, 
    creatOption,
    customCreate,
    refresh, 
    show,
    play,
    player,
    actions,
    title
  } = props;
  const headers = [];
  if(props.children){
    props.children.map( el => {
      const label = el.props.label || el.props.source;
      const id = el.props.source;
      return headers.push({ id, label, ...el.props })
    });
  }
  const [isMounted, setIsMounted] = useState(false);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState(props.sort.order.toLowerCase());
  const [orderBy, setOrderBy] = useState(props.sort.field);
  const [page, setPage] = useState(props.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(props.perPage || 25);
  const [filterValues, setFilterValues] = useState(props.filterDefaultValues || {});
  const [total, setTotal] = useState(0);
  const [menuItem, setMenuItem] = useState(null);
  

  const clearFilter = () => {
    const { filters } = props;
    if(filters){
      if(filters.props) {
        const { form } = props;
        if(form){
          props.reset(form);
        }
      }
    }
  }

  const setCreate = (type) => {
    const { match } = props;
    let path = `${history.location.pathname}/create`;
    if(type){
      path +='/?type='+type
    }
    if(!!(match&&match.params&&match.params.id)){
      const value = match.params.id;
      path =  history.location.pathname.replace(value, 'create');
    }
    history.push(path);
  }
  const handleMenu = (event) => {
    setMenuItem(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setMenuItem(null);
  };

  const setShow = (params) => {
    const { id } = params;
    const { match, pathToShow, search } = props;
    
    let path = `${history.location.pathname}/${id}${!!pathToShow ? pathToShow : ''}`;
    if(search){
      let searchStr = '?'
      const searchArray = [];
      search.map( param => params[param]&&searchArray.push(`${param}=${params[param]}`));
      if(searchStr.length>0){
        searchStr+=searchArray.join('&');
        path+=searchStr;
      }
    }
    if(!!(match&&match.params&&match.params.id)){
      const value = match.params.id;
      path =  history.location.pathname.replace(value, id);
    }
    history.push(path);
  }

  const setPlay = (obj) => {
    const { dispatch } = props;
    dispatch({type: 'SHOW_PLAYER', payload: { record: obj } });
  }

  const setFilters = (values) => {
    setFilterValues(values)
  }
  
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc? 'asc' : 'desc');
    setOrderBy(property);
    fetchData(props, filterValues, property, isDesc? 'asc' : 'desc', page, rowsPerPage, setData, setTotal);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(props, filterValues, orderBy, order, newPage, rowsPerPage, setData, setTotal);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    fetchData(props, filterValues, orderBy, order, 0, parseInt(event.target.value, 10), setData, setTotal);
  };

  const handleUpdate = () => {
    // setPage(0);
    // let searchAray = [`orderBy=${orderBy}`,`order=${order}`,`page=0`,`rowsPerPage=${rowsPerPage}`];
    // Object.keys(filterValues).map( key => {
    //   let item = filterValues[key];
    //   if(moment.isMoment(item)){
    //     return searchAray.push(`${key}=${item.format("YYYY-MM-DD")}`)
    //   }else{
    //     return searchAray.push(`${key}=${item}`)
    //   }
    // });
    history.push({
      pathname: history.location.pathname,
      // search: `?${searchAray.join("&")}`
    });
    setData([]);
    fetchData(props, filterValues, orderBy, order, page, rowsPerPage, setData, setTotal);
  }

  const controllerProps = {
    setFilters,
    clearFilter,
    setCreate,
    fetchData: handleUpdate
  };

  useEffect( () => {
    if(!isMounted){
      setIsMounted(true);
      fetchData(props, filterValues, orderBy, order, page, rowsPerPage, setData, setTotal);
    }
  }, [isMounted, props, filterValues, orderBy, order, page, rowsPerPage, setData, setTotal]);

  return(
    <Card className={classes.root}>
        <CardHeader
            classes={{ root: classes.cardHeaderMain }}
            title={title}
        />
        {/* <EnhancedTableToolbar {...props}/> */}
        {filters && (
            <ListToolbar
                filters={filters}
                isLoading={isLoading}
                actions={actions}
                {...controllerProps}
                filterValues={filterValues}
                permanentFilter={filter}
                creat={creat}
                customCreate={customCreate}
                menuItem={menuItem}
                handleMenu={handleMenu}
                handleMenuClose={handleMenuClose}
                creatOption={creatOption}
                refresh={refresh}
            />
        )}
        <TableContainer sx={{ maxHeight: `calc(100vh - ${ title ? 262 : 230}px)` }}>
          <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              stickyHeader
              aria-label="enhanced table"
          >
              <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headers}
                  rowCount={data.length}
                  show={show}
                  play={play}
              />
              {(data.length>0 && !isLoading)&&
                <TableBody>
                  {data.map((row, index) =>{ 
                    const id = !!(match &&match.params &&match.params.id !== 'create') ? match.params.id : false;
                    const selected = id === row.id || player.id === row.id;
                    return(
                      <TableRow 
                        key={index}
                        classes={{
                          root: classes.row
                        }}
                        style={{ backgroundColor: selected&&"#fee9be" }}
                      >
                         <TableCell>
                         </TableCell>
                        {children.map( (child, i) =>(
                            <TableCell
                              classes={{
                                root: classes.tableCell,
                                sizeSmall: classes.sizeSmall
                              }}
                              style={{minWidth : child.props.minWidth}}
                              key={`${index}-${i}`}
                            >
                              {React.cloneElement(child, { value: row[child.props.source], row: child.props.row ? row : null, onClick: child.props.onClick ? () => child.props.onClick(row) : null  })}
                            </TableCell>
                        ))}
                        {(!!show&&!isLoading)&&
                            <TableCell classes={{ root: classes.tableCell }} style={{textAlign: 'end'}}>
                              <IconButton onClick={() => setShow(row)} size='small' aria-label="Показать детали">
                                <KeyboardArrowRightIcon size="small"/>
                              </IconButton>
                            </TableCell>
                        }
                        {(!!play&&!isLoading)&&
                            <TableCell style={{textAlign: 'end'}}>
                              <IconButton style={{color: '#0a529f'}} onClick={() => setPlay(row)} size='small' aria-label="Проиграть">
                                <PlayArrowIcon size="small"/>
                              </IconButton>
                            </TableCell>
                        }
                      </TableRow>
                    )
                  }
                  )}
                </TableBody>
              }
              {(isLoading)&&
                <TableBody>
                  {[].map.call('_'.repeat(rowsPerPage), (a,i)=>i).map((row, index) =>{ 
                    return(
                      <TableRow 
                        key={index}
                        classes={{
                          root: classes.row
                        }}
                      >
                        <TableCell>
                        </TableCell>
                        {headers.map( (child, i) => {
                          return (
                            <TableCell
                              classes={{
                                root: classes.tableCell,
                                sizeSmall: classes.sizeSmall
                              }}
                              key={`${index}-${i}`}
                            >
                              <Skeleton
                                style={{
                                  height: 24
                                }}
                                key={`${i}`} 
                                variant="text" 
                                animation='wave'
                                className={classes.skeleton}
                              />
                            </TableCell>)
                        })}
                        {!!show&&
                            <TableCell
                              classes={{
                                root: classes.tableCell
                              }}
                              style={{textAlign: 'end'}}
                            >
                            </TableCell>
                        }
                        {!!play&&
                            <TableCell 
                              style={{textAlign: 'end'}}
                            >
                            </TableCell>
                        }
                      </TableRow>
                    )
                  }
                  )}
                </TableBody>
              }
          </Table>
        </TableContainer>
        {data.length>0&&
          <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
        {(data.length===0&&!isLoading)&&
            <Typography className={classes.notFound}>
              Результатов не найдено
            </Typography>
        }
    </Card>
  )
}

const mapStateToProps = (state, props) =>{
  const { fetch, player } = state;
  return {
      player,
      isLoading: !!fetch.loading
  }
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, { reset }),
)(EnhancedList);