import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import compose from 'recompose/compose';

import { DateRangeInput, Filter, PhoneInput } from '../../_Components';

const ReportFilter = ({filterDefaultValues = {}, formValues = {}, ...props}) => (   
    <Filter filterValues={filterDefaultValues} {...props}>
        {formValues.interval&&
            <DateRangeInput 
                source="interval"
                label={``}
                alwaysOn
                maxDate={new Date()}
            />
        }
        {/* <SelectInput
            source="type"
            alwaysOn
            label={`Тип`}
            options={[{id: 'call', name: 'Звонок'},{id: 'client', name: 'Клиент'}]}
        /> */}
        <PhoneInput 
            source="phone"
            alwaysOn
            label={`Телефон`}
        />
    </Filter>
)


const mapStateToProps = (state) => {
    var recordLiveValues = getFormValues('filterForm')(state);
    return { 
        formValues: recordLiveValues,
    };
};

export default  compose(
    connect(mapStateToProps),
)(ReportFilter);