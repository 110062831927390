import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Route } from 'react-router';

import { TextField, List, StatusField, Drawer } from '../_Components';

import { history } from '../_utils';
import Filter from './Filter';
import ListActions from './components/ListActions';
import Edit from './Edit';

const CabinetsList = ({ formValues, perPage, page }) =>{
    const handleClose = () => {
        history.push('/cabinets');
    }
    return(
        <Fragment>
            <Fragment>
                <Route path="/cabinets/:id">
                    {({ match }) => {
                        const isMatch = !!(
                            match &&
                            match.params
                        );
                        return (
                            <Fragment>
                                 <List
                                    refresh={true}
                                    clear={true}
                                    show={true}
                                    actions={<ListActions />}
                                    filters={<Filter form="cabinetsListForm"/>}
                                    filterDefaultValues={formValues}
                                    perPage={perPage}
                                    page={page}
                                    deletedField='deleted'
                                    sort={{ field: 'time', order: 'ASC' }} 
                                >
                                    <TextField
                                        source="name"
                                        label="Название"
                                    />
                                    <TextField
                                        source="project"
                                        label="Проект"
                                    />
                                    <StatusField
                                        notSort={true}
                                        source="status"
                                        label="Статус"
                                    />
                                    <TextField
                                        source="created"
                                        label="Создал"
                                    />
                                </List>
                                {isMatch ? (
                                    <Drawer
                                        isMatch={isMatch}
                                        handleClose={handleClose}
                                        content={isMatch ? <Edit id={match.params.id} /> : null}
                                    />
                                ) : null}
                                
                            </Fragment>
                        );
                    }}
                </Route>
                {/* {openCreate && (
                    <Modal 
                        width="lg"
                        open={openCreate}
                        title="Создание оператора"
                        handleSaveClick={handleSave} 
                        handleCloseClick={handleClose} 
                        fields={<OperatorForm freeLogins={freeLogins} />}
                    />
                )} */}
            </Fragment>
           
        </Fragment>
    );
} 

const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('cabinetsListForm')(state);
    let pairs = history.location.search.substring(1).split("&");
    let search = null;
    for ( let i in pairs ) {
        if ( pairs[i] === "" ) continue;
        if(!search) search = {};
        const pair = pairs[i].split("=");
        search[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
    let searchValues = null;
    for(let key in search){
        if(["orderBy", "order", "page", "rowsPerPage"].indexOf(key)<0){
            if(!searchValues) searchValues = {};
            searchValues[key] = search[key];
        }
    }
    return {
        perPage: search ? +search.rowsPerPage || 25 : 25,
        page: search ? +search.page || 0 : 0,
        formValues: searchValues || recordLiveValues || { status: 'Active' } ,
    };
};


export default compose(
    connect(mapStateToProps),
)(CabinetsList);
