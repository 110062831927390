import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TextField, InputAdornment } from '@mui/material';

// import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

const PhoneInput = ({input: { ...inputProps }, meta: { touched, error } = {}, className, disabled, label, isLoading}) => {
  const formatPhone = (value) =>{
    return value.replace(/[^0-9]/g,"");
  }

  return(
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      onChange={({ target: { value }}) => inputProps.onChange(formatPhone(value))}
      InputProps={{
        startAdornment: 
                <InputAdornment position="start">
                  <SmartphoneIcon size="small"/>
                </InputAdornment>,
        inputProps:{
          ...inputProps,
        }
      }}
      className={className} 
      disabled={isLoading || disabled}
      label={label}
      fullWidth
      focused
      variant="standard"
    />
  )
}
const mapStateToProps = (state, props) =>{
  const { fetch } = state;
  return {
      isLoading: !!fetch.loading
  }
}

export default compose(
  connect(mapStateToProps),
)(PhoneInput);


