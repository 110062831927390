import React, { Fragment } from 'react';
import { withStyles } from '@mui/styles';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    main: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'row',
    },
    div: {
        color: 'currentColor',
        width: '100%',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        background: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        verticalAlign: 'middle'
    },
    label: {
        position: 'relative',
        top: 6,
        left: 6,
    },
    succes: {
        color: "#4CAF50"
    },
    error: {
        color: "#ec716a"
    }
};

const StatusField = ({ classes, style, ...props}) =>(
    <div className={classes.main} style={style}>
        {props.value==='Active'
            ?
                <Fragment>
                    <div className={classes.succes}>
                        <CheckIcon size="small" />
                    </div>
                    <div className={classes.label} style={{color: "#4CAF50"}}>
                        Активен
                    </div>
                </Fragment>
            :
                <Fragment>
                    <div className={classes.error}>
                        <CloseIcon size="small" />
                    </div>
                    <div className={classes.label} style={{color: "#ec716a"}}>
                        Заблокирован
                    </div>
                </Fragment>
        }
    </div>
)

export default withStyles(style)(StatusField);