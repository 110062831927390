import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import classnames from 'classnames';
import { withStyles, createStyles } from '@mui/styles';

import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import lodashSet from 'lodash/set';
import lodashGet from 'lodash/get';

import FilterFormInput from './FilterFormInput';
// import { changeFilters } from '../../_actions/index'

const styles = theme =>
    createStyles({
        form: {
            // marginTop: '-10px',
            marginBottom: 8,
            paddingTop: 0,
            display: 'flex',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
        },
        body: { display: 'flex', alignItems: 'flex-end' },
        spacer: { width: '1em' },
        icon: {
            color: theme.palette.primary1Color || '#00bcd4',
            paddingBottom: 0,
        },
        clearFix: { clear: 'right' },
    });

export class FilterForm extends Component {
    componentDidMount() {
        this.props.filters.forEach(filter => {
            if (filter.props.alwaysOn && filter.props.defaultValue) {
                throw new Error(
                    'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.'
                );
            }
        });
    }

    getShownFilters() {
        const { filters, initialValues } = this.props;
        return filters.filter(
            filterElement =>{
                return filterElement.props.alwaysOn ||
                // displayedFilters[filterElement.props.source] ||
                typeof lodashGet(initialValues, filterElement.props.source) !==
                    'undefined'
            }
        );
    }

    handleHide = event =>
        this.props.hideFilter(event.currentTarget.dataset.key);

    render() {
        // const { classes = {}, className, resource, ...rest } = this.props;
        const { classes = {}, className, resource } = this.props;
        // const { form } = this.state;
        return (
            <div
                className={classnames(className, classes.form)}
                // {...sanitizeRestProps(rest)}
            >
                {this.getShownFilters().map(filterElement => (
                    <FilterFormInput
                        key={filterElement.props.source}
                        filterElement={filterElement}
                        handleHide={this.handleHide}
                        classes={classes}
                        resource={resource}
                    />
                ))}
                <div className={classes.clearFix} />
            </div>
        );
    }
}

FilterForm.propTypes = {
    resource: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.node).isRequired,
    displayedFilters: PropTypes.object,
    hideFilter: PropTypes.func,
    initialValues: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.string,
};

export const mergeInitialValuesWithDefaultValues = ({
    initialValues,
    filters,
}) =>{
    return({
        initialValues: {
            ...filters
            .filter(
                filterElement =>
                    filterElement.props.alwaysOn &&
                    filterElement.props.defaultValue
            )
            .reduce(
                (acc, filterElement) =>
                    lodashSet(
                        { ...acc },
                        filterElement.props.source,
                        filterElement.props.defaultValue
                    ),
                {}
            ),
            ...initialValues,
        },
    });
} 

const enhance = compose(
    withStyles(styles),
    withProps(mergeInitialValuesWithDefaultValues),
    connect( (state, props) => {
        // var recordLiveValues = getFormValues(props.form)(state);
        return ({
            form: props.form || 'filterForm',
        })
    }),
    reduxForm({
        // form: 'filterForm',
        // enableReinitialize: true,
        // destroyOnUnmount: false, // do not destroy to preserve state across navigation
        onChange: (values, dispatch, props) =>{
            return props && props.setFilters(values);
        }
    })
);

export default enhance(FilterForm);
