import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';

import { GET_ONE, GET_LIST, UPDATE, provider, history } from '../../_utils'; //DELETE, UPDATE, , confirm 
import { TabPanel, CardElement, Actions, SaveButton, BlockButton, CloseButton} from '../../_Components';

import Project from './Project';
import Fields from './Fields';
import Comboboxes from './Comboboxes';
import Cards from './Cards';
import Campaigns from './Campaigns';
import Reports from './Reports';


const ProjectEdit = ({change, dispatch, initialize, initialized, match, formValues, ...props}) => {
    const [operators, setOperators] = useState([]);
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        const  fetchData = async () => {
            const { data } = await provider( 
                    GET_ONE,
                    '/projects', 
                    match.params.id
            );
            initialize(data)
        }
    
        const getQueues = async () => {
            const { data } = await provider(
                GET_LIST,
                '/infra/queues',
                {
                    filter: {},
                    sort: { field: 'name', order: 'ASC' },
                    pagination: { page: 1, perPage: 10000 },
                }
            );
            setQueues(data);
        }

        const getOperators = async () => {
            const { data } = await provider( 
                GET_LIST,
                '/operators',
                {
                    filter: {  },
                    sort: ['time', 'asc'],
                    pagination: { page: 0, perPage: 9999 },
                }
            );
            setOperators(data);
        }
        if(!initialized){
            fetchData();
            getQueues();
            getOperators();
        }
    }, [match, initialize, initialized]);


    const save = async () => {
        provider( UPDATE, '/projects/', { id: formValues._id, data: formValues })
    }

    const disableProject = async () => {
        formValues.status = formValues.status==='Disabled' ? 'Active' : 'Disabled';
        change('status', formValues.status)
        await provider(
            UPDATE,
            '/projects',
            {  
                id: match.params.id,
                data: formValues
            }
        );
    }

    const close = () =>{
        return history.goBack();
    }

    const panels = [
        {
            label: 'Проект',
            element: <Project />
        },
        {
            label: 'Поля',
            element: <Fields />
        },
        {
            label: 'Справочники',
            element: <Comboboxes />
        },
        {   
            label: 'Карточки',
            element: <Cards />
        },
        {
            label: 'Компании',
            element: <Campaigns operators={operators} />
        },
        {
            label: 'Отчеты',
            element: <Reports queues={queues}/>
        }
    ];

    const actions =[
        <SaveButton handleSaveClick={save} />,
        <BlockButton handleBlockClick={disableProject} status={formValues ? formValues.status : 'Disabled'}/>,
        <CloseButton handleCloseClick={close} />
    ]

    return (
        <CardElement
            title={formValues&&formValues.name}
            action={<Actions actions={actions}/>}
            content={<TabPanel panels={panels} />}
        />
    )
}


const mapStateToProps = (state, props) => {
    const formValues = getFormValues('project')(state);
    return { 
        formValues
    }
};

const enhance = compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'project',
    })
);

export default enhance(ProjectEdit);


