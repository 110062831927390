import React, { Component, Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';

import { CardElement, CloseButton, SaveButton } from '../../_Components';

import { getFormValues } from 'redux-form';
// import { submit } from 'redux-form';

import {
    AgentsCard
} from './fields';

import {
    GET_ONE,
    GET_LIST,
    provider,
} from '../../_utils';



export const styles = {
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    login: {
        display: 'inline-block',
        width: '15%'
    },
    name: {
        display: 'inline-block',
        width: '50%'
    },
    places: {
        display: 'inline-block',
        width: '15%'
    },
    department: {
        display: 'inline-block',
        width: '15%',
        marginRight: 'unset'
    },
    start: {
        display: 'inline-block',
        width: '25%',
    },
    status: {
        display: 'inline-block',
        width: '70%',
        '& div:first-child':{
            width: '100%'
        }
    },
    occ: {
        display: 'inline-block',
        width: '10%'
    },
    ut: {
        display: 'inline-block',
        width: '10%'
    },
    talktimeAll: {
        display: 'inline-block',
        width: '25%',
        '& div:first-child':{
            width: '100%'
        }
    }, 
    talktimeIn: {
        display: 'inline-block',
        width: '37%',
        '& div:first-child':{
            width: '100%'
        }
    }, 
    talktimeOut: {
        display: 'inline-block',
        width: '38%',
        '& div:first-child':{
            width: '100%'
        }
    },
    schedule: {
        display: 'inline-block',
        width: '30%',
        '& p':{
            display: 'none'
        },
        '& section':{
            display: 'inline-flex',
            '& div.ra-input': {
                marginLeft: 20,
                '& div:first-child': {
                    width: 'unset'
                }
            }
        }
    },
    change: {
        width: '20%',
        display: 'inline-block'
    },
    description: {
        width: '45%',   
        display: 'inline-block',
    },
    projects: {
        display: 'inline-block',
        width: '28%',
        '& div:first-child':{
            width: '100%'
        },
        '& tr':{
            height: 25,
            '& span': {
                fontSize: '0.675rem'
            },
            '& td': {
                padding: '0 0',
            }
        }
    },
    agents: {
        display: 'inline-block',
        marginLeft: '2%',
        '& div:first-child':{
            width: '100%'
        },
    },
    searchInput: {
        margin: 0,
        '& div': { margin: 0 }
    }
    
};



class DialogInbound extends Component {
    state = {
        item: null,
        checked: [],
        left: [],
        right: [], 
    };
    componentDidMount() {
        let queueData= {};
        const queue = this.getQueue();
        queue.then( data => {
            queueData = data;
            this.setState({loading: false, item: data})
            return this.getAgents();
        })
        .then( result => {
            const agents = result.data;
            let right = [];                
            for (var i = 0; i < queueData.data.agents.length; i++) {
                let agent = queueData.data.agents[i];
                const agentData = agents.find( a => a.login === agent);
                if(agentData){
                    right.push(agentData)
                }else{
                    right.push({ login: agent, fullname: ""})
                }
            }
            let left = agents.filter( agent => !right.find( a => a.login===agent.login)&&agent.login).sort( (a,b) => parseInt(a.login.match(/\d+/)) - parseInt(b.login.match(/\d+/)));
            right.sort( (a,b) => parseInt(a.login.match(/\d+/)) - parseInt(b.login.match(/\d+/)));
            this.setState({left, right});
        })
    }

    async getQueue(){
        const { queue } = this.props;
        const { data } = await provider(GET_ONE, '/dashboard/queue', queue.id);
        if(data){
            return data;
        }else{
            return null;
        }
    }

    async getAgents() {
        const agents = await provider(
            GET_LIST,
            '/operators',
            {
                filter: {status: 'Active', },
                sort: { field: 'login', order: 'DESC' },
                pagination: { page: 1, perPage: 1000 },
            }
        );
        return agents;
    }

    handleSaveClick = () => {
        const { submit } = this.props;
        submit('agent');
    }

    not = (a, b) => {
        return a.filter(value => b.login!==value.login);
    }

    handleToggle = value => () => {
        let { left, right } = this.state;
        const leftIndex = left.find( l => l.login===value.login);
        if(leftIndex){
            right = right.concat([value]);
            left = this.not(left, value);
        }else{
            left = left.concat([value]);
            right = this.not(right, value);
        }
        left.sort( (a,b) => parseInt(a.login.match(/\d+/)) - parseInt(b.login.match(/\d+/)));
        right.sort( (a,b) => parseInt(a.login.match(/\d+/)) - parseInt(b.login.match(/\d+/)));
        return this.setState({left, right});
    };

    save = () => {
        const { right } = this.state;
        const { onSave } = this.props;
        onSave(right);
    }

    render() {
        const {classes, onCancel, queue={} } = this.props;
        const { left, right } =this.state;

        if(!queue){
            return <div />
        }
        return (
            <CardElement
                style={{paddingLeft: 16, paddingTop: 64}}
                title={`${queue.name}`}
                action={
                    <Fragment>
                        <SaveButton handleSaveClick={this.save}/>
                        <CloseButton handleCloseClick={onCancel}/>
                    </Fragment>
                }
                content={
                    <AgentsCard left={left} right={right} handleToggle={this.handleToggle} className={classes.queues} /> 
                }
            />
        );
    }
}


const mapStateToProps = (state, props) => {
    var recordLiveValues = getFormValues('inbound')(state);
    return { form: recordLiveValues};
};

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(DialogInbound);

