import React from "react";
import PropTypes from 'prop-types';

import { SaveButton, CloseButton } from './index';

const ModalButtons = ({handleSaveClick, handleCloseClick, ...props }) => {
    return(
        <div>
            <SaveButton handleSaveClick={handleSaveClick} {...props} />
            <CloseButton handleCloseClick={handleCloseClick} {...props} />
        </div>
    )
}

ModalButtons.propTypes = {
    handleSaveClick: PropTypes.func,
    handleCloseClick: PropTypes.func
};

export default ModalButtons;