import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Skeleton } from '@mui/material';

import {GET, GET_ONE, provider, history } from '../../_utils';
import { CardElement, CloseButton, Labeled } from '../../_Components';

import Playlist from './Playlist';

const  convertISO8601ToSeconds = (input) => {
    var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
    var hours = 0, minutes = 0, seconds = 0, totalseconds;
  
    if (reptms.test(input)) {
        var matches = reptms.exec(input);
        if (matches[1]) hours = Number(matches[1]);
        if (matches[2]) minutes = Number(matches[2]);
        if (matches[3]) seconds = Number(matches[3]);
        totalseconds = hours * 3600  + minutes * 60 + seconds;
    }
    return (totalseconds);
  }
  
const formatDate = (input) => {
    const date = input.substring(6, 19);
    return moment(date, 'x').format('YYYY-MM-DD HH:mm:ss')
}

const Call = ({callData}) =>{
    return callData.map( (field, key) => (
        <Labeled key={key} label={field.label}>{field.value}</Labeled>
    ))
}

const CallShow = ({id,callId}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [tracks, setTracks] = useState([]);
    const [callData, setCallData] = useState([]);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await provider(
                GET,
                `/visorList`,
                { id }
            );
            if(data) {
                let tracks = [];
                data.data.map( element =>{
                    const track = {
                        callType: element.callType,
                        destination: element.destination,
                        duration: convertISO8601ToSeconds(element.duration),
                        id: element.id,
                        mediaFileAvailable: element.mediaFileAvailable,
                        originator: element.originator,
                        start: formatDate(element.start),
                        uid: element.uid,
                    }
                    return tracks.push(track);
                })
                tracks.sort((a, b) => moment(a.start).diff(moment(b.start)));
                setIsMounted(true);
                setIsLoading(false);
                setTracks(tracks);
            }
        }

        const getCallData = async () => {
                const {data, status} = await provider(
                    GET_ONE,
                    `/call`,
                    callId
                );
                if(status === 200){
                    setCallData(data);
                }

        }

        if(!isMounted){
            fetchData();
            if(callId){ 
                getCallData();
            }
        }
        
    }, [id, callId, isMounted])

    const close = () => {
        const pathname = history.location.pathname.split('/');
        return history.push(`/${pathname[1]}/${pathname[2]}`)
    };

    return (
        <CardElement 
            action={<CloseButton handleCloseClick={close}/>}
            height={'calc(100vh - 130px)'}
            content={
                <Fragment>
                    <Playlist isLoading={isLoading} tracks={tracks} />
                    <div>
                        <p>Суть обращения</p>
                        {callData ?
                            <Call callData={callData} />
                        :
                            <div>
                                <Skeleton style={{minHeight: 45}}/>
                                <Skeleton style={{minHeight: 45}}/>
                                <Skeleton style={{minHeight: 45}}/>
                                <Skeleton style={{minHeight: 45}}/>
                                <Skeleton style={{minHeight: 45}}/>
                                <Skeleton style={{minHeight: 45}}/>
                                <Skeleton style={{minHeight: 45}}/>
                            </div>
                        }
                    </div>
                </Fragment>
            }
        />
    )
}

export default CallShow;
