import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';

import compose from 'recompose/compose';
// import moment from 'moment';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';

import { 
    TextInput, 
    TimeInput,
    TextField, 
    TimeField,
    Labeled,
    SelectField,
    DateField,
    Row,
    CardElement,
    SaveButton,
    CloseButton
} from '../_Components';

import {GET_ONE, UPDATE, provider, history } from '../_utils';
// import Actions from './components/ActionsEditPaySheet';
import Utils from './components/utils';
const { places, statuses, operatorsTypes, contractTypes, departments } = Utils.options();

const PaySheetHeader = ({data}) => {
    return (
        <Fragment>
                <Row>
                    <Labeled label="Тип">
                        <SelectField options={operatorsTypes} value={data.operator ? data.operator.type : "-"}/>
                    </Labeled>
                    <Labeled label="Статус">
                        <SelectField options={statuses} value={data.operator ? data.operator.status : "-"}/>
                    </Labeled>
                    <Labeled label="Отдел">
                        <SelectField options={departments} value={data.operator ? data.operator.department : "-"}/>
                    </Labeled>
                    <Labeled label="Площадка">
                        <SelectField options={places} value={data.operator ? data.operator.place : "-"}/>
                    </Labeled>
                </Row>
                <Row>
                    <Labeled label="Договор">
                        <SelectField options={contractTypes} value={data.operator&&data.operator.contract ? data.operator.contract.type : "-"}/>
                    </Labeled>
                    <Labeled label="Договор">
                        <TextField value={data.operator&&data.operator.contract ? data.operator.contract.no : "-"}/>
                    </Labeled>
                    <Labeled label="Дата окончания">
                        <DateField value={data.operator&&data.operator.contract ? moment(data.operator.contract.termitated) : "-"}/>
                    </Labeled>
                </Row>
        </Fragment>
    );
}

const parseNum = value => {
    return +value;
}

const PaySheetEdit = ({id, formValues, initialize, initialized, isSaving}) => {
    const [data, setData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            const{ data, status }  = await provider( 
                GET_ONE,
                '/operators/reports', 
                id
            );
            if( status === 200 ){
                setData(data);
                initialize(data);
            }
        }

        if(!initialized){
            fetchData();
        }

    }, [id, initialize, initialized]);

    const onChangeTime = value => {
        formValues.detail.paidTime.time = value;
        formValues.detail.paidTime.paid = value*formValues.detail.paidTime.tarif;
        formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
        if(formValues.summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
            formValues.summa = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
        }
        initialize(formValues);
        setData(formValues);
    }

    const onChangeTalkTime = value => {
        formValues.detail.talkTime.time = value;
        formValues.detail.talkTime.paid = value*formValues.detail.paidTime.tarif;
        formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
        if(formValues.summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
            formValues.summa = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
        }
        initialize(formValues);
        setData(formValues);

    }

    const onChangePremiaPercent = value => {
        const percent = value.target.value;
        formValues.detail.premia.percent = percent;
        formValues.detail.premia.paid = +((+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid))*(percent/100)).toFixed(2);
        const summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid));
        if(summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
            let minPaid = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
            formValues.detail.premia.paid = +(minPaid*(percent/100)).toFixed(2);
            formValues.summa = (minPaid+(+formValues.detail.premia.paid)).toFixed(2);
        }else{
            formValues.detail.premia.paid = +((+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid))*(percent/100)).toFixed(2);
            formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
        }
        initialize(formValues);
        setData(formValues);
    }

    const onChangePremia = value => {
        const premia = +value.target.value;
        formValues.detail.premia.paid = premia.toFixed(2);
        const summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid));
        if(summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
            let minPaid = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
            formValues.detail.premia.percent = (+(premia/minPaid)*100).toFixed(2);
            formValues.summa = (minPaid+(+formValues.detail.premia.paid)).toFixed(2);
        }else{
            formValues.detail.premia.percent = (+(premia/(+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)))*100).toFixed(2);
            formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
        }
        initialize(formValues);
        setData(formValues);
    }

    const save = async () => {
        const { status } = await provider(
            UPDATE,
            '/operators/reports',
            {  
                id: id,
                data: formValues
            }
        );
        if(status===200){
            history.push('/operators/reports')
        }
    }

    return (
        <Fragment>
            <CardElement 
                action={ 
                    <Fragment>
                        <SaveButton handleSaveClick={save} />
                        <CloseButton handleCloseClick={() => history.goBack()} />
                    </Fragment>
                }
                content={
                    <Fragment>
                        <p>{data.operator ? data.operator.login : ''} {data.operator ? data.operator.fullname : ''}</p>
                        <PaySheetHeader data={data}/>
                        {data.statistic&&
                            <Fragment>
                                <Labeled label="Тариф">
                                    <TextField value={data.salary.name}/>
                                </Labeled>
                                <Row>
                                    <Labeled label="Общее время">
                                        <TimeField value={data.statistic.totalTime ? data.statistic.totalTime : 0}/>
                                    </Labeled>
                                    <Field
                                        name={`detail.paidTime.time`}
                                        required={true}
                                        component={TimeInput}
                                        label={`В линии`}
                                        disabled={isSaving}
                                        onChange={(value) => onChangeTime(value)}
                                    />
                                    <Field
                                        name={`detail.talkTime.time`}
                                        required={true}
                                        component={TimeInput}
                                        label={`В разговоре`}
                                        disabled={isSaving}
                                        onChange={(value) => onChangeTalkTime(value)}
                                    />
                                </Row>
                                <Row>
                                    <Labeled label="В работаю">
                                        <TimeField value={data.statistic.work ? data.statistic.work : 0}/>
                                    </Labeled>
                                    <Labeled label="Не готов">
                                        <TimeField value={data.statistic.notReady ? data.statistic.notReady : 0}/>
                                    </Labeled>
                                    <Labeled label="OCC">
                                        <TextField value={data.statistic.occPercent ? `${data.statistic.occPercent.toFixed(2)}%` : `0.00%`}/>
                                    </Labeled>
                                    <Labeled label="Звонков">
                                        <TextField value={data.statistic.calls ? `${data.statistic.calls}` : `0`}/>
                                    </Labeled>
                                </Row>
                            </Fragment>
                        }
                        {data.detail&&
                            <Fragment>
                                <Row>
                                    <Labeled label="В линии">
                                        <TextField value={data.detail.paidTime ? `${data.detail.paidTime.time.toFixed(2)} мин x ${data.detail.paidTime.tarif} = ${data.detail.paidTime.paid.toFixed(2)} р` : `0 р`}/>
                                    </Labeled>
                                    <Labeled label="В разговоре">
                                        <TextField value={data.detail.talkTime ? `${data.detail.talkTime.time.toFixed(2)} мин x ${data.detail.talkTime.tarif} = ${data.detail.talkTime.paid.toFixed(2)} р` : `0 р`}/>
                                    </Labeled>
                                </Row>
                                <Row>
                                    <Field
                                        name="detail.premia.percent"
                                        label={`% премии`}
                                        type="number"
                                        // format={ (value) => formatNum(value)}
                                        parse={ (value) => parseNum(value)}
                                        onChange={(value) => onChangePremiaPercent(value)}
                                        disabled={isSaving}
                                        component={TextInput}
                                        style={{width: 200, paddingRight: 20}}

                                    />
                                    <Field
                                        name="detail.premia.paid"
                                        label={`Премиальные`}
                                        onChange={(value) => onChangePremia(value)}
                                        disabled={isSaving}
                                        component={TextInput}
                                        style={{width: 300, paddingRight: 20}}
                                    />
                                </Row>
                                <Field
                                    name="summa"
                                    label={`ИТОГО`}
                                    disabled={isSaving}
                                    component={TextInput}
                                    style={{width: 300, paddingRight: 20}}
                                />
                            </Fragment>
                        }
                    </Fragment>

                }
            />
       </Fragment>
        
    );
}

// class PaySheetEdit2 extends Component {
//     constructor(props) {
//         super(props);
//         this._isMounted = false;
//         this.state = {
//             data: {}
//         };
//     }

//     componentDidMount(){
//         this._isMounted = true;
//         this.getReport();
//     }

//     componentDidUpdate(){
//         // console.log(this.props);
//     }

//     componentWillUnmount() {
//         this._isMounted = false;
//     }

//     async getReport () {
//         const { match } = this.props;
//         const { data } = await provider( 
//             GET_ONE,
//             '/operators/reports', 
//             match.params.id
//         );
//         if(data) {
//             const { initialize } = this.props;
//             initialize(data);
//             this.setState({
//                 data
//             });
//         }
//     }

//     // async getSalary () {
//     //     const { match } = this.props;
//     //     const { data } = await provider( 
//     //         GET_ONE,
//     //         '/operators/reports/', 
//     //         match.params.id
//     //     );
//     //     if(data) {
//     //         const { initialize } = this.props;
//     //         initialize(data);
//     //         this.setState({
//     //             data
//     //         });
//     //     }
//     // }

//     onChangeTime = value => {
//         const { formValues, initialize } = this.props;
//         formValues.detail.paidTime.time = value;
//         formValues.detail.paidTime.paid = value*formValues.detail.paidTime.tarif;
//         formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
//         if(formValues.summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
//             formValues.summa = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
//         }
//         initialize(formValues);
//         this.setState({
//             data: formValues
//         });
//     }

//     onChangeTalkTime = value => {
//         const { formValues, initialize } = this.props;
//         formValues.detail.talkTime.time = value;
//         formValues.detail.talkTime.paid = value*formValues.detail.paidTime.tarif;
//         formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
//         if(formValues.summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
//             formValues.summa = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
//         }
//         initialize(formValues);
//         this.setState({
//             data: formValues
//         });
//     }

//     onChangePremiaPercent = value => {
//         const { formValues, initialize } = this.props;
//         const percent = value.target.value;
//         formValues.detail.premia.percent = percent;
//         formValues.detail.premia.paid = +((+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid))*(percent/100)).toFixed(2);
//         const summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid));
//         if(summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
//             let minPaid = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
//             formValues.detail.premia.paid = +(minPaid*(percent/100)).toFixed(2);
//             formValues.summa = (minPaid+(+formValues.detail.premia.paid)).toFixed(2);
//         }else{
//             formValues.detail.premia.paid = +((+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid))*(percent/100)).toFixed(2);
//             formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
//         }
//         initialize(formValues);
//         this.setState({
//             data: formValues
//         });
//     }

//     onChangePremia = value => {
//         const { formValues, initialize } = this.props;
//         const premia = +value.target.value;
//         formValues.detail.premia.paid = premia.toFixed(2);
//         const summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid));
//         if(summa/(formValues.detail.paidTime.time/60)<formValues.salary.minHourPaid){
//             let minPaid = +((formValues.detail.paidTime.time/60)*formValues.salary.minHourPaid).toFixed(2);
//             formValues.detail.premia.percent = (+(premia/minPaid)*100).toFixed(2);
//             formValues.summa = (minPaid+(+formValues.detail.premia.paid)).toFixed(2);
//         }else{
//             formValues.detail.premia.percent = (+(premia/(+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)))*100).toFixed(2);
//             formValues.summa = (+formValues.detail.paidTime.paid+(+formValues.detail.talkTime.paid)+(+formValues.detail.premia.paid)).toFixed(2);
//         }
//         initialize(formValues);
//         console.log(formValues.detail.premia);
//         this.setState({
//             data: formValues
//         });
//     }

//     async getStatistics (login, startDate, endDate) {
//         const { data } =  await provider(
//             GET_LIST,
//             `/infra/statistics/agents/${login}`,
//             {
//                 filter: { startDate, endDate },
//                 sort: { field: 'date', order: 'ASC' },
//                 pagination: { page: 1, perPage: 1000 },
//             }
//         );
//         this.setState({
//             statistics: data
//         });
        
//     }

//     render() {
//         const { classes, isSaving } = this.props;
//         const { data } = this.state;
//         return (
//             <Fragment>
//                <Card className={classes.cardStyleFull}>
//                     <CardHeader
//                         classes={{ root: classes.cardHeaderMain }}
//                         action={ <Actions />}
//                         title={`${data.operator ? data.operator.login : ''} ${data.operator ? data.operator.fullname : ''}`}
//                     />
//                     <PaySheetHeader data={data}/>
//                     <CardContent style={{padding: 0, display: 'flex'}}>
//                         {data.statistic&&
//                             <Fragment>
//                                 <Labeled label="Тариф">
//                                     <TextField value={data.salary.name}/>
//                                 </Labeled>
//                                 <Labeled label="Общее время">
//                                     <TimeField value={data.statistic.totalTime ? data.statistic.totalTime : 0}/>
//                                 </Labeled>
//                                 <Field
//                                     className={classes.premia}
//                                     name={`detail.paidTime.time`}
//                                     required={true}
//                                     component={TimeInput}
//                                     label={`В линии`}
//                                     disabled={isSaving}
//                                     onChange={(value) => this.onChangeTime(value)}
//                                 />
//                                 <Field
//                                     className={classes.premia}
//                                     name={`detail.talkTime.time`}
//                                     required={true}
//                                     component={TimeInput}
//                                     label={`В разговоре`}
//                                     disabled={isSaving}
//                                     onChange={(value) => this.onChangeTalkTime(value)}
//                                 />
//                                 <Labeled label="В работаю">
//                                     <TimeField value={data.statistic.work ? data.statistic.work : 0}/>
//                                 </Labeled>
//                                 <Labeled label="Не готов">
//                                     <TimeField value={data.statistic.notReady ? data.statistic.notReady : 0}/>
//                                 </Labeled>
//                                 <Labeled label="OCC">
//                                     <TextField value={data.statistic.occPercent ? `${data.statistic.occPercent.toFixed(2)}%` : `0.00%`}/>
//                                 </Labeled>
//                                 <Labeled label="Звонков">
//                                     <TextField value={data.statistic.calls ? `${data.statistic.calls}` : `0`}/>
//                                 </Labeled>
//                             </Fragment>
//                         }
//                     </CardContent>
//                     <CardContent style={{padding: 0, display: 'flex'}}>
//                         {data.detail&&
//                             <Fragment>
//                                 <Labeled label="В линии" className={classes.flex20}>
//                                     <TextField value={data.detail.paidTime ? `${data.detail.paidTime.time.toFixed(2)} мин x ${data.detail.paidTime.tarif} = ${data.detail.paidTime.paid.toFixed(2)} р` : `0 р`}/>
//                                 </Labeled>
//                                 <Labeled label="В разговоре" className={classes.flex20}>
//                                     <TextField value={data.detail.talkTime ? `${data.detail.talkTime.time.toFixed(2)} мин x ${data.detail.talkTime.tarif} = ${data.detail.talkTime.paid.toFixed(2)} р` : `0 р`}/>
//                                 </Labeled>
                                
//                                 <Field
//                                     className={classes.premia}
//                                     name="detail.premia.percent"
//                                     label={`% премии`}
//                                     type="number"
//                                     format={ (value) => formatNum(value)}
//                                     parse={ (value) => parseNum(value)}
//                                     onChange={(value) => this.onChangePremiaPercent(value)}
//                                     disabled={isSaving}
//                                     component={TextInput}
//                                     style={{width: 200, paddingRight: 20}}

//                                 />
//                                 <Field
//                                     className={classes.premia}
//                                     name="detail.premia.paid"
//                                     label={`Премиальные`}
//                                     onChange={(value) => this.onChangePremia(value)}
//                                     disabled={isSaving}
//                                     component={TextInput}
//                                     style={{width: 300, paddingRight: 20}}
//                                 />
//                                  <Field
//                                     className={classes.premia}
//                                     name="summa"
//                                     label={`ИТОГО`}
//                                     disabled={isSaving}
//                                     component={TextInput}
//                                     style={{width: 300, paddingRight: 20}}
//                                 />
//                             </Fragment>
//                         }
//                     </CardContent>
//                     <CardContent>
//                     {/* <div className={classes.scrolling}>
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
//                                         Дата
//                                     </TableCell>
//                                     <TableCell className={classes.tableHeader} style={{width: '60%', textAlign: 'center'}}>
//                                         Наименование / Примечание / Биоматериал
//                                     </TableCell>
//                                     <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
//                                         Прил.
//                                     </TableCell>
//                                     <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
//                                         Срок
//                                     </TableCell>
//                                     <TableCell className={classes.tableHeader} style={{width: '5%', textAlign: 'center'}}>
//                                         Цена
//                                     </TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             {(statistics.length>0) ?
//                                 <TableBody>
//                                     {statistics.map( (item, index )=>
//                                         <TableRow key={index} >
//                                             <TableCell className={classes.table} style={{textAlign: 'center'}}>
//                                                 <b>{item.Code}</b>
//                                             </TableCell>
//                                             <TableCell className={classes.table}>
//                                                 <b>{item.label || item.Name}</b>
//                                                 {item.description&&
//                                                     <div style={{ color: 'red' }} dangerouslySetInnerHTML={{__html: item.description}} />
//                                                 }
//                                                 {(item.materials&&item.materials.length>0) &&
//                                                     <div>
//                                                         {item.materials.map( (material, i) =>
//                                                                 <b style={{color: '#607D8B', paddingRight: 5}} key={i}>{material}</b>
//                                                         )}
//                                                     </div>
//                                                 }
//                                                 {(item.usedInComplex&&item.usedInComplex.length>0)&&
//                                                     <div><b  style={{color: 'red'}}>Анализ входит в комплекс: {item.usedInComplex.join(', ')}</b></div>
//                                                 }
//                                             </TableCell>
//                                             <TableCell className={classes.table} style={{textAlign: 'center'}}>
//                                                 {item.application}
//                                             </TableCell>
//                                             <TableCell className={classnames(
//                                                                         classes.table,
//                                                                         classes.rightAlignedCell
//                                                                 )}  style={{textAlign: 'center'}}>
//                                                 {item.WorkingDays}
//                                             </TableCell>
//                                             <TableCell
//                                                 className={classnames(
//                                                                     classes.table,
//                                                                     classes.rightAlignedCell,
//                                                             )}
//                                                 style={{textAlign: 'center'}}>
//                                                 {item.Price}
//                                             </TableCell>
//                                         </TableRow>
//                                     )}
//                                 </TableBody>
//                             :
//                                 <TableBody>
//                                     <TableRow>
//                                         <TableCell className={classes.notFound} colSpan={7}>
//                                             Нет анализов для расчета
//                                         </TableCell>
//                                     </TableRow>
//                                 </TableBody>
//                             }
//                         </Table>
//                     </div> */}
//                     </CardContent>
//                 </Card>
//            </Fragment>
            
//         );
//     }
// }

const mapStateToProps = (state, props) => {
    const { fetch } = state;
    const isSaving = fetch ? (fetch.creat>0 ? true : false) : false;
    var recordLiveValues = getFormValues('paySheetForm')(state);
    return { 
        formValues: recordLiveValues,
        isSaving
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'paySheetForm',
        validate: (values) => {
            const errors = {};
            if (!values.code) {
                errors.code = 'Обазятельное поле'
            }
            if (!values.name) {
                errors.name = 'Обазятельное поле'
            }
            if (!values.description) {
                errors.city = 'Обазятельное поле'
            } 
            return errors;
        },
        destroyOnUnmount: true,
    })
)(PaySheetEdit);
