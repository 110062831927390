import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import {compose} from 'recompose';
import { withRouter, Link } from "react-router-dom";
import clsx from 'clsx';

import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@mui/material';

import AppsIcon from '@mui/icons-material/Apps';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import { colorConstants, userActions, permissions } from '../_utils';
import Responsive from './responsive';

const drawerWidth = 240;

const style = (theme) =>({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        paddingTop: 48,
        whiteSpace: 'nowrap',
    },
    drawerDesktop: {
        paddingTop: theme.spacing(7),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(10),
    },
    drawerOpen: {
        paddingTop: 48,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        paddingTop: 48,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
    },
    list:{
        paddingTop: 0,
        paddingBottom: 48
    },
    listIcon:{
        color: `${colorConstants.primary} !important`,
        justifyContent: 'center'
    },
    listItem: {
        flexDirection: 'column',
        color: `${colorConstants.primary} !important` ,
        "&.Mui-selected": {
            backgroundColor: `${colorConstants.primary} !important`,
            '& div':{
                color: `${colorConstants.white} !important`,
                '& p':{
                    color: `${colorConstants.white} !important`
                }
            },
            "&:hover": {
                backgroundColor: "#0b2b60d4 !important",
                '& div':{
                    color: `${colorConstants.white} !important`,
                    '& p':{
                        color: `${colorConstants.white} !important`
                    }
                },
            }
        },
        "&:hover": {
            backgroundColor: "#0b2b60d4 !important",
            '& div':{
                color: `${colorConstants.white} !important`,
                '& p':{
                    color: `${colorConstants.white} !important`
                }
            },
        }
    },
    listItemSmall: {
        color: `${colorConstants.primary} !important` ,
        "&.Mui-selected": {
            backgroundColor: `${colorConstants.primary} !important`,
            '& div':{
                color: `${colorConstants.white} !important`,
                '& p':{
                    color: `${colorConstants.white} !important`
                }
            },
            "&:hover": {
                backgroundColor: "#0b2b60d4 !important",
                '& div':{
                    color: `${colorConstants.white} !important`,
                    '& p':{
                        color: `${colorConstants.white} !important`
                    }
                },
            }
        },
        "&:hover": {
            backgroundColor: "#0b2b60d4 !important",
            '& div':{
                color: `${colorConstants.white} !important`,
                '& p':{
                    color: `${colorConstants.white} !important`
                }
            },
        }
    },
    title: {
        fontSize: '0.61rem !important',
        fontWeight: '600 !important',
        color: `${colorConstants.primary} !important`,
    }
});


export const MenuItems = [
    {
        to: '/',
        title: 'Главная',
        responsive: ['xsmall', 'small', 'medium'],
        icon: <AppsIcon />
    },
    {
        to: '/schedule',
        title: 'Расписание',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <EventNoteIcon />
    },
    {
        to: '/reports',
        title: 'Отчеты',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <AssignmentIcon />
    },
    // {
    //     to: '/calls',
    //     title: 'Звонки',
    //     permissions: ['admin', 'supervisor'],
    //     responsive: ['xsmall', 'small', 'medium'],
    //     icon: <AssignmentIndIcon />
    // },
    {
        to: '/scripts',
        title: 'Скрипты',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <AccountTreeIcon />
    },
    {
        to: '/projects',
        title: 'Проекты',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <BusinessCenterIcon />
    },
    {
        to: '/queues',
        title: 'Очереди',
        permissions: ['admin'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <SwapHorizIcon />
    },
    {
        to: '/visor',
        title: 'Прослушка',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <VolumeUpIcon />
    },
    {
        to: '/statistics',
        title: 'Статистика',
        permissions: ['admin', 'supervisor'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <BarChartIcon />
    },
    {
        to: '/operators',
        title: 'Сотрудники',
        permissions: ['admin'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <RecentActorsIcon />
    },
    {
        to: '/users',
        title: 'Пользователи',
        permissions: ['admin'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <PeopleAltIcon />
    },
    {
        to: '/cabinets',
        title: 'Кабинеты',
        permissions: ['admin'],
        responsive: ['xsmall', 'small', 'medium'],
        icon: <HomeWorkIcon />
    },
    {
        to: '/login',
        title: 'Выход',
        responsive: ['xsmall', 'small'],
        onClick: userActions.logout,
        icon: <ExitToAppIcon />
    },
];

const Menu = ({classes, location, onSelect, open,  ...props}) => {
    // const [open] = useState(false);
    const [pathname, setPathname] = useState([]);
    const permission = permissions();

    useEffect(() => {
        if(location.pathname){
            const pathname = location.pathname.split('/');
            setPathname(pathname);
            const item = MenuItems.find( m => {
                const mainPath = m.to.split('/');
                return pathname[1] === mainPath[1] ? true : false
            })
            document.title = !!item ? `Profcontact | ${item.title}`: 'Profcontact';
        }
    }, [location])

    return (
        <Responsive
            xsmall={
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    open={open}
                >
                    <List>
                        {MenuItems.map((item, index) => {
                            if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
                                const mainPath = item.to.split('/');
                                return (
                                    <ListItem 
                                        button 
                                        key={index} 
                                        component={Link} 
                                        to={item.to} 
                                        onClick={item.onClick || onSelect } 
                                        selected={pathname[1] === mainPath[1] ? true : false}
                                        classes={{
                                            root: classes.listItemSmall,
                                            selected: classes.selected
                                        }}
                                    >
                                        <Tooltip title={item.title} placement="right" arrow>
                                            <ListItemIcon
                                                classes={{
                                                    root: classes.listIcon
                                                }}
                                            >
                                                {item.icon} 
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary={item.title} />
                                    </ListItem>
                                )
                            }else{
                                return null
                            }
                        })}
                    </List>
                </Drawer>
            }
            small={
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    open={open}
                >
                    <List>
                        {MenuItems.map((item, index) =>{
                            if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
                                const mainPath = item.to.split('/');
                                return (
                                    <ListItem 
                                        button 
                                        key={index} 
                                        component={Link} 
                                        to={item.to} 
                                        onClick={item.onClick || onSelect } 
                                        selected={pathname[1] === mainPath[1] ? true : false}
                                        classes={{
                                            root: classes.listItemSmall,
                                            selected: classes.selected
                                        }}
                                    >
                                        <Tooltip title={item.title} placement="right" arrow>
                                            <ListItemIcon
                                                classes={{
                                                    root: classes.listIcon
                                                }}
                                            >
                                                {item.icon} 
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary={item.title} />
                                    </ListItem>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </List>
                </Drawer>
            }
            medium={
                <Drawer
                    variant="permanent"
                    // className={classes.drawerDesktop}
                    classes={{
                        paper: classes.drawerDesktop,
                    }}
                >
                    <List
                        classes={{
                            root: classes.list
                        }}
                    >
                        {MenuItems.map((item, index) =>{
                            if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
                                const mainPath = item.to.split('/');
                                return (
                                    <ListItemButton 
                                        // button 
                                        key={index} 
                                        component={Link}
                                        to={item.to} 
                                        onClick={item.onClick} 
                                        selected={pathname[1] === mainPath[1] ? true : false}
                                        classes={{
                                            root: classes.listItem,
                                            selected: classes.selected
                                        }}
                                    >
                                        <ListItemIcon
                                            classes={{
                                                root: classes.listIcon
                                            }}
                                        >
                                            {item.icon} 
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{
                                                secondary: classes.title
                                            }}
                                            secondary={item.title}
                                        />
                                    </ListItemButton>
                                )
                            }else{
                                return null;
                            }
                        })}
                    </List>
                </Drawer>
            }
        />
    )
}

// class Menu2 extends Component{
//     state = {
//         open: false,
//     }

//     componentDidMount(){
//         const pathname = this.props.location.pathname.split('/');
//         const item = MenuItems.find( m => m.to === `/${pathname[1]}`)
//         document.title = !!item ? item.title : 'Profcontact'
//     }

//     componentDidUpdate(){
//         const pathname = this.props.location.pathname.split('/');
//         const item = MenuItems.find( m => {
//             const mainPath = m.to.split('/');
//             return pathname[1] === mainPath[1] ? true : false
//         })
//         document.title = !!item ? `Profcontact | ${item.title}`: 'Profcontact'
//     }

//     render(){
//         const { classes, location, open, onSelect } = this.props;
//         const permission = permissions();
//         return (
//             <Responsive
//                 xsmall={
//                     <Drawer
//                         variant="permanent"
//                         className={clsx(classes.drawer, {
//                             [classes.drawerOpen]: open,
//                             [classes.drawerClose]: !open,
//                         })}
//                         classes={{
//                             paper: clsx({
//                                 [classes.drawerOpen]: open,
//                                 [classes.drawerClose]: !open,
//                             }),
//                         }}
//                         open={open}
//                     >
//                         <List>
//                             {MenuItems.map((item, index) => {
//                                 if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
//                                     const pathname = location.pathname.split('/');
//                                     const mainPath = item.to.split('/');
//                                     return (
//                                         <ListItem 
//                                             button 
//                                             key={index} 
//                                             component={Link} 
//                                             to={item.to} 
//                                             onClick={item.onClick || onSelect } 
//                                             selected={pathname[1] === mainPath[1] ? true : false}
//                                             classes={{
//                                                 root: classes.listItemSmall,
//                                                 selected: classes.selected
//                                             }}
//                                         >
//                                             <Tooltip title={item.title} placement="right" arrow>
//                                                 <ListItemIcon
//                                                     classes={{
//                                                         root: classes.listIcon
//                                                     }}
//                                                 >
//                                                     {item.icon} 
//                                                 </ListItemIcon>
//                                             </Tooltip>
//                                             <ListItemText primary={item.title} />
//                                         </ListItem>
//                                     )
//                                 }else{
//                                     return null
//                                 }
//                             })}
//                         </List>
//                     </Drawer>
//                 }
//                 small={
//                     <Drawer
//                         variant="permanent"
//                         className={clsx(classes.drawer, {
//                             [classes.drawerOpen]: open,
//                             [classes.drawerClose]: !open,
//                         })}
//                         classes={{
//                             paper: clsx({
//                                 [classes.drawerOpen]: open,
//                                 [classes.drawerClose]: !open,
//                             }),
//                         }}
//                         open={open}
//                     >
//                         <List>
//                             {MenuItems.map((item, index) =>{
//                                 if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
//                                     const pathname = location.pathname.split('/');
//                                     const mainPath = item.to.split('/');
//                                     return (
//                                         <ListItem 
//                                             button 
//                                             key={index} 
//                                             component={Link} 
//                                             to={item.to} 
//                                             onClick={item.onClick || onSelect } 
//                                             selected={pathname[1] === mainPath[1] ? true : false}
//                                             classes={{
//                                                 root: classes.listItemSmall,
//                                                 selected: classes.selected
//                                             }}
//                                         >
//                                             <Tooltip title={item.title} placement="right" arrow>
//                                                 <ListItemIcon
//                                                     classes={{
//                                                         root: classes.listIcon
//                                                     }}
//                                                 >
//                                                     {item.icon} 
//                                                 </ListItemIcon>
//                                             </Tooltip>
//                                             <ListItemText primary={item.title} />
//                                         </ListItem>
//                                     )
//                                 }else{
//                                     return null;
//                                 }
//                             })}
//                         </List>
//                     </Drawer>
//                 }
//                 medium={
//                     <Drawer
//                         variant="permanent"
//                         className={clsx(classes.drawer, classes.drawerDesktop)}
//                         classes={{
//                             paper: classes.drawerDesktop,
//                         }}
//                     >
//                         <List
//                             classes={{
//                                 root: classes.list
//                             }}
//                         >
//                             {MenuItems.map((item, index) =>{
//                                 if(!item.permissions || (item.permissions&&item.permissions.indexOf(permission)>=0)){
//                                     const pathname = location.pathname.split('/');
//                                     const mainPath = item.to.split('/');
//                                     return (
//                                         <ListItem 
//                                             button 
//                                             key={index} 
//                                             component={Link}
//                                             to={item.to} 
//                                             onClick={item.onClick} 
//                                             selected={pathname[1] === mainPath[1] ? true : false}
//                                             classes={{
//                                                 root: classes.listItem,
//                                                 selected: classes.selected
//                                             }}
//                                         >
//                                             <ListItemIcon
//                                                 classes={{
//                                                     root: classes.listIcon
//                                                 }}
//                                             >
//                                                 {item.icon} 
//                                             </ListItemIcon>
//                                             <ListItemText
//                                                 classes={{
//                                                     secondary: classes.title
//                                                 }}
//                                                 secondary={item.title}
//                                             />
//                                         </ListItem>
//                                     )
//                                 }else{
//                                     return null;
//                                 }
//                             })}
//                         </List>
//                     </Drawer>
//                 }
//             />
//         );
//     }
// }

export default compose(
    withStyles(style),
    withRouter,
)(Menu);    