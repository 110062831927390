import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimerIcon from '@mui/icons-material/Timer';

import { Labeled } from '../../../_Components';
import TimeField from './Time';

const TalkOutbound = ({source, record ={}, ...props}) =>(
    <div className={props.className}>
        {record ?
            <Labeled className={props.className} label={props.label}>
                <span style={{display: 'flex', paddingRight: '15%', color: '#55bdd4'}}>
                    <PhoneForwardedIcon/>
                    <span style={{marginLeft: 10}}>{record.statistic.outbound}</span>
                    <ScheduleIcon style={{marginLeft: 'auto'}}/>
                    <TimeField source={`${source}.outboundTime`} record={record} {...props}/>
                    <TimerIcon style={{marginLeft: 'auto'}}/>
                    <TimeField source={`${source}.outboundAv`} record={record} {...props}/>
                </span>
            </Labeled>
        : 
            <div style={{padding: '0 20px'}}>
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={36} style={{padding: '0 20px'}}/>
            </div>
        }
    </div>
)

export default (TalkOutbound);