import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TextField, MenuItem } from '@mui/material';

const SelectInput = ({ dispatch, isLoading,input, meta: { touched, error } = {}, ...props } ) => (
      <TextField
        {...input}
        error={!!(touched && error)}
        helperText={touched && error}
        {...props}
        select
        variant="standard"
        disabled={isLoading}
        focused
      >
          {(props.options)
            ? 
            props.options.map((item, key)=>(
              <MenuItem disabled={item.disabled} key={key} value={item.id ? item.id : item._id}>{item.name}</MenuItem>
            ))
            :
            [].map((item, key)=>(
              <MenuItem disabled={item.disabled} key={key} value={item.id ? item.id : item._id}>{item.name}</MenuItem>
            ))
          }
      </TextField>
)

const mapStateToProps = (state, props) =>{
  const { fetch } = state;
  return {
      isLoading: !!fetch.loading
  }
}

export default compose(
  connect(mapStateToProps),
)(SelectInput);