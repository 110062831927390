import List from './List';
import Show from './Show';
import Edit from './Edit';
import Reports from './Reports';
import PaySheet from './PaySheet';
import PaySheetEdit from './PaySheetEdit';

export default {
    list: List,
    show: Show,
    edit: Edit,
    reports: Reports,
    paysheet: PaySheet,
    paysheetedit: PaySheetEdit
};
